import { Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ChatMessage } from '../../../models/chat_message';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { ChatMessageService } from '../../../services/chat-message.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageFormConfiguration } from './message-form-configuration';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.sass']
})
export class MessageFormComponent  extends AbstractFormComponent implements OnInit, OnDestroy {

  @Input()
  public modelValue: ChatMessage;
  @Input()
  public type: any;
  @Input()
  public chat_id: any;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private chatMessageService: ChatMessageService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = MessageFormConfiguration.structure;
  }

  ngOnInit() {
    this.new();
  }
  ngOnDestroy() {
    for(let i in this.subscribes) {
      let subscribe = this.subscribes[i];
      subscribe.unsubscribe();
    }
  }



  public new(): void {
    this.modelValue = new ChatMessage();
    this.modelValue.chat_id = this.chat_id;
    if (this.type === ChatMessageService.TYPE_EVALUATOR) {
      this.modelValue.dir = ChatMessageService.DIR_EVALUATOR_TO_CANDIDATE;
    } else {
      this.modelValue.dir = ChatMessageService.DIR_CANDIDATE_TO_EVALUATOR;
    }
    this.buildErrorModelAndEmitters();
  }

  public successResponseEdit(res): void {
    this.modelValue = ChatMessage.copy(this.modelValue);
  }

  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }

  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }

  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }

  public getModelService(): any {
    return this.chatMessageService;
  }

  public getValidationService(): ValidationService {
    return this.validationService;
  }

  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }

  setModel(model: any): any {
    this.modelValue = model;
  }

  successResponseStore(res): void {
    this.modelValue = ChatMessage.copy(res);
  }

  public success(): void {
    this.onSuccess.emit({id: this.modelValue.id});
  }
}
