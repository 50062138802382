import {EventEmitter, Output} from '@angular/core';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class CVExperienceTableFilter extends AbstractFilterComponent {
  @Output() searchModelChange: EventEmitter<any>;
  search: string;
  @Output() candidateIdModelChange: EventEmitter<any>;
  candidate_id: any;

  constructor() {
    super();
    this.search = null;
    this.searchModelChange = new EventEmitter();
    this.candidate_id = null;
    this.candidateIdModelChange = new EventEmitter();
  }
  public toFilter(): string[] {
    return ['search', 'candidate_id'];
  }
  public emit() {
    this.searchModelChange.emit(this.search);
    this.candidateIdModelChange.emit(this.candidate_id);
  }
  public copy(filter: {search: string, candidate_id: any}) {
    this.search = filter.search;
    this.candidate_id = filter.candidate_id;
    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      search: this.search,
      candidate_id: this.candidate_id};
  }
}

