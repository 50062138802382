import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-condition-page-component',
  templateUrl: './condition-page-component.component.html',
  styleUrls: ['./condition-page-component.component.sass']
})
export class ConditionPageComponentComponent implements OnInit {

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
  }

}
