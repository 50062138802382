import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { AbstractService } from '../../projects/bloonde-ngx-template/src/api/services/abstract.service';
import { ApiConfigHelper } from '../../projects/bloonde-ngx-template/src/api/api-config.helper';


@Injectable()
export class ThemeService {

  headerEventEmitter: EventEmitter<any> = new EventEmitter();
  resetEventEmitter: EventEmitter<any> = new EventEmitter();

}
