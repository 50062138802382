import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren } from '@angular/core';
import { Pagination } from '../../models/pagination';
import { NavigationHelper } from './../../../../../bloonde-ngx-template/src/navigation/navigation.helper';
@Component({
  selector: 'app-td-table,[appTdTable]',
  templateUrl: './td-table.component.html',
  styleUrls: ['./td-table.component.css']
})
export class TdTableComponent implements OnInit {
  @Input() datas;
  @Input() headers;
  @Input() pagination: Pagination;
  @Input() parentId: string = null; // Se usa para los hijos (acordeones)

  @Output() HandlerClickTd = new EventEmitter();
  @Output() HandlerClickTr = new EventEmitter();
  @Output() HandlerClickBtn = new EventEmitter();
  @Output() HandlerDoubleClickTr = new EventEmitter();
  @Output() Edit: EventEmitter<any> = new EventEmitter();
  @Output() Delete: EventEmitter<any> = new EventEmitter();
  @Output() Trash: EventEmitter<any> = new EventEmitter();
  @Output() OtherAction: EventEmitter<any> = new EventEmitter();
  @Output() HandlerAddOrRemove: EventEmitter<any> = new EventEmitter();

  @ViewChildren('selectorCheckbox') selectorCheckboxes;

  countClick = 0;
  timer: any;
  preventSimpleClick: boolean;

  constructor(
    private navigationHelper: NavigationHelper
  ) { }

  ngOnInit() {}

  ngAfterViewChecked() {
    // let cont = 0;
    // let dividir = 100;

    // for (let i in this.headers) {
    //   if ( this.headers[i]['isShow'] && this.headers[i]['label'] === 'check') {
    //     dividir = dividir - 5;
    //   }
    //   else if ( this.headers[i]['isShow'] && this.headers[i]['label'] === 'action') {
    //     dividir = dividir - 15;
    //   }
    //   else if ( this.headers[i]['isShow'] ) {
    //     cont += 1;
    //   }
    // }
    // const widthForItem = dividir /  cont ;
    // const element = document.querySelectorAll('.table__td');
    // for (let x = 0; x < element.length; x++) {
    //   element[x]['style']['width'] = widthForItem + '%'
    // }
  }

  onEdit(id): void {
    this.Edit.emit(id);
  }

  onDelete(id): void {
    this.Delete.emit(id);
  }

  onTrash(id): void {
    this.Trash.emit(id);
  }

  onOtherAction(id: any, action: string): void {
    this.OtherAction.emit({id: id, action: action});
  }

  onAddOrRemove(dataInput: any[]): void {
    let idAux = this.getId(dataInput);
    this.HandlerAddOrRemove.emit(idAux);
  }

  getId(data: any[]): number {
    // tslint:disable-next-line:forin
    for (const i in data) {
      const d = data[i];
      if (d['label'] === 'id') {
        return d['value'];
      }
    }
  }

  getSelectorCheckboxes(): void {
    return this.selectorCheckboxes;
  }

  clickTd( id, label ) {
    this.timer = 0;
    this.preventSimpleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        this.HandlerClickTd.emit( {id: id, label: label} );
      }
    }, delay);
  }

  clickTr( id ) {
    this.timer = 0;
    this.preventSimpleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        this.HandlerClickTr.emit( id );
      }
    }, delay);
  }

  doubleClickTr(e, id) {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    this.HandlerDoubleClickTr.emit({event: e, id: id});
  }

  isStatus(txt) {
    const isStatus = txt.indexOf('status_id');
    if (isStatus > -1) {
      return true;
    }
    return false;
  }

  log(): void {
    console.log('td ', this.datas)
  }

  getItemPerPage(): any {
    if (this.pagination) {
      return this.pagination.itemPerPage;
    } else {
      return null;
    }
  }

  getTotalItems(): any {
    if (this.pagination) {
      return this.pagination.total;
    } else {
      return null;
    }
  }

  getCurrentPage(): any {
    if (this.pagination) {
      return this.pagination.currentPage;
    } else {
      return null;
    }
  }

  clickBtn( e, id, action ) {
    const dataObject =  {
      event: e,
      id: id,
      action: action
    }
    this.HandlerClickBtn.emit( dataObject );
  }

  clickCustomer( e: any, customer: any) {
    this.navigationHelper.navigateTo('customer-edition', {}, customer['id']);
  }

  clickAddBooking( e: any, data: any, item: any) {
    const dataObject =  {
      event: e,
      data: data,
      item: item
    }
    this.HandlerClickBtn.emit( dataObject );
  }
  // Determina si una fila es o no hija de otra. En realidad solo busca si tiene una columna isChild con valor true;
  isChild(element: any): boolean{
    for(let col of element) {
      if(col['label'] === 'isChild') {
        return col['value'];
      }
    }
    return false;
  }
  getTrId(element: any): string {
    if(this.parentId) {
      return 'collapse__' + this.parentId + '_child';
    } else {
      for(let col of element) {
        if(col['label'] === 'id') {
          return col['value'];
        }
      }
    }
  }
}
