export class CVLanguageTableOrder {
  id: string;
  title: string;
  observations: string;
  type: string;
  level: string;
  document: string;
  value: string;

  constructor() {
    this.id = null;
    this.title = null;
    this.type = null;
    this.level = null;
    this.observations = null;
    this.document = null;
    this.value = null;
  }
}
