import { Component, OnInit } from '@angular/core';
import { AbstractCardComponent } from '../abstract-card.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.sass']
})
export class TrainingCardComponent extends AbstractCardComponent implements OnInit {

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
  }

  processValue(value: any): string {

    let vals = [];
    let val = JSON.parse(value);
    if (val) {
      for (let v of val) {
        if (v && v.value) {
          this.translateValue(v.value, vals);
        }
      }
    }
    return vals.join(' - ');
  }
  private translateValue(value: string, array: any): void {
    this.translateService.get(value).subscribe((res: string) => {
        array.push(res);
    });
  }
}
