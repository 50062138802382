import { Component, OnInit, ViewChild } from '@angular/core';
import { OfferFormComponent } from '../../components/forms/offer-form/offer-form.component';
import { ActivatedRoute } from '@angular/router';
import { Offer } from '../../models/offer';
import { ThemeService } from '../../services/theme.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-offer-edition-page',
  templateUrl: './offer-edition-page.component.html',
  styleUrls: ['./offer-edition-page.component.sass']
})
export class OfferEditionPageComponent implements OnInit {

  // ViewChild
  @ViewChild(OfferFormComponent) offerFormComponent;

  // Model attribute
  public offer: Offer;
  constructor(private route: ActivatedRoute, private themeService: ThemeService, private location: Location) {

  }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
    this.offer = this.route.snapshot.data['offer'];
    this.offerFormComponent.editWithModel(this.offer);
  }

  updateAndClose(): void {
    // this.next_action = 'update_and_continue';
    this.offerFormComponent.update();
  }

  goToPreviousRoute(): void {
    this.location.back();
  }

}
