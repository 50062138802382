export class TableOptions {
  autoload: boolean;
  withPagination: boolean;
  paginationItemsPerPage: number;
  localStorageKey: string;
  hasActionColumn: boolean;
  hasCheckboxColumn: boolean;
  title: string;
  isEdit: boolean;
  typeTable: string;
  constructor(model: string) {
    this.autoload = true;
    this.withPagination = false;
    this.paginationItemsPerPage = 20;
    this.localStorageKey = model + '-table';
    this.hasActionColumn = false;
    this.hasCheckboxColumn = false;
    this.title = null;
    this.isEdit = false;
    this.typeTable = null;
  }
}

