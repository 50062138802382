import { Component, Input, OnInit } from '@angular/core';
import { AbstractCardComponent } from '../abstract-card.component';

@Component({
  selector: 'app-experience-card',
  templateUrl: './experience-card.component.html',
  styleUrls: ['./experience-card.component.sass']
})
export class ExperienceCardComponent extends AbstractCardComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
