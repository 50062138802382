import { FilterSpecification } from './filter-specification';
import { ButtonOptions } from './button-options';
export class DataHeader {
  title: {
    isShow: boolean;
    label: string;
  };
  filters: {
    isShow: boolean;
    items: FilterSpecification[];
  };
  btns: {
    isShow: boolean;
    float?: string;
    items: ButtonOptions[];
  };
  actions: any[];
  style: string;
}

