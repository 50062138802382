import { PositionSelectComponent } from '../../inputs/position-select/position-select.component';
import { CompanyTypeSelectComponent } from '../../inputs/company-type-select/company-type-select.component';
import { DateComponent } from 'bloonde-ngx-template/src/inputs/date/date.component';
import { WorkingDaySelectComponent } from '../../inputs/working-day-select/working-day-select.component';
import { FileComponent } from 'bloonde-ngx-template/src/inputs/file/file.component';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import { TextAreaComponent } from 'bloonde-ngx-template/src/inputs/textarea/textarea.component';
export class CVExperienceFormConfiguration {
  public static structure = [
    {
      name: 'title',
      label: 'title_experience',
      placeholder: 'title',
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'position_id',
      label: 'position_id',
      placeholder: 'Select a position',
      type: PositionSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'company',
      label: 'company',
      placeholder: 'company',
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'company_type',
      label: 'company_type',
      placeholder: 'Select a company_type',
      type: CompanyTypeSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'location',
      label: 'location',
      placeholder: 'location',
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'start_date',
      label: 'start_date',
      placeholder: null,
      type: DateComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'end_date',
      label: 'end_date',
      placeholder: null,
      type: DateComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'working_day',
      label: 'working_day',
      placeholder: 'Select an option',
      type: WorkingDaySelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'working_day_hours',
      label: 'working_day_hours',
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      help: 'Indica el número de horas de la jornada parcial',
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: true,
      }
    },
    {
      name: 'volunteer',
      label: 'volunteer',
      placeholder: null,
      type: 'checkbox',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'check__reverse form-checkbox',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'functions',
      label: 'functions',
      placeholder: 'functions2',
      type: TextAreaComponent,
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      model: 'offer'
    },
    {
      name: 'observations',
      label: 'observations',
      placeholder: null,
      type: 'textarea',
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'document',
      label: 'document',
      placeholder: null,
      type: FileComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      url: ApiRoutesHelper.getUploadURL(),
      download_url: ApiRoutesHelper.FILE_FOLDER
    }
  ];
}
