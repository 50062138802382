import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { PositionService } from '../../../services/position.service';
import { ListRequestData } from '../../../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { Position } from '../../../models/position';

@Component({
  selector: '[app-position-filter],app-position-filter',
  templateUrl: './position-filter.component.html',
  styleUrls: ['./position-filter.component.sass']
})
export class PositionFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  positions: Position[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'Position', placeholder: 'Position', options: []};

  constructor(private positionService: PositionService,
              private responseHelper: ResponseHelper) { }
  ngOnInit() {
    this.list();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, [], []);
    this.positionService.selectList(listRequestData)
      .subscribe(
        res => {
          this.positions = <Position[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for (let i in this.positions) {
      let position = this.positions[i];
      options.push({
        value: position.id,
        label: position.name,
      });
    }
    this.options['options'] = options;
  }

}
