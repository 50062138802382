import { ApiConfigHelper } from '../../../bloonde-ngx-template/src/api/api-config.helper';
export class ApiRoutesHelper {

  public static getPrivileges(): string {
    return ApiConfigHelper.getAPIURL() + 'privileges';
  }
  public static getPrivilegesGroups(): string {
    return ApiConfigHelper.getAPIURL() + 'privileges_groups';
  }
}
