export class OfferCandidateChangeStatusToDiscardFormConfiguration {
  public static structure = [
    {
      name: 'discard_information',
      label: 'discard_information',
      placeholder: null,
      type: 'text',
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      init_if_condition: true,
    },
  ];
}
