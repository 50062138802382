import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {AbstractInputComponent} from '../abstract-input.component';
import {ApiHelper} from '../../session/helpers/api.helper';
import {MessageService} from '../../responses/message.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent extends AbstractInputComponent implements OnInit {

  @Input()
  public visualize: boolean = true;

  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Output() success: EventEmitter<any> = new EventEmitter();

  @Output() errorEmitter: EventEmitter<any> = new EventEmitter();

  public uploader;

  constructor(private messageService: MessageService) {
    super();
  }

  ngOnInit() {
    if (this.structure.visualize !== undefined) {
      this.visualize = this.structure.visualize;
    }
    let token = ApiHelper.getToken();
    this.uploader = new FileUploader({url: this.structure.url, authToken: 'Bearer ' + token, autoUpload: true});
    this.uploader.onCompleteItem = (item, response, status, header) => {
      if (status === 200) {
        const res = JSON.parse(response);
        this.model = res;
        this.success.emit();
      } else {
        const res = JSON.parse(response);
        this.errorEmitter.emit(res['file'][0]);
        this.messageService.showErrorMessage(res['file'][0]);
      }
    };
  }
  onDelete(file_id) {
    this.delete.emit(file_id);
  }
  downloadFile(file_id: any) {
      let token = ApiHelper.getToken();
      window.open(this.structure.download_url + file_id + '?token=' + token,'_blank');
  }

  completeUrl(url: string) {
    return this.structure.image_folder + url;
  }


}
