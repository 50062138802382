import { Sector } from './sector';
import { Position } from './position';
import { City } from './city';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
export class ChatMessage {
  id: any;
  content: string;
  attach_path_url: string;
  chat_id: any;
  dir: any;

  constructor() {
    this.id = null;
    this.content = null;
    this.attach_path_url = null;
    this.dir = null;
  }
  public static copy(model: any) {
    let aux = new ChatMessage();
    aux.id = model.id;
    aux.content = model.content;
    aux.attach_path_url = model.attach_path_url;
    aux.dir = model.dir;
    return aux;
  }
}
