import { Component, OnInit } from '@angular/core';
import { AccessService } from 'ngx-bloonde-user-and-privileges/src/services/access.service';
import { SessionService } from 'bloonde-ngx-template/src/session/services/session.service';
import { ThemeService } from '../../services/theme.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-message-management-page',
  templateUrl: './message-management-page.component.html',
  styleUrls: ['./message-management-page.component.sass']
})
export class MessageManagementPageComponent implements OnInit {

  public type = null;

  constructor(private accessService: AccessService,
              private themeService: ThemeService,
              private sessionService: SessionService,
              private location: Location) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
    if (this.accessService.checkProfile('candidate', this.sessionService.getLoggedUser())) {
      this.type = 'candidate';
    } else if (this.accessService.checkProfile('evaluator', this.sessionService.getLoggedUser())) {
      this.type = 'evaluator';
    }
  }

  goToPreviousRoute(): void {
    this.location.back();
  }

}
