import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Image } from '../../../models/image';
import { InputFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from '../../../../../bloonde-ngx-template/src/forms/services/validation.service';
import { ResponseHelper } from '../../../../../bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../../bloonde-ngx-template/src/responses/message.service';
import { AbstractFormComponent } from '../../../../../bloonde-ngx-template/src/forms/abstract-form.component';
import { GalleryImageFormConfiguration } from './gallery-image-form-configuration';
import { GalleryImageService } from '../../../services/galleryImage.service';
import { GalleryPhotoService } from '../../../services/galleryPhoto.service';

@Component({
  selector: 'bl-gallery-image-form',
  templateUrl: './gallery-image-form.component.html',
  styleUrls: ['./gallery-image-form.component.css']
})
export class GalleryImageFormComponent extends AbstractFormComponent implements OnInit {

  public image: Image;
  public nextAction = 'keep';

  @Input()
  public config: {type: any, fk_id: any};

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;
  inputFormComponents: any[];

  constructor(private validationService: ValidationService,
              private galleryPhotoService: GalleryPhotoService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.inputFormComponents = [];
    this.structure = GalleryImageFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(): void {
    this.image = new Image();
    this.image.type = this.config.type;
    this.image.fk_id = this.config.fk_id;
    this.buildErrorModelAndEmitters();
  }
  // public edit(id): void {
  //   this.adminService.get(id).subscribe(
  //     res => {
  //       this.admin = User.copy(res);
  //       this.buildErrorModelAndEmiters();
  //     },
  //     error => {
  //       if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
  //         this.serverErrors = error.error;
  //         this.emitServerError();
  //       } else {
  //         this.responseHelper.handleError(error);
  //       }
  //     }
  //   );
  // }
  public successResponseEdit(res): void {
    this.image = Image.copy(this.image);
    this.image.type = this.config.type;
    this.image.fk_id = this.config.fk_id;
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.image;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }
  public pushInputFormComponents(componentRef: any, key: any): void {
    this.inputFormComponents[key] = componentRef;
  }
  public pushConditionFormComponents(componentRef: any, key: any): void {
    this.inputFormComponents[key] = componentRef;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.galleryPhotoService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.image = model;
  }
  successResponseStore(res): void {
    // this.flat = Flat.copy(res); // TODO - Creo que no es necesario
  }
  public success(): void {
    this.onSuccess.emit(this.image);
  }

}
