import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sector } from '../../../models/sector';
import { SectorService } from '../../../services/sector.service';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from '../../../../projects/bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: 'app-sector-select',
  templateUrl: './sector-select.component.html',
  styleUrls: ['./sector-select.component.sass']
})
export class SectorSelectComponent implements OnInit {

  @Input()
  multiple: boolean;
  @Input()
  addTag: boolean;
  @Input()
  error: any;
  @Input()
  serverError: any;

  sectors: Sector[];

  @Input()
  get model() {
    return this.modelValue;
  }
  @Output()
  modelChange = new EventEmitter<any>();


  public modelValue: string;


  public structure: any;

  constructor(private sectorService: SectorService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    if (this.structure.autoload !== false) {
      this.list();
    }
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, [], []);
    this.sectorService.selectList(listRequestData)
      .subscribe(
        res => {
          this.sectors = <Sector[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for(let i in this.sectors) {
      let sector = this.sectors[i];
      options.push({
        value: sector.id,
        label: sector.name,
      });
    }
    this.structure['options'] = options;
  }

}
