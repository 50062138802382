import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OffersCandidatesFormComponent } from '../../forms/offers-candidates-form/offers-candidates-form.component';
import { AbstractFormModalComponent } from 'bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';

@Component({
  selector: 'app-offers-candidates-modal',
  templateUrl: './offers-candidates-modal.component.html',
  styleUrls: ['./offers-candidates-modal.component.sass']
})
export class OffersCandidatesModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: true, save_and_edit: false};
  @ViewChild(OffersCandidatesFormComponent) formComponent;
  public modal_id = 'offer_candidate_modal';

  constructor() {
    super();
  }

  ngOnInit() {
  }
  public getFormComponent(): any {
    return this.formComponent;
  }

}
