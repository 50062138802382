import { Component, OnInit } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';

import * as moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent extends AbstractInputComponent implements OnInit {

  limitError(): boolean {
    if(this.modelValue) {
      let sixMonthAgo = moment().subtract(6, 'month');
      let expeditionDate = moment(this.modelValue);
      return sixMonthAgo > expeditionDate;
    }
    return false;

  }

}
