import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-my-offer-candidate-management-page',
  templateUrl: './my-offer-candidate-management-page.component.html',
  styleUrls: ['./my-offer-candidate-management-page.component.sass']
})
export class MyOfferCandidateManagementPageComponent implements OnInit {

  public offerCandidateTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'my-offer-candidate-table',
    hasActionColumn: true,
    hasCheckboxColumn: false
  };

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
  }

}
