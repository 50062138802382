import { DateComponent } from 'bloonde-ngx-template/src/inputs/date/date.component';

export class OfferDetailsConfiguration {
  public static structure = [
    {
      name: 'name',
      label: 'name',
      type: 'read-only',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'description',
      label: 'description',
      type: 'read-only',
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      type: 'read-only',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'city.state.name',
      label: 'state_id',
      type: 'read-only',
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'city.name',
      label: 'city_id',
      type: 'read-only',
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
    },
    {
      name: 'position.name',
      label: 'Position',
      type: 'read-only',
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
    },
    {
      name: 'sector.name',
      label: 'Sector',
      type: 'read-only',
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
    },
    {
      name: 'functions',
      label: 'functions',
      type: 'read-only',
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
    },
    {
      name: 'expected_contract_date',
      label: 'expected_contract_date',
      placeholder: null,
      type: DateComponent,
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      disabled: true,
      read_only: true,
    }
  ];
}
