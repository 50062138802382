import { Component, OnInit } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-h2',
  templateUrl: './h2.component.html',
  styleUrls: ['./h2.component.css']
})
export class H2Component extends AbstractInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() { }

}
