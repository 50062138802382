import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../models/city';
import { CityService } from '../../../services/city.service';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from '../../../../projects/bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: '[app-city-filter],app-city-filter',
  templateUrl: './city-filter.component.html',
  styleUrls: ['./city-filter.component.sass']
})
export class CityFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  cities: City[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'City', placeholder: 'City', options: []};

  constructor(private cityService: CityService,
              private responseHelper: ResponseHelper) { }
  ngOnInit() {
    this.list();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, this.filter, []);
    this.cityService.selectList(listRequestData)
      .subscribe(
        res => {
          this.cities = <City[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for (let i in this.cities) {
      let city = this.cities[i];
      options.push({
        value: city.id,
        label: city.name,
      });
    }
    this.options['options'] = options;
  }

}
