import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


export abstract class AbstractCardComponent implements OnInit {

  @Input() data: any;

  @Output() editEmitter: EventEmitter<any> = new EventEmitter();
  @Output() deleteEmitter: EventEmitter<any> = new EventEmitter();
  @Output() otherActionEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  colValue(colName: string): any {
    for (let col of this.data) {
      if (colName !== 'action') {
        if (col['label'] === colName) {
          return col['value'];
        }
      } else {
        if (col['label'] === colName && col['value']) {
          return col['value'];
        }
      }
    }
    return null;
  }

  onEdit(data): void {
    this.editEmitter.emit({element: data});
  }
  onDelete(data): void {
    this.deleteEmitter.emit({element: data});
  }
  onOtherAction(data, action): void {
    this.otherActionEmitter.emit({element: data, action: action});
  }
}
