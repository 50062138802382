import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { OfferCandidateService } from '../../../services/offer-candidate.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { CodesHelper } from 'bloonde-ngx-template/src/responses/codes.helper';
import { OfferCandidateChangeStatusToDiscardFormConfiguration } from './offer-candidate-change-status-to-discard-form-configuration';

@Component({
  selector: 'app-offer-candidate-change-status-to-discard-form',
  templateUrl: './offer-candidate-change-status-to-discard-form.component.html',
  styleUrls: ['./offer-candidate-change-status-to-discard-form.component.sass']
})
export class OfferCandidateChangeStatusToDiscardFormComponent extends AbstractFormComponent implements OnInit {

  @Input()
  public modelValue: {offer_candidate_ids: any[], discard_information: string, status_id: any};

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private offerCandidateService: OfferCandidateService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = OfferCandidateChangeStatusToDiscardFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(args: any[]): void {
    this.modelValue = {offer_candidate_ids: [], discard_information: null, status_id: null};
    if (args['offer_candidate_ids']) {
      this.modelValue.offer_candidate_ids = args['offer_candidate_ids'];
    } else if (args['offer_candidate_id']) {
      this.modelValue.offer_candidate_ids = ['offer_candidate_id'];
    }
    this.modelValue.status_id = OfferCandidateService.DISCARD_STATUS_ID;
    this.buildErrorModelAndEmitters();
  }
  public store(args: any[] = []): void {
    const validate = this.checkForm();
    this.emitError();
    if (validate) {
      this.getModelService().bulkChangeStatus(this.getModel()).subscribe(
        res => {
          this.successResponseStore(res); // Hook post store success
          this.resetErrors(); // Reseteamos los errores
          this.success();
          this.getMessageService().showSuccessMessage();
        },
        error => {
          if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
            this.serverErrors = error.error;
            this.emitServerError();
          } else {
            this.getResponseHelper().handleError(error);
          }
        }
      );
    }
  }
  public successResponseEdit(res): void {
    // this.modelValue = OfferCandidate.copy(this.modelValue); // No habrá edicion
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.offerCandidateService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }
  successResponseStore(res): void {
    // this.modelValue = OfferCandidate.copy(res);
  }
  public success(): void {
    this.onSuccess.emit();
  }

  public extendConstructForm(): void {

    if (this.modelValue.offer_candidate_ids.length == 1) {
      this.setDisabledField('discard_information', false);
      this.setVisibilityField('discard_information', true);
    } else {
      this.setDisabledField('discard_information', true);
      this.setVisibilityField('discard_information', false);
    }
  }
}
