import {Component, Inject, Input, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import { AbstractTableComponent } from 'bloonde-ngx-template/src/tables/partials/abstract-table/abstract-table.component';
import { BodyTableComponent } from 'bloonde-ngx-template/src/tables/partials/body-table/body-table.component';
import { TableOptions } from 'bloonde-ngx-template/src/tables/models/table-options';
import { CandidateListItem } from '../../../models/listings/candidate_list_item';
import { Pagination } from 'bloonde-ngx-template/src/tables/models/pagination';
import { CandidateTableFilter } from './candidate-table-filter';
import { CandidateTableOrder } from './candidate-table-order';
import { CandidateTableVisibility } from './candidate-table-visibility';
import { DataHeader } from 'bloonde-ngx-template/src/tables/models/data-header';
import { SearchComponent } from 'bloonde-ngx-template/src/tables/components/search/search.component';
import { ActivatedFilterComponent } from '../../filters/activated-filter/activated-filter.component';
import { CandidateService } from '../../../services/candidate.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import { TableComponent } from 'bloonde-ngx-template/src/tables/partials/table/table.component';
import {formatDate} from '@angular/common';
import {PositionFilterComponent} from '../../filters/position-filter/position-filter.component';
import {ExperienceFilterComponent} from '../../filters/experience-filter/experience-filter.component';
import {HeaderTableComponent} from 'bloonde-ngx-template/src/tables/partials/header-table/header-table.component';
import {LanguageFilterComponent} from '../../filters/language-filter/language-filter.component';
import {LanguageLevelFilterComponent} from '../../filters/language-level-filter/language-level-filter.component';
import {YesNoFilterComponent} from '../../filters/yes-no-filter/yes-no-filter.component';
import {TrainingDegreeFilterComponent} from '../../filters/training-degree-filter/training-degree-filter.component';
import {TrainingLevelFilterComponent} from '../../filters/training-level-filter/training-level-filter.component';
import {TrainingFilterComponent} from '../../filters/training-filter/training-filter.component';
import {StateFilterComponent} from '../../filters/state-filter/state-filter.component';

@Component({
  selector: '[app-candidate-table]',
  templateUrl: './candidate-table.component.html',
  styleUrls: ['./candidate-table.component.sass']
})
export class CandidateTableComponent extends AbstractTableComponent implements OnInit {

  @ViewChild(TableComponent) tableComponent;
  @ViewChild(HeaderTableComponent) headerTableComponent;

  @Input() tableOptions: TableOptions;

  public candidates: CandidateListItem[];
  public pagination: Pagination;

  public filter: CandidateTableFilter = new CandidateTableFilter();
  public order: CandidateTableOrder = new CandidateTableOrder();
  public visibility: CandidateTableVisibility = new CandidateTableVisibility();

  public dataHeader: DataHeader = {
    title: {
      isShow: true,
      label: 'Candidate Management',
    },
    filters: {
      isShow: true,
      items: [
        {
          component: SearchComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'search', type: 'property'},
            {attr: 'placeholder', value: 'Surname or email...', type: 'property'},
            {attr: 'idText', value: 'generalSearch', type: 'property'},
            {attr: 'modelChange', value: 'search', type: 'output'},
            {attr: 'model', value: this.filter.searchModelChange, type: 'input'}
          ]
        },
        {
          component: PositionFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Position', type: 'property'},
            {attr: 'modelChange', value: 'position', type: 'output'},
            {attr: 'model', value: this.filter.positionModelChange, type: 'input'}
          ]
        },
        {
          component: ExperienceFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Experience', type: 'property'},
            {attr: 'modelChange', value: 'experience', type: 'output'},
            {attr: 'model', value: this.filter.experienceModelChange, type: 'input'},
            {attr: 'disabled', value: this.filter.disabledModelChange, type: 'input'}
          ]
        },
        {
          component: TrainingDegreeFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'name', value: 'trainingDegree', type: 'property'},
            {attr: 'modelChange', value: 'trainingDegree', type: 'output'},
            {attr: 'model', value: this.filter.trainingDegreeModelChange, type: 'input'},
          ]
        },
        {
          component: TrainingLevelFilterComponent,
          cols: 'col-6',
          inputs_and_outputs: [
            {attr: 'name', value: 'trainingLevel', type: 'property'},
            {attr: 'modelChange', value: 'trainingLevel', type: 'output'},
            {attr: 'model', value: this.filter.trainingLevelModelChange, type: 'input'},
            {attr: 'degree', value: this.filter.setDegreeModelChange, type: 'input-list'}
          ]
        },
        {
          component: TrainingFilterComponent,
          cols: 'col-6',
          inputs_and_outputs: [
            {attr: 'name', value: 'training', type: 'property'},
            {attr: 'modelChange', value: 'training', type: 'output'},
            {attr: 'model', value: this.filter.trainingModelChange, type: 'input'},
            {attr: 'degree', value: this.filter.setDegreeModelChange, type: 'input'},
            {attr: 'level', value: this.filter.setLevelModelChange, type: 'input-list'}
          ]
        },
        {
          component: StateFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'State', type: 'property'},
            {attr: 'modelChange', value: 'state_id', type: 'output'},
            {attr: 'model', value: this.filter.stateIdModelChange, type: 'input'}
          ]
        },
        {
          component: LanguageFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Language', type: 'property'},
            {attr: 'modelChange', value: 'language', type: 'output'},
            {attr: 'model', value: this.filter.languageModelChange, type: 'input'}
          ]
        },
        {
          component: LanguageLevelFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Language Level', type: 'property'},
            {attr: 'modelChange', value: 'languageLevel', type: 'output'},
            {attr: 'model', value: this.filter.languageLevelModelChange, type: 'input'},
            {attr: 'disabled', value: this.filter.disableLanguageLevelModelChange, type: 'input'}
          ]
        },
        {
          component: YesNoFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'Language with certificate', type: 'property'},
            {attr: 'name', value: 'withCertificate', type: 'property'},
            {attr: 'modelChange', value: 'withCertificate', type: 'output'},
            {attr: 'model', value: this.filter.withCertificateModelChange, type: 'input'},
            {attr: 'disabled', value: this.filter.disableLanguageLevelModelChange, type: 'input'}
          ]
        },
        {
          component: YesNoFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'volunteer', type: 'property'},
            {attr: 'name', value: 'volunteer', type: 'property'},
            {attr: 'modelChange', value: 'volunteer', type: 'output'},
            {attr: 'model', value: this.filter.volunteerModelChange, type: 'input'}
          ]
        },
        {
          component: YesNoFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'MAES/CAP', type: 'property'},
            {attr: 'name', value: 'maes', type: 'property'},
            {attr: 'modelChange', value: 'maes', type: 'output'},
            {attr: 'model', value: this.filter.maesModelChange, type: 'input'}
          ]
        },
        {
          component: YesNoFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'DECA', type: 'property'},
            {attr: 'name', value: 'deca', type: 'property'},
            {attr: 'modelChange', value: 'deca', type: 'output'},
            {attr: 'model', value: this.filter.decaModelChange, type: 'input'}
          ]
        },
        {
          component: YesNoFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'activated', type: 'property'},
            {attr: 'name', value: 'activated', type: 'property'},
            {attr: 'modelChange', value: 'activated', type: 'output'},
            {attr: 'model', value: this.filter.activatedModelChange, type: 'input'}
          ]
        }
      ]
    },
    btns: {
      isShow: true,
      float: 'right',
      items: [
        {
          isShow: false,
          value: 'Alta',
          icon: 'fas fa-plus',
          style: '',
          action: 'create'
        },
        {
          isShow: false,
          action: 'preselection',
          value: 'Preseleccionar',
          text: 'Preseleccionar',
          icon: 'fas fa-concierge-bell',
          style: 'btn btn-light',
        },
      ]
    },
    actions: [],
    style: ''
  };

  public isTable: boolean = true;

  constructor(
    private candidateService: CandidateService,
    private responseHelper: ResponseHelper,
    private navigationHelper: NavigationHelper,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super();
  }

  ngOnInit() {
    if (!this.tableOptions) {
      this.tableOptions = new TableOptions('offer-management');
    }
    this.resetFiltersOrdersAndColumns();
    if (this.tableOptions.withPagination) {
      this.pagination = new Pagination();
      this.pagination.currentPage = 1;
      this.pagination.itemPerPage = this.tableOptions.paginationItemsPerPage;
    }
    if (this.tableOptions.autoload) {
      this.list();
    }
  }

  list(): void {
    this.cleanSelected();
    this.saveFiltersAndOrders();
    const listRequestData = new ListRequestData(this.pagination ? this.pagination.currentPage : null, this.filter, this.order);
    this.candidateService.list(listRequestData).subscribe(
      res => {
        this.candidates = <CandidateListItem[]> res['elements'];
        if (this.tableOptions.withPagination) {
          this.pagination.total = res['total'];
        }
        this.getHeader();
        this.getData();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  defaultFilter(): any {
    return new CandidateTableFilter();
  }
  defaultOrders(): any {
    return new CandidateTableOrder();
  }
  defaultVisibility(): any {
    return new CandidateTableVisibility();
  }
  getElements(): any {
    return this.candidates;
  }
  getFilter(): any {
    return this.filter;
  }
  getOrder(): any {
    return this.order;
  }
  getVisibility(): any {
    return this.visibility;
  }
  setFilter(filter: any): void {
    this.filter.copy(filter);
  }
  setOrder(order: any): void {
    this.order = order;
  }
  setVisibility(visibility: any): void {
    this.visibility = visibility;
  }
  getLocalStorageKey(): any {
    return this.tableOptions.localStorageKey;
  }
  getAllSelectorCheckbox(): any {
    if (this.tableComponent) {
      return this.tableComponent.getAllSelectorCheckbox();
    }
  }
  getSelectorCheckboxes(): any {
    return this.tableComponent.getSelectorCheckboxes();
  }
  hasActionColumn(): boolean {
    return this.tableOptions.hasActionColumn;
  }
  hasCheckboxColumn(): boolean {
    return this.tableOptions.hasCheckboxColumn;
  }
  changeFilter($event) {
    this.filter[$event['key']] = $event['value'];
    if (this.filter.position) {
      this.filter.disabledModelChange.emit(false);
    } else {
      this.filter.experienceModelChange.emit(null);
      this.filter.disabledModelChange.emit(true);
    }
    if (this.filter.language) {
      this.filter.disableLanguageLevelModelChange.emit(false);
    } else {
      this.filter.languageLevelModelChange.emit(null);
      this.filter.withCertificateModelChange.emit(null);
      this.filter.disableLanguageLevelModelChange.emit(true);
    }

    this.filter.trainingModelChange.emit(this.filter.training);
    this.filter.trainingLevelModelChange.emit(this.filter.trainingLevel);
    this.filter.setDegreeModelChange.emit(this.filter.trainingDegree);
    this.filter.setLevelModelChange.emit(this.filter.trainingLevel);

    this.listWithTimeout();
  }
  enterSearch($event) {
    this.filter.search = $event.event.target.value;
    this.listWithTimeout();
  }
  clickHeaderBtn(e) {
    if ( e.action === 'create' ) {
      this.onCreate();
    } else {
      this.HandlerHeaderClickBtn.emit(e);
    }
  }
  constructBodyColumnsExceptions(key, value, isShow, isOrder, item): any {
    let objectData = null;
    let translate = false;
    if ( key === 'name') {
      value = value + ' ' + item['surname'];
      translate = true;
    }
    if ( key === 'activated') {
      value = 'activated_' + value;
      translate = true;
    }
    if (key === 'cv_updated_at') {
      value = this.transformDate(item[key]);
    }
    objectData = this.setData(key, isShow, isOrder, value, translate);
    return objectData;
  }

  onOtherAction( item: any) {
    // if ( item['action'] === 'booking' ) {
    //   console.log( '============================> TO DO NAVIGATE PAG BOOKING PUBLIC');
    //   // this.navigationHelper.navigateTo('room', {}, item['id']);
    // }
  }

  transformDate(date: any, format: string = 'dd/MM/yyyy HH:mm') {
    return formatDate(date, format, this.locale);
  }

  getDataActions() {
    return [
      {
        action: 'edit',
        isShow: true,
        icon: 'fas fa-edit',
        title: 'Edit'
      },
      {
        action: 'delete',
        isShow: true,
        icon: 'fas fa-trash-alt',
        title: 'Delete'
      }
    ];
  }

  extendAddOrRemove() {
    if ( this.selected.length > 0 ) {
      for ( let x = 0; x < this.dataHeader.btns.items.length; x++ ) {
        if ( this.dataHeader.btns.items[x].action === 'preselection' ) {
          this.dataHeader.btns.items[x].isShow = true;
        }
      }
    }
    if ( this.selected.length === 0 ) {
      for ( let x = 0; x < this.dataHeader.btns.items.length; x++ ) {
        if ( this.dataHeader.btns.items[x].action === 'preselection' ) {
          this.dataHeader.btns.items[x].isShow = false;
        }
      }
    }
  }

}
