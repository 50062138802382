import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {SelectService} from '../../../services/select.service';
import {ResponseHelper} from 'bloonde-ngx-template/src/responses/response.helper';
import {ListRequestData} from 'bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: '[app-experience-filter],app-experience-filter',
  templateUrl: './experience-filter.component.html',
  styleUrls: ['./experience-filter.component.sass']
})
export class ExperienceFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'Experience', placeholder: 'Experience', options: []};

  constructor(private selectService: SelectService,
              private responseHelper: ResponseHelper) { }
  ngOnInit() {
    this.list();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, [], []);
    this.selectService.selectList('experience')
      .subscribe(
        res => {
          this.options['options'] = <any[]> res;
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }
}
