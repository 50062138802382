import { StatusSelectComponent } from '../../inputs/status-select/status-select.component';
import { CitySelectComponent } from '../../inputs/city-select/city-select.component';
import { AdvancedSelectorComponent } from '../../inputs/advanced-selector/advanced-selector.component';
import { DateComponent } from 'bloonde-ngx-template/src/inputs/date/date.component';
import { TextAreaComponent } from 'bloonde-ngx-template/src/inputs/textarea/textarea.component';
import { StateSelectComponent } from '../../inputs/state-select/state-select.component';
export class OfferFormConfiguration {
  public static structure = [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'name',
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'status_id',
      label: 'Status',
      placeholder: 'Select a status',
      type: StatusSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      model: 'offer'
    },
    {
      name: 'institution',
      label: 'Institution',
      placeholder: 'institution',
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'state_id',
      label: 'State',
      placeholder: 'Select a state',
      type: StateSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'city_id',
      label: 'City',
      placeholder: 'Select a city',
      type: CitySelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      autoload: false,
      disabled: true
    },
    {
      name: 'expected_contract_date',
      label: 'expected_contract_date',
      placeholder: null,
      type: DateComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'sector_and_position',
      label: 'sector_and_position',
      placeholder: null,
      type: AdvancedSelectorComponent,
      default_value: null,
      col: 'col-12',
      style: 'bd--none',
      rules: [
        // 'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: false,
        hasInput: true,
        hasHelp: false,
      },
      option_type: 'positions'
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'description',
      type: 'textarea',
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'functions',
      label: 'Functions',
      placeholder: 'functions',
      type: TextAreaComponent,
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'sexual_abuse_document_required',
      label: 'sexual_abuse_document_required',
      placeholder: null,
      type: 'checkbox',
      default_value: null,
      col: 'col-12',
      style: 'check__reverse form-checkbox',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    }
  ];
}
