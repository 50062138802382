import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';

declare var tinymce: any;

@Component({
  selector: 'app-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.css']
})
export class WysiwygComponent extends AbstractInputComponent implements  OnInit, AfterViewInit{

  public editor;
  @Output() update: EventEmitter<any> = new EventEmitter();

  public custom_toolbar = 'insert | undo redo |  formatselect fontsizeselect fontselect  | bold italic forecolor backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help';
  public custom_plugins = [
    'advlist autolink lists link image charmap print preview anchor textcolor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table contextmenu paste code help']
  public init = {
    plugins: this.custom_plugins,
    toolbar: this.custom_toolbar,
    skin_url: '/assets/tinymce/skins/lightgray',
    inline: false,
  }
  constructor() {
    super();
    // this.executeTinyMCE();
  }
  ngAfterViewInit() {
    // this.initTinyMCE();
  }
  // initTinyMCE() {
  //   var component = this;
  //   tinymce.init({
  //     mode : 'specific_textareas',
  //     editor_selector: 'editor',
  //     plugins: this.custom_plugins,
  //     skin_url: '/assets/tinymce/skins/lightgray',
  //     toolbar: this.custom_toolbar,
  //     setup: editor => {
  //       this.editor = editor;
  //       editor.on('keyup', () => {
  //         const content = editor.getContent();
  //         this.update.emit(content);
  //       });
  //       editor.on('change', () => {
  //         const content = editor.getContent();
  //         component.modelValue = content;
  //         component.modelChange.emit(component.modelValue);
  //       });
  //     },
  //   });
  // }
  executeTinyMCE() {
    // let component = this;
    // setTimeout(function () {
    //   component.initTinyMCE();
    //   if(component.modelValue) {
    //     tinymce.activeEditor.setContent(component.modelValue);
    //   }
    // }, 1000);
  }

}
