export class Registration {
    id: any;
    name: string;
    surname: string;
    username: string;
    email: string;
    password: string;
    password_confirmation: string;

  constructor() {
    this.id = null;
    this.name = null;
    this.surname = null;
    this.username = null;
    this.email = null;
    this.password = null;
    this.password_confirmation = null;
  }

  public static copy(user: any) {
    let userAux = new Registration();
    userAux.id = user.id;
    userAux.name = user.name;
    userAux.surname = user.surname;
    userAux.username = user.username;
    userAux.email = user.email;
    return userAux;
  }
}
