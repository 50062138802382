export class CVExperienceTableOrder {
  id: string;
  title: string;
  observations: string;
  start_date: string;
  end_date: string;
  position_name: string;
  functions: string;
  company: string;
  company_type: string;
  location: string;
  working_day: string;
  volunteer: string;
  document: string;

  constructor() {
    this.id = null;
    this.title = null;
    this.start_date = null;
    this.end_date = null;
    this.position_name = null;
    this.functions = null;
    this.company = null;
    this.company_type = null;
    this.location = null;
    this.working_day = null;
    this.volunteer = null;
    this.document = null;
  }
}
