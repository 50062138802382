export class CVTrainingTableVisibility {
  id: boolean;
  title: boolean;
  observations: boolean;
  type: boolean;
  level: boolean;
  document: boolean;
  value: boolean;

  constructor() {
    this.id = true;
    this.title = true;
    this.type = true;
    this.level = true;
    this.observations = true;
    this.document = true;
    this.value = true;
  }
}

