export class NavbarConfiguration {
  styles?: any;
  logo: {
    show: boolean,
    src: string,
    dark_src: string,
    href: string,
    routerLink?: boolean,
    alt: string
  };
  menu: {
    show: boolean,
    items: {
      label: string,
      show: boolean,
      href?: string,
      routerLink?: boolean,
      queryParams?: any,
      icon?: string,
      classes?: string,
      items?: {
        label: string,
        show: boolean,
        href?: string,
        routerLink?: boolean,
        queryParams?: any,
        icon?: string,
        classes?: string,
      }[];
    }[];
  };
  search: {
    show: boolean,
    text: string
  };
  buttons: {
    show: boolean,
    login: {
      show: boolean
    },
    registration: {
      show: boolean
    }
  };
  user: {
    show: boolean,
    data: any,
    profile_selection?: boolean,
    api_image_route?: string,
    show_miniature_profile?: boolean,
    items: {
        label: string,
        show: boolean,
        type: string,
        href?: string,
        click?: string,
        icon: string,
        classes?: string,
        items?: any[]
    }[]
  }
}
