import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractInputFormComponent } from 'bloonde-ngx-template/src/forms/abstract-input-form.component';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { Offer } from '../../../models/offer';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { OfferService } from '../../../services/offer.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import {MessageService} from 'bloonde-ngx-template/src/responses/message.service';
import {OfferDetailsConfiguration} from './offer-details-configuration';
import {ActivatedRoute} from '@angular/router';
import {AccessService} from 'ngx-bloonde-user-and-privileges/src/services/access.service';
import {OfferCandidateService} from '../../../services/offer-candidate.service';
import {SessionService} from 'bloonde-ngx-template/src/session/services/session.service';
import {CandidateService} from '../../../services/candidate.service';
import {Candidate} from '../../../models/candidate';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.sass']
})
export class OfferDetailsComponent extends AbstractInputFormComponent implements OnInit {

  public loggedInUser;
  public userLoggerSubscribe: any;
  public candidate: Candidate;

  @Input()
  public modelValue: Offer;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private offerService: OfferService,
              private offerCandidateService: OfferCandidateService,
              private candidateService: CandidateService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private accessService: AccessService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private route: ActivatedRoute,
              private sessionService: SessionService
  ) {
    super();
    this.structure = OfferDetailsConfiguration.structure;
  }

  ngOnInit() {
    this.loggedInUser = this.route.snapshot.data['loggedInUser'];
    this.candidate = this.route.snapshot.data['candidate'];
    var component = this;
    this.userLoggerSubscribe = this.sessionService.userEmitter.subscribe(function(user) {
      component.loggedInUser = user;
      if (component.isCandidate()) {
        component.getCandidate(user.id);
      } else {
        component.candidate = null;
        component.candidateService.setLoggedCandidate(null);
      }
    });
    super.ngOnInit();
  }


  public new(): void {
    this.modelValue = new Offer();
    this.buildErrorModelAndEmitters();
  }
  public successResponseEdit(res): void {
    this.modelValue = Offer.copy(this.modelValue);
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.offerService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }
  successResponseStore(res): void {
    this.modelValue = Offer.copy(res);
  }
  public success(): void {
    this.onSuccess.emit({id: this.modelValue.id});
  }

  offerApply() {
    if (this.loggedInUser) {
      if (this.isCandidate()) {
        this.storeOffer();
      }
    } else {
      this.sessionService.showLoginModal();
    }

  }

  public storeOffer(): void {
      this.offerCandidateService.candidateApply(this.modelValue.id).subscribe(
        res => {
          this.getMessageService().showSuccessMessage();
          this.candidate = res;
          this.candidateService.setLoggedCandidate(res);
        },
        error => {
          this.getResponseHelper().handleError(error);
        }
      );
  }

  public getCandidate(candidate_id: any): void {
    this.candidateService.get(candidate_id).subscribe(
      res => {
        this.candidate = res;
        this.candidateService.setLoggedCandidate(res);
      },
      error => {
        this.getResponseHelper().handleError(error);
      }
    );
  }

  isCandidate() {
    return this.loggedInUser && this.accessService.checkProfile('candidate', this.loggedInUser);
  }

  alreadyApply() {
    return this.candidate && this.candidate.offers.indexOf(this.modelValue.id) != -1;
  }

  public extendConstructForm(): void {
    if (this.inputFormComponents['city.state.name'].instance.modelValue) {
      this.setVisibilityField('city.state.name', true);
    } else {
      this.setVisibilityField('city.state.name', false);
    }

    if (this.inputFormComponents['city.name'].instance.modelValue) {
      this.setVisibilityField('city.name', true);
    } else {
      this.setVisibilityField('city.name', false);
    }

    if (this.inputFormComponents['expected_contract_date'].instance.modelValue) {
      this.setVisibilityField('expected_contract_date', true);
    } else {
      this.setVisibilityField('expected_contract_date', false);
    }
  }

  ngOnDestroy() {
    this.userLoggerSubscribe.unsubscribe();
  }

}
