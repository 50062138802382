import { Component } from '@angular/core';
export class Pagination {
  itemPerPage: number;
  previousPage: number;
  currentPage: number;
  total: number;
  constructor() {
    this.itemPerPage = 20;
    this.previousPage = null;
    this.currentPage = 1;
    this.total = null;
  }
}

