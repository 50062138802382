import {EventEmitter, Output} from '@angular/core';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class ChatTableFilter extends AbstractFilterComponent {
  @Output() searchModelChange: EventEmitter<any>;
  search: string;
  @Output() openModelChange: EventEmitter<any>;
  open: any;

  constructor() {
    super();
    this.search = null;
    this.searchModelChange = new EventEmitter();
    this.open = null;
    this.openModelChange = new EventEmitter();
  }
  public toFilter(): string[] {
    return ['search', 'open'];
  }
  public emit() {
    this.searchModelChange.emit(this.search);
    this.openModelChange.emit(this.open);
  }
  public copy(filter: {search: string, open: any}) {
    this.search = filter.search;
    this.open = filter.open;
    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      search: this.search,
      open: this.open,
    };
  }
}

