import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { NavigationHelper } from '../../../bloonde-ngx-template/src/navigation/navigation.helper';
import { SessionService } from '../../../bloonde-ngx-template/src/session/services/session.service';
import { AccessService } from '../services/access.service';
import { User } from '../../../bloonde-ngx-template/src/session/models/user';
// import { RouterNavigationHelper } from '../../../helpers/router-navigation.helper';
@Injectable()
export class CheckProfileGuard implements CanActivate {

    constructor(
        private router: Router,
        private sessionService: SessionService,
        private accessService: AccessService,
        private navigationHelper: NavigationHelper
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = this.sessionService.getLoggedUser();
        if (!user) {
          const access = this.sessionService.getAccess();
          user = new User();
          user.access = access;
        }
        let profiles = route.data['profiles'];
        let hasAccess = false;
        if(route.data['allProfiles']) {
          hasAccess = this.accessService.checkProfiles(profiles, user, true);
        } else {
          hasAccess = this.accessService.checkProfiles(profiles, user);
        }
        if (!hasAccess) {
            if (this.sessionService.getLoggedUser()) {
              this.navigationHelper.navigateTo('root', null, null, false, this.sessionService.getLoggedUser());
            } else {
              return false;
            }
        } else {
          return true;
        }
    }
}
