import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tr-table,[appTrTable]',
  templateUrl: './tr-table.component.html',
  styleUrls: ['./tr-table.component.css']
})
export class TrTableComponent implements OnInit {

  @ViewChild('selectorCheckbox') selectorCheckbox;

  @Input()
  element: any;
  @Input() accordionStatus;
  @Input() accordionStatusArgs: {element_id: any, parent_ids: any[]};
  @Input() nbChild = 0; //indica el nivel del row en el acordeon. 0 es el nivel superior

  @Output() HandlerClickTd = new EventEmitter();
  @Output() HandlerClickBtn = new EventEmitter();
  @Output() HandlerInputModelChange: EventEmitter<any> = new EventEmitter();
  @Output() Edit: EventEmitter<any> = new EventEmitter();
  @Output() Delete: EventEmitter<any> = new EventEmitter();
  @Output() Trash: EventEmitter<any> = new EventEmitter(); // Se usa ??
  @Output() OtherAction: EventEmitter<any> = new EventEmitter();
  @Output() HandlerAddOrRemove: EventEmitter<any> = new EventEmitter();
  @Output() AccordionStatusChange: EventEmitter<any> = new EventEmitter();

  timer: any;
  preventSimpleClick: boolean;

  constructor() { }

  ngOnInit() {
  }

  onAddOrRemove(dataInput: any[]): void {
    let idAux = this.getId();
    this.HandlerAddOrRemove.emit(idAux);
  }

  // Método para obtener el id del element
  getId(): number {
    // tslint:disable-next-line:forin
    for (const i in this.element) {
      const d = this.element[i];
      if (d['label'] === 'id') {
        return d['value'];
      }
    }
  }
  // Este método determina si la columna pasada por parámetro es una columna de estado
  isStatus(txt) {
    const isStatus = txt.indexOf('status_id');
    if (isStatus > -1) {
      return true;
    }
    return false;
  }

  clickTd(id, label, col, index ) {
    this.timer = 0;
    this.preventSimpleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        this.HandlerClickTd.emit( {id: id, label: label, value: col['value'], index: index} );
      }
    }, delay);
  }
  onEdit(id): void {
    this.Edit.emit(id);
  }

  onDelete(id): void {
    this.Delete.emit(id);
  }
  onTrash(id): void {
    this.Trash.emit(id);
  }
  onOtherAction(id, action: string): void {
    this.OtherAction.emit({id: id, action: action});
  }



  // Muy mal, esta funcioin de booking no debería de estar aqui. La dejamos provisionalmente para no romper roomable
  clickAddBooking( e: any, data: any, item: any) {
    const dataObject =  {
      event: e,
      data: data,
      item: item
    }
    this.HandlerClickBtn.emit( dataObject );
  }

  inputModelChange(element, col): void {
    this.HandlerInputModelChange.emit({element: element, col: col});
  }

  showChildren() {
    if(this.accordionStatus) {
      if(this.accordionStatus) {
        let accordionStatus = this.accordionStatus;
        if(this.accordionStatusArgs.parent_ids){ // Si tiene padres accedemos a sus hijos (en ese conjunto de hijos estará el elemento en el que hemos clicado)
          for(let id of this.accordionStatusArgs.parent_ids) {
            accordionStatus = accordionStatus[id]['children'];
          }
        }
        let children = accordionStatus[this.accordionStatusArgs.element_id]['children']; // Accedemos a los hijos del elemento que hemos clicado
        for(let child of children) { // Por cada hijo le cambiamos el estado
          if(child) { // Hay indices que se quedan vacio ya que solo se rellenan los indices según los ids del os hijos
            if(child['class'] === 'show') {
              return true;
            } else if (child['class'] === '') {
              return false;
            }
          }
        }
      }
    }
  }
  setAccordionStatus() { // Método para cambiar el estado de una fila del acordeon
    if(this.accordionStatus) {
      let accordionStatus = this.accordionStatus;
      if(this.accordionStatusArgs.parent_ids){ // Si tiene padres accedemos a sus hijos (en ese conjunto de hijos estará el elemento en el que hemos clicado)
        for(let id of this.accordionStatusArgs.parent_ids) {
          accordionStatus = accordionStatus[id]['children'];
        }
      }
      let children = accordionStatus[this.accordionStatusArgs.element_id]['children']; // Accedemos a los hijos del elemento que hemos clicado
      for(let child of children) { // Por cada hijo le cambiamos el estado
        if(child) { // Hay indices que se quedan vacio ya que solo se rellenan los indices según los ids del os hijos
          if(child['class'] === 'show') {
            child['class'] = ''; // Si vamos a cerrar un hijo, debemos comprobar si tiene hijos para cerrarlos tb
            if(child['children']) {
                for(let subchild of child['children']) {
                  if(subchild) { // Hay indices que se quedan vacio ya que solo se rellenan los indices según los ids del os hijos
                    subchild['class'] = '';
                  }
                }
            }
          } else if (child['class'] === '') {
            child['class'] = 'show';
          }
        }
      }
      this.AccordionStatusChange.emit(this.accordionStatus);
    }
  }
}
