import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from '../../../../projects/bloonde-ngx-template/src/forms/abstract-form.component';
import { InputFormDirective } from '../../../../projects/bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from '../../../../projects/bloonde-ngx-template/src/forms/directives/label-form.directive';
import { Offer } from '../../../models/offer';
import { ValidationService } from '../../../../projects/bloonde-ngx-template/src/forms/services/validation.service';
import { OfferService } from '../../../services/offer.service';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../projects/bloonde-ngx-template/src/responses/message.service';
import { OfferFormConfiguration } from './offer-form-configuration';

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.sass']
})
export class OfferFormComponent extends AbstractFormComponent implements OnInit {

  @Input()
  public modelValue: Offer;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private offerService: OfferService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = OfferFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(): void {
    this.modelValue = new Offer();
    this.buildErrorModelAndEmitters();
  }
  public successResponseEdit(res): void {
    this.modelValue = Offer.copy(this.modelValue);
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.offerService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }
  successResponseStore(res): void {
    this.modelValue = Offer.copy(res);
  }
  public success(): void {
    this.onSuccess.emit({id: this.modelValue.id});
  }
  public preStore(args): void {
    if (this.inputFormComponents['sector_and_position']) {
      this.modelValue.sector_and_position = this.inputFormComponents['sector_and_position'].instance.getModel();
    }
  }
  public preUpdate(args): void {
    if (this.inputFormComponents['value']) {
      this.modelValue.sector_and_position = this.inputFormComponents['value'].instance.getModel();
    }
  }

  public extendConstructForm(): void {

    if (this.inputFormComponents['state_id'].instance.modelValue) {
      this.setDisabledField('city_id', false);
      this.inputFormComponents['city_id'].instance.filter = {state_id: this.inputFormComponents['state_id'].instance.modelValue};
      this.inputFormComponents['city_id'].instance.list();
    } else {
      this.setDisabledField('city_id', true);
    }

    /**
     * COMIENZO DETECCION DE CAMBIOS EN EL FORMULARIO
     */
    var component = this;
    this.getInputFormComponents('sector_and_position').onLoad.subscribe( function(data) {
      if (component.modelValue.sector_and_position) {
        component.getInputFormComponents('sector_and_position').setModel(component.modelValue.sector_and_position);
      }
    })
    this.inputFormComponents['state_id'].instance.modelChange.subscribe(function (value) { // Para detectar el cambio de state
      component.setDisabledField('city_id', !value); // Habilitamos o deshabilitamos dependiendo de si el value es distinto de null o no
      component.removeField('city_id', true); // Cada vez que cambiamos el valor del state_id reseteamos el valor del city_id porque si hay valor ese city_id ya no pertenece al stado seleccionado (porque lo hemos cambiado)
      component.inputFormComponents['city_id'].instance.filter = {state_id: value}; // Seteamos el filtro del city_id para que filtre por state_id en la peticion
      component.inputFormComponents['city_id'].instance.list(); // Hacemos un list del selector para que se traiga los nuevos cities filtrado por state_id
    });
    /**
     * FIN DETECCION DE CAMBIOS EN EL FORMULARIO
     */
  }

  checkForm(): boolean {
    return this.getValidationService().checkForm(this.errors, this.getModel()) && this.getInputFormComponents('sector_and_position').valid();
  }
}
