export class OfferCandidateTableVisibility {
  id: boolean;
  offer_name: boolean;
  candidate_name: boolean;
  status_id: boolean;
  registration_date: boolean;
  city_name: boolean;
  candidatures: boolean;

  constructor() {
    this.id = true;
    this.offer_name = true;
    this.candidate_name = true;
    this.status_id = true;
    this.registration_date = true;
    this.city_name = true;
    this.candidatures = true;
  }
}

