import {EventEmitter, Output} from '@angular/core';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class MyOffersCandidateTableFilter extends AbstractFilterComponent {

  status_id: any;
  statusIdModelChange: EventEmitter<any>
  candidate_id: any;
  @Output() positionModelChange: EventEmitter<any>;
  position: any;

  constructor() {
    super();
    this.status_id = null;
    this.statusIdModelChange = new EventEmitter();
    this.candidate_id = null;
    this.position = null;
    this.positionModelChange = new EventEmitter();
  }
  public toFilter(): string[] {
    return ['candidate_id', 'status_id', 'position'];
  }
  public emit() {
    this.statusIdModelChange.emit(this.status_id);
    this.positionModelChange.emit(this.position);
  }
  public copy(filter: {candidate_id: any, status_id: any, position: any}) {
    this.candidate_id = filter.candidate_id;
    this.status_id = filter.status_id;
    this.position = filter.position;
    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      candidate_id: this.candidate_id,
      status_id: this.status_id,
      position: this.position,
    };
  }
}

