import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PanelComponent } from '../pages/panel/panel.component';
import { NgTemplateModule } from '../../projects/bloonde-ngx-template/src/lib/ng-template.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LaravelTranslationLoader } from '../../projects/bloonde-ngx-template/src/translation/laravelTranslationLoader';
import { NgxBloondeUserAndPrivilegesModule } from '../../projects/ngx-bloonde-user-and-privileges/src/lib/ngx-bloonde-user-and-privileges.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebStorageModule } from 'ngx-store';
import { RegistrationFormService } from '../../projects/ngx-bloonde-user-and-privileges/src/components/forms/registration-form/registration-form.service';
import { LoginConfigService } from '../../projects/ngx-bloonde-user-and-privileges/src/components/forms/login-form/login-config.service';
import { LOGIN_STRUCTURE } from '../helpers/login.helper.';
import { REGISTRATION_STRUCTURE } from '../helpers/registration.helper';
import { Registration } from '../models/registration';
import { NgxBloondeGalleryModule } from '../../projects/ngx-bloonde-gallery/src/lib/ngx-bloonde-gallery.module';
import { NgxBloondeCacheModule } from '../../projects/ngx-bloonde-cache/src/lib/ngx-bloonde-cache.module';
import { GalleryPhotoService } from '../../projects/ngx-bloonde-gallery/src/services/galleryPhoto.service';
import { PhotoService } from '../services/photo.service';
import { OfferManagementPageComponent } from '../pages/offer-management-page/offer-management-page.component';
import { OfferManagementComponent } from '../components/managements/offer-management/offer-management.component';
import { OfferTableComponent } from '../components/tables/offer-table/offer-table.component';
import { OfferFormModalComponent } from '../components/modals/offer-form-modal/offer-form-modal.component';
import { OfferFormComponent } from '../components/forms/offer-form/offer-form.component';
import { OfferService } from '../services/offer.service';
import { StatusFilterComponent } from '../components/filters/status-filter/status-filter.component';
import { SectorFilterComponent } from '../components/filters/sector-filter/sector-filter.component';
import { PositionFilterComponent } from '../components/filters/position-filter/position-filter.component';
import { CityFilterComponent } from '../components/filters/city-filter/city-filter.component';
import { StatusService } from '../services/status.service';
import { PositionService } from '../services/position.service';
import { SectorService } from '../services/sector.service';
import { CityService } from '../services/city.service';
import { StatusSelectComponent } from '../components/inputs/status-select/status-select.component';
import { CitySelectComponent } from '../components/inputs/city-select/city-select.component';
import { SectorSelectComponent } from '../components/inputs/sector-select/sector-select.component';
import { PositionSelectComponent } from '../components/inputs/position-select/position-select.component';
import { OfferEditionPageComponent } from '../pages/offer-edition-page/offer-edition-page.component';
import { OfferResolver } from '../resolvers/offer.resolver';
import { CandidateProfilePageComponent } from '../pages/candidate-profile-page/candidate-profile-page.component';
import { CandidateService } from '../services/candidate.service';
import { NationalitySelectComponent } from '../components/inputs/nationality-select/nationality-select.component';
import { CandidateProfileFormComponent } from '../components/forms/candidate-profile-form/candidate-profile-form.component';
import { NationalityService } from '../services/nationality.service';
import { StateSelectComponent } from '../components/inputs/state-select/state-select.component';
import { StateService } from '../services/state.service';
import { CvExperienceManagementComponent } from '../components/managements/cv-experience-management/cv-experience-management.component';
import { CvExperienceTableComponent } from '../components/tables/cv-experience-table/cv-experience-table.component';
import { CvExperienceModalComponent } from '../components/modals/cv-experience-modal/cv-experience-modal.component';
import { CvExperienceFormComponent } from '../components/forms/cv-experience-form/cv-experience-form.component';
import { ActionConfirmationModalComponent } from '../components/modals/action-confirmation-modal/action-confirmation-modal.component';
import { CvExperienceService } from '../services/cv-experience.service';
import { CompanyTypeSelectComponent } from '../components/inputs/company-type-select/company-type-select.component';
import { SelectService } from '../services/select.service';
import { CvTrainingManagementComponent } from '../components/managements/cv-training-management/cv-training-management.component';
import { CvTrainingTableComponent } from '../components/tables/cv-training-table/cv-training-table.component';
import { CvTrainingModalComponent } from '../components/modals/cv-training-modal/cv-training-modal.component';
import { CvTrainingFormComponent } from '../components/forms/cv-training-form/cv-training-form.component';
import { TrainingTypeSelectComponent } from '../components/inputs/training-type-select/training-type-select.component';
import { CvTrainingService } from '../services/cv-training.service';
import { CvOthersFormComponent } from '../components/forms/cv-others-form/cv-others-form.component';
import { CvOtherService } from '../services/cv-other.service';
import { WorkingDaySelectComponent } from '../components/inputs/working-day-select/working-day-select.component';
import { ExperienceCardComponent } from '../components/cards/experience-card/experience-card.component';
import { CardsComponent } from '../components/cards/cards/cards.component';
import { CardDirective } from '../directives/card.directive';
import { TrainingCardComponent } from '../components/cards/training-card/training-card.component';
import { AdvancedSelectorComponent } from '../components/inputs/advanced-selector/advanced-selector.component';
import { CvLanguageManagementComponent } from '../components/managements/cv-language-management/cv-language-management.component';
import { CvLanguageFormComponent } from '../components/forms/cv-language-form/cv-language-form.component';
import { CvLanguageTableComponent } from '../components/tables/cv-language-table/cv-language-table.component';
import { CvLanguageModalComponent } from '../components/modals/cv-language-modal/cv-language-modal.component';
import { LevelSelectComponent } from '../components/inputs/level-select/level-select.component';
import { CandidateManagementPageComponent } from '../pages/candidate-management-page/candidate-management-page.component';
import { CandidateManagementComponent } from '../components/managements/candidate-management/candidate-management.component';
import { CandidateTableComponent } from '../components/tables/candidate-table/candidate-table.component';
import { ActivatedFilterComponent } from '../components/filters/activated-filter/activated-filter.component';
import { CandidateEditionPageComponent } from '../pages/candidate-edition-page/candidate-edition-page.component';
import { CandidateResolver } from '../resolvers/candidate.resolver';
import { OffersCandidatesManagementComponent } from '../components/managements/offers-candidates-management/offers-candidates-management.component';
import { OffersCandidatesTableComponent } from '../components/tables/offers-candidates-table/offers-candidates-table.component';
import { OffersCandidatesModalComponent } from '../components/modals/offers-candidates-modal/offers-candidates-modal.component';
import { OffersCandidatesFormComponent } from '../components/forms/offers-candidates-form/offers-candidates-form.component';
import { OfferCandidateService } from '../services/offer-candidate.service';
import { OfferSelectComponent } from '../components/inputs/offer-select/offer-select.component';
import { DateFilterComponent } from '../components/filters/date-filter/date-filter.component';
import { PreselectCandidateModalComponent } from '../components/modals/preselect-candidate-modal/preselect-candidate-modal.component';
import { PreselectCandidateFormComponent } from '../components/forms/preselect-candidate-form/preselect-candidate-form.component';
import { OfferCandidateManagementPageComponent } from '../pages/offer-candidate-management-page/offer-candidate-management-page.component';
import { OfferCandidateChangeStatusToDiscardFormComponent } from '../components/forms/offer-candidate-change-status-to-discard-form/offer-candidate-change-status-to-discard-form.component';
import { OfferCandidateChangeStatusToDiscardModalComponent } from '../components/modals/offer-candidate-change-status-to-discard-modal/offer-candidate-change-status-to-discard-modal.component';
import { ChatTableComponent } from '../components/tables/chat-table/chat-table.component';
import { ChatModalComponent } from '../components/modals/chat-modal/chat-modal.component';
import { ChatFormComponent } from '../components/forms/chat-form/chat-form.component';
import { ChatManagementPageComponent } from '../pages/chat-management-page/chat-management-page.component';
import { ChatManagementComponent } from '../components/managements/chat-management/chat-management.component';
import { ChatService } from '../services/chat.service';
import { MessageFormComponent } from '../components/forms/message-form/message-form.component';
import { ChatMessageService } from '../services/chat-message.service';
import { OfferCandidateSelectComponent } from '../components/inputs/offer-candidate-select/offer-candidate-select.component';
import { MessageManagementPageComponent } from '../pages/message-management-page/message-management-page.component';
import { MessageManagementComponent } from '../components/managements/message-management/message-management.component';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { FileUploadModule } from 'ng2-file-upload';
import { MyOfferCandidateManagementPageComponent } from '../pages/my-offer-candidate-management-page/my-offer-candidate-management-page.component';
import { ValuesPageComponentComponent } from '../pages/values-page-component/values-page-component.component';
import { ConditionPageComponentComponent } from '../pages/condition-page-component/condition-page-component.component';
import {RegistrationService} from 'bloonde-ngx-template/src/session/services/registration.service';
import { ExperienceFilterComponent } from '../components/filters/experience-filter/experience-filter.component';
import { PublicOfferSearchPageComponent } from '../pages/public-offer-search-page/public-offer-search-page.component';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { PublicComponent } from '../pages/public/public.component';
import { PrivateComponent } from '../pages/private/private.component';
import { LoginFormModalComponent } from '../components/modals/login-form-modal/login-form-modal.component';
import { SearchOfferTableComponent } from '../components/tables/search-offer-table/search-offer-table.component';
import { OfferCardComponent } from '../components/cards/offer-card/offer-card.component';
import { OfferDetailsComponent } from '../components/details/offer-details/offer-details.component';
import { OfferDetailsPageComponent } from '../pages/offer-details-page/offer-details-page.component';
import {PublicCandidateResolver} from '../resolvers/public-candidate.resolver';
import { MyOffersCandidateManagementComponent } from '../components/managements/my-offers-candidate-management/my-offers-candidate-management.component';
import { MyOffersCandidateTableComponent } from '../components/tables/my-offers-candidate-table/my-offers-candidate-table.component';
import { ThemeService } from '../services/theme.service';
import {LanguageService} from '../services/language.service';
import { LanguageSelectComponent } from '../components/inputs/language-select/language-select.component';
import { LanguageFilterComponent } from '../components/filters/language-filter/language-filter.component';
import { LanguageLevelFilterComponent } from '../components/filters/language-level-filter/language-level-filter.component';
import { YesNoFilterComponent } from '../components/filters/yes-no-filter/yes-no-filter.component';
import { TrainingDegreeFilterComponent } from '../components/filters/training-degree-filter/training-degree-filter.component';
import { TrainingLevelFilterComponent } from '../components/filters/training-level-filter/training-level-filter.component';
import { TrainingFilterComponent } from '../components/filters/training-filter/training-filter.component';
import {TermsPageComponent} from '../pages/terms-page/terms-page.component';
import {PrivacyPageComponent} from '../pages/privacy-page/privacy-page.component';
import { SuccessRegistrationModalComponent } from '../components/modals/success-registration-modal/success-registration-modal.component';
import { StateFilterComponent } from '../components/filters/state-filter/state-filter.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new LaravelTranslationLoader(http);
}
export function PhotoServiceFactory(http: HttpClient) {
  return new PhotoService(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PanelComponent,
    OfferManagementPageComponent,
    OfferManagementComponent,
    OfferTableComponent,
    OfferFormModalComponent,
    OfferFormComponent,
    StatusFilterComponent,
    SectorFilterComponent,
    PositionFilterComponent,
    CityFilterComponent,
    StatusSelectComponent,
    CitySelectComponent,
    SectorSelectComponent,
    PositionSelectComponent,
    OfferEditionPageComponent,
    CandidateProfileFormComponent,
    CandidateProfilePageComponent,
    NationalitySelectComponent,
    StateSelectComponent,
    CvExperienceManagementComponent,
    CvExperienceTableComponent,
    CvExperienceModalComponent,
    CvExperienceFormComponent,
    ActionConfirmationModalComponent,
    CompanyTypeSelectComponent,
    CvTrainingManagementComponent,
    CvTrainingTableComponent,
    CvTrainingModalComponent,
    CvTrainingFormComponent,
    TrainingTypeSelectComponent,
    CvOthersFormComponent,
    WorkingDaySelectComponent,
    ExperienceCardComponent,
    CardsComponent,
    CardDirective,
    TrainingCardComponent,
    AdvancedSelectorComponent,
    CvLanguageManagementComponent,
    CvLanguageTableComponent,
    CvLanguageModalComponent,
    CvLanguageFormComponent,
    LevelSelectComponent,
    CandidateManagementPageComponent,
    CandidateManagementComponent,
    CandidateTableComponent,
    ActivatedFilterComponent,
    CandidateEditionPageComponent,
    OffersCandidatesManagementComponent,
    OffersCandidatesTableComponent,
    OffersCandidatesModalComponent,
    OffersCandidatesFormComponent,
    OfferSelectComponent,
    DateFilterComponent,
    PreselectCandidateModalComponent,
    PreselectCandidateFormComponent,
    OfferCandidateManagementPageComponent,
    OfferCandidateChangeStatusToDiscardFormComponent,
    OfferCandidateChangeStatusToDiscardModalComponent,
    ChatTableComponent,
    ChatModalComponent,
    ChatFormComponent,
    ChatManagementPageComponent,
    ChatManagementComponent,
    MessageFormComponent,
    OfferCandidateSelectComponent,
    MessageManagementPageComponent,
    MessageManagementComponent,
    MyOfferCandidateManagementPageComponent,
    ValuesPageComponentComponent,
    ConditionPageComponentComponent,
    ExperienceFilterComponent,
    MyOfferCandidateManagementPageComponent,
    PublicOfferSearchPageComponent,
    HomePageComponent,
    PublicComponent,
    PrivateComponent,
    LoginFormModalComponent,
    SearchOfferTableComponent,
    OfferCardComponent,
    OfferDetailsComponent,
    OfferDetailsPageComponent,
    MyOffersCandidateManagementComponent,
    MyOffersCandidateTableComponent,
    LanguageSelectComponent,
    LanguageFilterComponent,
    LanguageLevelFilterComponent,
    YesNoFilterComponent,
    TrainingDegreeFilterComponent,
    TrainingLevelFilterComponent,
    TrainingFilterComponent,
    TermsPageComponent,
    PrivacyPageComponent,
    SuccessRegistrationModalComponent,
    StateFilterComponent
  ],
  imports: [
    NgTemplateModule,
    BrowserModule,
    RouterModule,
    FileUploadModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxBloondeUserAndPrivilegesModule,
    NgxBloondeGalleryModule.forRoot({
      imageService: {
        provide: GalleryPhotoService,
        useFactory: PhotoServiceFactory,
        deps: [HttpClient]
      }
    }),
    NgxBloondeCacheModule,
    WebStorageModule,
    NgbModule,
    NgProgressModule.withConfig({
      spinnerPosition: 'right',
      color: '#5c88da'
    }),
    NgProgressHttpModule
  ],
  providers: [
    PhotoService,
    OfferService,
    StatusService,
    PositionService,
    SectorService,
    CityService,
    StateService,
    NationalityService,
    OfferResolver,
    CandidateResolver,
    PublicCandidateResolver,
    CandidateService,
    CvExperienceService,
    CvTrainingService,
    CvOtherService,
    SelectService,
    CardDirective,
    OfferCandidateService,
    ChatService,
    ChatMessageService,
    {
      provide: RegistrationService,
      useClass: CandidateService
    },
    ThemeService,
    LanguageService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    StatusFilterComponent,
    CityFilterComponent,
    SectorFilterComponent,
    PositionFilterComponent,
    DateFilterComponent,
    StatusSelectComponent,
    CitySelectComponent,
    StateSelectComponent,
    NationalitySelectComponent,
    SectorSelectComponent,
    PositionSelectComponent,
    CompanyTypeSelectComponent,
    TrainingTypeSelectComponent,
    WorkingDaySelectComponent,
    ExperienceCardComponent,
    TrainingCardComponent,
    AdvancedSelectorComponent,
    LevelSelectComponent,
    ActivatedFilterComponent,
    OfferSelectComponent,
    OfferCandidateSelectComponent,
    ExperienceFilterComponent,
    OfferCandidateSelectComponent,
    OfferCardComponent,
    LanguageSelectComponent,
    LanguageFilterComponent,
    LanguageLevelFilterComponent,
    YesNoFilterComponent,
    TrainingDegreeFilterComponent,
    TrainingLevelFilterComponent,
    TrainingFilterComponent,
    StateFilterComponent
  ]
})
export class AppModule { }
RegistrationFormService.forRoot(REGISTRATION_STRUCTURE, Registration);
LoginConfigService.forRoot( LOGIN_STRUCTURE );

