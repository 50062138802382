import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SelectService } from '../../../services/select.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';

@Component({
  selector: 'app-advanced-selector',
  templateUrl: './advanced-selector.component.html',
  styleUrls: ['./advanced-selector.component.sass']
})
export class AdvancedSelectorComponent implements OnInit {

  @Output() onLoad: EventEmitter<any> = new EventEmitter();

  public error: boolean = false;
  public structure: any;

  public globalConfiguracion: any; // Configuración de la estructura de los selectores
  public levelConfigurations: any[]; // Los selectores serán enumerados a partir del 0
  public levelModels: any[]; // Modelo de cada uno de los selectores
  public debug = false; // Activar para mostrar información del comportamiento del componente por pantalla

  constructor(private selectService: SelectService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    this.reset();
    this.list();
  }

  list(): void {
    this.selectService.selectList(this.structure['option_type'])
      .subscribe(
        res => {
          this.globalConfiguracion = <any[]> res;
          this.prepareOptions(0); // Los selectores serán enumerados a partir del 0
          this.onLoad.emit(true);
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }
  loadOptions(level: any, fileName: any): void {
    this.selectService.selectList(fileName)
      .subscribe(
        res => {
          let options = [];
          for (let i in res) {
            options.push({
              label: res[i],
              value: i,
            });
          }
          this.levelConfigurations[level]['final_options'] = options;
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }
  prepareOptions(level: any, remove = true): void {
    if (remove) {
      this.removeGreaterThanLevel(level);
    }
    let levelConfig = this.globalConfiguracion['translated'] ? this.globalConfiguracion['options'] : this.globalConfiguracion;
    for (let i = 0; i < level; i++) {
      levelConfig = levelConfig['options'][this.levelModels[i]['value']];
    }
    if (levelConfig && levelConfig['config']) {
      if (levelConfig['config']['option_type'] === 'select') { // Si el selector es de tipo select
        let options = [];
        for (let key in levelConfig['options']) {
          options.push({
            label: this.globalConfiguracion['translated'] ? this.globalConfiguracion.translated[key] : key,
            value: key
          });
        }
        levelConfig['final_options'] = options;
      } else if (levelConfig['config']['option_type'] === 'file') { // Si las opciones se cargan de un fichero
        this.loadOptions(level, levelConfig['options']); // Hacemos una petición a back para obtener las opciones
      }
      this.levelConfigurations[level] = levelConfig;
      if (remove) {
        this.levelModels[level] = {type: levelConfig['config']['label'], value: null};
      }
    }
  }

  /**
   * Resetea todas las variables del componente
   */
  reset(): void {
    this.globalConfiguracion = [];
    this.levelConfigurations = [];
    this.levelModels = [];
    this.error = false;
  }
  // Borra opciones y models de nivel superior al indicado
  removeGreaterThanLevel(level: any): void {
    for (let i = (level + 1); i < this.levelConfigurations.length; i++) {
      this.levelConfigurations.splice(i, 1);
      this.levelModels.splice(i, 1);
    }
  }
  showModel(): string {
    return JSON.stringify(this.levelModels);
  }
  setModel(levelModel: any): void {
    if (this.levelConfigurations) {
      this.levelModels = levelModel;
      for (let i in levelModel) {
        if (this.levelModels[i]) {
          this.prepareOptions(i, false);
        }
      }
    }
  }
  getModel(): any {
    return this.levelModels;
  }
  valid(): boolean {
    let error = false;
    for (let levelModel of this.levelModels) {
      if (!levelModel.value) {
        error = true;
        break;
      }
    }
    return !error;
  }
}
