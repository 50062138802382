import {EventEmitter, Output} from '@angular/core';
import { FormatHelper } from '../../../../projects/bloonde-ngx-template/src/api/tools/format.helper';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class SearchOfferTableFilter extends AbstractFilterComponent {
  @Output() searchModelChange: EventEmitter<any>;
  search: string;
  @Output() positionIdModelChange: EventEmitter<any>;
  position_id: any;
  @Output() sectorIdModelChange: EventEmitter<any>;
  sector_id: any;
  @Output() cityIdModelChange: EventEmitter<any>;
  city_id: any;

  constructor() {
    super();
    this.search = null;
    this.searchModelChange = new EventEmitter();
    this.position_id = null;
    this.positionIdModelChange = new EventEmitter();
    this.sector_id = null;
    this.sectorIdModelChange = new EventEmitter();
    this.city_id = null;
    this.cityIdModelChange = new EventEmitter();
  }
  public toFilter(): any[] {
    return [
      'search',
      'position_id',
      'sector_id',
      'city_id'
      ];
  }
  public emit() {
    this.searchModelChange.emit(this.search);
    this.cityIdModelChange.emit(this.city_id);
    this.positionIdModelChange.emit(this.position_id);
    this.sectorIdModelChange.emit(this.sector_id);
  }
  public copy(filter: {search: string, position_id: any, sector_id: any, city_id: any}) {
    this.search = filter.search;
    this.position_id = filter.position_id;
    this.sector_id = filter.sector_id;
    this.city_id = filter.city_id;
    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      search: this.search,
      position_id: this.position_id,
      sector_id: this.sector_id,
      city_id: this.city_id
    };
  }
}

