import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OffersCandidatesTableComponent } from '../../tables/offers-candidates-table/offers-candidates-table.component';
import { OffersCandidatesModalComponent } from '../../modals/offers-candidates-modal/offers-candidates-modal.component';
import { OfferCandidateService } from '../../../services/offer-candidate.service';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { ActionConfirmationModalComponent } from '../../modals/action-confirmation-modal/action-confirmation-modal.component';
import { OfferCandidateChangeStatusToDiscardModalComponent } from '../../modals/offer-candidate-change-status-to-discard-modal/offer-candidate-change-status-to-discard-modal.component';

@Component({
  selector: '[app-offers-candidates-management]',
  templateUrl: './offers-candidates-management.component.html',
  styleUrls: ['./offers-candidates-management.component.sass']
})
export class OffersCandidatesManagementComponent implements OnInit {

  @Input() public candidate_id: any;
  @Input() public offer_id: any;

  @Input()
  public offerCandidateTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'offer-candidate-table',
    hasActionColumn: true,
    hasCheckboxColumn: true
  };

  @ViewChild(OffersCandidatesTableComponent) offersCandidatesTableComponent;
  @ViewChild(OffersCandidatesModalComponent) offersCandidatesModalComponent;
  @ViewChild(ActionConfirmationModalComponent) actionConfirmationModalComponent;
  @ViewChild(OfferCandidateChangeStatusToDiscardModalComponent) offerCandidateChangeStatusToDiscardModalComponent;

  public aux_id: any;
  public aux_action: any;
  public msgModal: string;

  constructor(private offerCandidateService: OfferCandidateService,
              private messageService: MessageService,
              private navigationHelper: NavigationHelper,
              private responseHelper: ResponseHelper) { }


  ngOnInit() {
  }

  updateTable(): void {
    const component = this;
    setTimeout(function() {
      component.offersCandidatesTableComponent.list();
    });
  }

  create(): void {
    this.offersCandidatesModalComponent.show('create', null);
  }
  edit(id: any): void {
    this.offersCandidatesModalComponent.show('edit', id);
  }
  delete(id: any): void {
    this.aux_id = id;
    this.actionConfirmationModalComponent.openModal();
  }
  confirmDelete(): void {
    this.offerCandidateService.delete(this.aux_id).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  onOtherAction($event) {
    this.aux_id = $event.id;
    this.aux_action = $event.action;
    switch ( $event.action ) {
      case 'interviewing':
        this.toInterviewing();
        break;
      case 'discard':
        this.toDiscard();
        break;
      case 'selected':
        this.toSelected();
        break;
      case 'see':
        this.goToCandidateEdition();
        break;
    }
  }
  clickHeaderBtn(e) {
    this.aux_action = e.action;
    if ( e.action === 'bulk-interviewing') {
      this.toBulkInterviewing();
    } else if ( e.action === 'bulk-discard') {
      this.toBulkDiscard();
    } else if ( e.action === 'bulk-selected') {
      this.toBulkSelected();
    }
  }
  actionModal(): void {
    switch ( this.aux_action ) {
      case 'delete':
        this.confirmDelete();
        break;
      case 'interviewing':
        this.confirmChangeStatus(OfferCandidateService.INTERVIEWING_STATUS_ID);
        break;
      case 'bulk-interviewing':
        this.confirmBulkChangeStatus(OfferCandidateService.INTERVIEWING_STATUS_ID);
        break;
      case 'selected':
        this.confirmChangeStatus(OfferCandidateService.SELECTED_STATUS_ID);
        break;
      case 'bulk-selected':
        this.confirmBulkChangeStatus(OfferCandidateService.SELECTED_STATUS_ID);
        break;
    }
  }
  toInterviewing(): void {
    this.msgModal = '¿Estás seguro de querer pasar esta candidatura a estado entrevistando?';
    this.actionConfirmationModalComponent.openModal();
  }
  toBulkInterviewing(): void {
    this.msgModal = '¿Estás seguro de querer pasar estas candidaturas a estado entrevistando? (Solo se pasarán aquellas en estado en espera)';
    this.actionConfirmationModalComponent.openModal();
  }
  toSelected(): void {
    this.msgModal = '¿Estás seguro de querer pasar esta candidatura a estado seleccionado?';
    this.actionConfirmationModalComponent.openModal();
  }
  toBulkSelected(): void {
    this.msgModal = '¿Estás seguro de querer pasar estas candidaturas a estado seleccionado? (Solo se pasarán aquellas en estado en entrevistando)';
    this.actionConfirmationModalComponent.openModal();
  }
  toDiscard(): void {
    this.msgModal = '¿Estás seguro de querer pasar esta candidatura a estado descartado?';
    this.offerCandidateChangeStatusToDiscardModalComponent.show('create', null, {offer_candidate_ids: [this.aux_id]});
  }
  toBulkDiscard(): void {
    this.msgModal = '¿Estás seguro de querer pasar estas candidaturas a estado descartado?';
    this.offerCandidateChangeStatusToDiscardModalComponent.show('create', null, {offer_candidate_ids: this.offersCandidatesTableComponent.selected});
  }

  goToCandidateEdition(): void {
    let candidate_id = null;
    for (let element of this.offersCandidatesTableComponent.getElements()) {
      if (this.aux_id == element.id) {
        candidate_id = element.candidate_id;
        break;
      }
    }
    this.navigationHelper.navigateTo('candidate-edition', {}, candidate_id);
  }

  confirmChangeStatus(status_id: any) {
    this.offerCandidateService.changeStatus(this.aux_id, status_id).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
        this.messageService.showSuccessMessage();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
  confirmBulkChangeStatus(status_id: any) {
    let data = {
      offer_candidate_ids: this.offersCandidatesTableComponent.selected,
      status_id: status_id
    }
    this.offerCandidateService.bulkChangeStatus(data).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
        this.messageService.showSuccessMessage();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

}
