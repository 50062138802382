import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { StatusService } from '../../../services/status.service';

@Component({
  selector: '[app-status-filter],app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.sass']
})
export class StatusFilterComponent implements OnInit {

  @Input()
  disabled: boolean;
  @Input()
  type: string;
  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  statuses: any[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'Status', placeholder: 'Status', options: []};

  constructor(private statusService: StatusService,
              private responseHelper: ResponseHelper) { }
  ngOnInit() {
    this.list();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    this.statusService.selectList(this.type)
      .subscribe(
        res => {
          this.statuses = <any[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for (let i in this.statuses) {
      let status = this.statuses[i];
      options.push({
        value: i,
        label: status
      });
    }
    this.options['options'] = options;
  }

}
