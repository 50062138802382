import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { ListRequestData } from '../../../bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../../bloonde-ngx-template/src/api/tools/query-params.helper';
import { Image } from '../models/image';


@Injectable()
export class GalleryImageService {

  constructor(public _http: HttpClient) {
  }
  /**
   * Para borrar una imagen de la carpeta public y que aun no ha sido guardado en BD
   * @param path
   * @returns {Observable<Object>}
   */
  deleteImage(path: any): Observable<Object> {
    return this._http.delete(ApiRoutesHelper.getImageDeleteImageURL(path));
  }
}
