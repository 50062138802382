export class SearchOfferTableVisibility {
  id: boolean;
  name: boolean;
  already_apply: boolean;
  city_name: boolean;
  description: boolean;
  institution: boolean;
  status_id: boolean;
  sector_name: boolean;
  position_name: boolean;
  functions: boolean;
  expected_contract_date: boolean;
  candidatures: boolean;

  constructor() {
    this.id = false;
    this.already_apply = false;
    this.name = true;
    this.city_name = true;
    this.description = true;
    this.institution = true;
    this.status_id = true;
    this.sector_name = true;
    this.position_name = true;
    this.functions = true;
    this.expected_contract_date = true;
    this.candidatures = true;
  }
}

