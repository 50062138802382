import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { State } from '../../../models/state';
import { StateService } from '../../../services/state.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: 'app-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.sass']
})
export class StateSelectComponent implements OnInit {

  @Input()
  multiple: boolean;
  @Input()
  addTag: boolean;
  @Input()
  error: any;
  @Input()
  serverErrors: any;

  states: State[];

  @Input()
  get model() {
    return this.modelValue;
  }
  @Output()
  modelChange = new EventEmitter<any>();
  @Output()
  goToStateEmitter = new EventEmitter<any>();

  public modelValue: string;


  public structure: any;

  constructor(private stateService: StateService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    if (this.structure.autoload !== false) {
      this.list();
    }
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, [], []);
    this.stateService.selectList(listRequestData)
      .subscribe(
        res => {
          this.states = <State[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }
  goToState(): void {
    this.goToStateEmitter.emit(this.modelValue);
  }

  private prepareOptions(): void {
    let options = [];
    for(let i in this.states) {
      let state = this.states[i];
      options.push({
        value: state.id,
        label: state.name
      });
    }
    this.structure['options'] = options;
  }

}
