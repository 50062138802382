import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { CVOther } from '../../../models/cv_other';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { CVOtherFormConfiguration } from './cv-others-form-configuration';
import { TemplateHelper } from 'bloonde-ngx-template/src/templates/helpers/template.helper';
import { CvOtherService } from '../../../services/cv-other.service';

@Component({
  selector: 'app-cv-others-form',
  templateUrl: './cv-others-form.component.html',
  styleUrls: ['./cv-others-form.component.sass']
})
export class CvOthersFormComponent extends AbstractFormComponent implements OnInit {

  @Input() modelValue: CVOther;
  // ViewChild
  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;
  // Outputs (events)


  // Other attributes
  inputFormComponents: any[];
  labelFormComponents: any[];

  @Input() candidate_id: any;

  constructor(private validationService: ValidationService,
              private cvOtherService: CvOtherService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = CVOtherFormConfiguration.getStructure();
  }

  ngOnInit() {
  }

  public new(args: any[]): void {
    this.args = args;
    this.modelValue = new CVOther();
    this.modelValue.candidate_id = this.args['candidate_id'];
    this.buildErrorModelAndEmitters();
  }
  preEdit(args: any[]): void {
    this.args = args;
  }
  successResponseEdit(res: any): void {
    this.modelValue.candidate_id = this.args['candidate_id'];
    this.modelValue.id = this.args['candidate_id'];
    this.modelValue = CVOther.copy(this.modelValue);
  }

  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }

  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }

  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getInputFormComponents(key: any): void {
    if (this.inputFormComponents[key]) {
      return this.inputFormComponents[key].instance;
    }
  }

  public getLabelFormComponents(key: any): void {
    if (this.labelFormComponents[key]) {
      return this.labelFormComponents[key].instance;
    }
  }

  public getConditionFormComponents(key: any): void {
    return this.conditionFormComponents[key];
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }

  public getModelService(): any {
    return this.cvOtherService;
  }

  public getValidationService(): ValidationService {
    return this.validationService;
  }

  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }

  setModel(model: any): any {
    this.modelValue = model;
  }

  public extendConstructForm(): void {
    // // Controlar inicialización del formulario
    var component = this;
    let fields = CVOtherFormConfiguration.fields;
    for (let field of fields) {
      if (field.info) {
        let commentLabel = field['label'] + '_info';
        this.setDisabledField(commentLabel, this.inputFormComponents[field.label].instance.modelValue);
        this.setVisibilityField(commentLabel, this.inputFormComponents[field.label].instance.modelValue ? this.inputFormComponents[field.label].instance.modelValue : false);

        // // Controlar cambios del formulario
        this.inputFormComponents[field.label].instance.modelChange.subscribe(function (value) { // Para detectar el cambio
          // Los elementos con el atributo show_and_hide_login = true tienen que ocultarse o mostrarse dependiendo del valor del checkbox principal
          component.setDisabledField(commentLabel, !value);
          component.setVisibilityField(commentLabel, value);
        });
      }
      if (field.document) {
        let documentLabel = field['label'] + '_document';
        this.setDisabledField(documentLabel, this.inputFormComponents[field.label].instance.modelValue);
        this.setVisibilityField(documentLabel, this.inputFormComponents[field.label].instance.modelValue ? this.inputFormComponents[field.label].instance.modelValue : false);

        // // Controlar cambios del formulario
        this.inputFormComponents[field.label].instance.modelChange.subscribe(function (value) { // Para detectar el cambio
          // Los elementos con el atributo show_and_hide_login = true tienen que ocultarse o mostrarse dependiendo del valor del checkbox principal
          component.setDisabledField(documentLabel, !value);
          component.setVisibilityField(documentLabel, value);
        });
        this.inputFormComponents[documentLabel].instance.delete.subscribe(function() {
          component.removeField(documentLabel, true);
          component.modelValue[documentLabel] = null;
        });
      }
      if (field['expedition_date'] && field['expedition_date']['exists']) {
        let expeditionDateLabel = field['label'] + '_expedition_date';
        this.setDisabledField(expeditionDateLabel, this.inputFormComponents[field.label].instance.modelValue);
        this.setVisibilityField(expeditionDateLabel, this.inputFormComponents[field.label].instance.modelValue ? this.inputFormComponents[field.label].instance.modelValue : false);

        // // Controlar cambios del formulario
        this.inputFormComponents[field.label].instance.modelChange.subscribe(function (value) { // Para detectar el cambio
          // Los elementos con el atributo show_and_hide_login = true tienen que ocultarse o mostrarse dependiendo del valor del checkbox principal
          component.setDisabledField(expeditionDateLabel, !value);
          component.setVisibilityField(expeditionDateLabel, value);
        });
      }
      if (field['accept'] && field['accept']['exists']) {
        let sexualAbuseAcceptLabel = field['label'] + '_accept';
        this.setDisabledField(sexualAbuseAcceptLabel, this.inputFormComponents[field.label].instance.modelValue);
        this.setVisibilityField(sexualAbuseAcceptLabel, this.inputFormComponents[field.label].instance.modelValue ? this.inputFormComponents[field.label].instance.modelValue : false);

        // // Controlar cambios del formulario
        this.inputFormComponents[field.label].instance.modelChange.subscribe(function (value) { // Para detectar el cambio
          // Los elementos con el atributo show_and_hide_login = true tienen que ocultarse o mostrarse dependiendo del valor del checkbox principal
          component.setDisabledField(sexualAbuseAcceptLabel, !value);
          component.setVisibilityField(sexualAbuseAcceptLabel, value);
        });
      }
    }
  }
}
