import { Injectable, Provider } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class NavigationHelper {

  public static ROUTES = [];
  constructor(private router: Router) {
  }


  public navigateTo(page: string, queryParams?: any, urlParams: any = null, targetBlank = false, user: any = null): void {
    let url = NavigationHelper.getRoute(page, user);
    if(urlParams) {
      url = url + urlParams;
    }
    if(url) {
      const extras = {};

      if (queryParams) {
        extras['queryParams'] = queryParams;
      }

      if(targetBlank) { // Si queremos que se abra en una página aparte
        window.open(url, "_blank")
      } else {
        this.router.navigate([url], extras);
      }
    } else {
      console.log('Undefined ' + url);
    }
  }
  public navigateToRoute(route: string): void {
    let route_splited = route.split('?');
    let params = [];
    if(route_splited[1]) {
      params = route_splited[1].split('&')
    }
    let queryParams = {};
    for(let param of params) {
      let param_splited = param.split('=');
      queryParams[param_splited[0]] = param_splited[1];
    }
    this.router.navigate([route_splited[0]], {queryParams: queryParams});
  }
  public static getRoute(page: string, user: any) {
    return NavigationHelper.ROUTES[page];
  }
  public static forRoot(routes: any): Provider {
    NavigationHelper.ROUTES = routes;
    return NavigationHelper
  }
  public static forRootGetRoute(getRoute: any) {
    this.getRoute = getRoute;
  }
}
