import { Component, OnInit } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.css']
})
export class ImgComponent extends AbstractInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() { }

}
