import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractInputComponent } from '../../../../../bloonde-ngx-template/src/inputs/abstract-input.component';
import { FileQueueObject, ImageUploaderOptions } from 'ngx-image-uploader';
import { GalleryImageService } from '../../../services/galleryImage.service';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import { FileUploader } from 'ng2-file-upload';
import { ApiConfigHelper } from '../../../../../bloonde-ngx-template/src/api/api-config.helper';
import { ResponseHelper } from '../../../../../bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../../bloonde-ngx-template/src/responses/message.service';
import { CodesHelper } from '../../../../../bloonde-ngx-template/src/responses/codes.helper';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css']
})
export class ImageUploaderComponent extends AbstractInputComponent implements OnInit {

  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Output() success: EventEmitter<any> = new EventEmitter();

  @Output() error: EventEmitter<any> = new EventEmitter();

  public uploader;

  constructor(private galleryImageService: GalleryImageService,
              private responseHelper: ResponseHelper) {
    super();
  }

  ngOnInit() {
    this.uploader = new FileUploader({url: this.structure.url, authToken: 'Bearer ' + this.structure.token, autoUpload: true});
    this.uploader.onCompleteItem = (item, response, status, header) => {
      if (status === 200) {
        const res = JSON.parse(response);
        this.model = res['path'];
        this.success.emit();
        this.modelChange.emit(this.model);
      } else {
        const res = JSON.parse(response);
        this.error.emit(res['file'][0]);
      }
    };
  }
  onDelete(path) {
    this.galleryImageService.deleteImage(path).subscribe(
      res => {
        this.model = null;
      },
      error => {
        if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
        } else {
          this.responseHelper.handleError(error);
        }
      }
    );
  }
  downloadFile(file_id: any) {
    window.location.href = this.structure.download_url + file_id + '?token=' + this.structure.token;
  }
  getImage(url: any) {
    return ApiConfigHelper.IMAGE_FOLDER + url;
  }
}
