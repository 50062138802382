import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Offer } from '../../../models/offer';
import { OfferService } from '../../../services/offer.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: 'app-offer-select',
  templateUrl: './offer-select.component.html',
  styleUrls: ['./offer-select.component.sass']
})
export class OfferSelectComponent implements OnInit {

  @Input()
  multiple: boolean;
  @Input()
  addTag: boolean;
  @Input()
  error: any;
  @Input()
  serverError: any;

  offers: Offer[];

  @Input()
  get model() {
    return this.modelValue;
  }
  @Output()
  modelChange = new EventEmitter<any>();
  @Output()
  modelOwnerChange = new EventEmitter<any>();

  public modelValue: string;


  public structure: any;

  constructor(private offerService: OfferService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    if (this.structure.autoload !== false) {
      this.list();
    }
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, [], []);
    this.offerService.selectList(listRequestData)
      .subscribe(
        res => {
          this.offers = <Offer[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for(let i in this.offers) {
      let offer = this.offers[i];
      options.push({
        value: offer.id,
        label: offer.name,
      });
    }
    this.structure['options'] = options;
  }

}
