import {EventEmitter, Output} from '@angular/core';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class CandidateTableFilter extends AbstractFilterComponent {
  @Output() searchModelChange: EventEmitter<any>;
  search: string;
  @Output() activatedModelChange: EventEmitter<any>;
  activated: any;
  @Output() positionModelChange: EventEmitter<any>;
  position: any;
  @Output() experienceModelChange: EventEmitter<any>;
  experience: any;
  @Output() disabledModelChange: EventEmitter<any>;

  @Output() languageModelChange: EventEmitter<any>;
  language: any;
  @Output() languageLevelModelChange: EventEmitter<any>;
  languageLevel: any;
  @Output() disableLanguageLevelModelChange: EventEmitter<any>;

  @Output() withCertificateModelChange: EventEmitter<any>;
  withCertificate: any;
  @Output() volunteerModelChange: EventEmitter<any>;
  volunteer: any;
  @Output() maesModelChange: EventEmitter<any>;
  maes: any;
  @Output() decaModelChange: EventEmitter<any>;
  deca: any;

  @Output() trainingDegreeModelChange: EventEmitter<any>;
  trainingDegree: any;
  @Output() trainingLevelModelChange: EventEmitter<any>;
  trainingLevel: any;
  @Output() trainingModelChange: EventEmitter<any>;
  training: any;

  @Output() setDegreeModelChange: EventEmitter<any>;
  @Output() setLevelModelChange: EventEmitter<any>;

  @Output() stateIdModelChange: EventEmitter<any>;
  state_id: any;


  constructor() {
    super();
    this.search = null;
    this.searchModelChange = new EventEmitter();
    this.activated = true;
    this.activatedModelChange = new EventEmitter();
    this.position = null;
    this.positionModelChange = new EventEmitter();
    this.experience = null;
    this.experienceModelChange = new EventEmitter();
    this.disabledModelChange = new EventEmitter();
    this.language = null;
    this.languageModelChange = new EventEmitter();
    this.languageLevel = null;
    this.languageLevelModelChange = new EventEmitter();
    this.disableLanguageLevelModelChange = new EventEmitter();
    this.withCertificate = null;
    this.withCertificateModelChange = new EventEmitter();
    this.volunteer = null;
    this.volunteerModelChange = new EventEmitter();
    this.maes = null;
    this.maesModelChange = new EventEmitter();
    this.deca = null;
    this.decaModelChange = new EventEmitter();

    this.trainingDegree = null;
    this.trainingDegreeModelChange = new EventEmitter();
    this.trainingLevel = null;
    this.trainingLevelModelChange = new EventEmitter();
    this.training = null;
    this.trainingModelChange = new EventEmitter();
    this.setDegreeModelChange = new EventEmitter();
    this.setLevelModelChange = new EventEmitter();

    this.state_id = null;
    this.stateIdModelChange = new EventEmitter();
  }
  public toFilter(): string[] {
    return ['search', 'activated', 'position', 'experience', 'language', 'languageLevel', 'withCertificate', 'volunteer',
      'maes', 'deca', 'trainingDegree', 'trainingLevel', 'training', 'state_id'];
  }
  public emit() {
    this.searchModelChange.emit(this.search);
    this.activatedModelChange.emit(this.activated);
    this.positionModelChange.emit(this.position);
    this.experienceModelChange.emit(this.experience);
    this.languageModelChange.emit(this.language);
    this.languageLevelModelChange.emit(this.languageLevel);
    this.withCertificateModelChange.emit(this.withCertificate);
    this.volunteerModelChange.emit(this.volunteer);
    this.maesModelChange.emit(this.maes);
    this.decaModelChange.emit(this.deca);

    this.trainingDegreeModelChange.emit(this.trainingDegree);
    this.trainingLevelModelChange.emit(this.trainingLevel);
    this.trainingModelChange.emit(this.training);

    this.stateIdModelChange.emit(this.state_id);
  }
  public copy(filter: {search: string, activated: any, position: any, experience: any, language: any, languageLevel: any,
    withCertificate: any, volunteer: any, maes: any, deca: any, trainingDegree: any, trainingLevel: any, training: any, state_id: any}) {
    this.search = filter.search;
    this.activated = filter.activated;
    this.position = filter.position;
    this.experience = filter.experience;
    this.language = filter.language;
    this.languageLevel = filter.languageLevel;
    this.withCertificate = filter.withCertificate;
    this.volunteer = filter.volunteer;
    this.maes = filter.maes;
    this.deca = filter.deca;

    this.trainingDegree = filter.trainingDegree;
    this.trainingLevel = filter.trainingLevel;
    this.training = filter.training;

    this.state_id = filter.state_id;
    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      search: this.search,
      activated: this.activated,
      position: this.position,
      experience: this.experience,
      language: this.language,
      languageLevel: this.languageLevel,
      withCertificate: this.withCertificate,
      volunteer: this.volunteer,
      maes: this.maes,
      deca: this.deca,

      trainingDegree: this.trainingDegree,
      trainingLevel: this.trainingLevel,
      training: this.training,

      state_id: this.state_id,
    };
  }
}

