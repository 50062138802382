import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { CacheHelper } from '../../../../src/helpers/cache.helper';
import { LocalStorageElement } from '../models/LocalStorageElement';


@Injectable()
export class CacheService {

  constructor(private localStorageService: LocalStorageService) {
  }

  saveInLocalStorage(key, data, withExpires = true): void {
    if (withExpires) {
      let timestamp = (new Date()).getTime();
      let expires = CacheHelper.EXPIRES_CONFIG[key] ? timestamp + CacheHelper.EXPIRES_CONFIG[key] : timestamp + CacheHelper.EXPIRES_CONFIG['default']; // TODO - Dani: Quitar esta referencia a proyecto base y usar un get y un foor root para añadir la configuracion desde el proyecto base
        data = {
          data: data,
          expires: expires
        };
    }
    this.localStorageService.set(key, data);
  }
  getInLocalStorage(key, withExpires = true): any {
    let data = this.localStorageService.get(key);
    if (data && withExpires && this.isValid(data)) {
        return data['data'];
    }
    return null;
  }

  isValid(localStorageElements: LocalStorageElement): boolean {
    if (localStorageElements.expires) {
      return (new Date()).getTime() < localStorageElements.expires;
    }
    return false;
  }
}
