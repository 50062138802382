export class DefaultClassNavbar {
  public static defaultCss = {
    // Raiz
    navbar: 'navbar',
    // Bloque logo
    navbar__logo: '',
    navbar__logo__img: 'navbar__logo__img',
    // Bloque menú
    navbar__menu: 'navbar__menu',
    navbar__menu__ul: 'navbar__menu__ul',
    navbar__menu__li: 'navbar__menu__li',
    navbar__menu__a: 'navbar__menu__a',
    // Bloque búsqueda
    navbar__search: 'navbar__search',
    header_search_style: {
      search: 'container-filter-search',
      search__input: 'form-control input-filter-search',
      search__span: 'icon-filter-search',
      search__icon: 'fas fa-search icon'
    },
    // Bloque botones
    navbar__buttons: 'navbar__buttons',
    navbar__buttons__login: 'navbar__login d-inline',
    navbar__buttons__registration: 'navbar__registration',
    // Bloque usuario
    navbar__user: 'navbar__user',
    navbar__user__dropdown: 'navbar__user__dropdown',
    navbar__user__img: 'navbar__user__img',
    navbar__user__name: '',
    navbar__user__dropdown__menu: '',
    navbar__user__profiles: 'profiles',
    navbar__user__profile: 'profile',
    navbar__user__menu: '',
    navbar__user__menu__a: '',
  }
}
