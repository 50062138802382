import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractFormModalComponent } from '../../../../../bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';
import { RegistrationFormComponent } from '../../forms/registration-form/registration-form.component';

@Component({
  selector: 'bl-registration-form-modal',
  templateUrl: './registration-form-modal.component.html',
  styleUrls: ['./registration-form-modal.component.sass']
})
export class RegistrationFormModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: false, save: true, save_and_new: false, save_and_edit: false};
  @Input() buttonsText: {cancelText: string, saveText: string, saveAndNewText: string, saveAndEditText: string, deleteText: string} = {cancelText: null, saveText: 'register', saveAndNewText: null, saveAndEditText: null, deleteText: null};

  @Input() withSuccesMessage = true;

  @ViewChild(RegistrationFormComponent) formComponent;

  public modal_id = 'registration_modal';

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public getFormComponent(): any {
    return this.formComponent;
  }

  saveAndClose(): void {
    this.getFormComponent().registration();
  }
}
