import { TemplateHelper } from 'bloonde-ngx-template/src/templates/helpers/template.helper';
import { FileComponent } from 'bloonde-ngx-template/src/inputs/file/file.component';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import { DateComponent } from 'bloonde-ngx-template/src/inputs/date/date.component';
export class CVOtherFormConfiguration {

  public static fields = [
    {label: 'sexual_abuse', type: 'checkbox', document: true,
      help: 'Se puede obtener online en el Ministerio de Justicia. No debe tener fecha de expedición superior a 6 meses de antigüedad',
      info: false, expedition_date: {exists: true, limit: 6},
      accept: {exists: true}},
    {label: 'disability', type: 'checkbox', document: true, info: true},
    {label: 'long_unemployed', type: 'checkbox', document: true, info: false},
    {label: 'young_bonus', type: 'checkbox', document: true, info: false},
    {label: 'residence_change', type: 'checkbox', document: false, info: false},
    {label: 'driving_license', type: 'checkbox', document: false, info: false},
    {label: 'vehicle', type: 'checkbox', document: false, info: false},
  ]

  public static getStructure(): any {
    let configuration = [];
    for (let field of CVOtherFormConfiguration.fields) {
      let fieldConfiguration = {
        name: field['label'],
        label: field['label'],
        type: 'checkbox',
        col: 'col-5 col-md-12',
        style: 'check__reverse form-checkbox'
      };
      configuration.push(fieldConfiguration);
      if (field.document) {
        let fieldCommentConfiguration = {
          name: field['label'] + '_document',
          label: field['label'] + '_document',
          type: FileComponent,
          col: 'col-12 col-md-4',
          rules: [
            'required_if:' + field['label'] + ',true'
          ],
          url: ApiRoutesHelper.getUploadURL(),
          download_url: ApiRoutesHelper.FILE_FOLDER
        };
        if (field['help']) {
          fieldCommentConfiguration['config'] = {
            hasHelp: true
          };
          fieldCommentConfiguration['help'] = field['help'];
        }
        configuration.push(fieldCommentConfiguration);
      }
      if (field.info) {
        let fieldCommentConfiguration = {
          name: field['label'] + '_info',
          label: field['label'] + '_info',
          type: 'textarea',
          col: 'col-12 col-md-4'
        };
        configuration.push(fieldCommentConfiguration);
      }
      if (field['expedition_date'] && field['expedition_date']['exists']) {
        let fieldCommentConfiguration = {
          name: field['label'] + '_expedition_date',
          label: field['label'] + '_expedition_date',
          rules: [
            'required_if:' + field['label'] + ',true'
          ],
          type: DateComponent,
          col: 'col-12 col-md-4',
          limit: field['expedition_date']['limit'],
          limit_error: 'expedition_error'
        };
        configuration.push(fieldCommentConfiguration);
      }
      if (field['accept'] && field['accept']['exists']) {
        let fieldAcceptConfig = {
          name: field['label'] + '_accept',
          label: field['label'] + '_accept',
          rules: [
            'required_if:' + field['label'] + ',true'
          ],
          type: 'checkbox',
          col: 'col-12 col-md-4'
        };
        configuration.push(fieldAcceptConfig);
      }
    }
    configuration = TemplateHelper.addToConfiguration([], configuration);
    return configuration;
  }
}
