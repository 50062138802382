import { Component, OnInit, ViewChild } from '@angular/core';
import { ChatTableComponent } from '../../tables/chat-table/chat-table.component';
import { PreselectCandidateModalComponent } from '../../modals/preselect-candidate-modal/preselect-candidate-modal.component';
import { ActionConfirmationModalComponent } from '../../modals/action-confirmation-modal/action-confirmation-modal.component';
import { ChatService } from '../../../services/chat.service';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ChatModalComponent } from '../../modals/chat-modal/chat-modal.component';

@Component({
  selector: '[app-chat-management]',
  templateUrl: './chat-management.component.html',
  styleUrls: ['./chat-management.component.sass']
})
export class ChatManagementComponent implements OnInit {

  public chatTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'chat-table',
    hasActionColumn: true,
    hasCheckboxColumn: false
  };

  @ViewChild(ChatTableComponent) chatTableComponent;
  @ViewChild(ChatModalComponent) chatModalComponent;
  @ViewChild('confirmDeleteModal') confirmDeleteModal;
  @ViewChild('confirmOpenModal') confirmOpenModal;

  public aux_id_to_delete: any;

  constructor(private chatService: ChatService,
              private navigationHelper: NavigationHelper,
              private responseHelper: ResponseHelper) { }


  ngOnInit() {
  }

  updateTable(): void {
    const component = this;
    setTimeout(function() {
      component.chatTableComponent.list();
    });
  }
  create(): void {
    this.chatModalComponent.show('create', null);
  }
  edit(id: any): void {
    this.navigationHelper.navigateTo('messages', {}, id);
  }
  // Aunque se llama delete lo que hacemos es cerrar el chat
  delete(id: any): void {
    this.aux_id_to_delete = id;
    this.confirmDeleteModal.openModal();
  }

  onOtherAction($event): void {
    if ($event['action'] === 'open') {
      this.aux_id_to_delete = $event['id'];
      this.confirmOpenModal.openModal();
    }
  }
  confirmDelete(): void {
    this.chatService.delete(this.aux_id_to_delete).subscribe(
      res => {
        this.updateTable();
        this.confirmDeleteModal.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
  confirmOpen(): void {
    this.chatService.openChat(this.aux_id_to_delete).subscribe(
      res => {
        this.updateTable();
        this.confirmOpenModal.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  clickHeaderBtn($event): void {}
}
