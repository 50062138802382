import { Component, OnInit, ViewChild } from '@angular/core';
import { CandidateProfileFormComponent } from '../../components/forms/candidate-profile-form/candidate-profile-form.component';
import { CvOthersFormComponent } from '../../components/forms/cv-others-form/cv-others-form.component';
import { Candidate } from '../../models/candidate';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from '../../services/theme.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-candidate-edition-page',
  templateUrl: './candidate-edition-page.component.html',
  styleUrls: ['./candidate-edition-page.component.sass']
})
export class CandidateEditionPageComponent implements OnInit {

  // ViewChild
  @ViewChild(CandidateProfileFormComponent) candidateProfileFormComponent;
  @ViewChild(CvOthersFormComponent) cvOthersFormComponent;

  // Model attribute
  public candidate: Candidate;

  public offerCandidateTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'candidate-edition-offer-candidate-table',
    hasActionColumn: true,
    hasCheckboxColumn: true
  };

  constructor(private route: ActivatedRoute,
              private themeService: ThemeService,
              private location: Location) {

  }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
    this.candidate = this.route.snapshot.data['candidate'];
    this.candidateProfileFormComponent.editWithModel(this.candidate);
    var component = this;
    setTimeout(function() {
      component.editCVOthers();
    });
  }

  editCVOthers(): void {
    this.cvOthersFormComponent.edit(this.candidate.id, {candidate_id: this.candidate.id}); // Lo mandamos dos veces (el primero sirve para hacer el edit y el segundo se guarda automaticamente en el objeto del formulario para enviarlo por post)
  }
  update(): void {
    this.candidateProfileFormComponent.update();
  }
  updateOthers(): void {
    this.cvOthersFormComponent.update();
  }

  goToPreviousRoute(): void {
    this.location.back();
  }
}
