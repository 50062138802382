import { Injectable, Provider } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormService {

  public static structure = [];
  public static model: any;
  public static navbarConfig: any;

  private static DEFAULT_NAVBAR_CONFIG = {
    logo: {
      isShow: true,
      src: 'assets/img/logo-company_2.jpg',
      alt: 'Logo'
    },
    icons: {
      isShow: false,
      items: [ ]
    },
    search: {
      isShow: false,
    },
    user: {
      isShow: false,
    }
  };

  public static forRoot(structure: any, model: any, navbarConfig = null): Provider {
    RegistrationFormService.structure = structure;
    RegistrationFormService.model = model;
    RegistrationFormService.navbarConfig = navbarConfig;
    if(!RegistrationFormService.navbarConfig){
      RegistrationFormService.navbarConfig = this.DEFAULT_NAVBAR_CONFIG
    }
    return RegistrationFormService;
  }
}
