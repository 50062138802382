import { Component, OnInit, ViewChild } from '@angular/core';
import { OfferDetailsComponent } from '../../components/details/offer-details/offer-details.component';
import { ActivatedRoute } from '@angular/router';
import { ResponseHelper } from '../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { NavigationHelper } from '../../../projects/bloonde-ngx-template/src/navigation/navigation.helper';
import { Offer } from '../../models/offer';
import { Location } from '@angular/common';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-offer-details-page',
  templateUrl: './offer-details-page.component.html',
  styleUrls: ['./offer-details-page.component.sass']
})
export class OfferDetailsPageComponent implements OnInit {

  @ViewChild(OfferDetailsComponent) offerDetailsComponent;

  public offer: Offer;
  constructor(private route: ActivatedRoute,
              private themeService: ThemeService,
              private location: Location) {

  }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
    this.offer = this.route.snapshot.data['offer'];
    this.offerDetailsComponent.editWithModel(this.offer);
  }

  updateAndClose(): void {
    this.offerDetailsComponent.update();
  }

  goToPreviousRoute(): void {
    this.location.back();
  }

}
