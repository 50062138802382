import { TemplateConfiguration } from '../models/template-configuration';



export class TemplateHelper {
  public static addToConfiguration(baseConfiguration: any[], configuration: TemplateConfiguration[], tag: string = null): any {
    let isImg = false;
    for (let key in configuration) {
      if( configuration[key]['type'] === 'img' ) {
        isImg = true;
      }
    }
    for (let key in configuration) {
      let data = configuration[key];
      let element = {
        name: data['name'],
        label: TemplateHelper.getLabel( data ),
        placeholder: data['placeholder'] ? data['placeholder'] : null,
        type: data['type'] ? data['type'] :  null,
        default_value: data['default_value'] ? data['default_value'] :  null,
        col: data['col'] ? data['col'] :  TemplateHelper.getColByType(data['type'], tag, isImg),
        style: data['style'] ? data['style'] :  TemplateHelper.withLabel(data['type']) ? 'container__input' : null,
        rules: data['rules'] ? data['rules'] :  [],
        label_classes: data['label_classes'] ? data['label_classes'] :  'label_classes',
        input_classes: data['input_classes'] ? data['input_classes'] :  'input_classes',
        element_classes: data['input_classes'] ? data['input_classes'] : TemplateHelper.getStyleElement(data['type']),
        config: data['config'] ? data['config'] :  { hasLabel: TemplateHelper.withLabel(data['type']) , hasInput: true, hasHelp: false },
        init_if_condition: data['init_if_condition'] != null ? data['init_if_condition'] :  true,
        show_and_hide_logic: data['show_and_hide_logic'] != null ? data['show_and_hide_logic'] :  false,
        tag: tag != null ? tag : null,
        url: data['url'] ? data['url']: null,
        image_folder: data['image_folder'] ? data['image_folder']: null,
        download_url: data['download_url'] ? data['download_url']: null,
        limit: data['limit'] ? data['limit']: null,
        limit_error: data['limit_error'] ? data['limit_error']: null,
        help: data['help'] ? data['help']: null
      };
      baseConfiguration.push(element);
    }
    return baseConfiguration;
  }
  // Solo para roomable
  public static oldAddToConfiguration(baseConfiguration: any[], configuration: TemplateConfiguration[]): any {
    for (let key in configuration) {
      let data = configuration[key];
      let element = {
        name: key,
        label: key,
        placeholder: data['placeholder'] ? data['placeholder'] : null,
        type: data['type'] ? data['type'] :  null,
        default_value: data['default_value'] ? data['default_value'] :  null,
        col: data['col'] ? data['col'] :  'col-12',
        style: data['style'] ? data['style'] :  TemplateHelper.withLabel(data['type']) ? 'container__input' : null,
        rules: data['rules'] ? data['rules'] :  [],
        label_classes: data['label_classes'] ? data['label_classes'] :  'label_classes',
        input_classes: data['input_classes'] ? data['input_classes'] :  'input_classes',
        config: data['config'] ? data['config'] :  { hasLabel: TemplateHelper.withLabel(data['type']) , hasInput: true, hasHelp: false },
        init_if_condition: data['init_if_condition'] != null ? data['init_if_condition'] :  true,
        show_and_hide_logic: data['show_and_hide_logic'] != null ? data['show_and_hide_logic'] :  false,
      };
      baseConfiguration.push(element);
    }
    return baseConfiguration;
  }

  public static withLabel(type: string): boolean {
    return type !== 'note';
  }
  public static getColByType(type: any, tag: string, isImg: boolean): any {
    if( tag !== 'help' && tag !== null ) {
      if ( !isImg ) {
        return 'col-12';
      } else if ( type !== 'img' && isImg ) {
        return 'col-12 col-lg-8';
      } else {
        return 'col-12 col-lg-4';
      }
    }
    return 'col-12';

  }
  public static getStyleElement(type: any): any {
    if ( type === 'h1' ) {
      return 'help__content__title'
    } else if ( type === 'h2' ) {
      return 'help__content__subtitle';
    } else if ( type === 'wysiwyg' ) {
      return 'help__content__container';
    } else {
      return null;
    }
  }
  public static getLabel(data: any): any {
    if ( data['type'] === 'img' ) {
      return null
    } else {
      return data['name'];
    }
  }
}
