import { Component, OnInit, ViewChild } from '@angular/core';
import { CandidateProfileFormComponent } from '../../components/forms/candidate-profile-form/candidate-profile-form.component';
import { ActivatedRoute } from '@angular/router';
import { CvOthersFormComponent } from '../../components/forms/cv-others-form/cv-others-form.component';
import { ActionConfirmationModalComponent } from '../../components/modals/action-confirmation-modal/action-confirmation-modal.component';
import { CandidateService } from '../../services/candidate.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ChangePasswordModalComponent } from 'ngx-bloonde-user-and-privileges/src/components/modals/change-password-modal/change-password-modal.component';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-candidate-profile-page',
  templateUrl: './candidate-profile-page.component.html',
  styleUrls: ['./candidate-profile-page.component.sass']
})
export class CandidateProfilePageComponent implements OnInit {

  public loggedInUser;
  public aux_id_to_delete: any;

  @ViewChild(CandidateProfileFormComponent) candidateProfileFormComponent;
  @ViewChild(CvOthersFormComponent) cvOthersFormComponent;
  @ViewChild(ActionConfirmationModalComponent) actionConfirmationModalComponent;
  @ViewChild(ChangePasswordModalComponent) changePasswordModalComponent;

  constructor(private route: ActivatedRoute,
              private themeService: ThemeService,
              private candidateService: CandidateService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
    this.loggedInUser = this.route.snapshot.data['loggedInUser'];
    this.edit();
    var component = this;
    setTimeout(function() {
      component.editCVOthers();
    });
  }
  edit(): void {
    this.candidateProfileFormComponent.edit(this.loggedInUser.id);
  }
  editCVOthers(): void {
    this.cvOthersFormComponent.edit(this.loggedInUser.id, {candidate_id: this.loggedInUser.id}); // Lo mandamos dos veces (el primero sirve para hacer el edit y el segundo se guarda automaticamente en el objeto del formulario para enviarlo por post)
  }
  update(): void {
    this.candidateProfileFormComponent.update();
  }
  updateOthers(): void {
    this.cvOthersFormComponent.update();
  }
  deleteAccountRequest(): void {
    this.aux_id_to_delete = this.loggedInUser.id;
    this.actionConfirmationModalComponent.openModal();
  }

  confirmDelete(): void {
    this.candidateService.requestDelete(this.aux_id_to_delete).subscribe(
      res => {
        this.actionConfirmationModalComponent.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
  showChangePasswordModal(): void {
    this.changePasswordModalComponent.show();
  }
}
