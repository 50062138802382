import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CvExperienceFormComponent } from '../../forms/cv-experience-form/cv-experience-form.component';
import { AbstractFormModalComponent } from 'bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';

@Component({
  selector: 'app-cv-experience-modal',
  templateUrl: './cv-experience-modal.component.html',
  styleUrls: ['./cv-experience-modal.component.sass']
})
export class CvExperienceModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: true, save_and_edit: false};
  @ViewChild(CvExperienceFormComponent) formComponent;
  public modal_id = 'cv_experience_modal';

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public getFormComponent(): any {
    return this.formComponent;
  }

}
