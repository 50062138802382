import { Component, OnInit, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input()
  public configSidebar: any;

  constructor() { }

  ngOnInit() {  }

  menuStatic( e ) {
    const header = document.querySelector('#container__sidebar');
    const main = document.querySelector('#container__main')
    const cicle = document.querySelector('#iconMenuCircle')

    const clasN = e.target.className;

    if( clasN.indexOf("isMenu") > -1 ) {
      header['style']['left'] = '-11.6rem';
      main['style']['marginLeft'] = '3.9rem';
      main['style']['paddingLeft'] = 0;
      cicle.classList.remove('fas');
      cicle.classList.add('far');
    } else {
      header['style']['left'] = 0;
      main['style']['marginLeft'] = '15.6rem';
      main['style']['paddingLeft'] = 0;
      cicle.classList.remove('far');
      cicle.classList.add('fas');
    }

    e.target.classList.toggle('isMenu')

  }
}
