import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-offer-management-page',
  templateUrl: './offer-management-page.component.html',
  styleUrls: ['./offer-management-page.component.sass']
})
export class OfferManagementPageComponent implements OnInit {

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
  }

}
