
export class FormActionsHelper {
    public static KEEP = 'keep';
    public static CREATE_ACTION = 'create';
    public static EDIT_ACTION = 'edit';
    public static SHOW_ACTION = 'show';
    public static SAVE_AND_CLOSE_ACTION = 'save_and_close';
    public static SAVE_AND_CONTINUE_ACTION = 'save_and_continue';
    public static SAVE_AND_EDIT_ACTION = 'save_and_edit';
    public static UPDATE_AND_CLOSE_ACTION = 'update_and_close'
}
