import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-yes-no-filter',
  templateUrl: './yes-no-filter.component.html',
  styleUrls: ['./yes-no-filter.component.sass']
})
export class YesNoFilterComponent implements OnInit {
  @Input()
  disabled: boolean;
  @Input()
  name: string;
  @Input()
  label: string;
  @Input()
  error: any;
  @Input()
  serverError: any;

  @Input()
  get model() {
    return this.modelValue;
  }
  @Output()
  modelChange = new EventEmitter<any>();

  public modelValue: string;

  public options: { name: string, label: string} = {name: null, label: null};

  constructor() { }

  ngOnInit() {
    if (this.name) {
      this.options.name = this.name;
    }
    if (this.label) {
      this.options.label = this.label;
    }
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

}
