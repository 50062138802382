import {EventEmitter, Output} from '@angular/core';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class OfferCandidateTableFilter extends AbstractFilterComponent {
  @Output() searchModelChange: EventEmitter<any>;
  search: string;
  status_id: any;
  statusIdModelChange: EventEmitter<any>
  candidate_id: any;
  offer_id: any;
  @Output() positionModelChange: EventEmitter<any>;
  position: any;
  @Output() experienceModelChange: EventEmitter<any>;
  experience: any;
  @Output() disabledModelChange: EventEmitter<any>;
  @Output() stateIdModelChange: EventEmitter<any>;
  state_id: any;

  constructor() {
    super();
    this.search = null;
    this.searchModelChange = new EventEmitter();
    this.status_id = null;
    this.statusIdModelChange = new EventEmitter();
    this.candidate_id = null;
    this.offer_id = null;
    this.position = null;
    this.positionModelChange = new EventEmitter();
    this.experience = null;
    this.experienceModelChange = new EventEmitter();
    this.disabledModelChange = new EventEmitter();
    this.state_id = null;
    this.stateIdModelChange = new EventEmitter();
  }
  public toFilter(): string[] {
    return ['search', 'candidate_id', 'offer_id', 'status_id', 'position', 'experience', 'state_id'];
  }
  public emit() {
    this.searchModelChange.emit(this.search);
    this.statusIdModelChange.emit(this.status_id);
    this.positionModelChange.emit(this.position);
    this.experienceModelChange.emit(this.experience);
    this.stateIdModelChange.emit(this.state_id);
  }
  public copy(filter: {search: string, candidate_id: any, offer_id: any, status_id: any, position: any, experience: any,
    state_id: any}) {
    this.search = filter.search;
    this.candidate_id = filter.candidate_id;
    this.offer_id = filter.offer_id;
    this.status_id = filter.status_id;
    this.position = filter.position;
    this.experience = filter.experience;
    this.state_id = filter.state_id;

    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      search: this.search,
      candidate_id: this.candidate_id,
      offer_id: this.offer_id,
      status_id: this.status_id,
      position: this.position,
      experience: this.experience,
      state_id: this.state_id,
    };
  }
}

