import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MultipleImage } from '../../../models/multiple-image';
import { InputFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from '../../../../../bloonde-ngx-template/src/forms/services/validation.service';
import { GalleryPhotoService } from '../../../services/galleryPhoto.service';
import { ResponseHelper } from '../../../../../bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../../bloonde-ngx-template/src/responses/message.service';
import { MultipleImageFormConfiguration } from './multiple-image-form-configuration';
import { AbstractFormComponent } from '../../../../../bloonde-ngx-template/src/forms/abstract-form.component';
import { FormActionsHelper } from '../../../../../bloonde-ngx-template/src/forms/helpers/form-actions.helper';
import { CodesHelper } from '../../../../../bloonde-ngx-template/src/responses/codes.helper';

@Component({
  selector: 'bl-multiple-gallery-image-form',
  templateUrl: './multiple-gallery-image-form.component.html',
  styleUrls: ['./multiple-gallery-image-form.component.css']
})
export class MultipleGalleryImageFormComponent extends AbstractFormComponent implements OnInit {

  public multipleImage: MultipleImage;
  public nextAction = 'keep';

  @Input()
  public config: {type: any, fk_id: any};

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;
  inputFormComponents: any[];

  constructor(private validationService: ValidationService,
              private galleryPhotoService: GalleryPhotoService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.inputFormComponents = [];
    this.structure = MultipleImageFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(): void {
    this.multipleImage = new MultipleImage();
    this.multipleImage.type = this.config.type;
    this.multipleImage.fk_id = this.config.fk_id;
    this.buildErrorModelAndEmitters();
  }
  public store(args: any[] = []): void {
    this.preStore(args); // Hook pre store
    const validate = this.checkForm();
    this.emitError();
    if (validate) {
      this.getModelService().storeMultiple(this.getModel()).subscribe(
        res => {
          this.successResponseStore(res); // Hook post store success
          this.resetErrors(); // Reseteamos los errores
          if (this.nextAction === FormActionsHelper.SAVE_AND_CONTINUE_ACTION) {
          }
          if (this.nextAction === FormActionsHelper.SAVE_AND_EDIT_ACTION) {
            this.goToEditEmitter.emit(res['id']);
          }
          this.success();
          this.getMessageService().showSuccessMessage();
        },
        error => {
          if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
            this.serverErrors = error.error;
            this.emitServerError();
          } else {
            this.getResponseHelper().handleError(error);
          }
        }
      );
    }
  }
  // public edit(id): void {
  //   this.adminService.get(id).subscribe(
  //     res => {
  //       this.admin = User.copy(res);
  //       this.buildErrorModelAndEmiters();
  //     },
  //     error => {
  //       if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
  //         this.serverErrors = error.error;
  //         this.emitServerError();
  //       } else {
  //         this.responseHelper.handleError(error);
  //       }
  //     }
  //   );
  // }
  public successResponseEdit(res): void {
    this.multipleImage = MultipleImage.copy(this.multipleImage);
    this.multipleImage.type = this.config.type;
    this.multipleImage.fk_id = this.config.fk_id;
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.multipleImage;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }
  public pushInputFormComponents(componentRef: any, key: any): void {
    this.inputFormComponents[key] = componentRef;
  }
  public pushConditionFormComponents(componentRef: any, key: any): void {
    this.inputFormComponents[key] = componentRef;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.galleryPhotoService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.multipleImage = model;
  }
  successResponseStore(res): void {
    // this.flat = Flat.copy(res); // TODO - Creo que no es necesario
  }
  public success(): void {
    this.onSuccess.emit(this.multipleImage);
  }

}
