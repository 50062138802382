import { Component, OnInit, ViewChild } from '@angular/core';
import { OfferTableComponent } from '../../tables/offer-table/offer-table.component';
import { OfferFormModalComponent } from '../../modals/offer-form-modal/offer-form-modal.component';
import { OfferService } from '../../../services/offer.service';
import { NavigationHelper } from '../../../../projects/bloonde-ngx-template/src/navigation/navigation.helper';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { ActionConfirmationModalComponent } from '../../modals/action-confirmation-modal/action-confirmation-modal.component';

@Component({
  selector: '[app-offer-management]',
  templateUrl: './offer-management.component.html',
  styleUrls: ['./offer-management.component.sass']
})
export class OfferManagementComponent implements OnInit {

  public offerTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'offer-table',
    hasActionColumn: true,
    hasCheckboxColumn: false
  };

  @ViewChild(OfferTableComponent) offerTableComponent;
  @ViewChild(OfferFormModalComponent) offerFormModalComponent;
  @ViewChild(ActionConfirmationModalComponent) actionConfirmationModalComponent;

  public aux_id: any;
  public aux_action: any;
  public msgModal: string;

  constructor(private offerService: OfferService,
              private navigationHelper: NavigationHelper,
              private messageService: MessageService,
              private responseHelper: ResponseHelper) { }


  ngOnInit() {
  }

  updateTable(): void {
    const component = this;
    setTimeout(function() {
      component.offerTableComponent.list();
    });
  }

  create(): void {
    this.offerFormModalComponent.show('create', null);
  }
  edit(id: any): void {
    this.navigationHelper.navigateTo('offer-edition', {}, id);
  }
  delete(id: any): void {
    this.aux_id = id;
    this.aux_action = 'delete';
    this.msgModal = '¿Estás seguro de querer eliminar esta oferta?';
    this.actionConfirmationModalComponent.openModal();
  }
  confirmDelete(): void {
    this.offerService.delete(this.aux_id).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  onOtherAction($event) {
    this.aux_id = $event.id;
    this.aux_action = $event.action;
    switch ( $event.action ) {
      case 'publish':
        this.publish();
        break;
      case 'unpublish':
        this.unpublish();
        break;
      case 'candidatures':
        this.navigationHelper.navigateTo('offer-candidature', {}, $event['id']);
        break;
    }
  }
  publish(): void {
    this.msgModal = '¿Estás seguro de querer publicar esta oferta?';
    this.actionConfirmationModalComponent.openModal();
  }
  unpublish(): void {
    this.msgModal = '¿Estás seguro de querer despublicar esta oferta?';
    this.actionConfirmationModalComponent.openModal();
  }
  actionModal(): void {
    switch ( this.aux_action ) {
      case 'delete':
        this.confirmDelete();
        break;
      case 'publish':
        this.confirmPublish();
        break;
      case 'unpublish':
        this.confirmUnpublish();
        break;
    }
  }
  confirmPublish(): void {
    this.offerService.publish(this.aux_id).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
        this.messageService.showSuccessMessage();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
  confirmUnpublish() {
    this.offerService.unpublish(this.aux_id).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
        this.messageService.showSuccessMessage();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
}
