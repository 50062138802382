export const ROUTES_ARRAY = {
  'login': '/login',
  'registration': '/registration',
  'root': '/panel/',
  'home': '/panel/home',
  'offer-edition': '/panel/offer/',
  'candidate-edition': '/panel/candidate/',
  'offer-candidature': '/panel/offer-candidate/',
  'messages': '/panel/messages/',
  'offer-details': '/panel/offer-details/',
  };

