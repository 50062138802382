export class User {
  public id: number;
  public activated: any;
  public email: string;
  public password: string;
  public name: string;
  public surname: string;
  public img_url: string;
  public profile_id: any;
  public access: { profile: string, in: any }[];

  constructor() {
    this.id = null;
    this.activated = null;
    this.email = null;
    this.password = null;
    this.name = null;
    this.surname = null;
    this.img_url = null;
    this.profile_id = null;
    this.access = [];
  }

  public static copy(user: any) {
    let userAux = new User();
    userAux.id = user.id;
    userAux.activated = user.activated.toString();
    userAux.profile_id = user.profile_id ? user.profile_id.toString() : null;
    userAux.email = user.email;
    userAux.name = user.name;
    userAux.surname = user.surname;
    userAux.img_url = user.img_url;
    userAux.access = user.access;
    return userAux;
  }
}
