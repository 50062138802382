import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bl-gallery-image-uploader',
  templateUrl: './gallery-image-uploader.component.html',
  styleUrls: ['./gallery-image-uploader.component.css']
})
export class GalleryImageUploaderComponent implements OnInit {

  @Output() clickAdd: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClickAdd(): void {
    this.clickAdd.emit();
  }
}
