import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {

  public if_condition = true;

  @Input()
  public structure: any[];

  constructor() { }

  ngOnInit() {
    this.if_condition = this.structure['init_if_condition'] != null ? this.structure['init_if_condition'] : true;
  }
}
