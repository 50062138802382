export class CVExperienceTableVisibility {
  id: boolean;
  title: boolean;
  observations: boolean;
  start_date: boolean;
  end_date: boolean;
  position_name: boolean;
  functions: boolean;
  company: boolean;
  company_type: boolean;
  location: boolean;
  working_day: boolean;
  volunteer: boolean;
  document: boolean;

  constructor() {
    this.id = true;
    this.title = true;
    this.observations = true;
    this.start_date = true;
    this.end_date = true;
    this.position_name = true;
    this.functions = true;
    this.company = true;
    this.company_type = true;
    this.location = true;
    this.working_day = true;
    this.volunteer = true;
    this.document = true;
  }
}

