import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { Image } from '../../projects/ngx-bloonde-gallery/src/models/image';


@Injectable()
export class PhotoService {

  constructor(public _http: HttpClient) {
  }

  get(id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getPhotoURL(id));
  }

  list(listRequestData: ListRequestData): Observable<any> {
    let query_params = QueryParamsHelper.addQueryParams(listRequestData); // Añadimos paginación, filtros, orden, selectores y número de items por pagina
    return this._http.get(ApiRoutesHelper.getPhotoListURL(), {params: query_params});
  }

  // store(image: FlatImage): Observable<any> {
  //   const params = this.buildParams(image);
  //   return this._http.post(ApiRoutesHelper.getPhotoCreateURL(), params);
  // }
  // storeMultiple(image: Image): Observable<any> {
  //   const params = this.buildParams(image);
  //   return this._http.post(ApiRoutesHelper.getPhotoMultipleCreateURL(), params);
  // }
  // update(image: Image): Observable<any> {
  //   const params = this.buildParams(image);
  //   return this._http.put(ApiRoutesHelper.getPhotoUpdateURL(image.id), params);
  // }

  /**
   * Para borrar una imagen de bd
   * @param id
   * @returns {Observable<Object>}
   */
  delete(id: any): Observable<any> {
    return this._http.delete(ApiRoutesHelper.getPhotoDeleteURL(id));
  }
  main(id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getPhotoMainURL(id));
  }
  // private buildParams(city: City): any {
  //   let fields = ['name', 'description', 'path', 'fk_id', 'type'];
  //   const params = {
  //   };
  //   fields.forEach( function(field) {
  //       params[field] = city[field];
  //   });
  //   return params;
  // }

}
