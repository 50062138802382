import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { NgxBloondeGalleryComponent } from './ngx-bloonde-gallery.component';
import { GalleryUploaderComponent } from '../components/inputs/gallery-uploader/gallery-uploader.component';
import { GalleryImageUploaderComponent } from '../components/misc/gallery-image-uploader/gallery-image-uploader.component';
import { GalleryImageComponent } from '../components/misc/gallery-image/gallery-image.component';
import { CommonModule } from '@angular/common';
import { GalleryImageFormComponent } from '../components/forms/gallery-image-form/gallery-image-form.component';
import { GalleryImageModalComponent } from '../components/modals/gallery-image-modal/gallery-image-modal.component';
import { GalleryImageService } from '../services/galleryImage.service';
import { ActionConfirmationModalComponent } from '../components/modals/action-confirmation-modal/action-confirmation-modal.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LaravelTranslationLoader } from '../../../bloonde-ngx-template/src/translation/laravelTranslationLoader';
import { NgTemplateModule } from '../../../bloonde-ngx-template/src/lib/ng-template.module';
import { ImageUploaderComponent } from '../components/inputs/image-uploader/image-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { GalleryPhotoService } from '../services/galleryPhoto.service';
import { MultipleImageUploaderComponent } from '../components/inputs/multiple-image-uploader/multiple-image-uploader.component';
import { MultipleGalleryImageFormComponent } from '../components/forms/multiple-gallery-image-form/multiple-gallery-image-form.component';



export function HttpLoaderFactory(http: HttpClient) {
  return new LaravelTranslationLoader(http);
}
export interface NgxBloondeGalleryModuleConf {
  imageService?: Provider;
}
@NgModule({
  imports: [
    CommonModule,
    NgTemplateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FileUploadModule
  ],
  declarations: [
    NgxBloondeGalleryComponent,
    GalleryUploaderComponent,
    GalleryImageComponent,
    GalleryImageUploaderComponent,
    GalleryImageModalComponent,
    GalleryImageFormComponent,
    ActionConfirmationModalComponent,
    ImageUploaderComponent,
    MultipleImageUploaderComponent,
    MultipleGalleryImageFormComponent
  ],
  providers: [
    GalleryImageService,
    GalleryPhotoService,
  ],
  exports: [NgxBloondeGalleryComponent, GalleryUploaderComponent],
  entryComponents: [
    ImageUploaderComponent,
    MultipleImageUploaderComponent,
  ]
})
export class NgxBloondeGalleryModule {
  static forRoot(config: NgxBloondeGalleryModuleConf = {}): ModuleWithProviders {
    return {
      ngModule: NgxBloondeGalleryModule,
      providers: [
        config.imageService,
      ]
    };
}}

