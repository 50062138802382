import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { CVTraining } from '../../../models/cv_training';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { CvTrainingService } from '../../../services/cv-training.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { CVLanguageFormConfiguration } from './cv-language-form-configuration';

@Component({
  selector: 'app-cv-language-form',
  templateUrl: './cv-language-form.component.html',
  styleUrls: ['./cv-language-form.component.sass']
})
export class CvLanguageFormComponent  extends AbstractFormComponent implements OnInit {

  @Input()
  public modelValue: CVTraining;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private cvTrainingService: CvTrainingService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = CVLanguageFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(args: any[]): void {
    this.args = args;
    this.modelValue = new CVTraining();
    this.modelValue.candidate_id = this.args['candidate_id'];
    this.modelValue.type = 'languages';
    this.buildErrorModelAndEmitters();
  }
  public successResponseEdit(res): void {
    this.modelValue = CVTraining.copy(this.modelValue);
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.cvTrainingService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }
  successResponseStore(res): void {
    this.modelValue = CVTraining.copy(res);
  }
  public success(): void {
    this.onSuccess.emit({id: this.modelValue.id});
  }
  public preStore(args): void {
    if (this.inputFormComponents['value']) {
      this.modelValue.value = this.inputFormComponents['value'].instance.getModel();
    }
  }
  public preUpdate(args): void {
    if (this.inputFormComponents['value']) {
      this.modelValue.value = this.inputFormComponents['value'].instance.getModel();
    }
  }
  public extendConstructForm(): void {
    /**
     * COMIENZO INICIALIZACION DEL FORMULARIO
     */


    /**
     * FIN INICIALIZACION DEL FORMULARIO
     */

    /**
     * COMIENZO DETECCION DE CAMBIOS EN EL FORMULARIO
     */
    var component = this;
    this.inputFormComponents['document'].instance.delete.subscribe(function() {
      component.removeField('document', true);
      component.modelValue['document'] = null;
    });
    /**
     * FIN DETECCION DE CAMBIOS EN EL FORMULARIO
     */
  }

  checkForm(): boolean {
    return this.getValidationService().checkForm(this.errors, this.getModel());
  }
}
