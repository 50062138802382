import { Sector } from './sector';
import { Position } from './position';
import { City } from './city';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
import { Offer } from './offer';
import { Candidate } from './candidate';
export class OfferCandidate {
  id: any;
  status_id: any;
  discard_information: string;
  registration_date: any;

  /**
   * Claves ajenas
   */
  offer_id: any;
  candidate_id: number;


  /**
   * Atributos derivados de la clave ajena
   */

  offer: Offer;
  candidate: Candidate;

  constructor() {
    this.id = null;
    this.status_id = null;
    this.discard_information = null;
    this.registration_date = null;
    this.offer_id = null;
    this.candidate_id = null;
    this.offer = null;
    this.candidate = null;
  }
  public static copy(model: any) {
    let aux = new OfferCandidate();
    aux.id = model.id;
    aux.status_id = model.status_id;
    aux.discard_information = model.discard_information;
    if (model.registration_date) {
      aux.registration_date = FormatHelper.formatFromApi(FormatHelper.DATE_FORMAT, model.registration_date);
    }
    aux.offer_id = model.offer_id;
    aux.candidate_id = model.candidate_id;
    aux.offer = model.offer;
    aux.candidate = model.candidate;
    return aux;
  }
}
