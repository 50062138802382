import { Injectable } from '@angular/core';
import { ValidationHelper } from '../helpers/validation.helper';

@Injectable()
export class ValidationService {

  constructor() {
  }

  buildErrorsArray(structure: any): any {
    const errors = {};
    const component = this;
    Object.keys(structure).forEach(function (key) {
      const field = structure[key];
      if(field['rules']) {
        const rules = component.addRules(field['rules']);
        errors[field['name']] = rules;
      }
    });
    return errors;
  }

  buildServerErrorsArray(structure: any): any {
    const errors = {};
    const component = this;
    Object.keys(structure).forEach(function (key) {
      const field = structure[key];
      errors[field['name']] = [];
    });
    return errors;
  }

  private addRules(rules: any): any {
    let result_rules = {};
    for (let i = 0; i < rules.length; i++) {
      let rule = this.addRule(rules[i]);
      if (rule != null) {
        if(rule.indexOf('required_if') > -1) {
          result_rules[rule] = {condition: rules[i].split(':')[1], error: false};
        } else if (rule.indexOf('min') > -1) {
          result_rules[rule] = {min: rules[i].split(':')[1], error: false};
        } else {
          result_rules[rule] = {error: false};
        }
      }
    }
    return result_rules;
  }

  private addRule(rule: any): any {
    if (rule === 'required') {
      return 'required';
    } else if (rule.indexOf('required_if') > -1) {
      return 'required_if';
    } else if (rule.indexOf('min') > -1) {
      return 'min';
    } else if (rule.indexOf('empty') > -1) {
      return 'empty';
    }
  }

  public checkForm(errors: any, model: any): boolean {
    let valid = true;
    Object.keys(errors).forEach(function (key) {
      if (!Array.isArray(model[key])) { // Si el valor no es un array
        if (errors[key] !== undefined
          && errors[key].required !== undefined) { // Comprobación required
          if (ValidationHelper.required(model[key])) {
            valid = false;
            errors[key].required.error = true;
          } else {
            errors[key].required.error = false;
          }
        }
        if (errors[key] !== undefined
          && errors[key].required_if !== undefined) { // Comprobación required_if
          if (ValidationHelper.requiredIf(model[key], model, errors[key]['required_if'].condition)) {
            valid = false;
            errors[key].required_if.error = true;
          } else {
            errors[key].required_if.error = false;
          }
        }
        if (errors[key] !== undefined
          && errors[key].min !== undefined) { // Comprobación min
          if (ValidationHelper.min(model[key], errors[key]['min'].min)) {
            valid = false;
            errors[key].min.error = true;
          } else {
            errors[key].min.error = false;
          }
        }
      } else {
        if (errors[key] !== undefined
          && errors[key].empty !== undefined) {
          if (ValidationHelper.empty(model[key])) {
            valid = false;
            errors[key].empty.error = true;
          } else {
            errors[key].empty.error = false;
          }
        }
        Object.keys(errors[key]).forEach(function (key2) {
          if (errors[key][key2] !== undefined
            && errors[key][key2].required !== undefined) {
            if (ValidationHelper.required(model[key][key2])) {
              valid = false;
              errors[key][key2].required.error = true;
            } else {
              errors[key][key2].required.error = false;
            }
          }
        });
      }
    });
    return valid;
  }
}
