
export class TextsHelper {
    public static texts = {
        'en': {
            '404': 'Resource not found',
            '500': 'Server Error',
            '502': 'You have not permissions for this operation'
        }
    };
}
