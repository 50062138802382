import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectService } from '../../../services/select.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';


@Component({
  selector: 'app-language-level-filter',
  templateUrl: './language-level-filter.component.html',
  styleUrls: ['./language-level-filter.component.sass']
})
export class LanguageLevelFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  levels: any[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'Language level', placeholder: 'Language level', options: []};

  constructor(private selectService: SelectService,
              private responseHelper: ResponseHelper) { }
  ngOnInit() {
    this.list();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    this.selectService.selectList('level')
      .subscribe(
        res => {
          this.levels = <any[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for (let i in this.levels) {
      let level = this.levels[i];
      options.push({
        value: level,
        label: level,
      });
    }
    this.options['options'] = options;
  }

}
