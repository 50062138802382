import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectService } from '../../../services/select.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: '[app-activated-filter],app-activated-filter',
  templateUrl: './activated-filter.component.html',
  styleUrls: ['./activated-filter.component.sass']
})
export class ActivatedFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  activated: any[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'Activated', placeholder: 'Activated', options: []};

  constructor(private selectService: SelectService,
              private responseHelper: ResponseHelper) { }
  ngOnInit() {
    this.list();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, [], []);
    this.selectService.selectList('activated')
      .subscribe(
        res => {
          this.activated = <any[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for (let i in this.activated) {
      let option = this.activated[i];
      options.push({
        value: i,
        label: option,
      });
    }
    this.options['options'] = options;
  }

}
