import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { FormatHelper } from '../../projects/bloonde-ngx-template/src/api/tools/format.helper';
import { Offer } from '../models/offer';


@Injectable()
export class OfferService {

  constructor(public _http: HttpClient) {
  }

  get(offer_id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getOfferURL(offer_id));
  }
  list(listRequestData: ListRequestData): Observable<object> {
    let query_params = QueryParamsHelper.addQueryParams(listRequestData); // Añadimos paginación, filtros, orden, selectores y número de items por pagina
    return this._http.get(ApiRoutesHelper.getOfferListURL(), {params: query_params});
  }
  selectList(listRequestData: ListRequestData): Observable<object> {
    let query_params = QueryParamsHelper.addQueryParams(listRequestData); // Añadimos paginación, filtros, orden, selectores y número de items por pagina
    return this._http.get(ApiRoutesHelper.getOfferSelectListURL(), {params: query_params});
  }

  store(offer: Offer): Observable<object> {
    const params = this.buildParams(offer);
    return this._http.post(ApiRoutesHelper.getOfferCreateURL(), params);
  }

  update(offer: Offer): Observable<object> {
    const params = this.buildParams(offer);
    return this._http.put(ApiRoutesHelper.getOfferUpdateURL(offer.id), params);
  }

  delete(offer_id: any): Observable<object> {
    return this._http.delete(ApiRoutesHelper.getOfferDeleteURL(offer_id));
  }
  publish(offer_id: any): Observable<Object> {
    return this._http.get(ApiRoutesHelper.getOfferPublishURL(offer_id));
  }

  unpublish(offer_id: any): Observable<Object> {
    return this._http.get(ApiRoutesHelper.getOfferUnpublishURL(offer_id));
  }

  private buildParams(offer: Offer): any {
    let fields = ['name', 'description', 'institution', 'status_id', 'functions', 'sector_id', 'position_id', 'city_id'
      , {type: FormatHelper.DATE_FORMAT, key: 'expected_contract_date'}, 'sexual_abuse_document_required'];
    const params = {};
    fields.forEach(function (field) {
      if (typeof field !== 'string') {
        params[field['key']] = FormatHelper.formatToApi(field['type'], offer[field['key']]);
      } else {
        params[field] = offer[field];
      }
    });
    if (offer['sector_and_position']) {
      params['sector_and_position'] = JSON.stringify(offer['sector_and_position']);
    }
    return params;
  }

}
