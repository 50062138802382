import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CvExperienceTableComponent } from '../../tables/cv-experience-table/cv-experience-table.component';
import { CvExperienceModalComponent } from '../../modals/cv-experience-modal/cv-experience-modal.component';
import { ActionConfirmationModalComponent } from '../../modals/action-confirmation-modal/action-confirmation-modal.component';
import { CvExperienceService } from '../../../services/cv-experience.service';
import { CvTrainingService } from '../../../services/cv-training.service';
import { CvTrainingTableComponent } from '../../tables/cv-training-table/cv-training-table.component';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { CvTrainingModalComponent } from '../../modals/cv-training-modal/cv-training-modal.component';

@Component({
  selector: '[app-cv-training-management]',
  templateUrl: './cv-training-management.component.html',
  styleUrls: ['./cv-training-management.component.sass']
})
export class CvTrainingManagementComponent implements OnInit {

  @ViewChild(CvTrainingTableComponent) cvTrainingTableComponent;
  @ViewChild(CvTrainingModalComponent) cvTrainingModalComponent;
  @ViewChild(ActionConfirmationModalComponent) actionConfirmationModalComponent;

  @Input() public candidate_id: any;

  public aux_id_to_delete: any;

  public tableOptions = {
    autoload: true,
    withPagination: false,
    paginationItemsPerPage: 5,
    localStorageKey: 'cv-experience-table',
    hasActionColumn: true,
    hasCheckboxColumn: false
  };

  constructor(
    private cvTrainingService: CvTrainingService,
    private responseHelper: ResponseHelper,
  ) { }

  ngOnInit() {
  }

  updateTable(): void {
    const component = this;
    setTimeout(function() {
      component.cvTrainingTableComponent.list();
    });
  }

  create(): void {
    this.cvTrainingModalComponent.show('create', null, {candidate_id: this.candidate_id});
  }
  edit(id: any): void {
    this.cvTrainingModalComponent.show('edit', id, {candidate_id: this.candidate_id});
  }
  delete(id: any): void {
    this.aux_id_to_delete = id;
    this.actionConfirmationModalComponent.openModal();
  }
  confirmDelete(): void {
    this.cvTrainingService.delete(this.aux_id_to_delete).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

}
