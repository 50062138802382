import { Component, Input, OnInit } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';
import {DatePipe, SlicePipe} from '@angular/common';

@Component({
  selector: 'app-read-only',
  templateUrl: './read-only.component.html',
  styleUrls: ['./read-only.component.css'],
  providers: [DatePipe, SlicePipe]
})
export class ReadOnlyComponent extends AbstractInputComponent implements OnInit {

  constructor(
    private datePipe: DatePipe,
    private slicePipe: SlicePipe
  ){
    super();
  }

  ngOnInit() {
    this.transform();
  }

  transform(): any {
    if (this.structure.pipe) {
      if (this.structure.pipe == 'date') {
        this.model = this.formattDate(this.model, this.structure.pipe_format);
      } else if (this.structure.pipe == 'slice'){
        this.model = this.slice(this.model, this.structure.pipe_start, this.structure.pipe_end);
      }
    }
  }

  formattDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  slice(value, start, end) {
    return this.slicePipe.transform(value, start, end);
  }

}
