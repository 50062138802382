import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MyOffersCandidateTableComponent } from '../../tables/my-offers-candidate-table/my-offers-candidate-table.component';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';

@Component({
  selector: '[app-my-offers-candidate-management]',
  templateUrl: './my-offers-candidate-management.component.html',
  styleUrls: ['./my-offers-candidate-management.component.sass']
})
export class MyOffersCandidateManagementComponent implements OnInit {

  @Input() public candidate_id: any;

  @Input()
  public offerCandidateTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'my-offers-candidate-table',
    hasActionColumn: true,
    hasCheckboxColumn: true
  };

  @ViewChild(MyOffersCandidateTableComponent) myOffersCandidateTableComponent;

  constructor(private navigationHelper: NavigationHelper) { }

  ngOnInit() {
  }

  updateTable(): void {
    const component = this;
    setTimeout(function() {
      component.myOffersCandidateTableComponent.list();
    });
  }

  onOtherAction($event) {
    switch ( $event.action ) {
      case 'see_offer':
        this.navigationHelper.navigateTo('offer-details', {}, $event.id);
        break;
    }
  }

}
