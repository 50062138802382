export class CandidateTableVisibility {
  id: boolean;
  name: boolean;
  surname: boolean;
  phone: boolean;
  email: boolean;
  activated: boolean;
  cv_updated_at: boolean;

  constructor() {
    this.id = true;
    this.name = true;
    this.surname = false;
    this.phone = true;
    this.email = true;
    this.activated = true;
    this.cv_updated_at = true;
  }
}

