import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-success-registration-modal',
  templateUrl: './success-registration-modal.component.html',
  styleUrls: ['./success-registration-modal.component.sass']
})
export class SuccessRegistrationModalComponent implements OnInit {

  buttonsConfig: {cancel: boolean} = {cancel: true};
  buttonsText: {cancelText: string} = {cancelText: 'Cerrar'};

  modal_id = 'success-registration-modal';
  current_action = null;

  constructor() { }

  ngOnInit() {
  }

  openModal(): void {
    if (typeof $ !== 'undefined') {
      $.magnificPopup.open({
        items: {
          src: '#' + this.modal_id,
          type: 'inline',
        }
      });
    }
  }
  closeModal(): void {
    if (typeof $ !== 'undefined') {
      $.magnificPopup.close();
    }
  }

}
