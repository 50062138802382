import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[appBoxContent]',
  templateUrl: './box-content.component.html',
  styleUrls: ['./box-content.component.css']
})
export class BoxContentComponent implements OnInit {

  @Input() isTable: boolean;
  @Input() withBackground: boolean = true;


  constructor() { }

  ngOnInit() { }

}
