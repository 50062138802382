import { Component, OnInit } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-h1',
  templateUrl: './h1.component.html',
  styleUrls: ['./h1.component.css']
})
export class H1Component extends AbstractInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() { }

}
