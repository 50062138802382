
export class ValidationHelper {
    public static required(value: string): any {
        return value === null || value === '' || value === undefined;
    }
    public static requiredIf(value: string, model: any, condition: string): any {
      let conditionSplitted = condition.split(',');
      let attribute = conditionSplitted[0];
      let attributeValue: any = conditionSplitted[1];
      if(attributeValue === 'true') {
        attributeValue = true;
      }
      return (value === null || value === '' || value === undefined || ValidationHelper.isEmptyObject(value)) && model[attribute] === attributeValue;
    }
    public static empty(value: any[]): any {
        return value.length === 0;
    }
    public static min(value: string, min: number): any {
        return parseFloat(value) < min;
    }
    public static email(value: string): any {
        return true;
    }
    public static isEmptyObject(value: any): boolean {
      if (typeof value === 'object') {
        let result = true;
        for (let i in value) {
          if (value[i]) {
            result = false;
            break;
          }
        }
        return result;
      }
      return false;
    }
}
