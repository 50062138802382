import { CandidateProfileFormComponent } from '../components/forms/candidate-profile-form/candidate-profile-form.component';
import { Candidate } from './candidate';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
import { CVOtherFormConfiguration } from '../components/forms/cv-others-form/cv-others-form-configuration';
export class CVOther {
    id: any;

    candidate_id: any;

    candidate: Candidate;

  constructor() {
    this.id = null;
    this.candidate_id = null;
    this.candidate = new Candidate();
  }
  public static copy(cvOther: any) {
    let cvOtherAux = new CVOther();
    cvOtherAux.id = cvOther.id;
    cvOtherAux.candidate_id = cvOther.candidate_id;
    cvOtherAux.candidate = cvOther.candidate;
    let fields = CVOtherFormConfiguration.fields;
    for (let field of fields) {
      if (field.type === FormatHelper.CHECKBOX_FORMAT) {
        cvOtherAux[field['label']] = FormatHelper.formatFromApi(FormatHelper.CHECKBOX_FORMAT, cvOther[field['label']]);
      } else {
        cvOtherAux[field['label']] = cvOther[field['label']];
      }
      if (field.info) {
        let commentLabel = field['label'] + '_info';
        cvOtherAux[commentLabel] = cvOther[commentLabel];
      }
      if (field.document) {
        let documentLabel = field['label'] + '_document';
        cvOtherAux[documentLabel] = cvOther[documentLabel];
      }
      if (field['expedition_date'] && field['expedition_date']['exists']) {
        let expeditionLabel = field['label'] + '_expedition_date';
        cvOtherAux[expeditionLabel] = FormatHelper.formatFromApi(FormatHelper.DATE_FORMAT, cvOther[expeditionLabel]);
      }
      if (field['accept'] && field['accept']['exists']) {
        let acceptLabel = field['label'] + '_accept';
        cvOtherAux[acceptLabel] = FormatHelper.formatFromApi(FormatHelper.CHECKBOX_FORMAT, cvOther[acceptLabel]);
      }
    }
    return cvOtherAux;
  }
}
