import {
  Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, QueryList,
  ViewChildren
} from '@angular/core';
import { LoginSession } from '../../../models/login-session';
import { InputFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from '../../../../../bloonde-ngx-template/src/forms/services/validation.service';
import { ResponseHelper } from '../../../../../bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../../bloonde-ngx-template/src/responses/message.service';
import { AbstractFormComponent } from '../../../../../bloonde-ngx-template/src/forms/abstract-form.component';
import { LoginFormConfiguration } from './login-form-configuration';
import { SessionService } from '../../../../../bloonde-ngx-template/src/session/services/session.service';
import { SessionHelper } from '../../../../../bloonde-ngx-template/src/session/helpers/session.helper';
import { NavigationHelper } from '../../../../../bloonde-ngx-template/src/navigation/navigation.helper';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bl-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent extends AbstractFormComponent implements OnInit {

  public loginSession: LoginSession;

  @Input()
  public type = null;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  @Output()
  public loginEmitter: EventEmitter<any> = new EventEmitter();

  public redirect: string;
  constructor(private validationService: ValidationService,
              private sessionService: SessionService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private navigationHelper: NavigationHelper,
              private componentFactoryResolver: ComponentFactoryResolver,
              private route: ActivatedRoute) {
    super();
    this.structure = LoginFormConfiguration.structure;
  }
  ngOnInit() {
    this.new();
    this.route.queryParams.subscribe(params => {
      if (params['redirect']) {
        this.redirect = params['redirect'];
      }
    });
  }


  public new(): void {
    this.loginSession = new LoginSession();
    this.buildErrorModelAndEmitters();
  }
  login(): void {
    this.sessionService.login(this.loginSession.email, this.loginSession.password)
      .subscribe(
        res => {
          this.sessionService.setSession(res['user'], res['token']);
          if(this.type != 'modal') {
            if (!this.redirect) {
              this.navigationHelper.navigateTo('root', null, null, false, this.sessionService.getLoggedUser());
            } else {
              this.navigationHelper.navigateToRoute(this.redirect);
            }
          } else {
            this.loginEmitter.emit();
          }
        },
        error => {
          this.messageService.showErrorMessage(error.error);
        }
      );
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.loginSession;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }
  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.sessionService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.loginSession = model;
  }

}
