import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BodyTableComponent } from '../../../../projects/bloonde-ngx-template/src/tables/partials/body-table/body-table.component';
import { TableOptions } from '../../../../projects/bloonde-ngx-template/src/tables/models/table-options';
import { OfferListItem } from '../../../models/listings/offer_list_item';
import { Pagination } from '../../../../projects/bloonde-ngx-template/src/tables/models/pagination';
import { OfferTableFilter } from './offer-table-filter';
import { OfferTableOrder } from './offer-table-order';
import { OfferTableVisibility } from './offer-table-visibility';
import { DataHeader } from '../../../../projects/bloonde-ngx-template/src/tables/models/data-header';
import { SearchComponent } from '../../../../projects/bloonde-ngx-template/src/tables/components/search/search.component';
import { StatusFilterComponent } from '../../filters/status-filter/status-filter.component';
import { OfferService } from '../../../services/offer.service';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { NavigationHelper } from '../../../../projects/bloonde-ngx-template/src/navigation/navigation.helper';
import { AbstractTableComponent } from '../../../../projects/bloonde-ngx-template/src/tables/partials/abstract-table/abstract-table.component';
import { ListRequestData } from '../../../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
import * as moment from 'moment';
import { DateFilterComponent } from '../../filters/date-filter/date-filter.component';

@Component({
  selector: '[app-offer-table]',
  templateUrl: './offer-table.component.html',
  styleUrls: ['./offer-table.component.sass']
})
export class OfferTableComponent extends AbstractTableComponent implements OnInit {

  @ViewChild(BodyTableComponent) bodyTableComponent;

  @Input() tableOptions: TableOptions;

  public offers: OfferListItem[];
  public pagination: Pagination;

  public filter: OfferTableFilter = new OfferTableFilter();
  public order: OfferTableOrder = new OfferTableOrder();
  public visibility: OfferTableVisibility = new OfferTableVisibility();

  public dataHeader: DataHeader = {
    title: {
      isShow: true,
      label: 'Offer Management',
    },
    filters: {
      isShow: true,
      items: [
        {
          component: SearchComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'search', type: 'property'},
            {attr: 'placeholder', value: 'Name...', type: 'property'},
            {attr: 'idText', value: 'generalSearch', type: 'property'},
            {attr: 'modelChange', value: 'search', type: 'output'},
            {attr: 'model', value: this.filter.searchModelChange, type: 'input'}
          ]
        },
        {
          component: StatusFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Status', type: 'property'},
            {attr: 'type', value: 'offer', type: 'property'},
            {attr: 'modelChange', value: 'status_id', type: 'output'},
            {attr: 'model', value: this.filter.statusIdModelChange, type: 'input'}
          ]
        },
        {
          component: DateFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'From', type: 'property'},
            {attr: 'modelChange', value: 'from', type: 'output'},
            {attr: 'model', value: this.filter.fromModelChange, type: 'input'}
          ]
        },
        {
          component: DateFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'To', type: 'property'},
            {attr: 'modelChange', value: 'to', type: 'output'},
            {attr: 'model', value: this.filter.toModelChange, type: 'input'}
          ]
        },
      ]
    },
    btns: {
      isShow: true,
      float: 'right',
      items: [
        {
          isShow: true,
          value: 'Alta',
          icon: 'fas fa-plus',
          style: '',
          action: 'create'
        },
      ]
    },
    actions: [
      {
        action: 'edit',
        isShow: true,
        icon: 'fas fa-edit',
        title: 'Edit'
      },
      {
        action: 'delete',
        isShow: true,
        icon: 'fas fa-trash-alt',
        title: 'Delete'
      },
      {
        action: 'publish',
        isShow: true,
        icon: 'fas fa-toggle-off',
        title: 'Publish'
      },
      {
        action: 'unpublish',
        isShow: true,
        icon: 'fas fa-toggle-on',
        title: 'Unpublish'
      }
    ],
    style: ''
  };

  public isTable: boolean = true;

  constructor(
    private offerService: OfferService,
    private responseHelper: ResponseHelper,
    private navigationHelper: NavigationHelper
  ) {
    super();
  }

  ngOnInit() {
    if (!this.tableOptions) {
      this.tableOptions = new TableOptions('offer-management');
    }
    this.resetFiltersOrdersAndColumns();
    if (this.tableOptions.withPagination) {
      this.pagination = new Pagination();
      this.pagination.currentPage = 1;
      this.pagination.itemPerPage = this.tableOptions.paginationItemsPerPage;
    }
    if (this.tableOptions.autoload) {
      this.list();
    }
  }

  list(): void {
    this.cleanSelected();
    this.saveFiltersAndOrders();
    const listRequestData = new ListRequestData(this.pagination ? this.pagination.currentPage : null, this.filter, this.order);
    this.offerService.list(listRequestData).subscribe(
      res => {
        this.offers = <OfferListItem[]> res['elements'];
        if (this.tableOptions.withPagination) {
          this.pagination.total = res['total'];
        }
        this.getHeader();
        this.getData();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  defaultFilter(): any {
    return new OfferTableFilter();
  }
  defaultOrders(): any {
    return new OfferTableOrder();
  }
  defaultVisibility(): any {
    return new OfferTableVisibility();
  }
  getElements(): any {
    return this.offers;
  }
  getFilter(): any {
    return this.filter;
  }
  getOrder(): any {
    return this.order;
  }
  getVisibility(): any {
    return this.visibility;
  }
  setFilter(filter: any): void {
    this.filter.copy(filter);
  }
  setOrder(order: any): void {
    this.order = order;
  }
  setVisibility(visibility: any): void {
    this.visibility = visibility;
  }
  getLocalStorageKey(): any {
    return this.tableOptions.localStorageKey;
  }
  getAllSelectorCheckbox(): any {
  }
  getSelectorCheckboxes(): any {
  }
  hasActionColumn(): boolean {
    return this.tableOptions.hasActionColumn;
  }
  hasCheckboxColumn(): boolean {
    return this.tableOptions.hasCheckboxColumn;
  }
  changeFilter($event) {
    this.filter[$event['key']] = $event['value'];
    this.listWithTimeout();
  }
  enterSearch($event) {
    this.filter.search = $event.event.target.value;
    this.listWithTimeout();
  }
  clickHeaderBtn(e) {
    if ( e.action === 'create' ) {
      this.onCreate();
    }
  }
  constructBodyColumnsExceptions(key, value, isShow, isOrder, item): any {
    let objectData = null;
    let translate = false;
    let action = null;
    let customClass = null;
    if ( key === 'created_at') {
      let date = moment(value).format('D/MM/YYYY');
      value = date;
      translate = true;
    }
    if (key === 'expected_contract_date' && value) {
      let date = moment(value).format('D/MM/YYYY');
      value = date;
    }
    if ( key === 'status_id') {
      value = 'offer_status_' + value;
      translate = true;
    }
    if ( key === 'published') {
      value = 'published_' + value;
      translate = true;
    }
    if (key === 'candidatures') {
      action = key; // Realmente el nombre de la acción no importa, lo importante es que es que lo gestionemos en el evento onOtherAction de OfferManagementComponent
      // customClass = 'href';
    }
    objectData = this.setData(key, isShow, isOrder, value, translate, false, customClass, action);
    return objectData;
  }

  getDataActions(item: any): any {
    let actions = this.copyActionHeaders();
    for (let action of actions) {
      if (action.action == 'publish' && item.published == 0) {
        action.isShow = true;
      } else if (action.action == 'publish' && item.published == 1) {
        action.isShow = false;
      }

      if (action.action == 'unpublish' && item.published == 1) {
        action.isShow = true;
      } else if (action.action == 'unpublish' && item.published == 0) {
        action.isShow = false;
      }
    }
    return actions;
  }
  copyActionHeaders() {
    let result = [];
    for (let action of this.dataHeader.actions) {
      let auxAction = {};
      auxAction['action'] = action['action'];
      auxAction['isShow'] = action['isShow'];
      auxAction['icon'] = action['icon'];
      auxAction['title'] = action['title'];
      result.push(auxAction);
    }
    return result;
  }

}
