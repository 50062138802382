import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from '../services/session.service';
// import { RouterNavigationHelper } from '../../../helpers/router-navigation.helper';
import { NavigationHelper } from '../../navigation/navigation.helper';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private _sesionService: SessionService,
        private navigationHelper: NavigationHelper
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this._sesionService.getLoggedUser();
        const hasCredential = this._sesionService.hasCredentials();
        if (user == null) {
            if (hasCredential) {
                return true;
            } else {
                let queryParams = {};
                if (typeof window !== 'undefined') {
                  let route: string = window.location.pathname + window.location.search;
                  if (route && route.indexOf('login') >= -1) {
                    queryParams = {redirect: route}
                  }
                }
                this.navigationHelper.navigateTo('login', queryParams);
                return false;
            }
        }
        return true;
    }
}
