import {
    Component, Input, EventEmitter, OnInit, Output, ComponentFactoryResolver, ViewChild,
    ViewChildren, QueryList, AfterViewInit
} from '@angular/core';
import { FiltersDirective } from '../../../filters/directives/filters.directive';
import { AbstractFilterComponent } from '../../../filters/abstract-filter.component';
import { AbstractFilterGroupComponent } from '../../../filters/abstract-filter-group.component';

@Component({
    selector: '[appHeaderTable]',
    templateUrl: './header-table.component.html',
    styleUrls: ['./header-table.component.css']
})
export class HeaderTableComponent  extends AbstractFilterGroupComponent implements OnInit, AfterViewInit {
  public initialZIndex: number = 1050;
  constructor(public componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
}
