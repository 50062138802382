import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: '[app-search],app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  public idText: string;
  public placeholder: string;
  public icon: string;

  @Input()
  multiple: boolean;
  @Input()
  styles: any;

  @Input()
  get model() {
    return this.modelValue;
  }

  disabled: boolean;

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  set model(model) {
    if (model !== this.modelValue) {
      this.modelValue = model;
      this.modelChange.next(this.modelValue);
    }
  }
  getStyle(id): any {
    if(this.styles) {
      return this.styles[id];
    }
  }
}
