import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BodyTableComponent } from 'bloonde-ngx-template/src/tables/partials/body-table/body-table.component';
import { AbstractTableComponent } from 'bloonde-ngx-template/src/tables/partials/abstract-table/abstract-table.component';
import { TableOptions } from 'bloonde-ngx-template/src/tables/models/table-options';
import { CVTraining } from '../../../models/cv_training';
import { CVTrainingTableFilter } from './cv-training-table-filter';
import { CVTrainingTableOrder } from './cv-training-table-order';
import { CVTrainingTableVisibility } from './cv-training-table-visibility';
import { DataHeader } from 'bloonde-ngx-template/src/tables/models/data-header';
import { CvTrainingService } from '../../../services/cv-training.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import { CVTrainingListItem } from '../../../models/listings/cv_training_list_item';
import { ApiHelper } from 'bloonde-ngx-template/src/session/helpers/api.helper';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import { TrainingCardComponent } from '../../cards/training-card/training-card.component';
import { CardsComponent } from '../../cards/cards/cards.component';
import { Pagination } from 'bloonde-ngx-template/src/tables/models/pagination';

@Component({
  selector: '[app-cv-training-table]',
  templateUrl: './cv-training-table.component.html',
  styleUrls: ['./cv-training-table.component.sass']
})
export class CvTrainingTableComponent extends AbstractTableComponent implements OnInit {

  @ViewChild(BodyTableComponent) bodyTableComponent;
  @ViewChild(CardsComponent) cardsComponent;

  @Input() tableOptions: TableOptions;
  @Input() public candidate_id;

  public cvTrainings: CVTrainingListItem[];
  public pagination: Pagination;

  public filter: CVTrainingTableFilter = new CVTrainingTableFilter();
  public order: CVTrainingTableOrder = new CVTrainingTableOrder();
  public visibility: CVTrainingTableVisibility = new CVTrainingTableVisibility();

  public type = TrainingCardComponent;

  public dataHeader: DataHeader = {
    title: {
      isShow: true,
      label: 'Trainings',
    },
    filters: {
      isShow: false,
      items: []
    },
    btns: {
      isShow: true,
      items: [
        {
          isShow: true,
          value: 'Alta',
          icon: 'fas fa-plus',
          style: '',
          action: 'create'
        },
      ]
    },
    actions: [
      {
        action: 'edit',
        isShow: true,
        icon: 'fas fa-edit',
        title: 'Edit'
      },
      {
        action: 'delete',
        isShow: true,
        icon: 'fas fa-trash-alt',
        title: 'Delete'
      },
      {
        action: 'download',
        isShow: true,
        icon: 'fas fa-download',
        title: 'Download'
      }
    ],
    style: ''
  };

  public isTable: boolean = true;

  constructor(
    private cvTrainingService: CvTrainingService,
    private responseHelper: ResponseHelper,
    private navigationHelper: NavigationHelper
  ) {
    super();
  }

  ngOnInit() {
    if (!this.tableOptions) {
      this.tableOptions = new TableOptions('candidate-cv-training-management');
    }
    this.resetFiltersOrdersAndColumns();
    if (this.tableOptions.withPagination) {
      this.pagination = new Pagination();
      this.pagination.currentPage = 1;
      this.pagination.itemPerPage = this.tableOptions.paginationItemsPerPage;
    }
    if (this.tableOptions.autoload) {
      this.list();
    }
  }

  list(): void {
    this.filter.candidate_id = this.candidate_id;
    this.filter.type = 'qualifications';
    this.cleanSelected();
    this.saveFiltersAndOrders();
    const listRequestData = new ListRequestData(this.pagination ? this.pagination.currentPage : null, this.filter, this.order);
    this.cvTrainingService.list(listRequestData).subscribe(
      res => {
        this.cvTrainings = <CVTrainingListItem[]> res['elements'];
        if (this.tableOptions.withPagination) {
          this.pagination.total = res['total'];
        }
        this.getHeader();
        this.getData();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  defaultFilter(): any {
    return new CVTrainingTableFilter();
  }
  defaultOrders(): any {
    return new CVTrainingTableOrder();
  }
  defaultVisibility(): any {
    return new CVTrainingTableVisibility();
  }
  getElements(): any {
    return this.cvTrainings;
  }
  getFilter(): any {
    return this.filter;
  }
  getOrder(): any {
    return this.order;
  }
  getVisibility(): any {
    return this.visibility;
  }
  setFilter(filter: any): void {
    this.filter.copy(filter);
  }
  setOrder(order: any): void {
    this.order = order;
  }
  setVisibility(visibility: any): void {
    this.visibility = visibility;
  }
  getLocalStorageKey(): any {
    return this.tableOptions.localStorageKey;
  }
  getAllSelectorCheckbox(): any {
  }
  getSelectorCheckboxes(): any {
  }
  hasActionColumn(): boolean {
    return this.tableOptions.hasActionColumn;
  }
  hasCheckboxColumn(): boolean {
    return this.tableOptions.hasCheckboxColumn;
  }
  changeFilter($event) {
    this.filter[$event['key']] = $event['value'];
    this.listWithTimeout();
  }
  enterSearch($event) {
    this.filter.search = $event.event.target.value;
    this.listWithTimeout();
  }
  clickHeaderBtn(e) {
    if ( e.action === 'create' ) {
      this.onCreate();
    }
  }
  constructBodyColumnsExceptions(key, value, isShow, isOrder, item): any {
    let objectData = null;
    let translate = false;
    // if ( key === 'type') {
    //   value = 'booking_type_' + value;
    //   translate = true;
    // }
    // if ( key === 'status_id') {
    //   value = 'booking_status_' + value;
    //   translate = true;
    // }
    objectData = this.setData(key, isShow, isOrder, value, translate);
    return objectData;
  }

  onOtherAction( $event: any) {
    if ( $event['action'] === 'download' ) {
      let token = ApiHelper.getToken();
      window.open(ApiRoutesHelper.FILE_FOLDER + '/' + this.colValue('document', $event['element']) + '?token=' + token,'_blank');
    }
  }

  getDataActions(item: any) {
    let actions = this.copyActionHeaders();
    for (let action of actions) {
      if (action.action == 'download' && item.document) {
        action.isShow = true;
      } else if (action.action == 'download' && !item.document) {
        action.isShow = false;
      }
    }
    return actions;
  }
  copyActionHeaders() {
    let result = [];
    for (let action of this.dataHeader.actions) {
      let auxAction = {};
      auxAction['action'] = action['action'];
      auxAction['isShow'] = action['isShow'];
      auxAction['icon'] = action['icon'];
      auxAction['title'] = action['title'];
      result.push(auxAction);
    }
    return result;
  }

  onEdit($event): void {
    this.edit.emit(this.colValue('id', $event['element']));
  }
  onDelete($event): void {
    this.delete.emit(this.colValue('id', $event['element']));
  }
  getData() {
    let dataTable = this.getElements();
    this.datas = this.getNewData(dataTable);
    this.firstLoadCompleted = true;
    var component = this;
    setTimeout(function() {
      if (component.cardsComponent) {
        component.cardsComponent.constructCard();
      }
    }, 1000);
  }

}
