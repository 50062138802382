import { NgModule } from '@angular/core';
import { NgxBloondeUserAndPrivilegesComponent } from './ngx-bloonde-user-and-privileges.component';
import { LoginPageComponent } from '../pages/login-page/login-page.component';
import { LoginFormComponent } from '../components/forms/login-form/login-form.component';
import { CommonModule } from '@angular/common';
import { NgTemplateModule } from '../../../bloonde-ngx-template/src/lib/ng-template.module';
import { RegistrationPageComponent } from '../pages/registration-page/registration-page.component';
import { RegistrationFormComponent } from '../components/forms/registration-form/registration-form.component';
import { RegistrationFormService } from '../components/forms/registration-form/registration-form.service';
import { ResetPasswordModalComponent } from '../components/modals/reset-password-modal/reset-password-modal.component';
import { ResetPasswordFormComponent } from '../components/forms/reset-password-form/reset-password-form.component';
import { ResetChangePasswordModalComponent } from '../components/modals/reset-change-password-modal/reset-change-password-modal.component';
import { ResetChangePasswordFormComponent } from '../components/forms/reset-change-password-form/reset-change-password-form.component';
import { ResetChangePasswordPageComponent } from '../pages/reset-change-password-page/reset-change-password-page.component';
import { AccessService } from '../services/access.service';
import { PrivilegesResolver } from '../resolvers/privileges.resolver';
import { PrivilegesGroupsResolver } from '../resolvers/privileges_groups.resolver';
import { CheckProfileGuard } from '../guards/check-profile-guard';
import { ChangePasswordModalComponent } from '../components/modals/change-password-modal/change-password-modal.component';
import { ChangePasswordFormComponent } from '../components/forms/change-password-form/change-password-form.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../../../bloonde-ngx-template/src/session/auth.interceptor';
import { RouterModule } from '@angular/router';
import { RegistrationFormModalComponent } from '../components/modals/registration-form-modal/registration-form-modal.component';
import { LoginFormModalComponent } from '../components/modals/login-form-modal/login-form-modal.component';
@NgModule({
  imports: [
    CommonModule,
    NgTemplateModule,
    RouterModule
  ],
  declarations: [
    NgxBloondeUserAndPrivilegesComponent,
    LoginPageComponent,
    LoginFormComponent,
    RegistrationFormComponent,
    RegistrationPageComponent,
    ResetPasswordModalComponent,
    ResetPasswordFormComponent,
    ResetChangePasswordModalComponent,
    ResetChangePasswordFormComponent,
    ResetChangePasswordPageComponent,
    ChangePasswordModalComponent,
    ChangePasswordFormComponent,
    LoginFormModalComponent,
    RegistrationFormModalComponent,
  ],
  providers: [
    RegistrationFormService,
    AccessService,
    PrivilegesResolver,
    PrivilegesGroupsResolver,
    CheckProfileGuard,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    }
  ],
  exports: [
    NgxBloondeUserAndPrivilegesComponent,
    ChangePasswordModalComponent,
    ResetPasswordModalComponent,
    LoginFormModalComponent,
    LoginFormComponent,
    RegistrationFormModalComponent
  ]
})
export class NgxBloondeUserAndPrivilegesModule { }
