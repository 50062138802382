import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {SessionService} from './services/session.service';
import { Observable } from 'rxjs/index';
import { ApiHelper } from './helpers/api.helper';
import { AccessService } from '../../../ngx-bloonde-user-and-privileges/src/services/access.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public sessionService: SessionService, public accessService: AccessService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                'Content-Type' : 'application/json; charset=utf-8',
                'Accept'       : 'application/json',
                'Authorization': `Bearer ${ApiHelper.getToken()}`,
                'Access-Control-Allow-Origin': 'http://localhost:4200',
                'Access-Control-Allow-Credentials': 'true',
                'X-Requested-With': 'XMLHttpRequest',
                'Accept-Language': this.sessionService.getLang(),
                'Profile-user-id': this.accessService.getSelectedAccessId()
            },
            withCredentials: true
        });

        return next.handle(req);
    }
}
