import { InputFormDirective } from './directives/input-form.directive';
import { ComponentFactoryResolver, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { InputTextComponent } from '../inputs/input-text/input-text.component';
import { LabelFormDirective } from './directives/label-form.directive';
import { LabelComponent } from '../inputs/label/label.component';
import {PasswordComponent} from '../inputs/password/password.component';
import {RadioButtonComponent} from '../inputs/radio-button/radio-button.component';
import { ValidationService } from './services/validation.service';
import { ResponseHelper } from '../responses/response.helper';
import { CodesHelper } from '../responses/codes.helper';
import { MessageService } from '../responses/message.service';
import {TextAreaComponent} from '../inputs/textarea/textarea.component';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { FloatComponent } from '../inputs/float/float.component';
import { NoteComponent } from '../inputs/note/note.component';
import { FormActionsHelper } from './helpers/form-actions.helper';
import { WysiwygComponent } from '../inputs/wysiwyg/wysiwyg.component';
import { TemplateModeHelper } from '../templates/helpers/template-mode.helper';
import { InnerHtmlComponent } from '../inputs/inner-html/inner-html.component';
import { H1Component } from '../inputs/h1/h1.component';
import { H2Component } from '../inputs/h2/h2.component';
import { ImgComponent } from '../inputs/img/img.component';
import { ReadOnlyComponent } from '../inputs/read-only/read-only.component';
/** Aunque se llama AbstractForm realmente sirve para renderizar una configuración. Se puede utilizar tanto para formulario como para páginas estáticas.
  Lo ideal en un futuro es separar la funcionalidad de renderizar y la de formulario en dos clases distintas en la que la del formulario extienda de la de renderizar
 **/
export abstract class AbstractFormComponent{

  public renderMode = TemplateModeHelper.EDITION_MODE; // Algunos inputes se deben renderizar diferente dependiendo del modo

  @Input() public withTitle = false;
  @Input() public withButtons = false;
  @Input() public withSuccesMessage = true;

  @Input()
  public nextAction;

  @Output() successCreation: EventEmitter<any> = new EventEmitter();
  @Output() successEdition: EventEmitter<any> = new EventEmitter();
  @Output() goToEditEmitter: EventEmitter<any> = new EventEmitter(); // Si la siguiente acción es edit se lanza hacia fuera este evento

  public args: any[]; // Parámetros recibidos desde el modal y que son usado en cada new o edit

  public structure: any; // Estructura que tiene el formulario

  public errors: any; // Variable donde se guardan los errores de frontend de cada campo
  public serverErrors: any; // Variable donde se guardan los errores de backend tras una petición store o update a la API

  public modelEventEmitterArray: EventEmitter<any>[];
  public errorEventEmitterArray: EventEmitter<any>[];
  public serverErrorEventEmitterArray: EventEmitter<any>[];

  public abstract getInputFormDirectives(): QueryList<InputFormDirective>;
  public abstract getLabelFormDirectives(): QueryList<LabelFormDirective>;
  public abstract getComponentFactoryResolver(): any;
  public abstract new(args: any[]): void; // Metodo que inicializa la variable del modelo del formulario
  public abstract getValidationService(): ValidationService;
  public abstract getModelService(): any;
  public abstract getResponseHelper(): ResponseHelper;
  public abstract getMessageService(): MessageService;
  public abstract getModel(): any;
  public abstract setModel(model: any): any;

  conditionFormComponents: any[];
  inputFormComponents: any[];
  labelFormComponents: any[];

  @Output() onSuccess: EventEmitter<any> = new EventEmitter(); // Emitter para lanzar un evento cuando el store o update se han realizado correctamente

  subscribes: any[] = [];

  constructor(){
    this.resetDynamicComponentArray();
  }

  public edit(id, args: any[] = []): void {
    this.preEdit(args); // Hook pre edit
    this.getModelService().get(id).subscribe(
      res => {
        if(res) {
          this.setModel(res['data'] ? res['data'] : res); // Reasignación del modelo
          this.successResponseEdit(res); //Hook post edit success
          this.buildErrorModelAndEmitters(); // Reseteamos errores, modelo y emitters
        }
      },
      error => {
        if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
          this.serverErrors = error.error;
          this.emitServerError();
        } else {
          this.getResponseHelper().handleError(error);
        }
      }
    );
  }
  public editWithModel(model, args: any[] = []): void {
    this.preEdit(args); // Hook pre edit
    this.setModel(model); // Reasignación del modelo
    this.successResponseEdit(model); //Hook post edit success
    this.buildErrorModelAndEmitters(); // Reseteamos errores, modelo y emitters
  }
  public store(args: any[] = []): void {
    this.preStore(args); // Hook pre store
    const validate = this.checkForm();
    this.emitError();
    if (validate) {
      this.getModelService().store(this.getModel()).subscribe(
        res => {
          this.successResponseStore(res); // Hook post store success
          this.resetErrors(); // Reseteamos los errores
          if(this.nextAction === FormActionsHelper.SAVE_AND_CONTINUE_ACTION) {
            this.new(this.args);
          }
          if(this.nextAction === FormActionsHelper.SAVE_AND_EDIT_ACTION) {
            this.goToEditEmitter.emit(res['id']);
          }
          this.success();
          if (this.withSuccesMessage) {
            this.getMessageService().showSuccessMessage();
          }
        },
        error => {
          if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
            this.serverErrors = error.error;
            this.emitServerError();
          } else {
            this.getResponseHelper().handleError(error);
          }
        }
      );
    }
  }
  public update(args: any[] = []): void {
    this.preUpdate(args); // Hook pre update
    const validate = this.checkForm();
    this.emitError();
    if (validate) {
      this.getModelService().update(this.getModel()).subscribe(
        res => {
          this.successResponseUpdate(res); // Hook post store success
          this.resetErrors();
          if(this.nextAction === FormActionsHelper.SAVE_AND_CONTINUE_ACTION) {
            this.new(this.args);
          }
          this.success();
          if (this.withSuccesMessage) {
            this.getMessageService().showSuccessMessage();
          }
        },
        error => {
          if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
            this.serverErrors = error.error;
            this.emitServerError();
          } else {
            this.getResponseHelper().handleError(error);
          }
        }
      );
    }
  }

  public constructForm(): void{
    if (this.getInputFormDirectives()) {
      for (let e = 0; e < this.getInputFormDirectives().length; e++) {
        if(this.structure[e]['config']['hasLabel'] && this.showLabelByTypeAndMode(this.structure[e]['type'])) {
          // Creación del label
          let labelFormDirectives = this.getLabelFormDirectives()['_results'][e];
          let componentFactory = this.getComponentFactoryResolver().resolveComponentFactory(LabelComponent);
          let viewContainerRef = labelFormDirectives.viewContainerRef;
          viewContainerRef.clear();
          let componentRef = viewContainerRef.createComponent(componentFactory);
          this.pushLabelFormComponents(componentRef, this.structure[e]['name']);
          componentRef.instance['structure'] = this.structure[e];
        }

        // Creación del input
        let inputFormDirectives = this.getInputFormDirectives()['_results'][e];
        let componentFactory = this.getComponentFactoryResolver().resolveComponentFactory(this.getComponentByFieldType(this.structure[e]['type']));
        let viewContainerRef = inputFormDirectives.viewContainerRef;
        let component = this;
        viewContainerRef.clear();
        let componentRef = viewContainerRef.createComponent(componentFactory);
        this.pushInputFormComponents(componentRef, this.structure[e]['name']);
        let attr = this.structure[e]['name'];
        if(this.structure[e].form) { // Si el input es un formulario
          componentRef.instance['inputStructure'] = this.structure[e];
        } else { // Si el input es un input normal
          componentRef.instance['structure'] = this.structure[e];
        }
        let model = this.getModel();
        let setModel = this.getModel()[attr];
        if (attr.indexOf('.') !== -1){ // Si tiene punto, es porque se está accediendo a la propiedad de un objeto del modelo.
          let parts = attr.split('.');
          for (let part of parts) {
            if(model[part]){
              model = model[part];
            } else {
              model = null;
              break;
            }
          }
          setModel = model;
        }

        if(componentRef.instance.setModel) {
          componentRef.instance.setModel(setModel);
        } else {
          componentRef.instance['model'] = setModel;
        }
        if(componentRef.instance.modelChange) {
          componentRef.instance.modelChange.subscribe(function(data) {
            component.changeModelAttr(attr, data);
          });
        } else {
          console.log('warning: no existe modelChange event emitter del campo ' + attr)
        }
        if(this.modelEventEmitterArray[attr]) {
          this.modelEventEmitterArray[attr].subscribe(function(data) {
            componentRef.instance['model'] = data;
          });
        } else {
          console.log('warning: no existe model event emitter del campo ' + attr)
        }
        if(this.errorEventEmitterArray[attr]) {
          this.errorEventEmitterArray[attr].subscribe(function(data) {
            componentRef.instance['error'] = data;
          });
        } else {
          console.log('warning: no existe error event emitter del campo ' + attr)
        }
        if(this.serverErrorEventEmitterArray[attr]) {
          this.serverErrorEventEmitterArray[attr].subscribe(function(data) {
            componentRef.instance['serverError'] = data;
          });
        } else {
          console.log('warning: no existe server error event emitter del campo ' + attr)
        }
        // Creacion del condiiton
        this.pushConditionFormComponents(this.structure[e]['init_if_condition'], this.structure[e]['name']);
      }
      this.extendConstructForm();
    }
  }

  public initModel(model: any, structure: any) {
    for(let i in structure) {
      let name = structure[i]['name'];
      if(!model[name]) {
        if(structure[i]['default_value']) {
          if (typeof(structure[i]['default_value']) == 'object'){
            let aux = [];
            for (let item in structure[i]['default_value']){
              aux.push(item);
            }
            model[name] = aux;
          } else {
            model[name] = structure[i]['default_value'];
          }
        }
      }
    }
  }
  private emitModel(model: any) {
    for(let i in model) {
      this.modelEventEmitterArray[i].emit(model[name]);
    }
  }
  public buildModelEmitters(structure: any): EventEmitter<any>[] {
    let modelEventEmitterArray: EventEmitter<any>[] = [];
    for(let i in structure) {
      let modelEventEmitter: EventEmitter<any> = new EventEmitter();
      modelEventEmitterArray[structure[i]['name']] = modelEventEmitter;
    }
    return modelEventEmitterArray;
  }
  public buildErrorEmitters(errors: any): EventEmitter<any>[] {
    let errorEventEmitterArray: EventEmitter<any>[] = [];
    for(let i in errors) {
      let errorEventEmitter: EventEmitter<any> = new EventEmitter();
      errorEventEmitterArray[i] = errorEventEmitter;
    }
    return errorEventEmitterArray;
  }
  public buildServerErrorEmitters(serverErrors: any): EventEmitter<any>[] {
    let serverErrorEventEmitterArray: EventEmitter<any>[] = [];
    for(let i in serverErrors) {
      let serverErrorEventEmitter: EventEmitter<any> = new EventEmitter();
      serverErrorEventEmitterArray[i] = serverErrorEventEmitter;
    }
    return serverErrorEventEmitterArray;
  }
  // funcion que determina que componente vamos a pintar en base al tipo del input indicado en la configuración y al modo de la template/form
  private getComponentByFieldType(type: any): any {
    if (type === 'input-text' || type === 'text') {
      if(this.renderMode === TemplateModeHelper.EDITION_MODE) {
        return InputTextComponent;
      } else if (this.renderMode === TemplateModeHelper.SHOW_MODE) {
        return InnerHtmlComponent;
      }
    } else if (type === 'h1') {
      if(this.renderMode === TemplateModeHelper.EDITION_MODE) {
        return InputTextComponent;
      } else if (this.renderMode === TemplateModeHelper.SHOW_MODE) {
        return H1Component;
      }
    } else if (type === 'h2') {
      if(this.renderMode === TemplateModeHelper.EDITION_MODE) {
        return InputTextComponent;
      } else if (this.renderMode === TemplateModeHelper.SHOW_MODE) {
        return H2Component;
      }
    } else if (type === 'input-password') {
      return PasswordComponent;
    } else if (type === 'radio-button') {
      return RadioButtonComponent;
    } else if (type === 'textarea') {
      return TextAreaComponent;
    } else if (type === 'float') {
      return FloatComponent;
    } else if (type === 'note') {
      return NoteComponent;
    } else if (type === 'checkbox' || type === 'boolean') {
      return CheckboxComponent;
    } else if (type === 'img') {
      return ImgComponent;
    } else if (type === 'read-only') {
      return ReadOnlyComponent;
    } else if (type === 'wysiwyg') {
      if(this.renderMode === TemplateModeHelper.EDITION_MODE) {
        return WysiwygComponent;
      } else if (this.renderMode === TemplateModeHelper.SHOW_MODE) {
        return InnerHtmlComponent;
      }
    } else {
      let extendResult = this.extendsGetComponentByFieldType(type);
      if(extendResult) {
        return extendResult;
      } else {
        return type;
      }
    }
  }
  // Función para determinar si el label debe ser mostrado en base al tipo del input indicado en la configuración y al modo de la template
  public showLabelByTypeAndMode(type: any) {
    if (type === 'wysiwyg' || type === 'input-text' || type === 'h1' || type === 'h2') { // El wysiwyh en modo SHOW no muestra el label, solo queremos mostrar su contenido
      if(this.renderMode === TemplateModeHelper.SHOW_MODE) {
        return false;
      }
    }
    return true;
  }
  public extendsGetComponentByFieldType(type: string): any {};
  private changeModelAttr(attr, value) {
    this.getModel()[attr] = value;
  }

  public emitError(): void {
    for(let i in this.errors) {
      this.errorEventEmitterArray[i].emit(this.errors[i]);
    }
  }
  public emitServerError(): void {
    for(let i in this.serverErrors) {
      if(this.serverErrorEventEmitterArray[i]) {
        this.serverErrorEventEmitterArray[i].emit(this.serverErrors[i][0]);
      }
    }
  }

  public success(): void {
    this.onSuccess.emit(this.getModel());
  }

  public buildErrorModelAndEmitters(): void {
    this.errors = this.getValidationService().buildErrorsArray(this.structure);
    this.serverErrors = this.getValidationService().buildServerErrorsArray(this.structure);
    this.modelEventEmitterArray = this.buildModelEmitters(this.structure);
    this.initModel(this.getModel(), this.structure);
    this.errorEventEmitterArray = this.buildErrorEmitters(this.errors);
    this.serverErrorEventEmitterArray = this.buildErrorEmitters(this.serverErrors);
    const component = this;
    setTimeout(function() {
      component.constructForm();
    }, 200);
  }

  // Sobreescribir si queremos hacer algo diferente
  successResponseEdit(res: any): void {}
  // Sobreescribir si queremos hacer algo diferente
  successResponseStore(res: any): void {}
  // Sobreescribir si queremos hacer algo diferente
  successResponseUpdate(res: any): void {}
  // Sobrescribir si queremos hacer algo antes del edit
  preEdit(args: any[]): void {}
  // Sobrescribir si queremos hacer algo antes del store
  preStore(args: any[]): void {}
  // Sobrescribir si queremos hacer algo antes del update
  preUpdate(args: any[]): void {}
  public getInputFormComponents(key: any): any {
    return this.inputFormComponents[key].instance;
  }
  public getLabelFormComponents(key: any): any {
    if(this.labelFormComponents[key]) {
      return this.labelFormComponents[key].instance;
    } else {
      return null;
    }
  }
  public getConditionFormComponents(key: any): void {
    return this.conditionFormComponents[key];
  }

  extendConstructForm(): void {}

  //Sobrescribir si queremos validar algo adicionalmente
  checkForm(): boolean {
    return this.getValidationService().checkForm(this.errors, this.getModel());
  }

  public resetErrors(): void {
    this.errors = this.getValidationService().buildErrorsArray(this.structure);
    this.serverErrors = {};
  }
  public setVisibilityField(field: string, visibility: boolean) {
    let input = this.getInputFormComponents(field);
    if(input) {
      input.if_condition = visibility;
    }
    let label = this.getLabelFormComponents(field);
    if(label) {
      label.if_condition = visibility;
    }
    this.conditionFormComponents[field] = visibility;
  }
  public setDisabledField(field: string, disabled: boolean, isFormInput: boolean = false) {
    let input = this.getInputFormComponents(field);
    if(input) {
      if(isFormInput) {
        input.inputStructure.disabled = disabled;
      } else {
        input.structure.disabled = disabled;
      }
    }
    let label = this.getLabelFormComponents(field);
    if(label) {
      label.structure.disabled = disabled;
    }
  }
  public removeField(field: string, remove: boolean) {
    let input = this.getInputFormComponents(field);
    if(input && remove) {
      input.modelValue = null;
      this.getModel()[field] = null;
    }
  }

  public pushInputFormComponents(componentRef: any, key: any): void {
    this.inputFormComponents[key] = componentRef;
  }
  public pushLabelFormComponents(componentRef: any, key: any): void {
    this.labelFormComponents[key] = componentRef;
  }
  public pushConditionFormComponents(componentRef: any, key: any): void {
    this.conditionFormComponents[key] = componentRef;
  }
  public resetDynamicComponentArray(): void {
    this.inputFormComponents = [];
    this.labelFormComponents = [];
    this.conditionFormComponents = [];
  }
}
