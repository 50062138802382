export class OfferTableOrder {
  id: string;
  name: string;
  created_at: string;
  expected_contract_date: string;
  status_id: string;
  published: string;
  candidatures: string;
  institution: string;
  function: string;
  sector_name: string;
  position_name: string;
  city_name: string;

  constructor() {
    this.id = null;
    this.name = null;
    this.created_at = null;
    this.expected_contract_date = null;
    this.candidatures = null;
    this.status_id = null;
    this.published = null;
    this.institution = null;
    this.function = null;
    this.sector_name = null;
    this.position_name = null;
    this.city_name = null;
  }
}
