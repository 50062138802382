import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable, Observer} from "rxjs/index";
import { OfferService } from '../services/offer.service';
import { Offer } from '../models/offer';

@Injectable()
export class OfferResolver implements Resolve<any> {
  offer_id: string;
  constructor(private offerService: OfferService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.offer_id = route.params['id'];
      if (this.offer_id) {
        this.offerService.get(this.offer_id)
          .subscribe(
            res => {
              observer.next(res);
              observer.complete();
            },
            error => {
              observer.next(null);
              observer.complete();
            }
          );
      } else {
        let owner = new Offer();
        observer.next(owner);
        observer.complete();
      }
    });
  }
}
