export class MultipleImage {
  id: any;
  name: string;
  description: string;
  path: string[];
  main: boolean;
  type: any;
  /**
   * Claves ajenas
   */
  fk_id: any;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.path = [];
    this.main = null;
    this.type = null;
    this.fk_id = null;
  }

  public static copy(model: any) {
    let aux = new MultipleImage();
    aux.id = model.id;
    aux.name = model.name;
    aux.description = model.description;
    aux.path = model.path;
    aux.main = model.main;
    aux.type = model.main;
    aux.fk_id = model.fk_id;

    return aux;
  }
}
