import { OfferSelectComponent } from '../../inputs/offer-select/offer-select.component';
import { OfferCandidateSelectComponent } from '../../inputs/offer-candidate-select/offer-candidate-select.component';
export class ChatFormConfiguration {
  public static structure = [
    {
      name: 'offer_id',
      label: 'offer',
      placeholder: 'Select an offer',
      type: OfferSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'candidate_id',
      label: 'candidate',
      placeholder: 'Select an candidate',
      type: OfferCandidateSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      disabled: true
    }
  ];
}
