import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { CVExperience } from '../../../models/cv_experience';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { CvExperienceService } from '../../../services/cv-experience.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { CVExperienceFormConfiguration } from './cv-experience-form-configuration';

@Component({
  selector: 'app-cv-experience-form',
  templateUrl: './cv-experience-form.component.html',
  styleUrls: ['./cv-experience-form.component.sass']
})
export class CvExperienceFormComponent extends AbstractFormComponent implements OnInit {

  @Input()
  public modelValue: CVExperience;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private cvExperienceService: CvExperienceService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = CVExperienceFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(args: any[]): void {
    this.args = args;
    this.modelValue = new CVExperience();
    this.modelValue.candidate_id = this.args['candidate_id'];
    this.buildErrorModelAndEmitters();
  }
  public successResponseEdit(res): void {
    this.modelValue = CVExperience.copy(this.modelValue);
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.cvExperienceService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }
  successResponseStore(res): void {
    this.modelValue = CVExperience.copy(res);
  }
  public success(): void {
    this.onSuccess.emit({id: this.modelValue.id});
  }

  public extendConstructForm(): void {
    /**
     * COMIENZO INICIALIZACION DEL FORMULARIO
     */
    if (this.inputFormComponents['working_day'].instance.modelValue) {
      if (this.inputFormComponents['working_day'].instance.modelValue != 'reduced') {
        this.setVisibilityField('working_day_hours', false);
      }
    } else {
      this.setVisibilityField('working_day_hours', false);
    }
    /**
     * FIN INICIALIZACION DEL FORMULARIO
     */

    /**
     * COMIENZO DETECCION DE CAMBIOS EN EL FORMULARIO
     */
    var component = this;
    this.inputFormComponents['document'].instance.delete.subscribe(function() {
      component.removeField('document', true);
      component.modelValue['document'] = null;
    });

    this.inputFormComponents['working_day'].instance.modelChange.subscribe(function (value) {
      let visible = value && value === 'reduced';
      component.setVisibilityField('working_day_hours', visible);
      // component.removeField('working_day_hours', true); Por si hay que limpiar siempre el valor.
    });
    /**
     * FIN DETECCION DE CAMBIOS EN EL FORMULARIO
     */
  }

}
