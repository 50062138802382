import { Sector } from './sector';
import { Position } from './position';
import { City } from './city';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
import { Offer } from './offer';
import { Candidate } from './candidate';
export class Chat {
  id: any;
  open: boolean;

  /**
   * Claves ajenas
   */
  offer_id: any;
  candidate_id: any;


  /**
   * Atributos derivados de la clave ajena
   */

  offer: Offer;
  candidate: Candidate;

  constructor() {
    this.id = null;
    this.offer_id = null;
    this.candidate_id = null;
  }
  public static copy(model: any) {
    let aux = new Chat();
    aux.id = model.id;
    aux.offer_id = model.offer_id;
    aux.candidate_id = model.candidate_id;
    return aux;
  }
}
