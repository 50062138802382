import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import {SelectService} from '../../../services/select.service';
import {ResponseHelper} from 'bloonde-ngx-template/src/responses/response.helper';
import {SessionHelper} from 'bloonde-ngx-template/src/session/helpers/session.helper';

@Component({
  selector: 'app-training-level-filter',
  templateUrl: './training-level-filter.component.html',
  styleUrls: ['./training-level-filter.component.sass']
})
export class TrainingLevelFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }
  @Input()
  degree: string;

  items: any[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'training_level', placeholder: 'Nivel', options: []};

  constructor(private selectService: SelectService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    this.getItems();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  setModel(model): void {}

  getItems(): void {
    this.items = this.selectService.getLevelFilterOptions();
    if (!this.items) {
      this.getOptions();
    }
  }

  list(): void {
    this.prepareOptions();
  }

  getOptions(): void {
    this.selectService.selectList('qualifications-filter').subscribe(
      res => {
        this.items = <any[]> res;
        this.selectService.qualificationFilterOptions = res;
        let options = JSON.stringify(res);
        SessionHelper.setLocalStorageField('level_filter_options', options);
        this.prepareOptions();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  private prepareOptions(): void {
    let options = [];
    if (this.degree) {
      this.disabled = false;
      // Si hay valor en el modelo, comprobar que exista en el array. Si no, se limpia.
      if (this.model && (!this.items[this.degree]['options'] || !this.items[this.degree]['options'][this.model])) {
        this.model = null;
        this.modelChange.emit(this.model);
      }

      for (let i in this.items) {
        if (this.degree == i) {
          let item = this.items[i];
          if (item['options']) {
            for (let option in item['options']) {
              if (item['options'][option]['options']) {
                options.push({
                  value: option,
                  label: item['options'][option]['translated'],
                });
              } else {
                options.push({
                  value: option,
                  label: item['options'][option],
                });
              }

            }
          } else {
            options.push({
              value: i,
              label: item['translated'],
            });
          }

        }
      }
    } else {
      this.model = null;
      this.modelChange.emit(this.model);
      this.disabled = true;
    }
    this.options['options'] = options;
  }

}
