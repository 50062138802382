import {EventEmitter, Output} from '@angular/core';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class CVLanguageTableFilter extends AbstractFilterComponent {
  @Output() searchModelChange: EventEmitter<any>;
  search: string;
  @Output() candidateIdModelChange: EventEmitter<any>;
  candidate_id: any;
  type: any;

  constructor() {
    super();
    this.search = null;
    this.searchModelChange = new EventEmitter();
    this.candidate_id = null;
    this.candidateIdModelChange = new EventEmitter();
    this.type = null;
  }
  public toFilter(): string[] {
    return ['search', 'candidate_id', 'type'];
  }
  public emit() {
    this.searchModelChange.emit(this.search);
    this.candidateIdModelChange.emit(this.candidate_id);
  }
  public copy(filter: {search: string, candidate_id: any, type: any}) {
    this.search = filter.search;
    this.candidate_id = filter.candidate_id;
    this.type = filter.type;
    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      search: this.search,
      candidate_id: this.candidate_id,
      type: this.type
    };
  }
}

