import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ResetChangePasswordFormComponent } from '../../forms/reset-change-password-form/reset-change-password-form.component';
import {AbstractFormModalComponent} from '../../../../../bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';

@Component({
  selector: 'bl-reset-change-password-modal',
  templateUrl: './reset-change-password-modal.component.html',
  styleUrls: ['./reset-change-password-modal.component.css']
})
export class ResetChangePasswordModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: false, save: true, save_and_new: false, save_and_edit: false};
  @Input() buttonsText: {cancelText: string, saveText: string, saveAndNewText: string, saveAndEditText: string, deleteText: string} = {cancelText: null, saveText: 'Accept', saveAndNewText: null, saveAndEditText: null, deleteText: null};

  @ViewChild(ResetChangePasswordFormComponent) resetChangePasswordFormComponent;

  @Output() success: EventEmitter<any> = new EventEmitter();

  @Input() from_app: boolean;

  public successApp: boolean;

  public modal_id = 'reset_change_password_modal';

  constructor() {
    super();
  }
  ngOnInit() {
  }

  public getFormComponent(): any {
    return this.resetChangePasswordFormComponent;
  }

  saveAndClose(): void {
    this.resetChangePasswordFormComponent.passwordReset();
  }
  onSuccess($event): void {
    if (!this.from_app) {
      this.closeModal();
      this.success.emit();
    } else {
      this.successApp = true;
    }

  }

}
