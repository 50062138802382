export class ResetPasswordFormConfiguration {
  public static structure = [
    {
      name: 'email',
      label: 'E-mail',
      placeholder: null,
      type: 'input-text',
      default_value: null,
      col: 'col-12',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    }
  ];
}
