import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { SelectService } from '../../../services/select.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import {SessionHelper} from 'bloonde-ngx-template/src/session/helpers/session.helper';

@Component({
  selector: 'app-training-degree-filter',
  templateUrl: './training-degree-filter.component.html',
  styleUrls: ['./training-degree-filter.component.sass']
})
export class TrainingDegreeFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  items: any[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'training_degree', placeholder: 'Grado', options: []};

  constructor(private selectService: SelectService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    this.list();
  }
  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    this.items = this.selectService.getLevelFilterOptions();
    if (this.items) {
      this.prepareOptions();
    } else {
      this.getOptions();
    }
  }

  getOptions(): void {
    this.selectService.selectList('qualifications-filter').subscribe(
      res => {
        this.items = <any[]> res;
        this.selectService.qualificationFilterOptions = res;
        let options = JSON.stringify(res);
        SessionHelper.setLocalStorageField('level_filter_options', options);
        this.prepareOptions();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }


  private prepareOptions(): void {
    let options = [];
    for (let i in this.items) {
      let item = this.items[i];
      options.push({
        value: i,
        label: item['translated'],
        options: item['options'],
      });
    }
    this.options['options'] = options;
  }

}
