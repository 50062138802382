import { NgModule, Provider } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from '../../projects/ngx-bloonde-user-and-privileges/src/pages/login-page/login-page.component';
import { RegistrationPageComponent } from '../../projects/ngx-bloonde-user-and-privileges/src/pages/registration-page/registration-page.component';
import { ResetChangePasswordPageComponent } from '../../projects/ngx-bloonde-user-and-privileges/src/pages/reset-change-password-page/reset-change-password-page.component';
import { PanelComponent } from '../pages/panel/panel.component';
import { AuthGuard } from '../../projects/bloonde-ngx-template/src/session/guards/auth-guard';
import { UserLoggedResolver } from '../../projects/bloonde-ngx-template/src/session/resolvers/user-logged.resolver';
import { ROUTES_ARRAY } from '../helpers/router-navigation.helper';
import { NavigationHelper } from '../../projects/bloonde-ngx-template/src/navigation/navigation.helper';
import { PrivilegesResolver } from '../../projects/ngx-bloonde-user-and-privileges/src/resolvers/privileges.resolver';
import { PrivilegesGroupsResolver } from '../../projects/ngx-bloonde-user-and-privileges/src/resolvers/privileges_groups.resolver';
import { CheckProfileGuard } from '../../projects/ngx-bloonde-user-and-privileges/src/guards/check-profile-guard';
import { OfferManagementPageComponent } from '../pages/offer-management-page/offer-management-page.component';
import { OfferEditionPageComponent } from '../pages/offer-edition-page/offer-edition-page.component';
import { OfferResolver } from '../resolvers/offer.resolver';
import { CandidateProfilePageComponent } from '../pages/candidate-profile-page/candidate-profile-page.component';
import { CandidateManagementPageComponent } from '../pages/candidate-management-page/candidate-management-page.component';
import { CandidateEditionPageComponent } from '../pages/candidate-edition-page/candidate-edition-page.component';
import { CandidateResolver } from '../resolvers/candidate.resolver';
import { OffersCandidatesManagementComponent } from '../components/managements/offers-candidates-management/offers-candidates-management.component';
import { OfferCandidateManagementPageComponent } from '../pages/offer-candidate-management-page/offer-candidate-management-page.component';
import { ChatManagementPageComponent } from '../pages/chat-management-page/chat-management-page.component';
import { MessageManagementPageComponent } from '../pages/message-management-page/message-management-page.component';
import { MyOfferCandidateManagementPageComponent } from '../pages/my-offer-candidate-management-page/my-offer-candidate-management-page.component';
import { ValuesPageComponentComponent } from '../pages/values-page-component/values-page-component.component';
import { ConditionPageComponentComponent } from '../pages/condition-page-component/condition-page-component.component';
import { PublicOfferSearchPageComponent } from '../pages/public-offer-search-page/public-offer-search-page.component';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { UserLoggedNoRedirectResolver } from 'bloonde-ngx-template/src/session/resolvers/user-logged-no-redirect.resolver';
import { User } from 'bloonde-ngx-template/src/session/models/user';
import { PublicComponent } from '../pages/public/public.component';
import { PrivateComponent } from '../pages/private/private.component';
import {OfferDetailsPageComponent} from '../pages/offer-details-page/offer-details-page.component';
import {PublicCandidateResolver} from '../resolvers/public-candidate.resolver';
import {TermsPageComponent} from '../pages/terms-page/terms-page.component';
import {PrivacyPageComponent} from '../pages/privacy-page/privacy-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/panel/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'values',
    component: ValuesPageComponentComponent,
  },
  {
    path: 'condition',
    component: ConditionPageComponentComponent,
  },
  {
    path: 'registration',
    component: RegistrationPageComponent,
  },
  {
    path: 'resetChangePassword/:token',
    component: ResetChangePasswordPageComponent,
  },
  {
    path: 'panel',
    component: PanelComponent,
    resolve: {
      loggedInUser: UserLoggedNoRedirectResolver, PrivilegesResolver, PrivilegesGroupsResolver,
    },
    children: [
      // Rutas públicas del panel
      {
        path: '',
        component: PublicComponent,
        children: [
          {
            path: 'home',
            component: HomePageComponent,
          },
          {
            path: 'terms',
            component: TermsPageComponent,
          },
          {
            path: 'privacy',
            component: PrivacyPageComponent,
          },
          {
            path: 'offer-search',
            resolve: {
              loggedInUser: UserLoggedNoRedirectResolver,
              candidate: PublicCandidateResolver,
            },
            component: PublicOfferSearchPageComponent,
          },
          {
            path: 'offer-details/:id',
            resolve: {
              offer: OfferResolver,
              loggedInUser: UserLoggedNoRedirectResolver,
              candidate: PublicCandidateResolver,
            },
            component: OfferDetailsPageComponent,
          },
        ]
      },
      // Rutas privadas del panel
      {
        path: '',
        component: PrivateComponent,
        canActivate: [AuthGuard],
        resolve: {
          loggedInUser: UserLoggedResolver,
        },
        children: [
          {
            path: 'offers',
            data: {profiles: ['evaluator']},
            component: OfferManagementPageComponent,
          },
          {
            path: 'offer/:id',
            resolve: {
              offer: OfferResolver
            },
            component: OfferEditionPageComponent,
          },
          {
            canActivate: [CheckProfileGuard],
            data: {profiles: ['candidate']},
            resolve: {
              loggedInUser: UserLoggedResolver
            },
            path: 'my-profile',
            component: CandidateProfilePageComponent,
          },
          {
            canActivate: [CheckProfileGuard],
            path: 'candidates',
            data: {profiles: ['evaluator']},
            component: CandidateManagementPageComponent,
          },
          {
            canActivate: [CheckProfileGuard],
            data: {profiles: ['evaluator']},
            resolve: {
              candidate: CandidateResolver
            },
            path: 'candidate/:id',
            component: CandidateEditionPageComponent,
          },
          {
            canActivate: [CheckProfileGuard],
            data: {profiles: ['candidate']},
            path: 'offer-candidate',
            component: MyOfferCandidateManagementPageComponent,
          },
          {
            canActivate: [CheckProfileGuard],
            data: {profiles: ['evaluator']},
            path: 'offer-candidate/:offer_id',
            component: OfferCandidateManagementPageComponent,
          },
          {
            path: 'chats',
            canActivate: [CheckProfileGuard],
            data: {profiles: ['evaluator', 'candidate']},
            resolve: {
              loggedInUser: UserLoggedResolver
            },
            component: ChatManagementPageComponent,
          },
          {
            canActivate: [CheckProfileGuard],
            data: {profiles: ['evaluator', 'candidate']},
            path: 'messages/:chat_id',
            component: MessageManagementPageComponent,
          },
        ]
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const navigation: Provider = NavigationHelper.forRoot(ROUTES_ARRAY);
