import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

export abstract class AbstractInputComponent implements OnInit, OnChanges {

    @Input()
    public structure: any;

    @Input()
    public error: any;

    @Input()
    public serverError: any;

    @Input()
    public prefix: string;

    @Input()
    public disabled: string;

    @Input()
    get model() {
        return this.modelValue;
    }
    @Output()
    modelChange = new EventEmitter<any>();

    public modelValue: any;

    constructor() {
    }

    ngOnInit() {}
    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && changes.options.currentValue) {
            this.structure = changes.options.currentValue;
        }
    }

    set model(model) {
        this.modelValue = model;
        this.modelChange.emit(this.modelValue);
    }
    setModel(model): void {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }

}
