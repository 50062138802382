import { CandidateProfileFormComponent } from '../components/forms/candidate-profile-form/candidate-profile-form.component';
import { Candidate } from './candidate';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
export class CVExperience {
    id: any;
    title: string;
    observations: string;
    start_date: any;
    end_date: any;
    position_id: string;
    functions: string;
    company: string;
    company_type: any;
    location: string;
    working_day: number;
    working_day_hours: number;
    volunteer: boolean;
    document: any;

    candidate_id: any;

    candidate: Candidate;

  constructor() {
    this.id = null;
    this.title = null;
    this.observations = null;
    this.start_date = null;
    this.end_date = null;
    this.position_id = null;
    this.functions = null;
    this.company = null;
    this.company_type = null;
    this.location = null;
    this.working_day = null;
    this.working_day_hours = null;
    this.volunteer = false;
    this.document = null;
    this.candidate_id = null;
    this.candidate = new Candidate();
  }
  public static copy(cvExperience: any) {
    let cvExperienceAux = new CVExperience();
    cvExperienceAux.id = cvExperience.id;
    cvExperienceAux.title = cvExperience.title;
    cvExperienceAux.observations = cvExperience.observations;
    if (cvExperience.start_date) {
      cvExperienceAux.start_date = FormatHelper.formatFromApi(FormatHelper.DATE_FORMAT, cvExperience.start_date);
    }
    if (cvExperience.end_date) {
      cvExperienceAux.end_date = FormatHelper.formatFromApi(FormatHelper.DATE_FORMAT, cvExperience.end_date);
    }
    cvExperienceAux.position_id = cvExperience.position_id;
    cvExperienceAux.functions = cvExperience.functions;
    cvExperienceAux.company = cvExperience.company;
    cvExperienceAux.company_type = cvExperience.company_type;
    cvExperienceAux.location = cvExperience.location;
    cvExperienceAux.working_day = cvExperience.working_day;
    cvExperienceAux.working_day_hours = cvExperience.working_day_hours;
    cvExperienceAux.volunteer = cvExperience.volunter;
    cvExperienceAux.document = cvExperience.document;
    cvExperienceAux.candidate_id = cvExperience.candidate_id;
    cvExperienceAux.candidate = cvExperience.candidate;

    return cvExperienceAux;
  }
}
