import {Component, Inject, Input, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import { AbstractTableComponent } from 'bloonde-ngx-template/src/tables/partials/abstract-table/abstract-table.component';
import { TableOptions } from 'bloonde-ngx-template/src/tables/models/table-options';
import { OfferCandidateListItem } from '../../../models/listings/offer_candidate_list_item';
import { Pagination } from 'bloonde-ngx-template/src/tables/models/pagination';
import { MyOffersCandidateTableFilter } from './my-offers-candidate-table-filter';
import { MyOffersCandidateTableOrder } from './my-offers-candidate-table-order';
import { MyOffersCandidateTableVisibility } from './my-offers-candidate-table-visibility';
import { DataHeader } from 'bloonde-ngx-template/src/tables/models/data-header';
import { OfferCandidateService } from '../../../services/offer-candidate.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import { TableComponent } from 'bloonde-ngx-template/src/tables/partials/table/table.component';
import { StatusFilterComponent } from '../../filters/status-filter/status-filter.component';
import {PositionFilterComponent} from '../../filters/position-filter/position-filter.component';
import {formatDate} from '@angular/common';

@Component({
  selector: '[app-my-offers-candidate-table]',
  templateUrl: './my-offers-candidate-table.component.html',
  styleUrls: ['./my-offers-candidate-table.component.sass']
})
export class MyOffersCandidateTableComponent extends AbstractTableComponent implements OnInit {

  @ViewChild(TableComponent) tableComponent;

  @Input() tableOptions: TableOptions;
  @Input() public candidate_id;

  public offersCandidates: OfferCandidateListItem[];
  public pagination: Pagination;

  public filter: MyOffersCandidateTableFilter = new MyOffersCandidateTableFilter();
  public order: MyOffersCandidateTableOrder = new MyOffersCandidateTableOrder();
  public visibility: MyOffersCandidateTableVisibility = new MyOffersCandidateTableVisibility();

  public dataHeader: DataHeader = {
    title: {
      isShow: true,
      label: 'My Offers Management',
    },
    filters: {
      isShow: true,
      items: [
        {
          component: StatusFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Status', type: 'property'},
            {attr: 'type', value: 'offer_candidate', type: 'property'},
            {attr: 'modelChange', value: 'status_id', type: 'output'},
            {attr: 'model', value: this.filter.statusIdModelChange, type: 'input'}
          ]
        },
        {
          component: PositionFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Position', type: 'property'},
            {attr: 'modelChange', value: 'position', type: 'output'},
            {attr: 'model', value: this.filter.positionModelChange, type: 'input'}
          ]
        }
      ]
    },
    btns: {
      isShow: false,
      items: []
    },
    actions: [
      {
        action: 'see_offer',
        isShow: true,
        icon: 'fas fa-eye',
        title: 'See offer'
      }
    ],
    style: ''
  };

  public isTable: boolean = true;

  constructor(
    private offerCandidateService: OfferCandidateService,
    private responseHelper: ResponseHelper,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super();
  }

  ngOnInit() {
    this.filter.candidate_id = this.candidate_id;
    if (!this.tableOptions) {
      this.tableOptions = new TableOptions('my-offers-candidate-management');
    }
    this.resetFiltersOrdersAndColumns();
    if (this.tableOptions.withPagination) {
      this.pagination = new Pagination();
      this.pagination.currentPage = 1;
      this.pagination.itemPerPage = this.tableOptions.paginationItemsPerPage;
    }
    if (this.tableOptions.autoload) {
      this.list();
    }
  }

  list(): void {
    if (this.candidate_id) {
      this.filter.candidate_id = this.candidate_id;
    }
    this.cleanSelected();
    this.saveFiltersAndOrders();
    const listRequestData = new ListRequestData(this.pagination ? this.pagination.currentPage : null, this.filter, this.order);
    this.offerCandidateService.list(listRequestData).subscribe(
      res => {
        this.offersCandidates = <OfferCandidateListItem[]> res['elements'];
        if (this.tableOptions.withPagination) {
          this.pagination.total = res['total'];
        }
        this.getHeader();
        this.getData();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  defaultFilter(): any {
    return new MyOffersCandidateTableFilter();
  }
  defaultOrders(): any {
    return new MyOffersCandidateTableOrder();
  }
  defaultVisibility(): any {
    return new MyOffersCandidateTableVisibility();
  }
  getElements(): any {
    return this.offersCandidates;
  }
  getFilter(): any {
    return this.filter;
  }
  getOrder(): any {
    return this.order;
  }
  getVisibility(): any {
    return this.visibility;
  }
  setFilter(filter: any): void {
    this.filter.copy(filter);
  }
  setOrder(order: any): void {
    this.order = order;
  }
  setVisibility(visibility: any): void {
    this.visibility = visibility;
  }
  getLocalStorageKey(): any {
    return this.tableOptions.localStorageKey;
  }
  getAllSelectorCheckbox(): any {
    if (this.tableComponent) {
      return this.tableComponent.getAllSelectorCheckbox();
    }
  }
  getSelectorCheckboxes(): any {
    return this.tableComponent.getSelectorCheckboxes();
  }
  hasActionColumn(): boolean {
    return this.tableOptions.hasActionColumn;
  }
  hasCheckboxColumn(): boolean {
    return this.tableOptions.hasCheckboxColumn;
  }
  changeFilter($event) {
    this.filter[$event['key']] = $event['value'];
    this.listWithTimeout();
  }

  onOtherAction($event) {
    switch ( $event.action ) {
      case 'see_offer':
        let offer_id = this.getOfferByOfferCandidateId($event.id);
        let auxEvent = $event;
        auxEvent.id = offer_id;
        this.otherAction.emit(auxEvent);
        break;
    }
  }

  constructBodyColumnsExceptions(key, value, isShow, isOrder, item): any {
    let objectData = null;
    let translate = false;
    if ( key === 'status_id') {
      value = 'offer_candidate_status_' + value;
      translate = true;
    } else if (key === 'registration_date') {
      value = this.transformDate(item[key]);
    }
    objectData = this.setData(key, isShow, isOrder, value, translate);
    return objectData;
  }

  transformDate(date: any, format: string = 'dd/MM/yyyy') {
    return formatDate(date, format, this.locale);
  }
  getDataActions(item: any) {
    return this.dataHeader.actions;
  }
  getOfferByOfferCandidateId(offer_candidate_id: any) {
    for (let offerCandidate of this.offersCandidates) {
      if (offer_candidate_id == offerCandidate.id) {
        return offerCandidate.offer_id;
      }
    }
    return null;
  }

}
