export class OfferTableVisibility {
  id: boolean;
  name: boolean;
  created_at: boolean;
  expected_contract_date: boolean;
  status_id: boolean;
  published: boolean;
  candidatures: boolean;
  institution: boolean;
  function: boolean;
  sector_name: boolean;
  position_name: boolean;
  city_name: boolean;

  constructor() {
    this.id = true;
    this.name = true;
    this.created_at = true;
    this.expected_contract_date = true;
    this.candidatures = true;
    this.status_id = true;
    this.published = true;
    this.institution = false;
    this.function = false;
    this.sector_name = false;
    this.position_name = false;
    this.city_name = false;
  }
}

