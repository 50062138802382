import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { Candidate } from '../models/candidate';
import {FormatHelper} from 'bloonde-ngx-template/src/api/tools/format.helper';

@Injectable()
export class CandidateService {

  private loggedCandidate: Candidate;

  constructor(public _http: HttpClient) {
  }

  public setLoggedCandidate(data: any): void {
    return this.loggedCandidate = data;
  }

  public getLoggedCandidate(): Candidate {
    return this.loggedCandidate;
  }

  get(candidate_id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getCandidateURL(candidate_id));
  }

  list(listRequestDate: ListRequestData): Observable<Object> {
    let query_params = {};
    query_params = QueryParamsHelper.addPagination(query_params, listRequestDate.currentPage);
    query_params = QueryParamsHelper.addFilters(query_params, listRequestDate.filter);
    query_params = QueryParamsHelper.addOrders(query_params, listRequestDate.order);
    query_params = QueryParamsHelper.addSelects(query_params, listRequestDate.select);
    query_params = QueryParamsHelper.addItemsPerPage(query_params, listRequestDate.itemsPerPage);
    return this._http.get(ApiRoutesHelper.getCandidateListURL(), {params: query_params});
  }

  store(candidate: Candidate): Observable<Object> {
    const params = this.buildParams(candidate);
    return this._http.post(ApiRoutesHelper.getCandidateCreateURL(), params);
  }

  update(candidate: Candidate): Observable<Object> {
    const params = this.buildParams(candidate);
    return this._http.put(ApiRoutesHelper.getCandidateUpdateURL(candidate.id), params);
  }

  anonymize(user_id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getCandidateAnonymizeURL(user_id));
  }
  delete(candidate_id: any): Observable<any> {
    return this._http.delete(ApiRoutesHelper.getCandidateDeleteURL(candidate_id));
  }
  requestDelete(candidate_id: any): Observable<any> {
    return this._http.delete(ApiRoutesHelper.getRequestCandidateDeleteURL(candidate_id));
  }

  private buildParams(candidate: Candidate): any {
    const params = {
      id: candidate.id,
      name: candidate.name,
      email: candidate.email,
      activated: candidate.activated,
    };
    let candidate_fields = [
      'username',
      'password',
      'password_confirmation',
      'surname',
      'phone',
      'address',
      'city_id',
      'nationality_id',
      'condition_accept',
      'values_accept',
      'new_offers_accept',
      'cv',
      'laboral_life',
      {type: FormatHelper.DATE_FORMAT, key: 'birthdate'}
    ];
    for (let i in candidate_fields) {
      let field = candidate_fields[i];
      if (typeof field !== 'string') {
        params[field['key']] = FormatHelper.formatToApi(field['type'], candidate[field['key']]);
      } else {
        if (candidate[field]) {
          params[field] = candidate[field];
        }
      }

    }
    return params;
  }

}
