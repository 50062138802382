import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';


@Injectable()
export class StatusService {

  constructor(public _http: HttpClient) {
  }

  selectList(model: string): Observable<object> {
    return this._http.get(ApiRoutesHelper.getStatusSelectListURL(model));
  }
}
