import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { OfferCandidate } from '../../../models/offer_candidate';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { OfferCandidateService } from '../../../services/offer-candidate.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { OffersCandidatesFormConfiguration } from './offers-candidates-form-configuration';

@Component({
  selector: 'app-offers-candidates-form',
  templateUrl: './offers-candidates-form.component.html',
  styleUrls: ['./offers-candidates-form.component.sass']
})
export class OffersCandidatesFormComponent extends AbstractFormComponent implements OnInit {

  @Input()
  public modelValue: OfferCandidate;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private offerCandidateService: OfferCandidateService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = OffersCandidatesFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(): void {
    this.modelValue = new OfferCandidate();
    this.buildErrorModelAndEmitters();
  }
  public successResponseEdit(res): void {
    this.modelValue = OfferCandidate.copy(this.modelValue);
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.offerCandidateService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }
  successResponseStore(res): void {
    this.modelValue = OfferCandidate.copy(res);
  }
  public success(): void {
    this.onSuccess.emit({id: this.modelValue.id});
  }
  public extendConstructForm(): void {

    if (this.inputFormComponents['status_id'].instance && this.inputFormComponents['status_id'].instance.modelValue == OfferCandidateService.DISCARD_STATUS_ID) {
      this.setDisabledField('discard_information', false);
      this.setVisibilityField('discard_information', true);
    } else {
      this.setDisabledField('discard_information', true);
      this.setVisibilityField('discard_information', false);
    }

    /**
     * COMIENZO DETECCION DE CAMBIOS EN EL FORMULARIO
     */
    var component = this;
    this.getInputFormComponents('status_id').modelChange.subscribe( function(data) {
      component.setDisabledField('discard_information', data !== 4);
      component.setVisibilityField('discard_information', data === 4);
      component.removeField('discard_information', data !== 4);
    })
    /**
     * FIN DETECCION DE CAMBIOS EN EL FORMULARIO
     */
  }
}
