export class ChatTableVisibility {
  id: boolean;
  offer_name: boolean;
  candidate_name: boolean;
  open: boolean;
  created_at: boolean;

  constructor() {
    this.id = true;
    this.offer_name = true;
    this.candidate_name = true;
    this.open = true;
    this.created_at = true;
  }
}

