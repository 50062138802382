import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractFormModalComponent } from 'bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';
import { CvTrainingFormComponent } from '../../forms/cv-training-form/cv-training-form.component';

@Component({
  selector: 'app-cv-training-modal',
  templateUrl: './cv-training-modal.component.html',
  styleUrls: ['./cv-training-modal.component.sass']
})
export class CvTrainingModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: true, save_and_edit: false};
  @ViewChild(CvTrainingFormComponent) formComponent;
  public modal_id = 'cv_training_modal';

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public getFormComponent(): any {
    return this.formComponent;
  }

}
