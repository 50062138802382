import { FileComponent } from 'bloonde-ngx-template/src/inputs/file/file.component';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import { AdvancedSelectorComponent } from '../../inputs/advanced-selector/advanced-selector.component';
export class CVTrainingFormConfiguration {
  public static structure = [
    {
      name: 'title',
      label: 'title_education',
      placeholder: 'title',
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'value',
      label: 'value',
      placeholder: null,
      type: AdvancedSelectorComponent,
      default_value: null,
      col: 'col-12',
      style: 'bd--none',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: false,
        hasInput: true,
        hasHelp: false,
      },
      option_type: 'qualifications'
    },
    {
      name: 'observations',
      label: 'observations',
      placeholder: null,
      type: 'textarea',
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    }
    ,
    {
      name: 'document',
      label: 'document',
      placeholder: null,
      type: FileComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      url: ApiRoutesHelper.getUploadURL(),
      download_url: ApiRoutesHelper.FILE_FOLDER
    }
  ];
}
