import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'bl-action-confirmation-modal',
  templateUrl: './action-confirmation-modal.component.html',
  styleUrls: ['./action-confirmation-modal.component.css']
})
export class ActionConfirmationModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: false, save_and_edit: false};

  @Input() message: string;
  @Input() modal_id: string;

  @Output() confirmEmitter: EventEmitter<any> = new EventEmitter();

  public current_action = 'confirm';

  constructor() { }

  ngOnInit() { }

  private openModal(): void {
    $( '#' + this.modal_id ).modal( 'show' );
  }
  private closeModal(): void {
    $( '#' + this.modal_id ).modal( 'hide' );
  }
  confirm(): void {
    this.confirmEmitter.emit();
  }

}
