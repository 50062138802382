import { Component, ComponentFactoryResolver, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from '../../../../projects/bloonde-ngx-template/src/forms/abstract-form.component';
import { Candidate } from '../../../models/candidate';
import { InputFormDirective } from '../../../../projects/bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from '../../../../projects/bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from '../../../../projects/bloonde-ngx-template/src/forms/services/validation.service';
import { CandidateService } from '../../../services/candidate.service';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../projects/bloonde-ngx-template/src/responses/message.service';
import { CandidateProfileFormConfiguration } from './candidate-profile-form-configuration';

@Component({
  selector: 'app-candidate-profile-form',
  templateUrl: './candidate-profile-form.component.html',
  styleUrls: ['./candidate-profile-form.component.sass']
})
export class CandidateProfileFormComponent extends AbstractFormComponent implements OnInit {

  public modelValue: Candidate;

  public nextAction = 'keep';

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;
  inputFormComponents: any[];
  condictionFormComponents: any[];

  constructor(private validationService: ValidationService,
              private candidateService: CandidateService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.inputFormComponents = [];
    this.condictionFormComponents = [];
    this.structure = CandidateProfileFormConfiguration.structure;
  }
  ngOnInit() {
  }


  public new(): void {
    this.modelValue = new Candidate();
    this.buildErrorModelAndEmitters();
  }
  // public edit(id): void {
  //   this.adminService.get(id).subscribe(
  //     res => {
  //       this.admin = User.copy(res);
  //       this.buildErrorModelAndEmiters();
  //     },
  //     error => {
  //       if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
  //         this.serverErrors = error.error;
  //         this.emitServerError();
  //       } else {
  //         this.responseHelper.handleError(error);
  //       }
  //     }
  //   );
  // }
  public successResponseEdit(res): void {
    this.modelValue = Candidate.copy(this.modelValue);
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }
  public getCondictionFormComponents(key: any): void {
    return this.condictionFormComponents[key];
  }
  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }
  public pushInputFormComponents(componentRef: any, key: any): void {
    this.inputFormComponents[key] = componentRef;
  }
  public pushCondictionFormComponents(componentRef: any, key: any): void {
    this.condictionFormComponents[key] = componentRef;
  }
  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.candidateService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }

  public extendConstructForm(): void {
    /**
     * COMIENZO INICIALIZACION DEL FORMULARIO
     */
    /**
     * Si entramos en el modal o edición y el state_id ya tiene value entonces activamos el campo city_id y hacemos un list
     */
    if (this.inputFormComponents['state_id'].instance.modelValue) {
      this.setDisabledField('city_id', false);
      this.inputFormComponents['city_id'].instance.filter = {state_id: this.inputFormComponents['state_id'].instance.modelValue};
      this.inputFormComponents['city_id'].instance.list();
    } else {
      this.setDisabledField('city_id', true);
    }
    /**
     * FIN INICIALIZACION DEL FORMULARIO
     */

    /**
     * COMIENZO DETECCION DE CAMBIOS EN EL FORMULARIO
     */
    var component = this;
    this.inputFormComponents['state_id'].instance.modelChange.subscribe(function (value) { // Para detectar el cambio de state
      component.setDisabledField('city_id', !value); // Habilitamos o deshabilitamos dependiendo de si el value es distinto de null o no
      component.removeField('city_id', true); // Cada vez que cambiamos el valor del state_id reseteamos el valor del city_id porque si hay valor ese city_id ya no pertenece al stado seleccionado (porque lo hemos cambiado)
      component.inputFormComponents['city_id'].instance.filter = {state_id: value}; // Seteamos el filtro del city_id para que filtre por state_id en la peticion
      component.inputFormComponents['city_id'].instance.list(); // Hacemos un list del selector para que se traiga los nuevos cities filtrado por state_id
    });

    this.inputFormComponents['cv'].instance.delete.subscribe(function() {
      component.removeField('cv', true);
      component.modelValue['cv'] = null;
    });
    this.inputFormComponents['laboral_life'].instance.delete.subscribe(function() {
      component.removeField('laboral_life', true);
      component.modelValue['laboral_life'] = null;
    });
    /**
     * FIN DETECCION DE CAMBIOS EN EL FORMULARIO
     */
  }

}
