import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren } from '@angular/core';
import { Pagination } from '../../models/pagination';
import { NavigationHelper } from '../../../navigation/navigation.helper';
import { TrTableComponent } from '../tr-table/tr-table.component';

@Component({
    selector: '[appBodyTable]',
    templateUrl: './body-table.component.html',
    styleUrls: ['./body-table.component.css']
})
export class BodyTableComponent implements OnInit {
  @Input() datas;
  @Input() accordionStatus;
  @Input() headers;
  @Input() pagination: Pagination;
  @Input() parentId: string = null; // Se usa para los hijos (acordeones)

  @Output() HandlerClickTd = new EventEmitter();
  @Output() HandlerClickTr = new EventEmitter();
  @Output() HandlerClickBtn = new EventEmitter();
  @Output() HandlerInputModelChange: EventEmitter<any> = new EventEmitter();
  @Output() HandlerDoubleClickTr = new EventEmitter();
  @Output() Edit: EventEmitter<any> = new EventEmitter();
  @Output() Delete: EventEmitter<any> = new EventEmitter();
  @Output() Trash: EventEmitter<any> = new EventEmitter();
  @Output() OtherAction: EventEmitter<any> = new EventEmitter();
  @Output() HandlerAddOrRemove: EventEmitter<any> = new EventEmitter();
  @Output() AccordionStatusChange: EventEmitter<any> = new EventEmitter();

  @ViewChildren(TrTableComponent) trTableComponent;

  countClick = 0;
  timer: any;
  preventSimpleClick: boolean;

  constructor(
    private navigationHelper: NavigationHelper
  ) { }

  ngOnInit() {}

  ngAfterViewChecked() {
    // let cont = 0;
    // let dividir = 100;

    // for (let i in this.headers) {
    //   if ( this.headers[i]['isShow'] && this.headers[i]['label'] === 'check') {
    //     dividir = dividir - 5;
    //   }
    //   else if ( this.headers[i]['isShow'] && this.headers[i]['label'] === 'action') {
    //     dividir = dividir - 15;
    //   }
    //   else if ( this.headers[i]['isShow'] ) {
    //     cont += 1;
    //   }
    // }
    // const widthForItem = dividir /  cont ;
    // const element = document.querySelectorAll('.table__td');
    // for (let x = 0; x < element.length; x++) {
    //   element[x]['style']['width'] = widthForItem + '%'
    // }
  }

  onEdit($event): void {
    this.Edit.emit($event);
  }

  onDelete($event): void {
    this.Delete.emit($event);
  }

  onTrash($event): void {
    this.Trash.emit($event);
  }

  onOtherAction($event): void {
    this.OtherAction.emit($event);
  }

  onAddOrRemove($event): void {
    this.HandlerAddOrRemove.emit($event);
  }

  getSelectorCheckboxes(): any {
    let checkboxes = [];
    for(let tr of this.trTableComponent._results) {
      if(tr.selectorCheckbox) {
        checkboxes.push(tr.selectorCheckbox)
      }
    }
    return {_results: checkboxes};
  }

  clickTd($event) {
    this.HandlerClickTd.emit( $event );
  }

  clickTr( id ) {
    this.timer = 0;
    this.preventSimpleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        this.HandlerClickTr.emit( id );
      }
    }, delay);
  }
  doubleClickTr(e, id) {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    this.HandlerDoubleClickTr.emit({event: e, id: id});
  }

  log(): void {
    console.log('td ', this.datas)
  }

  getItemPerPage(): any {
    if (this.pagination) {
      return this.pagination.itemPerPage;
    } else {
      return null;
    }
  }

  getTotalItems(): any {
    if (this.pagination) {
      return this.pagination.total;
    } else {
      return null;
    }
  }

  getCurrentPage(): any {
    if (this.pagination) {
      return this.pagination.currentPage;
    } else {
      return null;
    }
  }

  clickBtn($event) {
    this.HandlerClickBtn.emit( $event );
  }

  inputModelChange($event) {
    this.HandlerInputModelChange.emit( $event );
  }

  clickCustomer( e: any, customer: any) {
    this.navigationHelper.navigateTo('customer-edition', {}, customer['id']);
  }

  clickAddBooking( e: any, data: any, item: any) {
    const dataObject =  {
      event: e,
      data: data,
      item: item
    }
    this.HandlerClickBtn.emit( dataObject );
  }
  // Determina si una fila es o no hija de otra. En realidad solo busca si tiene una columna isChild con valor true;
  isChild(element: any): boolean{
    for(let col of element) {
      if(col['label'] === 'isChild') {
        return col['value'];
      }
    }
    return false;
  }
  getTrId(element: any): string {
    if(this.parentId) {
      return 'collapse__' + this.parentId + '_child';
    } else {
      for(let col of element) {
        if(col['label'] === 'id') {
          return col['value'];
        }
      }
    }
  }
  // Método para obtener el id del element
  getId(element: any): number {
    // tslint:disable-next-line:forin
    for (const i in element) {
      const d = element[i];
      if (d['label'] === 'id') {
        return d['value'];
      }
    }
  }

  /**
   *
   * @param element_id - el id de la fila que vamos a consultar
   * @param args - ids de los padres
   * @returns {any}
   */
  getAccordionStatus(element_id: any, args: any[]) { // Método que obtiene el estado de una fila.
    if(this.accordionStatus) {
      let accordionStatus = this.accordionStatus;
      if(args){ // Primero si tenemos ids de padres entonces accedemos a estos
        for(let id of args) {
          accordionStatus = accordionStatus[id]['children'];
        }
      }
      return accordionStatus[element_id]['class']; // Accedemos a la clase del id que queremos consultar
    }
  }
  onChangeAccordionStatus($event) {
    this.AccordionStatusChange.emit($event);
  }

  getClasses(element: any) {
    for(let i of element) {
      if(i['label'] === 'classes') {
        return i['value'];
      }
    }
    return null;
  }
}
