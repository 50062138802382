import { CitySelectComponent } from '../../inputs/city-select/city-select.component';
import { NationalitySelectComponent } from '../../inputs/nationality-select/nationality-select.component';
import { StateSelectComponent } from '../../inputs/state-select/state-select.component';
import { FileComponent } from 'bloonde-ngx-template/src/inputs/file/file.component';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import {DateComponent} from 'bloonde-ngx-template/src/inputs/date/date.component';
export class CandidateProfileFormConfiguration {
  public static structure = [
    // {
    //   name: 'identification',
    //   label: 'Identification',
    //   placeholder: null,
    //   type: 'input-text',
    //   default_value: null,
    //   col: 'col-12 col-md-6',
    //   style: 'container__input',
    //   rules: [
    //     'required'
    //   ],
    //   label_classes: null,
    //   input_classes: null,
    //   config: {
    //     hasLabel: true,
    //     hasInput: true,
    //     hasHelp: false,
    //   }
    // },
    {
      name: 'name',
      label: 'Nombre',
      placeholder: null,
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'surname',
      label: 'Apellidos',
      placeholder: null,
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'email',
      label: 'E-mail',
      placeholder: null,
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'phone',
      label: 'Teléfono',
      placeholder: null,
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'birthdate',
      label: 'birthdate',
      placeholder: null,
      type: DateComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'address',
      label: 'Address',
      placeholder: null,
      type: 'input-text',
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'state_id',
      label: 'State',
      placeholder: 'Select a state',
      type: StateSelectComponent,
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
    },
    {
      name: 'city_id',
      label: 'City',
      placeholder: 'Select a city',
      type: CitySelectComponent,
      default_value: null,
      col: 'col-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      autoload: false,
      disabled: true
    },
    {
      name: 'nationality_id',
      label: 'nationality',
      placeholder: 'Select a nationality',
      type: NationalitySelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'cv',
      label: 'cv',
      placeholder: null,
      type: FileComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      url: ApiRoutesHelper.getUploadURL(),
      download_url: ApiRoutesHelper.FILE_FOLDER
    },
    {
      name: 'laboral_life',
      label: 'laboral_life',
      placeholder: null,
      type: FileComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      url: ApiRoutesHelper.getUploadURL(),
      download_url: ApiRoutesHelper.FILE_FOLDER
    },
    {
      name: 'condition_accept',
      label: 'conditions',
      label_link: '/condition',
      placeholder: null,
      type: 'checkbox',
      default_value: null,
      col: 'col-12',
      style: 'check__reverse form-checkbox',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'values_accept',
      label: 'values',
      label_link: '/values',
      placeholder: null,
      type: 'checkbox',
      default_value: null,
      col: 'col-12',
      style: 'check__reverse form-checkbox',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'new_offers_accept',
      label: 'new_offers_accept',
      placeholder: null,
      type: 'checkbox',
      default_value: null,
      col: 'col-12',
      style: 'check__reverse form-checkbox',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    }
  ];
}
