import { Component, OnInit } from '@angular/core';
import { AbstractCardComponent } from '../abstract-card.component';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.sass']
})
export class OfferCardComponent extends AbstractCardComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  processValue(value: any): string {

    let vals = [];
    let val = JSON.parse(value);
    if (val) {
      for (let v of val) {
        if (v && v.value) {
          vals.push(v.value);
        }
      }
    }
    return vals.join(' - ');
  }
}
