import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';


@Injectable()
export class SectorService {

  constructor(public _http: HttpClient) {
  }

  get(sector_id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getSectorURL(sector_id));
  }
  list(listRequestData: ListRequestData): Observable<object> {
    let query_params = QueryParamsHelper.addQueryParams(listRequestData); // Añadimos paginación, filtros, orden, selectores y número de items por pagina
    return this._http.get(ApiRoutesHelper.getSectorListURL(), {params: query_params});
  }
  selectList(listRequestData: ListRequestData): Observable<object> {
    let query_params = QueryParamsHelper.addQueryParams(listRequestData); // Añadimos paginación, filtros, orden, selectores y número de items por pagina
    return this._http.get(ApiRoutesHelper.getSectorSelectListURL(), {params: query_params});
  }

  // store(offer: Offer): Observable<object> {
  //   const params = this.buildParams(offer);
  //   return this._http.post(ApiRoutesHelper.getOfferSelectListURL(), params);
  // }
  //
  // update(offer: Offer): Observable<object> {
  //   const params = this.buildParams(offer);
  //   return this._http.put(ApiRoutesHelper.getOfferUpdateURL(offer.id), params);
  // }
  //
  // delete(offer_id: any): Observable<object> {
  //   return this._http.delete(ApiRoutesHelper.getOfferDeleteURL(offer_id));
  // }
  //
  // private buildParams(offer: Offer): any {
  //   let fields = ['name', 'description', 'institution', 'status_id', 'function', 'sector_id', 'position_id'];
  //   const params = {};
  //   fields.forEach(function (field) {
  //     if (typeof field !== 'string') {
  //       params[field['key']] = FormatHelper.formatToApi(field['type'], offer[field['key']]);
  //     } else {
  //       params[field] = offer[field];
  //     }
  //   });
  //   return params;
  // }

}
