import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'bloonde-ngx-template/src/api/services/abstract.service';
import { OffersCandidatesFormConfiguration } from '../components/forms/offers-candidates-form/offers-candidates-form-configuration';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from 'bloonde-ngx-template/src/api/tools/query-params.helper';


@Injectable()
export class OfferCandidateService extends AbstractService {

  static WAITING_STATUS_ID = 1;
  static INTERVIEWING_STATUS_ID = 2;
  static DISCARD_STATUS_ID = 4;
  static SELECTED_STATUS_ID = 3;
  static PRESELECTED_STATUS_ID = 6;

  constructor(public _http: HttpClient) {
    super('offer_candidate', OffersCandidatesFormConfiguration, _http);
  }
  selectList(listRequestData: ListRequestData): Observable<object> {
    let query_params = QueryParamsHelper.addQueryParams(listRequestData); // Añadimos paginación, filtros, orden, selectores y número de items por pagina
    return this._http.get(ApiRoutesHelper.getOfferCandidateSelectListURL(), {params: query_params});
  }
  changeStatus(id: any, status_id): Observable<any> {
    return this._http.get(ApiRoutesHelper.getOfferCandidateChangeStatusURL(id, status_id));
  }
  bulkChangeStatus(data: any): Observable<any> {
    let params = JSON.parse(JSON.stringify(data));
    return this._http.post(ApiRoutesHelper.getOfferCandidateBulkChangeStatusURL(), params);
  }

  candidateApply(offer_id: any): Observable<any> {
    let params = {
      'offer_id': offer_id,
      'status_id': OfferCandidateService.WAITING_STATUS_ID,
    };
    return this._http.post(ApiRoutesHelper.getOfferCandidateApplyURL(), params);
  }
  extendBuildParams(model: any, params: any): any {
    params['candidate_id'] = model['candidate_id'];
    return params;
  }
}
