import { TextAreaComponent } from 'bloonde-ngx-template/src/inputs/textarea/textarea.component';
import { FileComponent } from 'bloonde-ngx-template/src/inputs/file/file.component';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
export class MessageFormConfiguration {
  public static structure = [
    {
      name: 'content',
      label: 'content',
      placeholder: 'Type a text ...',
      type: TextAreaComponent,
      default_value: null,
      col: 'col-12 col-md-12',
      style: '',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: false,
        hasInput: true,
        hasHelp: false,
      },
      tag: 'content'
    },
    {
      name: 'attach_path_url',
      label: 'attach_path_url',
      placeholder: null,
      type: FileComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: '',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: false,
        hasInput: true,
        hasHelp: false,
      },
      url: ApiRoutesHelper.getUploadURL(),
      download_url: ApiRoutesHelper.FILE_FOLDER,
      tag: 'file'
    }
  ];
}
