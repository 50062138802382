export class SearchOfferTableOrder {
  id: string;
  name: string;
  city_name: string;
  description: string;
  institution: string;
  status_id: string;
  sector_name: string;
  position_name: string;
  functions: string;
  expected_contract_date: string;
  candidatures: string;
  constructor() {
    this.id = null;
    this.name = null;
    this.city_name = null;
    this.description = null;
    this.institution = null;
    this.status_id = null;
    this.sector_name = null;
    this.position_name = null;
    this.functions = null;
    this.expected_contract_date = null;
    this.candidatures = null;
  }
}
