import {ApiConfigHelper} from '../../api/api-config.helper';
export class SessionHelper {

    public static getLocalStorageField(field: string) {
      if (typeof localStorage !== 'undefined') {
        return localStorage.getItem(ApiConfigHelper.PROJECT_NAME + '-' + field);
      } else {
        return null;
      }
    }
    public static setLocalStorageField(field: string, value: any) {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(ApiConfigHelper.PROJECT_NAME + '-' + field, value);
      }
    }
    public static clearLocalStorageField(field: string) {
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(ApiConfigHelper.PROJECT_NAME + '-' + field);
      }
    }
}
