import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ChangePasswordFormComponent } from '../../forms/change-password-form/change-password-form.component';

declare var $: any;

@Component({
  selector: 'bl-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent implements OnInit {

  @ViewChild(ChangePasswordFormComponent) changePasswordFormComponent;

  @Output() success: EventEmitter<any> = new EventEmitter();

  public modal_id = 'change_password_modal';

  constructor() { }

  ngOnInit() {
  }
  show(): void {
    this.openModal();
    this.changePasswordFormComponent.new();
  }
  onSuccess($event): void {
    this.closeModal();
    this.success.emit();
  }
  save(): void {
    this.changePasswordFormComponent.changePass();
  }
  private openModal(): void {
    if (typeof $ !== 'undefined') {
      $('#' + this.modal_id).modal('show');
    }
  }
  private closeModal(): void {
    if (typeof $ !== 'undefined') {
      $('#' + this.modal_id).modal('hide');
    }
  }
}
