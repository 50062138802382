import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ResetPasswordFormComponent } from '../../forms/reset-password-form/reset-password-form.component';
import {AbstractFormModalComponent} from '../../../../../bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';

@Component({
  selector: 'bl-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.css']
})
export class ResetPasswordModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: false, save_and_edit: false};
  @Input() buttonsText: {cancelText: string, saveText: string, saveAndNewText: string, saveAndEditText: string, deleteText: string} = {cancelText: null, saveText: 'Accept', saveAndNewText: null, saveAndEditText: null, deleteText: null};


  @ViewChild(ResetPasswordFormComponent) resetPasswordFormComponent;

  public modal_id = 'reset_password_modal';

  constructor() {
    super();
  }
  ngOnInit() {
  }

  public getFormComponent(): any {
    return this.resetPasswordFormComponent;
  }
  saveAndClose(): void {
    this.resetPasswordFormComponent.passwordRecovery();
  }
  onSuccess($event): void {
   this.closeModal();
   }


}
