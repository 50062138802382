import { ComponentFactoryResolver, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FiltersDirective } from './directives/filters.directive';
import {DataHeader} from '../tables/models/data-header';
export class AbstractFilterGroupComponent{
  @Input() data: DataHeader;
  @Input() isEdit: boolean;
  @Input() typeTable: string;

  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() HandlerEnterSearch = new EventEmitter();
  @Output() HandlerClickBtn = new EventEmitter();
  @Output() HandlerChangeFilter = new EventEmitter();

  @ViewChildren(FiltersDirective) filtersDirectives: QueryList<FiltersDirective>;
  filterComponents: any[];

  filtersLoaded: boolean = false;

  showTitle: string = 'v-visible';
  showFilters: string = 'v-visible';
  showBtns: string = 'v-visible';

  constructor(public componentFactoryResolver: ComponentFactoryResolver) {
    this.filterComponents = [];
  }

  ngOnInit( ) {
    this.showTitle = this.getShow( this.data.title.isShow ) ;
    this.showFilters = this.getShow( this.data.filters.isShow ) ;
    this.showBtns = this.getShow( this.data.btns.isShow )
  }

  getShow( item ) {
    if ( item ) {
      return 'v-visible'
    } else {
      return 'v-hidden'
    }
  }

  ngAfterViewInit() {
    this.loadFilterComponent();
  }

  enterSearch(e) {
    this.HandlerEnterSearch.emit({event: e});
  }

  clickHeaderBtn( e, action) {
    this.HandlerClickBtn.emit({event: e, action: action});
  }

  onCreate(): void {
    this.create.emit();
  }

  loadFilterComponent(): void {
    if (this.filtersDirectives) {
      for (let e = 0; e < this.filtersDirectives.length; e++) {
        if(this.data.filters.items[e].show !== false) {
          let filtersDirective = this.filtersDirectives['_results'][e];
          let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.filters.items[e]['component']);
          let viewContainerRef = filtersDirective.viewContainerRef;
          viewContainerRef.clear();
          let componentRef = viewContainerRef.createComponent(componentFactory);
          this.filterComponents.push(componentRef);
          let inputs_and_outputs = this.data.filters.items[e].inputs_and_outputs;
          for (let i in inputs_and_outputs) {
            let parameter = inputs_and_outputs[i];
            let attr = parameter['attr'];
            let value = parameter['value'];
            let type = parameter['type'];
            if (type === 'property') {
              componentRef.instance[attr] = this.data.filters.items[e].inputs_and_outputs[i]['value'];
            } else if (parameter['type'] === 'output') {
              var component = this;
              componentRef.instance[attr].subscribe(function(data) {
                let key = component.data.filters.items[e].inputs_and_outputs[i]['value'];
                component.HandlerChangeFilter.emit({key: key, value: data});
              });
            } else if (parameter['type'] === 'input') {
              var component = this;
              component.data.filters.items[e].inputs_and_outputs[i]['value'].subscribe(function(data) {
                if(data != componentRef.instance[attr]) {
                  componentRef.instance[attr] = data;
                }
              });
              let init = component.data.filters.items[e].inputs_and_outputs[i]['init'];
              if(init) {
                componentRef.instance[attr] = init;
              }
            } else if (parameter['type'] === 'input-list') {
              var component = this;
              component.data.filters.items[e].inputs_and_outputs[i]['value'].subscribe(function(data) {
                if(data != componentRef.instance[attr]) {
                  componentRef.instance[attr] = data;
                  componentRef.instance.setModel(null);
                  componentRef.instance.list();
                }
              });
            }
          }
          componentRef.changeDetectorRef.detectChanges();
        }
      }
      this.filtersLoaded = true;
    }
  }

  list(): void {
    for (let e = 0; e < this.filterComponents.length; e++) {
      if (this.filterComponents[e].instance.list) {
        this.filterComponents[e].instance.list();
      }
    }
  }
}
