import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractFormModalComponent } from 'bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';
import { OfferCandidateChangeStatusToDiscardFormComponent } from '../../forms/offer-candidate-change-status-to-discard-form/offer-candidate-change-status-to-discard-form.component';

@Component({
  selector: 'app-offer-candidate-change-status-to-discard-modal',
  templateUrl: './offer-candidate-change-status-to-discard-modal.component.html',
  styleUrls: ['./offer-candidate-change-status-to-discard-modal.component.sass']
})
export class OfferCandidateChangeStatusToDiscardModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: false, save_and_edit: false};
  @Input() buttonsText: {cancelText: string, saveText: string, saveAndNewText: string, saveAndEditText: string, deleteText: string} = {cancelText: null, saveText: 'Confirmar', saveAndNewText: null, saveAndEditText: null, deleteText: null};
  @Input() title: string;
  @Input() message: string;

  @ViewChild(OfferCandidateChangeStatusToDiscardFormComponent) formComponent;
  public modal_id = 'offer_candidate_change_status_to_discard_modal';

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public getFormComponent(): any {
    return this.formComponent;
  }

}
