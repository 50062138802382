import {Injectable} from '@angular/core';
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable, Observer} from 'rxjs/index';
import {CandidateService} from '../services/candidate.service';
import {SessionService} from 'bloonde-ngx-template/src/session/services/session.service';
import {AccessService} from 'ngx-bloonde-user-and-privileges/src/services/access.service';

@Injectable()
export class PublicCandidateResolver implements Resolve<any> {

  constructor(private candidateService: CandidateService, private sessionService: SessionService, private accessService: AccessService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      const candidate: any = this.candidateService.getLoggedCandidate();
      const user: any = this.sessionService.getLoggedUser();
      if (candidate && user) {
        observer.next(candidate);
        observer.complete();
      } else {
        if (user && this.accessService.checkProfile('candidate', user)) {
          this.candidateService.get(user.id)
            .subscribe(
              res => {
                let data = null;
                if (res['data']) {
                  data = res['data'];
                } else {
                  data = res;
                }
                this.candidateService.setLoggedCandidate(data);
                observer.next(data);
                observer.complete();
              },
              error => {
                observer.next(null);
                observer.complete();
              },
            );
        } else {
          observer.next(null);
          observer.complete();
        }
      }
    });
  }
}
