import { NgModule } from '@angular/core';
import { NgxBloondeCacheComponent } from './ngx-bloonde-cache.component';
import { CacheService } from '../services/cache.service';
import { WebStorageModule } from 'ngx-store';

@NgModule({
  imports: [
    WebStorageModule
  ],
  providers: [
    CacheService,
  ],
  declarations: [NgxBloondeCacheComponent],
  exports: [
    NgxBloondeCacheComponent,
  ]
})
export class NgxBloondeCacheModule { }
