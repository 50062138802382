import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import { Observable } from 'rxjs/index';
import { ApiConfigHelper } from '../api/api-config.helper';

export class LaravelTranslationLoader implements TranslateLoader {
    constructor(public _http: HttpClient) {
    }

    getTranslation(lang: string): Observable<any> {
        return this._http.get(ApiConfigHelper.getTranslationURL(lang));
    }
}
