import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CvExperienceTableComponent } from '../../tables/cv-experience-table/cv-experience-table.component';
import { CvExperienceModalComponent } from '../../modals/cv-experience-modal/cv-experience-modal.component';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { CvExperienceService } from '../../../services/cv-experience.service';
import { ActionConfirmationModalComponent } from '../../modals/action-confirmation-modal/action-confirmation-modal.component';

@Component({
  selector: '[app-cv-experience-management]',
  templateUrl: './cv-experience-management.component.html',
  styleUrls: ['./cv-experience-management.component.sass']
})
export class CvExperienceManagementComponent implements OnInit {

  @ViewChild(CvExperienceTableComponent) cvExperienceTableComponent;
  @ViewChild(CvExperienceModalComponent) cvExperienceModalComponent;
  @ViewChild(ActionConfirmationModalComponent) actionConfirmationModalComponent;

  @Input() public candidate_id: any;

  public aux_id_to_delete: any;

  public tableOptions = {
    autoload: true,
    withPagination: false,
    paginationItemsPerPage: 5,
    localStorageKey: 'cv-experience-table',
    hasActionColumn: true,
    hasCheckboxColumn: false
  };

  constructor(
    private cvExperienceService: CvExperienceService,
    private responseHelper: ResponseHelper,
  ) { }

  ngOnInit() {
  }

  updateTable(): void {
    const component = this;
    setTimeout(function() {
      component.cvExperienceTableComponent.list();
    });
  }

  create(): void {
    this.cvExperienceModalComponent.show('create', null, {candidate_id: this.candidate_id});
  }
  edit(id: any): void {
    this.cvExperienceModalComponent.show('edit', id, {candidate_id: this.candidate_id});
  }
  delete(id: any): void {
    this.aux_id_to_delete = id;
    this.actionConfirmationModalComponent.openModal();
  }
  confirmDelete(): void {
    this.cvExperienceService.delete(this.aux_id_to_delete).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

}
