import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesHelper} from '../helpers/api-routes.helper';
import { AbstractService } from 'bloonde-ngx-template/src/api/services/abstract.service';
import { MessageFormConfiguration } from '../components/forms/message-form/message-form-configuration';

@Injectable()
export class ChatMessageService extends AbstractService {

  public static DIR_EVALUATOR_TO_CANDIDATE = 1;
  public static DIR_CANDIDATE_TO_EVALUATOR = 2;
  public static TYPE_EVALUATOR = 'evaluator';
  public static TYPE_CANDIDATE = 'candidate';

  constructor(public _http: HttpClient) {
    super('message', MessageFormConfiguration, _http);
  }

  markAsRead(data: any): Observable<object> {
    data = JSON.parse(JSON.stringify(data));
    return this._http.post(ApiRoutesHelper.getMessageMarkAsReadURL(), data);
  }

  extendBuildParams(model: any, params: any): any {
    params['dir'] = model['dir'];
    params['chat_id'] = model['chat_id'];
    return params;
  }
}
