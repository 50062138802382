import { Component, OnInit, ViewChild } from '@angular/core';
import { CandidateTableComponent } from '../../tables/candidate-table/candidate-table.component';
import { CandidateService } from '../../../services/candidate.service';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ActionConfirmationModalComponent } from '../../modals/action-confirmation-modal/action-confirmation-modal.component';
import { PreselectCandidateModalComponent } from '../../modals/preselect-candidate-modal/preselect-candidate-modal.component';
import { OfferCandidateService } from '../../../services/offer-candidate.service';

@Component({
  selector: '[app-candidate-management]',
  templateUrl: './candidate-management.component.html',
  styleUrls: ['./candidate-management.component.sass']
})
export class CandidateManagementComponent implements OnInit {

  public candidateTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'candidate-table',
    hasActionColumn: true,
    hasCheckboxColumn: true
  };

  @ViewChild(CandidateTableComponent) candidateTableComponent;
  @ViewChild(PreselectCandidateModalComponent) preselectCandidateModalComponent;
  @ViewChild(ActionConfirmationModalComponent) actionConfirmationModalComponent;

  public aux_id_to_delete: any;

  constructor(private candidateService: CandidateService,
              private navigationHelper: NavigationHelper,
              private responseHelper: ResponseHelper) { }


  ngOnInit() {
  }

  updateTable(): void {
    const component = this;
    setTimeout(function() {
      component.candidateTableComponent.list();
    });
  }
  edit(id: any): void {
    this.navigationHelper.navigateTo('candidate-edition', {}, id);
  }
  delete(id: any): void {
    this.aux_id_to_delete = id;
    this.actionConfirmationModalComponent.openModal();
  }
  confirmDelete(): void {
    this.candidateService.delete(this.aux_id_to_delete).subscribe(
      res => {
        this.updateTable();
        this.actionConfirmationModalComponent.closeModal();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  clickHeaderBtn(e) {
    if ( e.action === 'preselection') {
      this.preselectCandidateModalComponent.show('create', null, {candidate_ids: this.candidateTableComponent.selected, status_id: OfferCandidateService.PRESELECTED_STATUS_ID});
    }
  }
}
