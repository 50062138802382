import { EventEmitter, Input, Output } from '@angular/core';
import { FormActionsHelper } from '../../forms/helpers/form-actions.helper';
declare var $;
export abstract class AbstractFormModalComponent {

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() goToEditEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();
  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: true, save_and_edit: true};

  public current_action;
  public next_action = FormActionsHelper.KEEP;

  @Input()
  public modal_id = '';

  abstract getFormComponent(): any;

  show(action: string, id: any = null, args: any = null): void {
    this.current_action = action;
    this.openModal();
    if (action === FormActionsHelper.CREATE_ACTION) {
      this.getFormComponent().new(args);
    } else if (action === FormActionsHelper.EDIT_ACTION) {
      this.getFormComponent().edit(id, args);
    } else if (action === FormActionsHelper.SHOW_ACTION) {
      this.getFormComponent().edit(id, args);
    }
  }
  saveAndClose(): void {
    this.next_action = FormActionsHelper.SAVE_AND_CLOSE_ACTION;
    this.getFormComponent().store();
  }
  saveAndContinue(): void {
    this.next_action = FormActionsHelper.SAVE_AND_CONTINUE_ACTION;
    this.getFormComponent().store();
  }
  saveAndEdit(): void {
    this.next_action = FormActionsHelper.SAVE_AND_EDIT_ACTION;
    this.getFormComponent().store();
  }
  goToEdit($event): void {
    this.goToEditEmitter.emit($event);
  }
  updateAndClose(): void {
    this.next_action = FormActionsHelper.UPDATE_AND_CLOSE_ACTION;
    this.getFormComponent().update();
  }
  successCreation($event): void {
    if(this.next_action !== FormActionsHelper.SAVE_AND_CONTINUE_ACTION) {
      this.closeModal();
    }
    this.success.emit($event);
  }
  openModal(): void {
    if (typeof $ !== 'undefined') {
      $.magnificPopup.open({
        items: {
          src: '#' + this.modal_id,
          type: 'inline',
        }
      });
    }
  }
  closeModal(): void {
    if (typeof $ !== 'undefined') {
      $.magnificPopup.close();
      this.onClose();
    }
  }
  onClose(){
    this.closeEmitter.emit();
  }

}
