import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractTableComponent } from 'bloonde-ngx-template/src/tables/partials/abstract-table/abstract-table.component';
import { BodyTableComponent } from 'bloonde-ngx-template/src/tables/partials/body-table/body-table.component';
import { CardsComponent } from '../../cards/cards/cards.component';
import { TableOptions } from 'bloonde-ngx-template/src/tables/models/table-options';
import { OfferListItem } from '../../../models/listings/offer_list_item';
import { Pagination } from 'bloonde-ngx-template/src/tables/models/pagination';
import { SearchOfferTableFilter } from './search-offer-table-filter';
import { SearchOfferTableOrder } from './search-offer-table-order';
import { SearchOfferTableVisibility } from './search-offer-table-visibility';
import { OfferCardComponent } from '../../cards/offer-card/offer-card.component';
import { DataHeader } from 'bloonde-ngx-template/src/tables/models/data-header';
import { SearchComponent } from 'bloonde-ngx-template/src/tables/components/search/search.component';
import { PositionFilterComponent } from '../../filters/position-filter/position-filter.component';
import { SectorFilterComponent } from '../../filters/sector-filter/sector-filter.component';
import { CityFilterComponent } from '../../filters/city-filter/city-filter.component';
import { OfferService } from '../../../services/offer.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import {Candidate} from '../../../models/candidate';
import {ActivatedRoute} from '@angular/router';
import {SessionService} from 'bloonde-ngx-template/src/session/services/session.service';
import {CandidateService} from '../../../services/candidate.service';
import {AccessService} from 'ngx-bloonde-user-and-privileges/src/services/access.service';

@Component({
  selector: 'app-search-offer-table',
  templateUrl: './search-offer-table.component.html',
  styleUrls: ['./search-offer-table.component.sass']
})
export class SearchOfferTableComponent extends AbstractTableComponent implements OnInit {

  public loggedInUser;
  public userLoggerSubscribe: any;
  public candidate: Candidate;

  @ViewChild(BodyTableComponent) bodyTableComponent;
  @ViewChild(CardsComponent) cardsComponent;

  @Input() tableOptions: TableOptions;

  public events: OfferListItem[];
  public pagination: Pagination;

  public filter: SearchOfferTableFilter = new SearchOfferTableFilter();
  public order: SearchOfferTableOrder = new SearchOfferTableOrder();
  public visibility: SearchOfferTableVisibility = new SearchOfferTableVisibility();

  public type = OfferCardComponent;


  public dataHeader: DataHeader = {
    title: {
      isShow: true,
      label: 'Offers',
    },
    filters: {
      isShow: false,
      items: [
        {
          component: SearchComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'search', type: 'property'},
            {attr: 'placeholder', value: 'Name...', type: 'property'},
            {attr: 'idText', value: 'generalSearch', type: 'property'},
            {attr: 'modelChange', value: 'search', type: 'output'},
            {attr: 'model', value: this.filter.searchModelChange, type: 'input'}
          ]
        },
        {
          component: PositionFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Position', type: 'property'},
            {attr: 'modelChange', value: 'position_id', type: 'output'},
            {attr: 'model', value: this.filter.positionIdModelChange, type: 'input'}
          ]
        },
        {
          component: SectorFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Sector', type: 'property'},
            {attr: 'modelChange', value: 'sector_id', type: 'output'},
            {attr: 'model', value: this.filter.sectorIdModelChange, type: 'input'}
          ]
        },
        {
          component: CityFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'City', type: 'property'},
            {attr: 'filter', value: {withOffer: true}, type: 'property'},
            {attr: 'modelChange', value: 'city_id', type: 'output'},
            {attr: 'model', value: this.filter.cityIdModelChange, type: 'input'}
          ]
        }
      ]
    },
    btns: {
      isShow: true,
      items: [
        {
          isShow: false,
          value: 'Alta',
          icon: 'fas fa-plus',
          style: '',
          action: 'create'
        },
      ]
    },
    actions: [
    ],
    style: ''
  };

  public isTable: boolean = true;

  constructor(
    private offerService: OfferService,
    private responseHelper: ResponseHelper,
    private navigationHelper: NavigationHelper,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private candidateService: CandidateService,
    private accessService: AccessService
  ) {
    super();
  }

  ngOnInit() {
    this.loggedInUser = this.route.snapshot.data['loggedInUser'];
    this.candidate = this.route.snapshot.data['candidate'];
    var component = this;
    this.userLoggerSubscribe = this.sessionService.userEmitter.subscribe(function(user) {
      component.loggedInUser = user;
      if (component.isCandidate()) {
        component.getCandidate(user.id);
      } else {
        component.candidate = null;
        component.candidateService.setLoggedCandidate(null);
      }
      component.list();
    });


    if (!this.tableOptions) {
      this.tableOptions = new TableOptions('offer-search-table');
    }
    this.resetFiltersOrdersAndColumns();
    if (this.tableOptions.withPagination) {
      this.pagination = new Pagination();
      this.pagination.currentPage = 1;
      this.pagination.itemPerPage = this.tableOptions.paginationItemsPerPage;
    }
    if (this.tableOptions.autoload) {
      this.list();
    }
  }

  list(): void {
    this.cleanSelected();
    this.saveFiltersAndOrders();
    const listRequestData = new ListRequestData(this.pagination ? this.pagination.currentPage : null, this.filter, this.order);
    this.offerService.list(listRequestData).subscribe(
      res => {
        this.events = <OfferListItem[]> res['elements'];
        if (this.tableOptions.withPagination) {
          this.pagination.total = res['total'];
        }
        this.getHeader();
        this.getData();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  defaultFilter(): any {
    return new SearchOfferTableFilter();
  }
  defaultOrders(): any {
    return new SearchOfferTableOrder();
  }
  defaultVisibility(): any {
    return new SearchOfferTableVisibility();
  }
  getElements(): any {
    return this.events;
  }
  getFilter(): any {
    return this.filter;
  }
  getOrder(): any {
    return this.order;
  }
  getVisibility(): any {
    return this.visibility;
  }
  setFilter(filter: any): void {
    this.filter.copy(filter);
  }
  setOrder(order: any): void {
    this.order = order;
  }
  setVisibility(visibility: any): void {
    this.visibility = visibility;
  }
  getLocalStorageKey(): any {
    return this.tableOptions.localStorageKey;
  }
  getAllSelectorCheckbox(): any {
  }
  getSelectorCheckboxes(): any {
  }
  hasActionColumn(): boolean {
    return this.tableOptions.hasActionColumn;
  }
  hasCheckboxColumn(): boolean {
    return this.tableOptions.hasCheckboxColumn;
  }
  changeFilter($event) {
    this.filter[$event['key']] = $event['value'];
    this.listWithTimeout();
  }
  enterSearch($event) {
    this.filter.search = $event.event.target.value;
    this.listWithTimeout();
  }
  clickHeaderBtn(e) {
    if ( e.action === 'create' ) {
      this.onCreate();
    }
  }
  constructBodyColumnsExceptions(key, value, isShow, isOrder, item): any {
    let objectData = null;
    let translate = false;
    if ( key === 'already_apply') {
      value = this.alreadyApply(item['id']);
    }
    // if ( key === 'status_id') {
    //   value = 'booking_status_' + value;
    //   translate = true;
    // }
    objectData = this.setData(key, isShow, isOrder, value, translate);
    return objectData;
  }

  getDataActions(item: any) {
    let actions = this.copyActionHeaders();
    // for (let action of actions) {
    //   if (action.action == 'download' && item.document) {
    //     action.isShow = true;
    //   } else if (action.action == 'download' && !item.document) {
    //     action.isShow = false;
    //   }
    // }
    return actions;
  }
  copyActionHeaders() {
    let result = [];
    for (let action of this.dataHeader.actions) {
      let auxAction = {};
      auxAction['action'] = action['action'];
      auxAction['isShow'] = action['isShow'];
      auxAction['icon'] = action['icon'];
      auxAction['title'] = action['title'];
      result.push(auxAction);
    }
    return result;
  }

  onEdit($event): void {
    this.edit.emit(this.colValue('id', $event['element']));
  }
  onDelete($event): void {
    this.delete.emit(this.colValue('id', $event['element']));
  }
  getData() {
    let dataTable = this.getElements();
    this.datas = this.getNewData(dataTable);
    this.firstLoadCompleted = true;
    var component = this;
    setTimeout(function() {
      if (component.cardsComponent) {
        component.cardsComponent.constructCard();
      }
    }, 1000);
  }

  public getCandidate(candidate_id: any): void {
    this.candidateService.get(candidate_id).subscribe(
      res => {
        this.candidate = res;
        this.candidateService.setLoggedCandidate(res);
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  isCandidate() {
    return this.loggedInUser && this.accessService.checkProfile('candidate', this.loggedInUser);
  }

  alreadyApply(offer_id: any) {
    return this.candidate && this.candidate.offers.indexOf(offer_id) != -1;
  }

  ngOnDestroy() {
    this.userLoggerSubscribe.unsubscribe();
  }
}
