import { Sector } from './sector';
import { Position } from './position';
import { City } from './city';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
export class Offer {
  id: any;
  name: string;
  description: string;
  institution: string;
  status_id: number;
  status: string; // Estado traducido para la vista de detalles de oferta
  expected_contract_date: any;
  functions: string;
  sector_and_position: any;
  sexual_abuse_document_required: any;

  /**
   * Claves ajenas
   */
  position_id: any;
  sector_id: any;
  city_id: any;
  state_id: any;


  /**
   * Atributos derivados de la clave ajena
   */

  position: Position;
  sector: Sector;
  city: City;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.institution = null;
    this.status_id = null;
    this.expected_contract_date = null;
    this.functions = null;
    this.sector_and_position = null;
    this.sexual_abuse_document_required = null;
    this.position_id = null;
    this.sector_id = null;
    this.city_id = null;
    this.state_id = null;
  }
  public static copy(model: any) {
    let aux = new Offer();
    aux.id = model.id;
    aux.name = model.name;
    aux.description = model.description;
    aux.institution = model.institution;
    aux.functions = model.functions;
    if (model.sector_and_position) {
      aux.sector_and_position = JSON.parse(model.sector_and_position);
    }
    if (model.expected_contract_date) {
      aux.expected_contract_date = FormatHelper.formatFromApi(FormatHelper.DATE_FORMAT, model.expected_contract_date);
    }
    aux.sexual_abuse_document_required = FormatHelper.formatFromApi(FormatHelper.CHECKBOX_FORMAT, model.sexual_abuse_document_required);
    aux.status_id = model.status_id;
    aux.position_id = model.position_id;
    aux.sector_id = model.sector_id;
    aux.state_id = model.state_id;
    aux.status = model.status;
    aux.city_id = model.city_id;
    aux.city = model.city;
    aux.sector = model.sector;
    aux.position = model.position;

    return aux;
  }
}
