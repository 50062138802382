import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-offer-candidate-management-page',
  templateUrl: './offer-candidate-management-page.component.html',
  styleUrls: ['./offer-candidate-management-page.component.sass']
})
export class OfferCandidateManagementPageComponent implements OnInit {

  public offer_id;

  public offerCandidateTableConfig = {
    autoload: true,
    withPagination: true,
    paginationItemsPerPage: 20,
    localStorageKey: 'offer-candidate-table',
    hasActionColumn: true,
    hasCheckboxColumn: true
  };

  constructor(private route: ActivatedRoute,
              private themeService: ThemeService) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
    this.offer_id = this.route.snapshot.paramMap.get('offer_id');
  }

}
