import { ImageUploaderComponent } from '../../inputs/image-uploader/image-uploader.component';
import { FileComponent } from '../../../../../bloonde-ngx-template/src/inputs/file/file.component';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import { MultipleImageUploaderComponent } from '../../inputs/multiple-image-uploader/multiple-image-uploader.component';

export class MultipleImageFormConfiguration {
  public static structure = [
    {
      name: 'name',
      label: 'name',
      placeholder: null,
      type: 'input-text',
      default_value: null,
      style: 'container__input',
      rules: [
        'required'
      ],
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'description',
      label: 'description',
      placeholder: null,
      type: 'textarea',
      default_value: null,
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    },
    {
      name: 'path',
      label: 'path',
      placeholder: null,
      type: MultipleImageUploaderComponent,
      default_value: null,
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      url: ApiRoutesHelper.getUploadURL()
    }
  ];
}
