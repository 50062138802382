import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: '[app-date-filter],app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit {

  @Input()
  disabled: boolean;
  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'Year', placeholder: 'Year', options: []};

  constructor() { }
  ngOnInit() {
    if (this.placeholder) {
      this.options.placeholder = this.placeholder;
    } else {
      this.placeholder = 'yyyy-mm-dd';
    }
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }
  setModel(model): void {
    this.modelValue = model;
    this.modelChange.emit(this.modelValue);
  }
}
