import {Component, Input, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import { AbstractTableComponent } from 'bloonde-ngx-template/src/tables/partials/abstract-table/abstract-table.component';
import { TableComponent } from 'bloonde-ngx-template/src/tables/partials/table/table.component';
import { TableOptions } from 'bloonde-ngx-template/src/tables/models/table-options';
import { ChatListItem } from '../../../models/listings/chat_list_item';
import { Pagination } from 'bloonde-ngx-template/src/tables/models/pagination';
import { ChatTableOrder } from './chat-table-order';
import { ChatTableFilter } from './chat-table-filter';
import { ChatTableVisibility } from './chat-table-visibility';
import { DataHeader } from 'bloonde-ngx-template/src/tables/models/data-header';
import { SearchComponent } from 'bloonde-ngx-template/src/tables/components/search/search.component';
import { ChatService } from '../../../services/chat.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import {AccessService} from 'ngx-bloonde-user-and-privileges/src/services/access.service';
import {ActivatedRoute} from '@angular/router';
import {formatDate} from '@angular/common';
import {StatusFilterComponent} from '../../filters/status-filter/status-filter.component';

@Component({
  selector: '[app-chat-table]',
  templateUrl: './chat-table.component.html',
  styleUrls: ['./chat-table.component.sass']
})
export class ChatTableComponent extends AbstractTableComponent implements OnInit {

  public loggedInUser;

  @ViewChild(TableComponent) tableComponent;

  @Input() tableOptions: TableOptions;

  public chats: ChatListItem[];
  public pagination: Pagination;

  public filter: ChatTableFilter = new ChatTableFilter();
  public order: ChatTableOrder = new ChatTableOrder();
  public visibility: ChatTableVisibility = new ChatTableVisibility();

  public dataHeader: DataHeader = {
    title: {
      isShow: true,
      label: 'Chat Management',
    },
    filters: {
      isShow: true,
      items: [
        {
          component: SearchComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'label', value: 'search', type: 'property'},
            {attr: 'placeholder', value: 'Name...', type: 'property'},
            {attr: 'idText', value: 'generalSearch', type: 'property'},
            {attr: 'modelChange', value: 'search', type: 'output'},
            {attr: 'model', value: this.filter.searchModelChange, type: 'input'}
          ]
        },
        {
          component: StatusFilterComponent,
          cols: 'col-3',
          inputs_and_outputs: [
            {attr: 'placeholder', value: 'Status', type: 'property'},
            {attr: 'type', value: 'custom_chat', type: 'property'},
            {attr: 'modelChange', value: 'open', type: 'output'},
            {attr: 'model', value: this.filter.openModelChange, type: 'input'}
          ]
        },
      ]
    },
    btns: {
      isShow: true,
      float: 'right',
      items: [
        {
          isShow: true,
          value: 'Alta',
          icon: 'fas fa-plus',
          style: '',
          action: 'create'
        }
      ]
    },
    actions: [
      {
        action: 'edit',
        isShow: true,
        icon: 'fas fa-eye',
        title: 'See messages'
      },
      {
        action: 'delete',
        isShow: true,
        icon: 'fas fa-lock',
        title: 'Close'
      },
      {
        action: 'open',
        isShow: true,
        icon: 'fas fa-lock-open',
        title: 'Open'
      }
    ],
    style: ''
  };

  public isTable: boolean = true;

  constructor(
    private chatService: ChatService,
    private responseHelper: ResponseHelper,
    private navigationHelper: NavigationHelper,
    private accessService: AccessService,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string

  ) {
    super();
  }

  ngOnInit() {
    this.loggedInUser = this.route.snapshot.data['loggedInUser'];
    this.dataHeader.btns.items[0]['isShow'] = this.isEvaluator(); // Solo se muestra el botón de crear si es evaluator
    if (!this.tableOptions) {
      this.tableOptions = new TableOptions('chat-management');
    }
    this.resetFiltersOrdersAndColumns();
    this.filter.open = '1';
    if (this.tableOptions.withPagination) {
      this.pagination = new Pagination();
      this.pagination.currentPage = 1;
      this.pagination.itemPerPage = this.tableOptions.paginationItemsPerPage;
    }
    if (this.tableOptions.autoload) {
      this.list();
    }
  }

  list(): void {
    this.cleanSelected();
    this.saveFiltersAndOrders();
    const listRequestData = new ListRequestData(this.pagination ? this.pagination.currentPage : null, this.filter, this.order);
    this.chatService.list(listRequestData).subscribe(
      res => {
        this.chats = <ChatListItem[]> res['elements'];
        if (this.tableOptions.withPagination) {
          this.pagination.total = res['total'];
        }
        this.getHeader();
        this.getData();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  defaultFilter(): any {
    return new ChatTableFilter();
  }
  defaultOrders(): any {
    return new ChatTableOrder();
  }
  defaultVisibility(): any {
    return new ChatTableVisibility();
  }
  getElements(): any {
    return this.chats;
  }
  getFilter(): any {
    return this.filter;
  }
  getOrder(): any {
    return this.order;
  }
  getVisibility(): any {
    return this.visibility;
  }
  setFilter(filter: any): void {
    this.filter.copy(filter);
  }
  setOrder(order: any): void {
    this.order = order;
  }
  setVisibility(visibility: any): void {
    this.visibility = visibility;
  }
  getLocalStorageKey(): any {
    return this.tableOptions.localStorageKey;
  }
  getAllSelectorCheckbox(): any {
    if (this.tableComponent) {
      return this.tableComponent.getAllSelectorCheckbox();
    }
  }
  getSelectorCheckboxes(): any {
    return this.tableComponent.getSelectorCheckboxes();
  }
  hasActionColumn(): boolean {
    return this.tableOptions.hasActionColumn;
  }
  hasCheckboxColumn(): boolean {
    return this.tableOptions.hasCheckboxColumn;
  }
  changeFilter($event) {
    this.filter[$event['key']] = $event['value'];
    this.listWithTimeout();
  }
  enterSearch($event) {
    this.filter.search = $event.event.target.value;
    this.listWithTimeout();
  }
  clickHeaderBtn(e) {
    if ( e.action === 'create' ) {
      this.onCreate();
    } else {
      this.HandlerHeaderClickBtn.emit(e);
    }
  }
  constructBodyColumnsExceptions(key, value, isShow, isOrder, item): any {
    let objectData = null;
    let translate = false;
    if ( key === 'candidate_name') {
      value = value + ' ' + item['candidate_surname'];
      translate = true;
    }
    if ( key === 'open') {
      value = 'open_' + value;
      translate = true;
    } else if (key === 'created_at') {
      value = this.transformDate(item[key]);
    }
    objectData = this.setData(key, isShow, isOrder, value, translate);
    return objectData;
  }

  transformDate(date: any, format: string = 'dd/MM/yyyy HH:mm') {
    return formatDate(date, format, this.locale);
  }

  getDataActions(item: any) {
    let actions = this.copyActionHeaders();
    for (let action of actions) {
      if (action.action === 'delete') {
        action.isShow = this.isEvaluator() && item['open'];
      } else if (action.action === 'open') {
        action.isShow = this.isEvaluator() && !item['open'];
      }
    }
    return actions;
  }

  copyActionHeaders() {
    let result = [];
    for (let action of this.dataHeader.actions) {
      let auxAction = {};
      auxAction['action'] = action['action'];
      auxAction['isShow'] = action['isShow'];
      auxAction['icon'] = action['icon'];
      auxAction['title'] = action['title'];
      result.push(auxAction);
    }
    return result;
  }
  isEvaluator(): boolean {
    return this.accessService.checkProfile('evaluator', this.loggedInUser);
  }

}
