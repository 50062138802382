export class CandidateTableOrder {
  id: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  activated: string;
  cv_updated_at: string;

  constructor() {
    this.id = '+-';
    this.name = '+-';
    this.surname = null;
    this.phone = null;
    this.email = '+-';
    this.activated = '+-';
    this.cv_updated_at = '+-';
  }
}
