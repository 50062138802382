import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AccessService } from '../../../ngx-bloonde-user-and-privileges/src/services/access.service';
import { SessionService } from '../session/services/session.service';
import { NavbarConfiguration } from './navbar-configuration';

declare var $;

@Component({
  selector: 'app-navbar,[appNavbar]',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Output() openLoginModalEmiter: EventEmitter<any> = new EventEmitter();
  @Output() openRegistrationModalEmiter: EventEmitter<any> = new EventEmitter();

  public search = null;
  @Output() searchEventEmitter : EventEmitter<string> = new EventEmitter();

  @Input()
  public configNavbar: NavbarConfiguration;

  constructor(private accessService: AccessService,
    private sessionService: SessionService) { }

  ngOnInit() {
    var component = this;
    this.sessionService.userUpdateProfile.subscribe(function(user){
      component.configNavbar['user']['data']['img_url'] = user['img_url'];
      component.configNavbar['user']['data']['name'] = user['name'];
    })
  }

  changeProfile(access: any): void {
    this.accessService.changeProfile(access);
  }
  isSelectedProfile(access: any): boolean {
    return access['id'] === this.getSelectedProfile()['id'];
  }
  getSelectedProfile(): void {
    return this.accessService.selectedAccess;
  }
  onOpenLoginModal(): void {
    if (typeof $ !== 'undefined') {
      $('#primary-menu-trigger').click()
      this.openLoginModalEmiter.emit();
    }
  }
  onShowRegistrationModal(): void {
    if (typeof $ !== 'undefined') {
      $('#primary-menu-trigger').click()
      this.openRegistrationModalEmiter.emit();
    }
  }
  logout(): void {
    this.sessionService.logout();
  }
  // getStyle(id): any {
  //   if(this.configNavbar && this.configNavbar.styles) {
  //     return this.configNavbar.styles[id];
  //   }
  // }
  // getProfileStyle(access: any): any {
  //   let style = this.isSelectedProfile(access) ? 'navbar__user__profile-selected' : '';
  //   style = style + ' ' + this.getStyle('header_user_profile');
  //   return style;
  // }

  getUserLogo(): string {
    if(this.configNavbar
      && this.configNavbar['user']['data']
      && this.configNavbar['user']['data']['img_url']
      && this.configNavbar['user']['api_image_route']) {
      let url = this.configNavbar['user']['data']['img_url'];
      if (this.configNavbar.user.show_miniature_profile) {
        let parts = this.configNavbar['user']['data']['img_url'].split('/');
        let user_id = parts[1];
        let filename = parts[2];
        url = '/' + user_id + '/' + 'min_' + filename;
      }
      return this.completeUrl(url);
    } else {
      return 'assets/img/avatar.jpg'
    }
  }
  onSearch(): void {
    this.searchEventEmitter.emit(this.search);
  }

  completeUrl(url: string) {
    return this.configNavbar.user.api_image_route + url;
  }
}
