export class ChatTableOrder {
  id: string;
  offer_name: string;
  candidate_name: string;
  open: string;
  created_at: string;

  constructor() {
    this.id = '+-';
    this.offer_name = '+-';
    this.candidate_name = '+-';
    this.open = null;
    this.created_at = '+-';

  }
}
