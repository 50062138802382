import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { LocalStorageHelpers } from '../../../filters/helpers/local-storage.helper';
import { OrderHelper } from '../../helpers/order.helper';
import { Pagination } from '../../models/pagination';

export abstract class AbstractTableComponent {

    public if_condition = true;

    @Output() create: EventEmitter<any> = new EventEmitter(); // Create event
    @Output() edit: EventEmitter<any> = new EventEmitter(); // Edit event emitted when press edit button of a row
    @Output() delete: EventEmitter<any> = new EventEmitter(); // Trash event emitted when press trash button of a row
    @Output() otherAction: EventEmitter<any> = new EventEmitter(); // Other action event emitted when press other button of a row
    @Output() HandlerClickItem: EventEmitter<any> = new EventEmitter();
    @Output() HandlerHeaderClickBtn: EventEmitter<any> = new EventEmitter();

    public selected: any[] = []; // Array with the (element) ids selected in the first column with checkboxes
    public allSelected = false; // Flag to indicate if all row are selected or not
    public accordionStatus: any = {};

    @Input()
    public with_pagination: boolean;
    public pagination: Pagination;

    public datas: any; // Data structure for construct the table body
    public headers: any[] = []; // Data structure for construct the table header

    abstract getElements(): any; // return the element array
    abstract getFilter(): any; // return a object with the filters of the table
    abstract setFilter(filter: any): void; // To config the filter
    abstract getOrder(): any; // return a object with the order of the table
    abstract setOrder(orders: any): void; // to config the table order
    abstract getVisibility(): any; // return a object with the column that must be showed
    abstract setVisibility(show_col: any): void; // to config the cols to sho
    abstract getLocalStorageKey(): any; // return the key used in order to store info in localstorage like filters, orders, columns to show, etc
    abstract defaultFilter(): any; // return the default configuration of filters. It's used in the case of in the localstorage there aren't a previous configuration
    abstract defaultOrders(): any; // return the default configuration of orders. It's used in the case of in the localstorage there aren't a previous configuration
    abstract defaultVisibility(): any; // return the default configuration columns to show. It's used in the case of in the localstorage there aren't a previous configuration
    abstract getSelectorCheckboxes(): any; // return the view childrens of checkboxes of the first column
    abstract getAllSelectorCheckbox(): any; // return the view child of the checkbox in the table header
    abstract list(): void; // function to get the element array to show in table
    abstract hasCheckboxColumn(): boolean;
    abstract hasActionColumn(): boolean;

    firstLoadCompleted: boolean = false;

    lastFilter: any = null;
    lastOrder: any = null;

    constructor() {
    }

    /**
     * function in order to clean all selectboxes of the first column
     */
    cleanSelected(): void {
        const component = this;
        setTimeout(function () {
            if (component.getAllSelectorCheckbox() && component.getAllSelectorCheckbox()._results[0]) {
                const checkbox = component.getAllSelectorCheckbox()._results[0].nativeElement;
                if (checkbox.checked) {
                    component.getAllSelectorCheckbox()._results[0].nativeElement.click();
                } else {
                    for (let i = 0; i < component.getSelectorCheckboxes()._results.length; i++) {
                        const checkbox = component.getSelectorCheckboxes()._results[i].nativeElement;
                        if (checkbox.checked) {
                            component.getSelectorCheckboxes()._results[i].nativeElement.click();
                        }
                    }
                }
                component.allSelected = false;
                component.selected = [];
            }
        }, 500);
    }

    /**
     * function in order to save filters and orders configuration in local storage
     */
    saveFiltersAndOrders(): void {
        const filters = this.getFilter();
        LocalStorageHelpers.saveFilters(this.getLocalStorageKey(), filters);
        LocalStorageHelpers.saveOrders(this.getLocalStorageKey(), this.getOrder());
    }

    /**
     * function in order to add or remove an (element) id in selected array
     * @param id
     */
    addOrRemove(id: number): void {
        if (this.selected.includes(id)) {
            const index = this.selected.indexOf(id);
            this.selected.splice(index, 1);
        } else {
            this.selected.push(id);
        }
        this.extendAddOrRemove();
        this.clickItem( { numElement: this.selected.length, numElementId: this.selected } );
    }

    /**
     * function in order to select or diselect all checkboxes. It's executed when you press in checkbox of first column of table header.
     */
    checkOrDischeckAllSelected(): void {
        this.allSelected = !this.allSelected;
        if (this.allSelected) {
            for (let i = 0; i < this.getSelectorCheckboxes()._results.length; i++) {
                const checkbox = this.getSelectorCheckboxes()._results[i].nativeElement;
                if (!checkbox.checked) {
                    this.getSelectorCheckboxes()._results[i].nativeElement.click();
                }
            }
        } else {
            for (let i = 0; i < this.getSelectorCheckboxes()._results.length; i++) {
                const checkbox = this.getSelectorCheckboxes()._results[i].nativeElement;
                if (checkbox.checked) {
                    this.getSelectorCheckboxes()._results[i].nativeElement.click();
                }
            }
        }
        this.clickItem( { numElement: this.selected.length, numElementId: this.selected } );
    }

    clickItem( e ) {
      this.HandlerClickItem.emit( e );
    }

    /**
     * function in order to init filters, orders and column showed
     */
    resetFiltersOrdersAndColumns(getOfLocalStorage = true): void {
        const filters = LocalStorageHelpers.getFilters(this.getLocalStorageKey());
        if (filters && getOfLocalStorage) {
            this.setFilter(filters);
        } else {
            this.setFilter(this.defaultFilter());
        }
        const orders = LocalStorageHelpers.getOrders(this.getLocalStorageKey());
        if (orders && getOfLocalStorage) {
            this.setOrder(orders);
        } else {
            this.setOrder(this.defaultOrders());
        }
        const show_cols = LocalStorageHelpers.getCols(this.getLocalStorageKey());
        if (show_cols && getOfLocalStorage) {
            this.setVisibility(show_cols);
        } else {
            this.setVisibility(this.defaultVisibility());
        }
    }

    saveShowCols(): void {
        LocalStorageHelpers.saveCols(this.getLocalStorageKey(), this.getVisibility());
    }

    getTr(item, element) {
        let tag = item;
        while ( tag.tagName.toLowerCase() !== element ) {
            tag = tag.parentElement;
        }
        return tag;
    }

    formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0], // get only two digits
            month = datePart[1],
            day = datePart[2];
        return day + '/' + month + '/' + year;
    }

    formatTime(input) {
        return input.substring(0,5); // get only 5 digits (hh:mm)
    }

    onCreate(): void {
        this.create.emit();
    }
    onEdit(id): void {
        this.edit.emit(id);
    }
    onDelete(id): void {
        for (const i in this.getElements()) {
            if (this.getElements()[i].id === id) {
                this.delete.emit(this.getElements()[i].id);
                break;
            }
        }
    }
    onOtherAction(result: any): void {
      this.otherAction.emit(result);
    }

    getHeader() {
        const order = this.getOrder();
        const show = this.getVisibility();
        const headers = [];
        let isShow = false;
        let isOrder = 0;
        let value = '';
        let isPeriodStart = false;
        let isPeriodEnd = false;
        let objectData = {};

        if (this.hasCheckboxColumn()) {
            objectData = this.setData('check', true, null, '', false);
            headers.push( objectData );
        }
        for (let key in show) {
            if (show.hasOwnProperty(key)) {
                isShow = show[key];
                isOrder = order[key];
                value = key;
                if( value === 'id'){
                    value = value.toUpperCase();
                }

                objectData = this.constructHeaderColumnsExceptions(key, value, isShow, isOrder);
                if (!objectData) {
                    if (value === 'isCheck') {
                        value = '';
                        objectData = this.setData(key, isShow, isOrder, value);
                    } else {
                        objectData = this.setData(key, isShow, isOrder, value);
                    }
                }
                headers.push( objectData );
            }
        }

        if (this.hasActionColumn()) {
            objectData = this.setData('action', true, null, 'Action');
            headers.push( objectData );
        }

        this.headers = headers;
    }

    getData() {
        let dataTable = this.getElements();
        this.datas = this.getNewData(dataTable);
        this.firstLoadCompleted = true;
    }

  /**
   * funciton provisional. La idea es tener una función para extraer los datos en la que le puedas decir de donde hay que extraer datos y donde hay que guardarlos (necesario si queremos aplicar recursividad en las columnas para acordeones en las tablas)
   * @param data
   * @returns {Array}
   */
    getNewData(dataTable: any[]): any {
      const dataAll = [];
      // tslint:disable-next-line:forin
      for (const key in dataTable) {
        const item = dataTable[key];
        const order = this.getOrder();
        const show = this.getVisibility();
        const headersTable = this.headers.concat({label: 'classes'});
        let isShow = null;
        let isOrder = null;
        let isParent = null;
        let label = null;
        let value = null;
        let objectData = {};
        const data = [];

        if (this.hasCheckboxColumn()) {
          objectData = this.setData('check', true, null, '');
          data.push( objectData );
        }

        // tslint:disable-next-line:forin
        for (const key in headersTable) {
          label = headersTable[key].label;
          value = item[label];
          isParent = item['isParent'];
          isShow = show[label];
          isOrder = order[label];

          objectData = this.constructBodyColumnsExceptions(label, value, isShow, isOrder, item);
          if (!objectData) {
            objectData = this.setData(label, isShow, isOrder, value, isParent);
          }
          data.push( objectData );
        }
        if (this.hasActionColumn()) {
          let actions = this.getDataActions(item);
          objectData = this.setData('action', true, 0, actions);
          data.push( objectData );
        }

        dataAll.push(data);
      }
      return dataAll;
    }
    setData(key, isShow = false, isOrder = 0, value, translation = false, isParent = false, customClass = null,
            action = null, input = null, btn = null) {

        if (!customClass) {
            customClass = key;
        }
        return {
            label: key,
            value: value,
            isShow: isShow,
            order: isOrder,
            class: customClass,
            isParent: isParent,
            translation: translation,
            action: action,
            input: input,
            btn: btn
        };
    }

    // function in order to implement a custom process of some columns in the header table
    constructHeaderColumnsExceptions(key, value, isShow, isOrder): any {}
    // function in order to implement a custom process of some columns in the body table
    constructBodyColumnsExceptions(key, value, isShow, isOrder, item): any {}

    extendAddOrRemove(): any {}

    listPaginate($event): void {
        this.pagination.previousPage = this.pagination.currentPage;
        this.pagination.currentPage = $event;
        this.list();
    }
    listWithTimeout(): void {
        const component = this;
        setTimeout(function() {
          // if(component.firstLoadCompleted) {
            component.list();
          // }
        }, 100);
    }
    toOrder(field: string): void {
        this.setOrder(OrderHelper.order(this.getOrder(), field));
        this.list();
    }
    /*
     Esta función se debe sobreescribir en las tablas cuyas acciones, no sean editar y eliminar
     Actualización: Esta función no debería de hacer falta. Debería de coger las acciones de la configuración de la tabla
     */
    getDataActions(item: any): any {
      return [
        {
          action: 'edit',
          isShow: true,
          icon: 'fas fa-edit',
          title: 'Edit'
        },
        {
          action: 'delete',
          isShow: true,
          icon: 'fas fa-trash-alt',
          title: 'Delete'
        }
      ];
    }

    buildAccordionStatus(): any { // Para construir un objeto con el estado que debe tener el acordeon (guarda por cada fila su clase y sus hijos, que a la vez contienen lo mismo)
      let result = this.accordionStatus; // Cada vez que se ejecute cogemos el estado actual y trabajamos con el (es decir el estado se mantiene ante operaciones como paginacion, filtrado, etc...)
      for(let i in this.getElements()) { // Recorremos cada uno de los elementos
        let element = this.getElements()[i];
        if(!result[element.id]) { // Si en el array del estado aun no está el estado del elemento lo creamos. Sino mantenemos el que existiera
          result[element.id] = {class: '', children: []};
        }
        if(element['isParent'] && element['children']) { // Si el elemento es padre y tiene hijos repetimos la operación con sus hijos
          let classSubelement = ''
          for(let e in element['children']) {
            let subelement = element['children'][e];
            if(!result[element.id]['children'][subelement.id]) { // Si el estado del elemento no existe lo creamos
              result[element.id]['children'][subelement.id] = {class: classSubelement, children: []};
            } else { // Si existe guardamos su clase en una variable para así podersela aplicar a sus hermanos (esto sirve para cuando creamos un elemento y sus hermanos están abiertos. Así consigues que el que se crea tb esté abierto)
              classSubelement = result[element.id]['children'][subelement.id]['class'];
            }
            if(subelement['isParent'] && subelement['children']) { // Repetimos la misma operación con los hijos de los hijos
              let classSubsubelement = '';
              for(let j in subelement['children']) {
                let subsubelement = subelement['children'][j];
                if(!result[element.id]['children'][subelement.id]['children'][subsubelement.id]) {
                  result[element.id]['children'][subelement.id]['children'][subsubelement.id] = {class: classSubsubelement};
                } else {
                  classSubsubelement = result[element.id]['children'][subelement.id]['children'][subsubelement.id]['class'];
                }
              }
            }
          }
        }
      }
      return result;
    }
    onChangeAccordionStatus($event): void {
      this.accordionStatus = $event;
    }

  colValue(colName: string, element: any): any {
    for (let i in element) {
      let col = element[i];
      if (colName !== 'action') {
        if (col['label'] === colName) {
          return col['value'];
        }
      } else {
        if (col['label'] === colName && col['value']) {
          return col['value'];
        }
      }
    }
    return null;
  }

  /**
   * Comprueba si el filtro o el orden ha cambiado. En caso afirmativo guarda el ultimo filtro y el último orden para la siguiente comprobacion.
   */
  filterHasChanged(): boolean {
      if((this.pagination && this.pagination.currentPage != this.pagination.previousPage && this.pagination.previousPage != null) || JSON.stringify(this.lastFilter) != JSON.stringify(this.getFilter().toJson()) || JSON.stringify(this.lastOrder) != JSON.stringify(this.getOrder())) {
        this.lastFilter = JSON.parse(JSON.stringify(this.getFilter().toJson()));
        this.lastOrder = JSON.parse(JSON.stringify(this.getOrder()));
        return true;
      }
      return false;
  }
  resetLastFilterAndOrder(): void {
    this.lastFilter = null;
    this.lastOrder = null;
  }
}
