import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.sass']
})
export class PrivacyPageComponent implements OnInit {

  constructor(private themeService: ThemeService
  ) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(false);
  }

}
