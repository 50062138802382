export const REGISTRATION_STRUCTURE = [
  {
    name: 'name',
    label: 'Nombre',
    placeholder: null,
    type: 'input-text',
    default_value: null,
    col: 'col-12 col-md-6',
    style: 'container__input',
    rules: [
      'required'
    ],
    label_classes: null,
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  },
  {
    name: 'surname',
    label: 'Apellidos',
    placeholder: null,
    type: 'input-text',
    default_value: null,
    col: 'col-12 col-md-6',
    style: 'container__input',
    rules: [
      'required'
    ],
    label_classes: null,
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  },
  {
    name: 'username',
    label: 'Nombre de usuario',
    placeholder: null,
    type: 'input-text',
    default_value: null,
    col: 'col-12 col-md-6',
    style: 'container__input',
    rules: [
      'required'
    ],
    label_classes: null,
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  },
  {
    name: 'email',
    label: 'E-mail',
    placeholder: null,
    type: 'input-text',
    default_value: null,
    col: 'col-12 col-md-6',
    style: 'container__input',
    rules: [
      'required'
    ],
    label_classes: null,
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  },
  {
    name: 'password',
    label: 'Contraseña',
    placeholder: null,
    type: 'input-password',
    default_value: null,
    col: 'col-12 col-md-6',
    style: 'container__input',
    rules: [
      'required'
    ],
    label_classes: null,
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  },
  {
    name: 'password_confirmation',
    label: 'Contraseña confirmar',
    placeholder: null,
    type: 'input-password',
    default_value: null,
    col: 'col-12 col-md-6',
    style: 'container__input',
    rules: [
      'required'
    ],
    label_classes: null,
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  },
  {
    name: 'accept_terms',
    label: 'accept_terms',
    label_link: '/panel/terms',
    placeholder: null,
    type: 'checkbox',
    default_value: null,
    col: 'col-12 col-md-12',
    style: 'check__reverse form-checkbox',
    rules: [
      'required'
    ],
    label_classes: 'link-register note',
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  },
  {
    name: 'accept_privacy',
    label: 'accept_privacy',
    label_link: '/panel/privacy',
    placeholder: null,
    type: 'checkbox',
    default_value: null,
    col: 'col-12 col-md-12',
    style: 'check__reverse form-checkbox',
    rules: [
      'required'
    ],
    label_classes: 'link-register note',
    input_classes: null,
    config: {
      hasLabel: true,
      hasInput: true,
      hasHelp: false,
    }
  }
];

