import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(private router: Router,
              private themeService: ThemeService) { }

  ngOnInit() {
    this.themeService.headerEventEmitter.emit(true);
  }

  ngAfterViewInit() {
    // JQUERY_EASING($, window);
  }

  goToAnchor(anchor: string) {
    this.router.navigate(['/panel/home'], {fragment: anchor});
  }


}
