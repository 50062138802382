import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { FormatHelper } from '../../projects/bloonde-ngx-template/src/api/tools/format.helper';
import { Offer } from '../models/offer';
import { CVExperience } from '../models/cv_experience';
import { AbstractService } from 'bloonde-ngx-template/src/api/services/abstract.service';
import { CVOtherFormConfiguration } from '../components/forms/cv-others-form/cv-others-form-configuration';
import { DateComponent } from 'bloonde-ngx-template/src/inputs/date/date.component';


@Injectable()
export class CvOtherService extends AbstractService {

  constructor(public _http: HttpClient) {
    super('cv_other', CVOtherFormConfiguration, _http);
  }
  public buildParams(model: any): any {
    let params: any = {};
    this.modelConfigurationClass.getStructure().forEach(function (field) {
      if (field.type == DateComponent) {
        if (model[field.name]) {
          params[field.name] = FormatHelper.formatToApi(FormatHelper.DATE_FORMAT, model[field.name]);
        }
      } else if(field.type == 'checkbox') {
        params[field.name] = FormatHelper.formatToApi(FormatHelper.CHECKBOX_FORMAT, model[field.name]);
      } else {
        if (model[field.name] != null) {
          params[field.name] = model[field.name];
        }
      }
    });
    params = this.extendBuildParams(model, params);
    return params;
  }

  extendBuildParams(model: any, params: any): any {
    params['candidate_id'] = model['candidate_id'];
    return params;
  }
}
