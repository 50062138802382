import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from 'bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import {SessionHelper} from 'bloonde-ngx-template/src/session/helpers/session.helper';


@Injectable()
export class SelectService {
  public qualificationFilterOptions: any;

  constructor(public _http: HttpClient) {}

  selectList(file: string): Observable<any> {
    return this._http.get(ApiRoutesHelper.getSelectSelectListURL(file));
  }

  getLevelFilterOptions(): any {
    let result = null;
    if (this.qualificationFilterOptions) {
      result = this.qualificationFilterOptions;
    } else {
      let options = SessionHelper.getLocalStorageField('level_filter_options');
      if (options) {
        result = JSON.parse(options);
      }
    }
    return result;
  }
}
