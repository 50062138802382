export class MyOffersCandidateTableOrder {
  id: string;
  offer_name: string;
  status_id: string;
  registration_date: string;
  city_name: string;
  candidatures: string;

  constructor() {
    this.id = null;
    this.offer_name = null;
    this.status_id = null;
    this.registration_date = null;
    this.city_name = null;
    this.candidatures = null;
  }
}
