import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { FormatHelper } from '../../projects/bloonde-ngx-template/src/api/tools/format.helper';
import { Offer } from '../models/offer';
import { CVExperience } from '../models/cv_experience';
import { AbstractService } from 'bloonde-ngx-template/src/api/services/abstract.service';
import { CVExperienceFormConfiguration } from '../components/forms/cv-experience-form/cv-experience-form-configuration';


@Injectable()
export class CvExperienceService extends AbstractService {

  constructor(public _http: HttpClient) {
    super('cv_experience', CVExperienceFormConfiguration, _http);
  }

  extendBuildParams(model: any, params: any): any {
    params['candidate_id'] = model['candidate_id'];
    return params;
  }
}
