import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../models/city';
import { CityService } from '../../../services/city.service';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from '../../../../projects/bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.sass']
})
export class CitySelectComponent implements OnInit {

  @Input()
  multiple: boolean;
  @Input()
  addTag: boolean;
  @Input()
  error: any;
  @Input()
  serverError: any;
  @Input()
  filter: any;

  cities: City[];

  @Input()
  get model() {
    return this.modelValue;
  }
  @Output()
  modelChange = new EventEmitter<any>();
  @Output()
  goToCityEmitter = new EventEmitter<any>();

  public modelValue: string;


  public structure: any;

  constructor(private cityService: CityService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    if (this.structure.autoload !== false) {
      this.list();
    }
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, this.filter, []);
    this.cityService.selectList(listRequestData)
      .subscribe(
        res => {
          this.cities = <City[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }
  goToCity(): void {
    this.goToCityEmitter.emit(this.modelValue);
  }

  private prepareOptions(): void {
    let options = [];
    for(let i in this.cities) {
      let city = this.cities[i];
      options.push({
        value: city.id,
        label: city.name
      });
    }
    this.structure['options'] = options;
  }

}
