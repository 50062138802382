import {Injectable} from '@angular/core';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionHelper } from '../helpers/session.helper';
import { ApiHelper } from '../helpers/api.helper';
import { Observable } from 'rxjs/index';
import { Params } from '../../api/tools/params.helper';

/**
 * Created by Daniel on 14/05/2017.
 */

@Injectable()
export class RegistrationService {

}
