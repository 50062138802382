import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractFormModalComponent } from '../../../../../bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';
import { LoginFormComponent } from '../../forms/login-form/login-form.component';

@Component({
  selector: 'bl-login-form-modal',
  templateUrl: './login-form-modal.component.html',
  styleUrls: ['./login-form-modal.component.sass']
})
export class LoginFormModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: false, save_and_edit: false};
  @Input() buttonsText: {cancelText: string, saveText: string, saveAndNewText: string, saveAndEditText: string, deleteText: string} = {cancelText: null, saveText: 'sign in', saveAndNewText: null, saveAndEditText: null, deleteText: null};

  @Output() showRegistrationModal: EventEmitter<any> = new EventEmitter();
  @Output() showResetPasswordModal: EventEmitter<any> = new EventEmitter();

  @ViewChild(LoginFormComponent) formComponent;
  public modal_id = 'login_modal';

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public getFormComponent(): any {
    return this.formComponent;
  }

  saveAndClose(): void {
    this.getFormComponent().login();
  }

  onShowRegistrationModal(type: string): void {
    this.closeModal();
    var component = this;
    setTimeout(function(){
      component.showRegistrationModal.emit(type);
    }, 100);
  }
  onShowResetPasswordModal(): void {
    this.closeModal();
    var component = this;
    setTimeout(function(){
      component.showResetPasswordModal.emit();
    }, 100);
  }

  onLogin(): void {
    this.closeModal();
  }
}
