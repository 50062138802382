import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

// Helpers
import {User} from '../models/user';
import {SessionService} from '../services/session.service';
import { SessionHelper } from '../helpers/session.helper';
import { NavigationHelper } from '../../navigation/navigation.helper';
import { Observable, Observer } from 'rxjs/Rx';



@Injectable()
export class UserLoggedNoRedirectResolver implements Resolve<User> {
    constructor(private navigationHelper: NavigationHelper,
                private _sessionService: SessionService) {
    }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
        return Observable.create((observer: Observer<User>) => {
            const user: User = this._sessionService.getLoggedUser();
            if (user) {
                observer.next(user);
                observer.complete();
            } else {
                if (SessionService.AUTOLOGIN) {
                    this._sessionService.login('user1@email.com', '123456789')
                        .subscribe(
                            res => {
                                this._sessionService.setSession(res['user'], res['token']);
                                observer.next(res['user']);
                                observer.complete();
                            },
                            error => {}
                        );
                } else {
                  if (this._sessionService.getSessionData()) {
                    this._sessionService.initializeSession()
                      .subscribe(
                        res => {
                          let data = null;
                          if (res['data']) {
                            data = res['data'];
                          } else {
                            data = res;
                          }

                          this._sessionService.setSession(data);
                          observer.next(data);
                          observer.complete();
                        },
                        error => {
                          observer.next(null);
                          observer.complete();
                        },
                      );
                  } else {
                    observer.next(null);
                    observer.complete();
                  }
                }
            }
        });
    }
}
