import {EventEmitter, Output} from '@angular/core';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
import { AbstractFilterComponent } from 'bloonde-ngx-template/src/filters/abstract-filter.component';
export class OfferTableFilter extends AbstractFilterComponent {
  @Output() searchModelChange: EventEmitter<any>;
  search: string;
  @Output() statusIdModelChange: EventEmitter<any>;
  status_id: any;
  @Output() positionIdModelChange: EventEmitter<any>;
  position_id: any;
  @Output() sectorIdModelChange: EventEmitter<any>;
  sector_id: any;
  @Output() cityIdModelChange: EventEmitter<any>;
  city_id: any;
  @Output() fromModelChange: EventEmitter<any>;
  from: any;
  @Output() toModelChange: EventEmitter<any>;
  to: any;

  constructor() {
    super();
    this.search = null;
    this.searchModelChange = new EventEmitter();
    this.status_id = null;
    this.statusIdModelChange = new EventEmitter();
    // this.position_id = null;
    // this.positionIdModelChange = new EventEmitter();
    // this.sector_id = null;
    // this.sectorIdModelChange = new EventEmitter();
    // this.city_id = null;
    // this.cityIdModelChange = new EventEmitter();
    this.from = null;
    this.fromModelChange = new EventEmitter();
    this.to = null;
    this.toModelChange = new EventEmitter();
  }
  public toFilter(): any[] {
    return ['search', 'status_id', {type: FormatHelper.DATE_FORMAT, field: 'from'}, {type: FormatHelper.DATE_FORMAT, field: 'to'}];
  }
  public emit() {
    this.searchModelChange.emit(this.search);
    this.statusIdModelChange.emit(this.status_id);
    // this.positionIdModelChange.emit(this.position_id);
    // this.sectorIdModelChange.emit(this.sector_id);
    // this.cityIdModelChange.emit(this.city_id);
    this.fromModelChange.emit(this.from);
    this.toModelChange.emit(this.to);
  }
  public copy(filter: {search: string, status_id: any, from: any, to: any}) {
    this.search = filter.search;
    this.status_id = filter.status_id;
    // this.position_id = filter.position_id;
    // this.sector_id = filter.sector_id;
    // this.city_id = filter.city_id;
    this.from = filter.from;
    this.to = filter.to;
    let component = this;
    setTimeout(function() {
      component.emit();
    }, 100);
  }
  public toJson(): any {
    return {
      search: this.search,
      status_id: this.status_id,
      from: this.from,
      to: this.to
    };
  }
}

