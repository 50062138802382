import {
  Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, QueryList,
  ViewChildren
} from '@angular/core';
import { NavigationHelper } from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { CardDirective } from '../../../directives/card.directive';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';

@Component({
  selector: '[appCards]',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.sass']
})
export class CardsComponent implements OnInit {

  @Input() datas;
  @Input() headers;
  @Input() type;

  @Output() Edit: EventEmitter<any> = new EventEmitter();
  @Output() Delete: EventEmitter<any> = new EventEmitter();
  @Output() Trash: EventEmitter<any> = new EventEmitter();
  @Output() OtherAction: EventEmitter<any> = new EventEmitter();

  @ViewChildren(CardDirective) cardDirectives: QueryList<CardDirective>

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    console.log('DATAS => ', this.datas);
  }

  ngAfterViewChecked() {}

  onEdit($event): void {
    this.Edit.emit($event);
    console.log('Edit - ID: ' + $event['element']['id']);
  }

  onDelete($event): void {
    this.Delete.emit($event);
    console.log('Delete - ID: ' + $event['element']['id']);
  }

  onTrash($event): void {
    this.Trash.emit($event);
    console.log('Trash - ID: ' + $event['element']['id']);
  }

  onOtherAction($event): void {
    this.OtherAction.emit($event);
    console.log('Other action - ID: ' + $event['element']['id'] + ' ; ACTION: ' + $event['action']);
  }

  public constructCard(): void {
    if (this.cardDirectives) {
      for (let e = 0; e < this.cardDirectives.length; e++) {
        let cardDirectives = this.cardDirectives['_results'][e];
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.type);
        let viewContainerRef = cardDirectives.viewContainerRef;
        viewContainerRef.clear();
        let componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.data = this.datas[e];
        var component = this;
        componentRef.instance.editEmitter.subscribe(function($event) {
          component.onEdit($event);
        });
        componentRef.instance.deleteEmitter.subscribe(function($event) {
          component.onDelete($event);
        });
        componentRef.instance.otherActionEmitter.subscribe(function($event) {
          component.onOtherAction($event);
        });
      }
    }
  }
}
