import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Image } from '../../../models/image';
import { GalleryImageModalComponent } from '../../modals/gallery-image-modal/gallery-image-modal.component';
import { ActionConfirmationModalComponent } from '../../modals/action-confirmation-modal/action-confirmation-modal.component';
import { GalleryImageService } from '../../../services/galleryImage.service';
import { NavigationHelper } from '../../../../../bloonde-ngx-template/src/navigation/navigation.helper';
import { ResponseHelper } from '../../../../../bloonde-ngx-template/src/responses/response.helper';
import { GalleryPhotoService } from '../../../services/galleryPhoto.service';
import { ListRequestData } from '../../../../../bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: 'bl-gallery-uploader',
  templateUrl: './gallery-uploader.component.html',
  styleUrls: ['./gallery-uploader.component.css']
})
export class GalleryUploaderComponent implements OnInit {

  @ViewChild('createGalleryImageModal') createGalleryImageModalComponent;
  @ViewChild('editionGalleryImageModal') editionGalleryImageModalComponent;
  @ViewChild('delete_image_modal') actionDeleteConfirmationModalComponent;
  @ViewChild('main_image_modal') actionMainConfirmationModalComponent;

  @Output() successStore: EventEmitter<any> = new EventEmitter();

  public images: Image[];

  public aux_id_to_delete: any;
  public aux_id_to_main: any;

  @Input()
  public config: {type: any, fk_id: any, multiple: boolean, options: {main: boolean, edit: boolean, delete: boolean}};


  constructor(private galleryPhotoService: GalleryPhotoService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    this.list();
  }


  list(): void {
    let listRequestData = new ListRequestData(null, {fk_id: this.config.fk_id, type: this.config.type}, null);
    this.galleryPhotoService.list(listRequestData).subscribe(
      res => {
        this.images = res['elements'];
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
  create(): void {
    this.createGalleryImageModalComponent.show('create', null);
  }
  edit(id: any): void {
    this.editionGalleryImageModalComponent.show('edit', id);
  }
  delete(id: any): void {
    this.aux_id_to_delete = id;
    this.actionDeleteConfirmationModalComponent.openModal();
  }
  main(id: any): void {
    this.aux_id_to_main = id;
    this.actionMainConfirmationModalComponent.openModal();
  }
  confirmDelete(): void {
    this.galleryPhotoService.delete(this.aux_id_to_delete).subscribe(
      res => {
        this.actionDeleteConfirmationModalComponent.closeModal();
        this.list();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
  confirmMain(): void {
    this.galleryPhotoService.main(this.aux_id_to_main).subscribe(
      res => {
        this.actionMainConfirmationModalComponent.closeModal();
        this.list();
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
}
