import {FormatHelper} from 'bloonde-ngx-template/src/api/tools/format.helper';
export class Candidate {
    id: any;
    name: string;
    username: string;
    email: string;
    password: string;
    password_confirmation: string;
    activated: any;
    surname: string;
    phone: string;
    address: string;
    city_id: any;
    state_id: any;
    condition_accept: boolean;
    values_accept: boolean;
    new_offers_accept: boolean;
    nationality_id: any;
    cv: any;
    laboral_life: any;
    birthdate: any;
    offers: any[];

  constructor() {
    this.id = null;
    this.name = null;
    this.username = null;
    this.email = null;
    this.password = null;
    this.password_confirmation = null;
    this.activated = null;
    this.surname = null;
    this.phone = null;
    this.address = null;
    this.city_id = null;
    this.state_id = null;
    this.condition_accept = false;
    this.values_accept = false;
    this.new_offers_accept = false;
    this.nationality_id = null;
    this.cv = null;
    this.laboral_life = null;
    this.birthdate = null;
  }
  public static copy(candidate: any) {
    let candidateAux = new Candidate();
    candidateAux.id = candidate.id;
    candidateAux.name = candidate.name;
    candidateAux.username = candidate.username;
    candidateAux.email = candidate.email;
    if (candidate.activated) {
      candidateAux.activated = candidate.activated.toString();
    }
    candidateAux.surname = candidate.surname;
    candidateAux.phone = candidate.phone;
    candidateAux.address = candidate.address;
    if (candidate.city_id) {
      candidateAux.city_id = parseInt(candidate.city_id);
    }
    candidateAux.state_id = candidate.state_id;
    candidateAux.condition_accept = candidate.condition_accept;
    candidateAux.values_accept = candidate.values_accept;
    candidateAux.new_offers_accept = candidate.new_offers_accept;
    candidateAux.nationality_id = candidate.nationality_id;
    candidateAux.cv = candidate.cv;
    candidateAux.laboral_life = candidate.laboral_life;
    candidateAux.birthdate = FormatHelper.formatFromApi(FormatHelper.DATE_FORMAT, candidate.birthdate);
    candidateAux.offers = candidate.offers;
    return candidateAux;
  }
}
