import { Injectable, Provider } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginConfigService {

  public static config = { };

  public static forRoot(config: any): Provider {
    LoginConfigService.config = config;
    return LoginConfigService;
  }
}
