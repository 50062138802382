import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessService } from '../../../projects/ngx-bloonde-user-and-privileges/src/services/access.service';
import { ResetPasswordModalComponent } from 'ngx-bloonde-user-and-privileges/src/components/modals/reset-password-modal/reset-password-modal.component';
import { SessionService } from 'bloonde-ngx-template/src/session/services/session.service';
import { LoginFormModalComponent } from '../../components/modals/login-form-modal/login-form-modal.component';
import { RegistrationFormModalComponent } from 'ngx-bloonde-user-and-privileges/src/components/modals/registration-form-modal/registration-form-modal.component';
import {NavigationHelper} from 'bloonde-ngx-template/src/navigation/navigation.helper';
import { NavbarConfiguration } from 'bloonde-ngx-template/src/navbar/navbar-configuration';
import { ThemeService } from '../../services/theme.service';
import {DefaultClassNavbar} from 'bloonde-ngx-template/src/navbar/default-class-navbar';
import {SuccessRegistrationModalComponent} from '../../components/modals/success-registration-modal/success-registration-modal.component';

declare var $: any;

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.sass']
})
export class PanelComponent implements OnInit {

  @ViewChild(LoginFormModalComponent) loginFormModalComponent;
  @ViewChild(RegistrationFormModalComponent) registrationFormModalComponent;
  @ViewChild(ResetPasswordModalComponent) resetPasswordModalComponent;
  @ViewChild(SuccessRegistrationModalComponent) successRegistrationModalComponent;

  public loggedInUser;
  public activationMessage: string;

  constructor(private route: ActivatedRoute,
              private accessService: AccessService,
              private sessionService: SessionService,
              private themeService: ThemeService,
              private navigationHelper: NavigationHelper
  ) {}

  public configLogin: any = {
    title: 'Personae',
    subtitle: 'Administration Panel.',
    img: '/src/assets/img/panel-logo.jpg',
  };

  public configNavbar: NavbarConfiguration = {
    styles: DefaultClassNavbar.defaultCss,
    logo: {
      show: true,
      src: 'assets/img/logo-company_2.jpg',
      dark_src: 'assets/img/logo-company_2.jpg',
      alt: 'Logo',
      href: '/panel/home',
    },
    menu: {
      show: true,
      items: [
        {
          label: 'home',
          show: true,
          href: '/panel/home',
          routerLink: true,
          icon: null,
        },
        {
          label: 'offer-search',
          show: true,
          href: '/panel/offer-search',
          routerLink: true,
          icon: null,
        },
      ]
    },
    search: {
      show: false,
      text: null
    },
    buttons: {
      show: true,
      login: {
        show: false,
      },
      registration: {
        show: false,
      }
    },
    user: {
      show: false,
      data: null,
      items: [
        // {
        //   label: 'password_change',
        //   show: true,
        //   type: 'link',
        //   href: '',
        //   icon: 'fas fa-key',
        //   item: []
        // },
        {
          label: 'my_profile',
          show: true,
          type: 'link',
          href: '/panel/my-profile',
          icon: 'fas fa-user',
        },
        {
          label: 'Offer Management',
          show: true,
          type: 'link',
          href: '/panel/offers',
          icon: 'fas fa-concierge-bell',
        },
        {
          label: 'candidates',
          show: true,
          type: 'link',
          href: '/panel/candidates',
          icon: 'fas fa-users',
        },
        {
          label: 'messages',
          show: true,
          type: 'link',
          href: '/panel/chats',
          icon: 'fas fa-envelope',
        },
        {
          label: 'my_offers',
          show: true,
          type: 'link',
          href: '/panel/offer-candidate',
          icon: 'fas fa-briefcase',
        },
        {
          label: 'logout',
          show: true,
          type: 'link',
          click: 'logout',
          icon: 'fas fa-power-off',
        },
      ],
    }
  };

  public configSidebar: any = {
    show: false,
    title: {
      show: false,
      text: '',
      href: ''
    },
    item: [

    ],
    itemBtn: []
  };

  ngOnInit() {
    this.loggedInUser = this.route.snapshot.data['loggedInUser'];
    this.reset();
    var component = this;
    this.route.queryParams.subscribe(params => {
      if (params['activation'] || params['already_activated']) {
        if (params['activation']) {
          this.activationMessage = 'success-account-activation';
        } else {
          this.activationMessage = 'success-account-already-activated';
        }
        setTimeout(function() {
          component.loginFormModalComponent.show('create');
        }, 100);
      }
    });
    this.sessionService.userEmitter.subscribe(function(user) {
      component.loggedInUser = user;
      component.reset();
      if (!component.loggedInUser) {
        component.navigationHelper.navigateTo('home');
      }
    });
    this.sessionService.showModalLoginEmmiter.subscribe(function() {
      component.onOpenLoginFormModal();
    });
    this.themeService.headerEventEmitter.subscribe(function(value){
      setTimeout(function() {
        let header = $('#header');
        let headerWrap = $('#header-wrap');
        let reset = false;
        if(value) {
          if(!header.hasClass('dark')) {
            // header.addClass('dark')
            reset = true;
          }
          if(!header.hasClass('transparent-header')) {
            header.addClass('transparent-header')
            reset = true;
          }
          if(headerWrap.hasClass('force-not-dark')) {
            headerWrap.removeClass('force-not-dark')
            reset = true;
          }
        } else {
          if(header.hasClass('dark')) {
            header.removeClass('dark')
            reset = true;
          }
          // if(!header.hasClass('no-sticky')) {
          //   $('#header').addClass('no-sticky')
          //   reset = true;
          // }
          if(!header.hasClass('force-not-dark')) {
            headerWrap.addClass('force-not-dark')
            reset = true;
          }
          if(header.hasClass('transparent-header')) {
            header.removeClass('transparent-header')
            reset = true;
          }
          if(!header.hasClass('sticky-header')) {
            header.addClass('sticky-header')
            reset = true;
          }
        }
        if(reset) {
          component.themeService.resetEventEmitter.emit(true);
        }
      }, 2000)
    });
  }

  reset(): void {
    this.checkProfile();
    if (this.loggedInUser) {
      this.configNavbar.user.data = this.loggedInUser;
    }
  }

  checkProfile(): void {
    this.configNavbar.user.show = this.loggedInUser != null;
    this.configNavbar.buttons.login.show = this.loggedInUser == null;
    this.configNavbar.buttons.registration.show = this.loggedInUser == null;

    this.configNavbar.user.items[0]['show'] = this.loggedInUser && this.accessService.checkProfile('candidate', this.loggedInUser);
    this.configNavbar.user.items[1]['show'] = this.loggedInUser && this.accessService.checkProfile('evaluator', this.loggedInUser);
    this.configNavbar.user.items[2]['show'] = this.loggedInUser && this.accessService.checkProfile('evaluator', this.loggedInUser);
    this.configNavbar.user.items[4]['show'] = this.loggedInUser && this.accessService.checkProfile('candidate', this.loggedInUser);
  }

  onOpenLoginFormModal(): void {
    this.loginFormModalComponent.show('create');
  }
  onOpenRegistrationFormModal(): void {
    this.registrationFormModalComponent.show('create');
  }
  onShowResetPasswordModal(): void {
    this.resetPasswordModalComponent.show('create');
  }
  onShowSuccessRegistrationModal(): void {
    this.successRegistrationModalComponent.openModal();
  }
}
