import { Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { OfferCandidateService } from '../../../services/offer-candidate.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { PreselectCandidateFormConfiguration } from './preselect-candidate-form-configuration';
import { FormActionsHelper } from 'bloonde-ngx-template/src/forms/helpers/form-actions.helper';
import { CodesHelper } from 'bloonde-ngx-template/src/responses/codes.helper';

@Component({
  selector: 'app-preselect-candidate-form',
  templateUrl: './preselect-candidate-form.component.html',
  styleUrls: ['./preselect-candidate-form.component.sass']
})
export class PreselectCandidateFormComponent extends AbstractFormComponent implements OnInit {

  @Input()
  public modelValue: {candidate_ids: any[], offer_id: any, status_id: any};

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private offerService: OfferCandidateService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = PreselectCandidateFormConfiguration.structure;
  }

  ngOnInit() {
  }


  public new(args: any[]): void {
    this.modelValue = {candidate_ids: null, offer_id: null, status_id: null};
    this.modelValue.candidate_ids = args['candidate_ids'];
    this.modelValue.status_id = args['status_id'];
    this.buildErrorModelAndEmitters();
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public store(args: any[] = []): void {
    const validate = this.checkForm();
    this.emitError();
    if (validate) {
      this.getModelService().bulkStore(this.getModel()).subscribe(
        res => {
          this.successResponseStore(res); // Hook post store success
          this.resetErrors(); // Reseteamos los errores
          this.success();
          this.getMessageService().showSuccessMessage();
        },
        error => {
          if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
            this.serverErrors = error.error;
            this.emitServerError();
          } else {
            this.getResponseHelper().handleError(error);
          }
        }
      );
    }
  }

  public getModel(): any {
    return this.modelValue;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.offerService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.modelValue = model;
  }
  successResponseStore(res): void {
    // this.modelValue = Offer.copy(res); // No habrá edicion
  }
  public success(): void {
    this.onSuccess.emit();
  }
}
