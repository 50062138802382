import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginFormComponent } from '../../components/forms/login-form/login-form.component';
import { ResetPasswordModalComponent } from '../../components/modals/reset-password-modal/reset-password-modal.component';

import { LoginConfigService } from './../../../src/components/forms/login-form/login-config.service';
import {ActivatedRoute} from '@angular/router';
import { SessionService } from '../../../../bloonde-ngx-template/src/session/services/session.service';
import { ApiConfigHelper } from '../../../../bloonde-ngx-template/src/api/api-config.helper';

@Component({
  selector: 'bl-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  public config: any;
  public activation: boolean;
  public currentWeb: any;

  @ViewChild(LoginFormComponent) loginFormComponent;
  @ViewChild(ResetPasswordModalComponent) resetPasswordModalComponent;

  constructor(
    private loginConfigService: LoginConfigService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
  ) {
    this.config = LoginConfigService.config;
  }

  ngOnInit() {
    this.sessionService.logout();
    this.route.queryParams.subscribe(params => {
      if (params['activation']) {
        this.activation = params['activation'];
      }
    });
    this.currentWeb = this.route.snapshot.data['currentWeb'];
    if (this.currentWeb) {
      this.config.logo = ApiConfigHelper.IMAGE_FOLDER + this.currentWeb['img_logo'];
    }
  }

  onLogin(): void {
    this.loginFormComponent.login();
  }
  showResetPasswordModal(): void {
    this.resetPasswordModalComponent.show();
  }
}
