import { CandidateProfileFormComponent } from '../components/forms/candidate-profile-form/candidate-profile-form.component';
import { Candidate } from './candidate';
import { FormatHelper } from 'bloonde-ngx-template/src/api/tools/format.helper';
export class CVTraining {
    id: any;
    title: string;
    observations: string;
    type: any;
    level: string;
    document: any;
    candidate_id: any;
    language_id: any;
    value: any;

    candidate: Candidate;

  constructor() {
    this.id = null;
    this.title = null;
    this.observations = null;
    this.type = null;
    this.level = null;
    this.document = null;
    this.candidate_id = null;
    this.language_id = null;
    this.value = null;
    this.candidate = new Candidate();
  }
  public static copy(cvTraining: any) {
    let cvTrainingAux = new CVTraining();
    cvTrainingAux.id = cvTraining.id;
    cvTrainingAux.title = cvTraining.title;
    cvTrainingAux.observations = cvTraining.observations;
    cvTrainingAux.level = cvTraining.level;
    cvTrainingAux.type = cvTraining.type;
    cvTrainingAux.document = cvTraining.document;
    if (cvTraining.value) {
      cvTrainingAux.value = JSON.parse(cvTraining.value);
    }
    cvTrainingAux.candidate_id = cvTraining.candidate_id;
    cvTrainingAux.candidate = cvTraining.candidate;
    if (cvTraining.language_id) {
      cvTrainingAux.language_id = cvTraining.language_id;
    }
    return cvTrainingAux;
  }
}
