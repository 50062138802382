import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionHelper } from '../helpers/session.helper';
import { ApiHelper } from '../helpers/api.helper';
import { Observable } from 'rxjs/index';
import { Params } from '../../api/tools/params.helper';
import { ResetPassword } from '../../../../ngx-bloonde-user-and-privileges/src/models/reset-password';
import { ResetChangePassword } from '../../../../ngx-bloonde-user-and-privileges/src/models/reset-change-password';
import { ChangePassword } from '../../../../ngx-bloonde-user-and-privileges/src/models/change-password';
import { AccessService } from '../../../../ngx-bloonde-user-and-privileges/src/services/access.service';

/**
 * Created by Daniel on 14/05/2017.
 */

@Injectable()
export class SessionService {

    public static AUTOLOGIN: boolean = false;

    private user: User;
    public userEmitter: EventEmitter<User> = new EventEmitter();
    public userUpdateProfile: EventEmitter<User> = new EventEmitter();
    public showModalLoginEmmiter: EventEmitter<User> = new EventEmitter();

    constructor(public _http: HttpClient,
      private accessService: AccessService) {
    }

    public getSessionData(): boolean {
        const email = SessionHelper.getLocalStorageField('email');
        const token = SessionHelper.getLocalStorageField('token');

        return email != null && token != null;
    }

    public initializeSession(): Observable<Object> {
        const id = SessionHelper.getLocalStorageField('id');
        const token = SessionHelper.getLocalStorageField('token');

        const headers = new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + token
        });

        let options: {headers: HttpHeaders} = {headers: null};
        options.headers = headers;

        return this._http.get(ApiHelper.getMyUserDetailURL(), options);
    }

    public getLoggedUser(): User {
        return this.user;
    }
    public hasCredentials(): boolean {
        const email = SessionHelper.getLocalStorageField('email');
        const token = SessionHelper.getLocalStorageField('token');
        return (email !== undefined && email !== null)
            && (token !== undefined && token !== null);
    }
    public getAccess(): any {
      let access = SessionHelper.getLocalStorageField('access');
      if (access) {
        return JSON.parse(access);
      } else {
        return null;
      }
    }

    public setSession(data: any, token: any = null): void {
        SessionHelper.setLocalStorageField('id', data['id']);
        SessionHelper.setLocalStorageField('email', data['email']);
        if(token) {
          SessionHelper.setLocalStorageField('token', token['token']);
        }
        SessionHelper.setLocalStorageField('access', JSON.stringify(data['access']));
        SessionHelper.setLocalStorageField('token_expired', false);

        this.user = new User;
        this.user.id = data['id'];
        this.user.email = data['email'];
        this.user.name = data['name'];
        this.user.surname = data['surname'];
        this.user.img_url = data['img_url'];
        // this.user.profile  = data['profile'];
        this.user['access'] = data['access'];
        this.userEmitter.emit(this.user);
    }
    public getLang(): string {
        if (typeof localStorage !== 'undefined') {
            let lang = SessionHelper.getLocalStorageField('lang');
            if (lang === null) {
                lang = 'fr';
            }
            return lang;
        } else {
            return '';
        }
    }

    login(email: string, password: string): Observable<any> {
        const parameters = {
            'email': email,
            'password': password,
        };

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        });
        return this._http.post(ApiHelper.getLoginUrl(),
            parameters,
            {headers: headers}
        );
    }

    logout(): void {
        SessionHelper.clearLocalStorageField('token');
        SessionHelper.clearLocalStorageField('email');
        SessionHelper.clearLocalStorageField('id');
        SessionHelper.clearLocalStorageField('access');
        this.user = null;
        this.accessService.changeProfile(null);
        this.userEmitter.emit(null);
    }

    // Para solicitar un cambio de contraseña cuando esta ha sido olvidada
    passwordRecovery(resetPassword: ResetPassword) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        });


        let params = {
          'email': resetPassword.email,
        };

        return this._http.post(
            ApiHelper.getPasswordRecoveryUrl(),
            params,
            {
                headers: headers
            });
    }
    // Para hacer un cambio de contraseña
  changePassword(changePassword: ChangePassword) {
    let params = {
      'password': changePassword.password,
      'confirm_password': changePassword.confirm_password,
    };

    return this._http.post(ApiHelper.getChangePasswordUrl(), params);
  }

  // Para cambiar la contraseña tras una petición de cambio de contraseña por olvido
    passwordReset(resetChangePassword: ResetChangePassword, token: string) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        });
        let params = {
            'email': resetChangePassword.email,
            'password': resetChangePassword.password,
            'password_confirmation': resetChangePassword.password_confirmation,
            'token': token,
        };

        return this._http.post(
            ApiHelper.getPasswordResetUrl(),
            params,
            {
                headers: headers
            });
    }
    registration(user: User) {
        let params = {
            'email': user.email,
            'password': user.password,
            'name': user.name,
            'surname': user.surname,
        };

        params = Params.cleanParams(params);
        const headers = new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + ApiHelper.getToken()
        });
        return this._http.post(
            ApiHelper.getRegistrationUrl(),
            params,
            {headers: headers}
        );
    }

  public userPasswordResetByAdmin(user_id: any): Observable<Object> {
    return this._http.get(ApiHelper.getUserPasswordResetByAdminUrl(user_id));
  }

  showLoginModal() {
      this.showModalLoginEmmiter.emit();
  }

}
