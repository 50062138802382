import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Image } from '../../../models/image';
import { ApiConfigHelper } from '../../../../../bloonde-ngx-template/src/api/api-config.helper';

@Component({
  selector: 'bl-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.css']
})
export class GalleryImageComponent implements OnInit {

  @Output() editEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() trashEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() mainEventEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  public image: Image;

  @Input()
  public config: {};

  constructor() { }

  ngOnInit() {
  }

  public onEdit(): void {
    this.editEventEmitter.emit(this.image.id);
  }
  public onTrash(): void {
    this.trashEventEmitter.emit(this.image.id);
  }
  public onSetMain(): void {
    this.mainEventEmitter.emit(this.image.id);
  }
  getImage(url: any) {
    return ApiConfigHelper.IMAGE_FOLDER + url;
  }
}
