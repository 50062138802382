import { Component} from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']

})
export class PasswordComponent extends AbstractInputComponent {

}
