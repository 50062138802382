import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AbstractService} from 'bloonde-ngx-template/src/api/services/abstract.service';
import {ListRequestData} from 'bloonde-ngx-template/src/api/tools/list-request-data';
import {Observable} from 'rxjs/index';
import {QueryParamsHelper} from 'bloonde-ngx-template/src/api/tools/query-params.helper';
import {ApiRoutesHelper} from '../helpers/api-routes.helper';


@Injectable()
export class LanguageService extends AbstractService {

  constructor(public _http: HttpClient) {
    super('language', null, _http);
  }

  selectList(listRequestData: ListRequestData): Observable<any> {
    let query_params = {};
    query_params = QueryParamsHelper.addFilters(query_params, listRequestData.filter);
    query_params = QueryParamsHelper.addOrders(query_params, listRequestData.order);
    return this._http.get(ApiRoutesHelper.getLanguageSelectListURL(), {params: query_params});
  }
}
