import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SectorService } from '../../../services/sector.service';
import { ResponseHelper } from '../../../../projects/bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from '../../../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { Sector } from '../../../models/sector';

@Component({
  selector: '[app-sector-filter],app-sector-filter',
  templateUrl: './sector-filter.component.html',
  styleUrls: ['./sector-filter.component.sass']
})
export class SectorFilterComponent implements OnInit {

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  @Input()
  public filter: any;

  sectors: Sector[];

  @Output()
  modelChange = new EventEmitter<string>();

  public modelValue: string;

  public loading: boolean;

  public options: {
    name: string,
    placeholder: string,
    options: any[]
  } = {name: 'Sector', placeholder: 'Sector', options: []};

  constructor(private sectorService: SectorService,
              private responseHelper: ResponseHelper) { }
  ngOnInit() {
    this.list();
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    const listRequestData = new ListRequestData(null, [], []);
    this.sectorService.selectList(listRequestData)
      .subscribe(
        res => {
          this.sectors = <Sector[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for (let i in this.sectors) {
      let sector = this.sectors[i];
      options.push({
        value: sector.id,
        label: sector.name,
      });
    }
    this.options['options'] = options;
  }
}
