import { InputFormDirective } from './directives/input-form.directive';
import { ComponentFactoryResolver, EventEmitter, Input, Output, QueryList, SimpleChanges } from '@angular/core';
import { InputTextComponent } from '../inputs/input-text/input-text.component';
import { LabelFormDirective } from './directives/label-form.directive';
import { LabelComponent } from '../inputs/label/label.component';
import {PasswordComponent} from '../inputs/password/password.component';
import {RadioButtonComponent} from '../inputs/radio-button/radio-button.component';
import { ValidationService } from './services/validation.service';
import { ResponseHelper } from '../responses/response.helper';
import { CodesHelper } from '../responses/codes.helper';
import { MessageService } from '../responses/message.service';
import {TextAreaComponent} from '../inputs/textarea/textarea.component';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { FloatComponent } from '../inputs/float/float.component';
import { NoteComponent } from '../inputs/note/note.component';
import { FormActionsHelper } from './helpers/form-actions.helper';
import { AbstractFormComponent } from './abstract-form.component';
export abstract class AbstractInputFormComponent extends AbstractFormComponent{
  @Input()
  public inputStructure: any;

  @Input()
  public prefix: string;

  @Input()
  public mode = 'edition';

  @Input()
  public disabled: string;

  @Input()
  get model() {
    return this.modelValue;
  }
  @Output()
  modelChange = new EventEmitter<any>();

  public modelValue: any;

  constructor() {
    super();
  }

  ngOnInit() {
    if(this.inputStructure && this.inputStructure.autoload !== false) {
      if(this.modelValue && this.modelValue.id) {
        this.editWithModel(this.modelValue, []);
      } else {
        this.new([]);
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue) {
      this.inputStructure = changes.options.currentValue;
    }
  }

  set model(model) {
    this.modelValue = model;
    this.modelChange.emit(this.modelValue);
  }
}
