import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.css']
})
export class FormButtonsComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean, delete: boolean} = {cancel: true, save: true, save_and_new: false, save_and_edit: false, delete: false};
  @Input() currentAction: string;
  @Input() buttonsText: {cancelText: string, saveText: string, saveAndNewText: string, saveAndEditText: string, deleteText: string} = {cancelText: null, saveText: null, saveAndNewText: null, saveAndEditText: null, deleteText: null};

  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  public saveAndCloseEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  public saveAndContinueEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  public saveAndEditEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  public updateAndCloseEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  public confirmEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  public deleteEmitter: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  saveAndClose(): void {
    this.saveAndCloseEmitter.emit();
  }
  saveAndContinue(): void {
    this.saveAndContinueEmitter.emit()
  }
  saveAndEdit(): void {
    this.saveAndEditEmitter.emit()
  }
  updateAndClose(): void {
    this.updateAndCloseEmitter.emit();
  }
  confirm(): void {
    this.confirmEmitter.emit();
  }
  onClose(){
    this.closeEmitter.emit();
  }
  onDelete(){
    this.deleteEmitter.emit();
  }

}
