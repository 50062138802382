import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { ListRequestData } from '../../../bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../../bloonde-ngx-template/src/api/tools/query-params.helper';
import { Image } from '../models/image';


@Injectable()
export class GalleryPhotoService {

  constructor(public _http: HttpClient) {
  }

  get(id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getPhotoURL(id));
  }

  list(listRequestDate: ListRequestData): Observable<Object> {
    let query_params = {};
    query_params = QueryParamsHelper.addPagination(query_params, listRequestDate.currentPage);
    query_params = QueryParamsHelper.addFilters(query_params, listRequestDate.filter);
    query_params = QueryParamsHelper.addOrders(query_params, listRequestDate.order);
    query_params = QueryParamsHelper.addSelects(query_params, listRequestDate.select);
    query_params = QueryParamsHelper.addItemsPerPage(query_params, listRequestDate.itemsPerPage);
    return this._http.get(ApiRoutesHelper.getPhotoListURL(), {params: query_params});
  }

  store(image: Image): Observable<Object> {
    const params = this.buildParams(image);
    return this._http.post(ApiRoutesHelper.getPhotoCreateURL(), params);
  }

  update(image: Image): Observable<Object> {
    const params = this.buildParams(image);
    return this._http.put(ApiRoutesHelper.getPhotoUpdateURL(image.id), params);
  }

  /**
   * Para borrar una imagen de bd
   * @param id
   * @returns {Observable<Object>}
   */
  delete(id: any): Observable<Object> {
    return this._http.delete(ApiRoutesHelper.getPhotoDeleteURL(id));
  }

  main(id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getPhotoMainURL(id));
  }

  private buildParams(image: Image): any {
    let fields = ['name', 'description', 'path'];
    const params = {
    };
    fields.forEach(function(field) {
      params[field] = image[field];
    });
    return params;
  }
}
