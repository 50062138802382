import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'bloonde-ngx-template/src/session/services/session.service';
import { ThemeService } from '../services/theme.service';

declare var $;
declare var FUNCTIONS;
declare var SEMICOLON;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ng-personae-admin';

  constructor(translate: TranslateService,
              private themeService: ThemeService,
              private sessionService: SessionService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');
    // registerLocaleData(localeEs);
  }

  ngOnInit() {
    var component = this;
    this.sessionService.userEmitter.subscribe(function(data) {
      component.resetJS();
    });
    this.themeService.resetEventEmitter.subscribe(function(data) {
      if (data) {
        component.resetJS();
      }
    });
  }

  ngAfterContentInit(): void {
    this.resetJS();
  }
  resetJS(): void {
    setTimeout(function() {
      if ($('#primary-menu > ul').hasClass('sf-js-enabled')) {
        $('#primary-menu > ul').superfish('destroy');
      }
      if (FUNCTIONS) {
        FUNCTIONS();
      }
      $('#primary-menu > ul').superfish();
      if (SEMICOLON) {
        SEMICOLON.documentOnReady.init();
      }
    }, 1000 * 2);
  }
}
