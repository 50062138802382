import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[app-label],app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {

  public if_condition = null;

  @Input()
  public structure: any[];

  @Output()
  actionEmitter: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.if_condition = !this.if_condition ? (this.structure['init_if_condition'] != null ? this.structure['init_if_condition'] : true) : true;
  }
  isRequired(): boolean {
    return this.structure['rules'] && (this.structure['rules'].indexOf("required") > -1 || this.hasRule('required_if'));
  }
  hasRule(rule: string): boolean {
    let result = false;
    this.structure['rules'].forEach(function(rule){
      if(rule.indexOf('required_if') > -1) {
        result = true;
      }
    });
    return result;
  }
  onAction(): void {
    this.actionEmitter.emit(this.structure['action']['event']);
  }

}
