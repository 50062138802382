import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

// Helpers
import { Observable, Observer } from 'rxjs/Rx';
import { AccessService } from '../services/access.service';



@Injectable()
export class PrivilegesResolver implements Resolve<any> {
    constructor(private accessService: AccessService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
          this.accessService.getPrivileges()
            .subscribe(
              res => {
                this.accessService.setPrivileges(res);
                observer.next(res);
                observer.complete();
              },
              error => {
                observer.next(null);
                observer.complete();
              }
            );
        });
    }
}
