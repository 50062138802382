import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageListItem } from '../../../models/listings/message_list_item';
import { ChatMessageService } from '../../../services/chat-message.service';
import { MessageFormComponent } from '../../forms/message-form/message-form.component';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import { ApiHelper } from 'bloonde-ngx-template/src/session/helpers/api.helper';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import {ChatService} from '../../../services/chat.service';
import {Chat} from '../../../models/chat';

@Component({
  selector: '[app-message-management]',
  templateUrl: './message-management.component.html',
  styleUrls: ['./message-management.component.sass']
})
export class MessageManagementComponent implements OnInit, OnDestroy {

  @ViewChild(MessageFormComponent) messageFormComponent;

  public custom_chat_id: any;
  public chat: Chat;
  messages: MessageListItem[];
  @Input() type: string = 'candidate';
  request: boolean;

  constructor(
    private chatMessageService: ChatMessageService,
    private chatService: ChatService,
    private responseHelper: ResponseHelper,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.custom_chat_id = this.route.snapshot.paramMap.get('chat_id');
    this.getChat();
    this.list();
    this.request = true;
  }
  ngOnDestroy() {
    this.request = false;
  }

  successCreation($event): any {
    this.list(false);
    this.messageFormComponent.new('create');
  }
  getChat(): void {
    this.chatService.get(this.custom_chat_id).subscribe(
      res => {
        this.chat = res;
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }

  list(withTimeOut = true): void {
    let filter = {
      chat_id: this.custom_chat_id
    }
    const listRequestData = new ListRequestData(null, filter, null);
    this.chatMessageService.list(listRequestData).subscribe(
      res => {
        this.messages = <MessageListItem[]> res['elements'];
        this.markAsRead();
        if (withTimeOut) {
          var component = this;
          if (this.request) {
            setTimeout(function(){
              component.list();
            }, 1000 * 15);
          }
        }
      },
      error => {
        this.responseHelper.handleError(error);
      }
    );
  }
  markAsRead(): void {
    let data = {message_ids: []};
    for (let message of this.messages) {
      if (!message.read &&
        (this.isEvaluatorPanel() && this.isDirEvalutaorToCandidate(message)) || (this.isCandidatePanel() && this.isDirCandidateToEvaluator(message))) {
        data.message_ids.push(message.id);
      }
    }
    if (data.message_ids.length > 0) {
      this.chatMessageService.markAsRead(data).subscribe(
        res => {
        },
        error => {
        }
      );
    }
  }

  isEvaluatorPanel(): boolean {
    let res =  this.type == ChatMessageService.TYPE_EVALUATOR;
    return res;
  }
  isCandidatePanel(): boolean {
    let res =  this.type == ChatMessageService.TYPE_CANDIDATE;
    return res;
  }
  isDirEvalutaorToCandidate(message: MessageListItem): boolean {
    let res = message.dir == ChatMessageService.DIR_EVALUATOR_TO_CANDIDATE;
    return res;
  }
  isDirCandidateToEvaluator(message: MessageListItem): boolean {
    let res = message.dir == ChatMessageService.DIR_CANDIDATE_TO_EVALUATOR;
    return res;
  }

  downloadFile(file_id: any) {
    let token = ApiHelper.getToken();
    window.open(ApiRoutesHelper.FILE_FOLDER + file_id + '?token=' + token,'_blank');
  }
}
