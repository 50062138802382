import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {AbstractInputComponent} from '../abstract-input.component';
import {ApiHelper} from '../../session/helpers/api.helper';
import {MessageService} from '../../responses/message.service';

@Component({
  selector: 'app-file-multiple',
  templateUrl: './file-multiple.component.html',
  styleUrls: ['./file-multiple.component.css']
})
export class FileMultipleComponent extends AbstractInputComponent implements OnInit {

  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Output() success: EventEmitter<any> = new EventEmitter();

  @Output() error: EventEmitter<any> = new EventEmitter();

  public uploader;

  constructor(private messageService: MessageService) {
    super();
  }

  ngOnInit() {
    this.new();
    this.uploader.onCompleteItem = (item, response, status, header) => {
      if (status === 200) {
        const res = JSON.parse(response);
        this.model.push(res);
        this.success.emit();
      } else {
        const res = JSON.parse(response);
        this.error.emit(res['file'][0]);
        this.messageService.showErrorMessage(res['file'][0]);
      }
    };
  }

  new()  {
    let token = ApiHelper.getToken();
    this.uploader = new FileUploader({url: this.structure.url, authToken: 'Bearer ' + token, autoUpload: true});
  }

  onDelete(file_id: any) {
    this.delete.emit(file_id);
  }

  deleteFile(file_id: any) {
    for (let i in this.model) {
      if (this.model[i]['id'] == file_id) {
        this.model.splice(i, 1);
        break;
      }
    }
  }
  downloadFile(file_id: any) {
    let token = ApiHelper.getToken();
    window.location.href = this.structure.download_url + file_id + '?token=' + token;
  }

}
