import { Component, ComponentFactoryResolver, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ResetPassword } from '../../../models/reset-password';
import { InputFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from '../../../../../bloonde-ngx-template/src/forms/services/validation.service';
import { SessionService } from '../../../../../bloonde-ngx-template/src/session/services/session.service';
import { ResponseHelper } from '../../../../../bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../../bloonde-ngx-template/src/responses/message.service';
import { NavigationHelper } from '../../../../../bloonde-ngx-template/src/navigation/navigation.helper';
import { ResetPasswordFormConfiguration } from './reset-password-form-configuration';
import { AbstractFormComponent } from '../../../../../bloonde-ngx-template/src/forms/abstract-form.component';
import { CodesHelper } from '../../../../../bloonde-ngx-template/src/responses/codes.helper';

@Component({
  selector: 'bl-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent extends AbstractFormComponent implements OnInit {

  public resetPassword: ResetPassword;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private sessionService: SessionService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private navigationHelper: NavigationHelper,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = ResetPasswordFormConfiguration.structure;
  }
  ngOnInit() {
    this.new();
  }
  public passwordRecovery(args: any[]): void {
    const validate = this.checkForm();
    this.emitError();
    if (validate) {
      this.getModelService().passwordRecovery(this.getModel()).subscribe(
        res => {
          this.resetErrors(); // Reseteamos los errores
          this.new();
          this.success();
          this.getMessageService().showSuccessPasswordRecoveryMessage();
        },
        error => {
          if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
            this.serverErrors = error.error;
            this.emitServerError();
          } else {
            this.getResponseHelper().handleError(error);
          }
        }
      );
    }
  }

  public new(): void {
    this.resetPassword = new ResetPassword();
    this.buildErrorModelAndEmitters();
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.resetPassword;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }
  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.sessionService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.resetPassword= model;
  }

}
