export class ResetChangePassword {
    public email: string;
    public password: string;
    public password_confirmation: string;

  constructor() {
    this.email = null;
    this.password = null;
    this.password_confirmation = null;
  }
}
