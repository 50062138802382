import { environment } from '../../../../src/environments/environment';
export class ApiConfigHelper {
  public static ANGULAR_HOST =  environment.angularHost ? environment.angularHost : 'http://localhost:4200';
  public static HOST = environment.laravelHost ? environment.laravelHost : 'http://localhost/demo_angular_laravel/demo-laravel/public/';
  public static API = environment.hasOwnProperty('apiPrefix') ? environment['apiPrefix'] : 'api/';
  public static VERSION = 'v1/';
  public static IMAGE_FOLDER = ApiConfigHelper.HOST + 'img/';
  public static STORAGE_IMAGE_FOLDER = ApiConfigHelper.HOST + 'storage/';
  public static FILE_FOLDER = ApiConfigHelper.HOST + 'file/';

  public static PROJECT_NAME = environment.projectName ? environment.projectName : '';


  public static getAPIURL(): string {
    return ApiConfigHelper.HOST + ApiConfigHelper.API + ApiConfigHelper.VERSION;
  }
  public static getURL(): string {
    return ApiConfigHelper.HOST;
  }
  /*
   Translation
   */
  public static getTranslationURL(lang): string {
    return ApiConfigHelper.getAPIURL() + 'translation/' + lang;
  }


  public static buildURL(model: string, id: any = null, abstract: boolean = false, option: string = null, bulk = false) {
    let url = model;
    if(bulk) {
      url = url + '/bulk'
    }
    if (id) {
      url = url + '/' + id;
    }
    if (abstract) {
      url = 'abstract/' + url;
    }
    if (option) {
      if (option === 'select') {
        url = url + '/filter';
      }
    }
    return ApiConfigHelper.getAPIURL() + url;
  }
}
