import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationHelper } from '../../../../bloonde-ngx-template/src/navigation/navigation.helper';
import { ActivatedRoute } from '@angular/router';
import { ResetChangePasswordModalComponent } from '../../components/modals/reset-change-password-modal/reset-change-password-modal.component';

@Component({
  selector: 'app-reset-change-password-page',
  templateUrl: './reset-change-password-page.component.html',
  styleUrls: ['./reset-change-password-page.component.css']
})
export class ResetChangePasswordPageComponent implements OnInit {

  @ViewChild(ResetChangePasswordModalComponent) resetChangePasswordModalComponent;
  public token: string;
  public from_app: boolean;
  private sub: any;

  constructor(private navigationHelper: NavigationHelper,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.token = params['token']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
      if (this.token) {
        let component = this;
        setTimeout(function() {
          component.showResetChangePasswordModal();
        }, 1500);
      }
    });
    this.route.queryParams.subscribe(params => {
      this.from_app = params['from_app'];
    });
  }

  redirectToLogin(): void {
    this.navigationHelper.navigateTo('login');
  }
  showResetChangePasswordModal(): void {
    this.resetChangePasswordModalComponent.show('create');
  }

}
