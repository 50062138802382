import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { NgTemplateComponent } from './ng-template.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NavigationHelper } from '../navigation/navigation.helper';
import { MessageService } from '../responses/message.service';
import { ResponseHelper } from '../responses/response.helper';
import { ValidationService } from '../forms/services/validation.service';
import { BodyTableComponent } from '../tables/partials/body-table/body-table.component';
import { ThTableComponent } from '../tables/partials/th-table/th-table.component';
import { TdTableComponent } from '../tables/partials/td-table/td-table.component';
import { HeaderTableComponent } from '../tables/partials/header-table/header-table.component';
import { FiltersDirective } from '../filters/directives/filters.directive';
import { InputFormDirective } from '../forms/directives/input-form.directive';
import { LabelFormDirective } from '../forms/directives/label-form.directive';
import { HeaderPanelPipe } from '../tables/pipes/header-panel.pipe';
import { LabelComponent } from '../inputs/label/label.component';
import { InputTextComponent } from '../inputs/input-text/input-text.component';
import { PasswordComponent } from '../inputs/password/password.component';
import { RadioButtonComponent } from '../inputs/radio-button/radio-button.component';
import { SearchComponent } from '../tables/components/search/search.component';
import { UserLoggedResolver } from '../session/resolvers/user-logged.resolver';
import { AuthGuard } from '../session/guards/auth-guard';
import { SessionService } from '../session/services/session.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { TextAreaComponent } from '../inputs/textarea/textarea.component';
import { ReadOnlyComponent } from '../inputs/read-only/read-only.component';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { SelectComponent } from '../inputs/select/select.component';
import { RegistrationFormService } from '../../../ngx-bloonde-user-and-privileges/src/components/forms/registration-form/registration-form.service';
import { ApiHelper } from '../session/helpers/api.helper';
import { RegistrationService } from '../session/services/registration.service';
import { FileComponent } from '../inputs/file/file.component';
import { FileDropDirective, FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { FileMultipleComponent } from '../inputs/file-multiple/file-multiple.component';
import { BoxContentComponent } from '../box-content/box-content.component';
import { FormModalComponent } from '../modals/form-modal/form-modal.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiRoutesHelper } from '../../../ngx-bloonde-user-and-privileges/src/helpers/api-routes.helper';
import { LaravelTranslationLoader } from '../translation/laravelTranslationLoader';
import { FloatComponent } from '../inputs/float/float.component';
import { NoteComponent } from '../inputs/note/note.component';
import { FormButtonsComponent } from '../forms/components/form-buttons/form-buttons.component';
import { DateComponent } from '../inputs/date/date.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WysiwygComponent } from '../inputs/wysiwyg/wysiwyg.component';
import { InnerHtmlComponent } from '../inputs/inner-html/inner-html.component';
import { H1Component } from '../inputs/h1/h1.component';
import { H2Component } from '../inputs/h2/h2.component';
import { ImgComponent } from '../inputs/img/img.component';
import { TableComponent } from '../tables/partials/table/table.component';
import { TrTableComponent } from '../tables/partials/tr-table/tr-table.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { EditorModule } from '@tinymce/tinymce-angular';
import { UserLoggedNoRedirectResolver } from '../session/resolvers/user-logged-no-redirect.resolver';
import {DragDropDirective} from '../directives/dragdrop.directive';
import {DragDropComponent} from '../inputs/drag-drop/drag-drop.component';
import {FixedTableComponent} from '../tables/partials/fixed-table/fixed-table.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new LaravelTranslationLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory, // Change for TranslateLoaderFactory to get translation from API.
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    FormsModule,
    NgSelectModule,
    FileUploadModule,
    NgbModule,
    LazyLoadImageModule,
    EditorModule,
  ],
  providers: [
    NavigationHelper,
    MessageService,
    ResponseHelper,
    ValidationService,
    SessionService,
    AuthGuard,
    UserLoggedResolver,
    UserLoggedNoRedirectResolver,
    ApiHelper,
    RegistrationService,
  ],
  declarations: [
    NgTemplateComponent,
    BodyTableComponent,
    ThTableComponent,
    TdTableComponent,
    HeaderTableComponent,
    TableComponent,
    TrTableComponent,
    FiltersDirective,
    InputFormDirective,
    LabelFormDirective,
    HeaderPanelPipe,
    NavbarComponent,
    SidebarComponent,
    LabelComponent,
    InputTextComponent,
    PasswordComponent,
    RadioButtonComponent,
    SearchComponent,
    TextAreaComponent,
    SelectComponent,
    ReadOnlyComponent,
    CheckboxComponent,
    FileComponent,
    FileMultipleComponent,
    BoxContentComponent,
    FormModalComponent,
    FloatComponent,
    NoteComponent,
    FormButtonsComponent,
    DateComponent,
    WysiwygComponent,
    InnerHtmlComponent,
    H1Component,
    H2Component,
    ImgComponent,
    DragDropDirective,
    DragDropComponent,
    FixedTableComponent
  ],
  exports: [
    NgTemplateComponent,
    BrowserModule,
    BrowserAnimationsModule,
    BodyTableComponent,
    HeaderTableComponent,
    TableComponent,
    TrTableComponent,
    NavbarComponent,
    SidebarComponent,
    LabelComponent,
    InputTextComponent,
    PasswordComponent,
    RadioButtonComponent,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    InputFormDirective,
    LabelFormDirective,
    FiltersDirective,
    SearchComponent,
    TextAreaComponent,
    SelectComponent,
    ReadOnlyComponent,
    CheckboxComponent,
    FileComponent,
    FileMultipleComponent,
    FileUploadModule,
    BoxContentComponent,
    FormModalComponent,
    FormButtonsComponent,
    WysiwygComponent,
    InnerHtmlComponent,
    H1Component,
    H2Component,
    ImgComponent,
    DragDropComponent,
    FixedTableComponent,
    NgxPaginationModule
  ],
  entryComponents: [
    LabelComponent,
    InputTextComponent,
    PasswordComponent,
    RadioButtonComponent,
    SearchComponent,
    TextAreaComponent,
    ReadOnlyComponent,
    CheckboxComponent,
    FileComponent,
    FileMultipleComponent,
    FloatComponent,
    NoteComponent,
    DateComponent,
    WysiwygComponent,
    InnerHtmlComponent,
    H1Component,
    H2Component,
    ImgComponent,
    DragDropComponent
  ]
})
export class NgTemplateModule {
  static forRoot(service: Provider): ModuleWithProviders {
    // User config get logged here
    return {
      ngModule: NgTemplateModule,
      providers: [RegistrationFormService, {provide: 'service', useValue: service}]
    };
  }
}

