import { ApiConfigHelper } from '../../api/api-config.helper';
import { SessionHelper } from './session.helper';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiHelper {

  public static registration_url = 'registration';

  public static getLoginUrl(): string {
    return ApiConfigHelper.getAPIURL() + 'login';
  }

  public static getPasswordRecoveryUrl(): string {
    return ApiConfigHelper.getAPIURL() + 'passwordRecovery';
  }

  public static getPasswordResetUrl(): string {
    return ApiConfigHelper.getAPIURL() + 'passwordReset';
  }

  public static getUserPasswordResetByAdminUrl(user_id: any): string {
    return ApiConfigHelper.getAPIURL() + 'user/resetUserPassword/' + user_id;
  }
  public static getChangePasswordUrl(): string {
    return ApiConfigHelper.getAPIURL() + 'changePassword';
  }

  public static getRegistrationUrl(): string {
    return ApiConfigHelper.getAPIURL() + ApiHelper.registration_url;
  }

  public static getToken(): string {
    if (typeof localStorage !== 'undefined') {
      return SessionHelper.getLocalStorageField('token');
    } else {
      return '';
    }
  }

  public static getMyUserDetailURL(): string {
    return ApiConfigHelper.getAPIURL() + 'user/me';
  }

  public static configRegistrationUrl(url: string): void {
    ApiHelper.registration_url = url;
  }
}
