import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { ListRequestData } from '../../projects/bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from '../../projects/bloonde-ngx-template/src/api/tools/query-params.helper';
import { Candidate } from '../models/candidate';
import { AbstractService } from 'bloonde-ngx-template/src/api/services/abstract.service';
import { ChatFormConfiguration } from '../components/forms/chat-form/chat-form-configuration';

@Injectable()
export class ChatService extends AbstractService {

  constructor(public _http: HttpClient) {
    super('custom_chat', ChatFormConfiguration, _http);
  }

  openChat(id: any): Observable<object> {
    return this._http.get(ApiRoutesHelper.getOpenChatUrl(id));
  }

}
