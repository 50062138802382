import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable, Observer} from "rxjs/index";
import { OfferService } from '../services/offer.service';
import { Offer } from '../models/offer';
import { CandidateService } from '../services/candidate.service';
import { Candidate } from '../models/candidate';

@Injectable()
export class CandidateResolver implements Resolve<any> {
  candidate_id: string;
  constructor(private candidateService: CandidateService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.candidate_id = route.params['id'];
      if (this.candidate_id) {
        this.candidateService.get(this.candidate_id)
          .subscribe(
            res => {
              observer.next(res);
              observer.complete();
            },
            error => {
              observer.next(null);
              observer.complete();
            }
          );
      } else {
        let candidate = new Candidate();
        observer.next(candidate);
        observer.complete();
      }
    });
  }
}
