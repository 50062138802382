import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractFormModalComponent } from '../../../../../bloonde-ngx-template/src/modals/abstract-form-modal/abstract-form-modal.component';
import { GalleryImageFormComponent } from '../../forms/gallery-image-form/gallery-image-form.component';
import { MultipleGalleryImageFormComponent } from '../../forms/multiple-gallery-image-form/multiple-gallery-image-form.component';

@Component({
  selector: 'bl-gallery-image-modal',
  templateUrl: './gallery-image-modal.component.html',
  styleUrls: ['./gallery-image-modal.component.css']
})
export class GalleryImageModalComponent extends AbstractFormModalComponent implements OnInit {

  @Input() buttonsConfig: {cancel: boolean, save: boolean, save_and_new: boolean, save_and_edit: boolean} = {cancel: true, save: true, save_and_new: false, save_and_edit: false};

  @ViewChild(GalleryImageFormComponent) galleryImageFormComponent;
  @ViewChild(MultipleGalleryImageFormComponent) multipleGalleryImageFormComponent;

  @Input()
  public config: {type: any, fk_id: any, multiple: boolean};

  constructor() {
    super();
  }

  ngOnInit() {}
  public getFormComponent(): any {
    if (this.config.multiple) {
      return this.multipleGalleryImageFormComponent;
    } else {
      return this.galleryImageFormComponent;
    }
  }

}
