import { StatusSelectComponent } from '../../inputs/status-select/status-select.component';
import { DateComponent } from 'bloonde-ngx-template/src/inputs/date/date.component';
import { OfferSelectComponent } from '../../inputs/offer-select/offer-select.component';
export class OffersCandidatesFormConfiguration {
  public static structure = [
    {
      name: 'offer_id',
      label: 'offer',
      placeholder: 'Select an offer',
      type: OfferSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      disabled: true
    },
    {
      name: 'registration_date',
      label: 'registration_date',
      placeholder: null,
      type: DateComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      disabled: true
    },
    {
      name: 'status_id',
      label: 'Status',
      placeholder: 'Select a status',
      type: StatusSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      model: 'offer_candidate'
    },
    {
      name: 'discard_information',
      label: 'discard_information',
      placeholder: null,
      type: 'text',
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      init_if_condition: true,
    },
  ];
}
