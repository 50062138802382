
import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CodesHelper } from './codes.helper';
import { TextsHelper } from './texts.helper';

declare var Noty: any;

@Injectable()
export class MessageService {

    public static INVALID_TOKEN_MESSAGE = 'Sus credenciales no son correctas';
    public static SUCCESS_MESSAGE_KEY = 'success_message';
    public static WIZARD_ERROR_MESSAGE = 'There are some errors yet';

    // public success_message = 'success_message';
    public success_message = 'Guardado correctamente';
    public success_registration_message = 'success_registration_message';
    public success_password_recovery_message = 'success_password_recovery_message';
    public success_change_password_message = 'success_change_password_message';

    constructor(private translate: TranslateService) {
        // translate.get(MessageService.SUCCESS_MESSAGE_KEY).subscribe((res: string) => {
        //     this.success_message = res;
        // });
    }

    public showServerErrorMessage(message): void {
        if (message.status === CodesHelper.FAILED_VALIDATOR_CODE) {

          if (message._body) {
            let messages = '';
            const json = JSON.parse(message._body);
            for (let i = 0; i < json.length; i++) {
              messages = messages + json[i] + ' ';
            }
            this.showErrorMessage(messages);
          } else if (message.error) {
            const service = this;
            Object.keys(message.error).forEach(function(key) {
              for (let error of message.error[key]) {
                service.showErrorMessage(error);
              }
            });
          }
        } else if (message.status === CodesHelper.DISALLOW_DELETE){
          this.showErrorMessage(message.error);
        }else if (message.status === CodesHelper.NO_PERMISSIONS || message.status === CodesHelper.NOT_FOUND
                || message.status === CodesHelper.SERVER_ERROR_CODE) {
          this.showErrorMessage(this.getTextByStatus(message.status));
        } else {
            this.showErrorMessage(message.error);
        }
    }
    public showErrorMessage(message: any): void {
      if (message['errors'] || message['error']) {
        let service = this;
        let errors = message['errors'];
        if (!errors && message['error']) {
          errors = message;
        }
        if (typeof Noty !== 'undefined') {
          Object.keys(errors).forEach(function(key) {
            new Noty(
              {
                type: 'error',
                timeout: 5000,
                theme: 'relax',
                text: errors[key],
              }
            ).show();
          });
        }
      } else {
        if (typeof Noty !== 'undefined') {
          this.translate.get(message, {}).subscribe((res: string) => {
            new Noty(
              {
                type: 'error',
                timeout: 5000,
                theme: 'relax',
                text: res,
              }
            ).show();
          });
        }
      }

    }
    public showSuccessRegistrationMessage(): void {
      this.showSuccessMessage(this.success_registration_message);
    }
    public showSuccessPasswordRecoveryMessage(): void {
      this.showSuccessMessage(this.success_password_recovery_message);
    }
    public showSuccessChangePasswordMessage(): void {
      this.showSuccessMessage(this.success_change_password_message);
    }
    public showSuccessMessage(message: string = null): void {
        if (!message) {
          message = this.success_message;
        }
        if (typeof Noty !== 'undefined') {
          this.translate.get(message, {}).subscribe((res: string) => {
            new Noty(
              {
                type: 'success',
                timeout: 5000,
                theme: 'relax',
                text: res,
              }
            ).show();
          });
        }
    }
    public showWizardErrorMessage(): void {
        let message = MessageService.WIZARD_ERROR_MESSAGE;
        if (typeof Noty !== 'undefined') {
          new Noty(
            {
              type: 'error',
              timeout: 5000,
              theme: 'relax',
              text: message,
            }
          ).show();
        }
    }
    public handlerError(code, message) {
        if (code === CodesHelper.INVALID_TOKEN) {
            message = MessageService.INVALID_TOKEN_MESSAGE;
        }
        this.showErrorMessage(message);
    }

    public getTextByStatus(status: string): string {
        return TextsHelper.texts[this.getLanguage()][status];
    }
    private getLanguage(): string {
        return 'en';
    }
}
