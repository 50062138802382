import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfferCandidateService } from '../../../services/offer-candidate.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';

@Component({
  selector: 'app-offer-candidate-select',
  templateUrl: './offer-candidate-select.component.html',
  styleUrls: ['./offer-candidate-select.component.sass']
})
export class OfferCandidateSelectComponent implements OnInit {

  @Input()
  multiple: boolean;
  @Input()
  addTag: boolean;
  @Input()
  error: any;
  @Input()
  serverError: any;

  offerCandidates: any[];
  offer_id: any;

  @Input()
  get model() {
    return this.modelValue;
  }
  @Output()
  modelChange = new EventEmitter<any>();

  public modelValue: string;


  public structure: any;

  constructor(private offerCandidateService: OfferCandidateService,
              private responseHelper: ResponseHelper) { }

  ngOnInit() {
    if (this.structure.autoload !== false) {
      this.list();
    }
  }

  set model(model) {
    if (this.modelValue != model) {
      this.modelValue = model;
      this.modelChange.emit(this.modelValue);
    }
  }

  list(): void {
    let filter: any = [];
    if (this.offer_id) {
      filter = {offer_id: this.offer_id};
    }
    const listRequestData = new ListRequestData(null, filter, []);
    this.offerCandidateService.selectList(listRequestData)
      .subscribe(
        res => {
          this.offerCandidates = <any[]> res;
          this.prepareOptions();
        },
        error => {
          this.responseHelper.handleError(error);
        }
      );
  }

  private prepareOptions(): void {
    let options = [];
    for(let i in this.offerCandidates) {
      let offerCandidate = this.offerCandidates[i];
      options.push({
        value: offerCandidate.candidate_id,
        label: offerCandidate.candidate_name + ' ' + offerCandidate.candidate_surname,
      });
    }
    this.structure['options'] = options;
  }
}
