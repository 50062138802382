import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { ListRequestData } from 'bloonde-ngx-template/src/api/tools/list-request-data';
import { QueryParamsHelper } from 'bloonde-ngx-template/src/api/tools/query-params.helper';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { State } from '../models/state';


@Injectable()
export class StateService {

  constructor(public _http: HttpClient) {
  }

  get(state_id: any): Observable<any> {
    return this._http.get(ApiRoutesHelper.getStateURL(state_id));
  }

  list(listRequestData: ListRequestData): Observable<Object> {
    let query_params = QueryParamsHelper.addQueryParams(listRequestData); // Añadimos paginación, filtros, orden, selectores y número de items por pagina
    return this._http.get(ApiRoutesHelper.getStateListURL(), {params: query_params});
  }

  selectList(listRequestData: ListRequestData): Observable<Object> {
    let query_params = {};
    query_params = QueryParamsHelper.addFilters(query_params, listRequestData.filter);
    query_params = QueryParamsHelper.addOrders(query_params, listRequestData.order);
    return this._http.get(ApiRoutesHelper.getStateSelectListURL(), {params: query_params});
  }

  store(state: State): Observable<Object> {
    const params = this.buildParams(state);
    return this._http.post(ApiRoutesHelper.getStateCreateURL(), params);
  }

  update(state: State): Observable<Object> {
    const params = this.buildParams(state);
    return this._http.put(ApiRoutesHelper.getStateUpdateURL(state.id), params);
  }

  delete(state_id: any): Observable<Object> {
    return this._http.delete(ApiRoutesHelper.getStateDeleteURL(state_id));
  }

  private buildParams(state: State): any {
    let fields = ['name', 'country_id'];
    const params = {
    };
    fields.forEach(function(field) {
      params[field] = state[field];
    });
    return params;
  }

}
