import { ApiConfigHelper } from '../../../bloonde-ngx-template/src/api/api-config.helper';
export class ApiRoutesHelper {

  /* Image */

  public static getPhotoURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'photo/' + id;
  }
  public static getPhotoListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'photo';
  }
  public static getPhotoCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'photo';
  }
  public static getPhotoMultipleCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'photo/multiple';
  }
  public static getPhotoUpdateURL(id: any): string {
    return ApiConfigHelper.getAPIURL() + 'photo/' + id;
  }
  public static getPhotoMainURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'photo/main/' + id;
  }

  /**
   * Para borrar una imagen guardada ya en BD
   */
  public static getPhotoDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'photo/' + id;
  }

  /**
   * Para borrar una imagen no guardada aun en BD
   */
  public static getImageDeleteImageURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'image/delete/' + id;
  }

  public static getUploadURL(): string {
    return ApiConfigHelper.getAPIURL() + 'image/upload';
  }

}
