import { Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { InputFormDirective } from 'bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from 'bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from 'bloonde-ngx-template/src/forms/services/validation.service';
import { ChatService } from '../../../services/chat.service';
import { ResponseHelper } from 'bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from 'bloonde-ngx-template/src/responses/message.service';
import { AbstractFormComponent } from 'bloonde-ngx-template/src/forms/abstract-form.component';
import { ChatFormConfiguration } from './chat-form-configuration';
import { Chat } from '../../../models/chat';

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.sass']
})
export class ChatFormComponent  extends AbstractFormComponent implements OnInit, OnDestroy {

  @Input()
  public modelValue: Chat;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private chatService: ChatService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = ChatFormConfiguration.structure;
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    for(let i in this.subscribes) {
      let subscribe = this.subscribes[i];
      subscribe.unsubscribe();
    }
  }



  public new(): void {
    this.modelValue = new Chat();
    this.buildErrorModelAndEmitters();
  }

  public successResponseEdit(res): void {
    this.modelValue = Chat.copy(this.modelValue);
  }

  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }

  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.modelValue;
  }

  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }

  public getMessageService(): MessageService {
    return this.messageService;
  }

  public getModelService(): any {
    return this.chatService;
  }

  public getValidationService(): ValidationService {
    return this.validationService;
  }

  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }

  setModel(model: any): any {
    this.modelValue = model;
  }

  successResponseStore(res): void {
    this.modelValue = Chat.copy(res);
  }

  public success(): void {
    this.onSuccess.emit({id: this.modelValue.id});
  }

  public extendConstructForm(): void {

    /**
     * COMIENZO DETECCION DE CAMBIOS EN EL FORMULARIO
     */
    var component = this;
    this.subscribes.push(this.inputFormComponents['offer_id'].instance.modelChange.subscribe(function (value) { // Para detectar el cambio
      component.inputFormComponents['candidate_id'].instance.offer_id = value;
      component.inputFormComponents['candidate_id'].instance.list();
      component.setDisabledField('candidate_id', value === null);
      component.inputFormComponents['candidate_id'].instance.modelValue = null;
    }));
    /**
     * FIN DETECCION DE CAMBIOS EN EL FORMULARIO
     */
  }
}
