import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.css']
})
export class FormModalComponent implements OnInit {

  @Input()
  public modal_id;
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();
  @Input()
  public width = 600;
 @Input()
  public bodyExtraClass;

  constructor() { }

  ngOnInit() {
  }

  show(): void {
    this.openModal();
  }
  private openModal(): void {
    if (typeof $ !== 'undefined') {
      $('#' + this.modal_id).modal('show');
    }
  }
  private closeModal(): void {
    if (typeof $ !== 'undefined') {
      $.magnificPopup.close();
      this.onClose();
    }
  }
  onClose(){
    this.closeEmitter.emit();
  }
}
