import {ApiConfigHelper} from '../../projects/bloonde-ngx-template/src/api/api-config.helper';
export class ApiRoutesHelper {

  public static IMAGE_FOLDER = ApiConfigHelper.HOST + 'storage';
  public static FILE_FOLDER = ApiConfigHelper.HOST + 'storage';
  // /* Usuarios */
  //
  // public static getUserURL(user_id: string): string {
  //   return ApiConfigHelper.getAPIURL() + 'user/' + user_id;
  // }
  // public static getUserListURL(): string {
  //   return ApiConfigHelper.getAPIURL() + 'user';
  // }
  // public static getUserCreateURL(): string {
  //   return ApiConfigHelper.getAPIURL() + 'user';
  // }
  // public static getUserUpdateURL(user_id: number): string {
  //   return ApiConfigHelper.getAPIURL() + 'user/' + user_id;
  // }
  // public static getUserDeleteURL(user_id: string): string {
  //   return ApiConfigHelper.getAPIURL() + 'user/' + user_id;
  // }
  //
  // /**
  //  * Profile User
  //  */
  // public static getProfileUserURL(user_id: any): string { // El privilegio lo obtenemos a través del id del usuario y no de su id
  //   return ApiConfigHelper.getAPIURL() + 'profile_user/' + user_id;
  // }
  // public static getProfileUserCreateURL(): string {
  //   return ApiConfigHelper.getAPIURL() + 'profile_user';
  // }
  // public static getProfileUserUpdateURL(profile_user_id: number): string {
  //   return ApiConfigHelper.getAPIURL() + 'profile_user/' + profile_user_id;
  // }
  // public static getProfileSelectListURL(): string {
  //   return ApiConfigHelper.getAPIURL() + 'profile_user/filter';
  // }

  /**
   * Customers
   */
  public static getCandidateURL(candidate_id: any): string {
    return ApiConfigHelper.getAPIURL() + 'candidate/' + candidate_id;
  }
  public static getCandidateListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'candidate';
  }
  public static getCandidateCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'candidate';
  }
  public static getCandidateUpdateURL(candidate_id: number): string {
    return ApiConfigHelper.getAPIURL() + 'candidate/' + candidate_id;
  }
  public static getCandidateAnonymizeURL(candidate_id: string): string {
    return ApiConfigHelper.getAPIURL() + 'candidate/anonymize/' + candidate_id;
  }
  public static getCandidateDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'candidate/' + id;
  }
  public static getRequestCandidateDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'candidate/request/' + id;
  }


  /* Cities */

  public static getCityURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/city/' + id;
  }
  public static getCityListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/city';
  }
  public static getCitySelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'city/filter';
  }
  public static getCityCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/city';
  }
  public static getCityUpdateURL(id: number): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/city/' + id;
  }
  public static getCityDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/city/' + id;
  }


  /* States */

  public static getStateURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/state/' + id;
  }
  public static getStateListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/state';
  }
  public static getStateCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/state';
  }
  public static getStateUpdateURL(id: number): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/state/' + id;
  }
  public static getStateDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/state/' + id;
  }
  public static getStateSelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/state/filter';
  }

  /* Nationality */

  public static getNationalityURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/nationality/' + id;
  }
  public static getNationalityListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/nationality';
  }
  public static getNationalitySelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/nationality/filter';
  }
  public static getNationalityCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/nationality';
  }
  public static getNationalityUpdateURL(id: number): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/nationality/' + id;
  }
  public static getNationalityDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/nationality/' + id;
  }

  /* Images */

  public static getPhotoURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'photo/' + id;
  }
  public static getPhotoListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'photo';
  }
  public static getPhotoCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'photo';
  }
  public static getPhotoMultipleCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'photo/multiple';
  }
  public static getPhotoUpdateURL(id: number): string {
    return ApiConfigHelper.getAPIURL() + 'photo/' + id;
  }
  /**
   * Para borrar una imagen ya guardad en BD
   */
  public static getPhotoDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'photo/' + id;
  }
  public static getPhotoMainURL(id: any): string {
    return ApiConfigHelper.getAPIURL() + 'photo/main/' + id;
  }

  /**
   * Para borrar una imagen que aun no ha sido guarda en BD
   */
  public static getImageDeleteURL(path: any): string {
    return ApiConfigHelper.getAPIURL() + 'image/delete/' + path;
  }

  /* Offers */

  public static getOfferURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'offer/' + id;
  }
  public static getOfferListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'offer';
  }
  public static getOfferSelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'offer/filter';
  }
  public static getOfferCreateURL(): string {
    return ApiConfigHelper.getAPIURL() + 'offer';
  }
  public static getOfferUpdateURL(id: number): string {
    return ApiConfigHelper.getAPIURL() + 'offer/' + id;
  }
  public static getOfferPublishURL(id: number): string {
    return ApiConfigHelper.getAPIURL() + 'offer/publish/' + id;
  }
  public static getOfferUnpublishURL(id: number): string {
    return ApiConfigHelper.getAPIURL() + 'offer/unpublish/' + id;
  }
  public static getOfferDeleteURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'offer/' + id;
  }
  /* OfferCandidate */

  public static getOfferCandidateChangeStatusURL(id: any, status_id: any): string {
    return ApiConfigHelper.getAPIURL() + 'offer_candidate/changeStatus/' + id + '/' + status_id;
  }
  public static getOfferCandidateBulkChangeStatusURL(): string {
    return ApiConfigHelper.getAPIURL() + 'offer_candidate/bulkChangeStatus';
  }
  public static getOfferCandidateSelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'offer_candidate/filter';
  }
  public static getOfferCandidateApplyURL(): string {
    return ApiConfigHelper.getAPIURL() + 'offer_candidate/apply';
  }
  /* Position */

  public static getPositionURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/position/' + id;
  }
  public static getPositionListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/position';
  }
  public static getPositionSelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'position/filter';
  }

  /* Experience */
  public static getExperienceSelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/experience/filter';
  }

  /* Sector */

  public static getSectorURL(id: string): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/sector/' + id;
  }
  public static getSectorListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/sector';
  }
  public static getSectorSelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'sector/filter';
  }

  /* Messages */

  public static getMessageMarkAsReadURL(): string {
    return ApiConfigHelper.getAPIURL() + 'message/markAsRead';
  }

  /* Custom Chats */

  public static getOpenChatUrl(id: any): string {
    return ApiConfigHelper.getAPIURL() + 'custom_chat/open/' + id;
  }

  /* Language */

  public static getLanguageSelectListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'abstract/language/filter';
  }

  /* General */
  public static getStatusSelectListURL(model: string): string {
    return ApiConfigHelper.getAPIURL() + model + '/statusSelectList';
  }
  public static getSelectSelectListURL(file: string): string {
    return ApiConfigHelper.getAPIURL() + 'select/' + file + '/selectList';
  }
  public static getOptionListURL(): string {
    return ApiConfigHelper.getAPIURL() + 'options';
  }

  public static getUploadURL(): string {
    return ApiConfigHelper.getAPIURL() + 'image/upload';
  }
}
