import { Component, OnInit } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-inner-html',
  templateUrl: './inner-html.component.html',
  styleUrls: ['./inner-html.component.css']
})
export class InnerHtmlComponent extends AbstractInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
