import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';

@Component({
    selector: '[appThTable]',
    templateUrl: './th-table.component.html',
    styleUrls: ['./th-table.component.css']
})
export class ThTableComponent implements OnInit {
    @Input() headers;

    @Output() HandlerOrder: EventEmitter<any> = new EventEmitter();
    @Output() HandlerCheckOrDischeckAllSelected: EventEmitter<any> = new EventEmitter();

    @ViewChildren('allSelectorCheckbox') allSelectorCheckbox;


    constructor() { }

    ngOnInit() { }

    ngAfterViewChecked() {
        // debugger;
        // let cont = 0;
        // let dividir = 100;
    
        // for (let i in this.headers) {
        //   if ( this.headers[i]['isShow'] && this.headers[i]['label'] === 'check') {
        //     dividir = dividir - 5;
        //   }
        //   else if ( this.headers[i]['isShow'] && this.headers[i]['label'] === 'action') {
        //     dividir = dividir - 15;
        //   }
        //   else if ( this.headers[i]['isShow'] ) {
        //     cont += 1;
        //   }
        // }
        // const widthForItem = dividir /  cont ;
        // const element = document.querySelectorAll('.table__th');
        // for (let x = 0; x < element.length; x++) {
        //   element[x]['style']['width'] = widthForItem + '%'
        // }
    }

    onOrder($event): void {
        this.HandlerOrder.emit($event);
    }

    checkOrDischeckAllSelected(): void {
        this.HandlerCheckOrDischeckAllSelected.emit();
    }

    getAllSelectorCheckbox(): any {
        return this.allSelectorCheckbox;
    }
}
