import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractInputComponent} from '../abstract-input.component';
import {Observable} from 'rxjs/index';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseHelper} from '../../responses/response.helper';
import {ApiHelper} from '../../session/helpers/api.helper';
import {CodesHelper} from '../../responses/codes.helper';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.css']
})
export class DragDropComponent extends AbstractInputComponent implements OnInit {

  @Output() deleteEmitter: EventEmitter<any> = new EventEmitter();
  @Output() uploadingEmitter: EventEmitter<any> = new EventEmitter();
  @Output() successEmitter: EventEmitter<any> = new EventEmitter();
  @Output() errorEmitter: EventEmitter<any> = new EventEmitter();

  private httpClient: HttpClient;

  public uploading: boolean;
  public index: number;
  public files: any;

  constructor( private handler: HttpBackend,
               private responseHelper: ResponseHelper) {
    super();
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit() {
  }

  uploadFile(event) {
    this.index = 0;
    this.files = event;
    this.onUpload(event[0]);
  }

  onUpload(file: any) {
    this.uploading = true;
    this.uploadingEmitter.emit(this.uploading);
    this.upload(file).subscribe(
      res => {
        this.index++;
        this.uploading = false;
        this.uploadingEmitter.emit(this.uploading);
        this.model.unshift(res['path'] ? res['path'] : res);

        if (this.index < this.files.length) {
          this.onUpload(this.files[this.index]);
        } else{
          this.successEmitter.emit(this.model.length);
        }
      },
      error => {
        if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
          this.index++;
          this.uploading = false;
          this.uploadingEmitter.emit(this.uploading);
          if (this.index < this.files.length) {
            this.onUpload(this.files[this.index]);
          }
        } else {
          this.uploading = false;
          this.uploadingEmitter.emit(this.uploading);
          this.errorEmitter.emit({file: this.files[this.index], model: this.model});
        }
        this.responseHelper.handleError(error);
      }
    );
  }


  upload(file: any): Observable<any> {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${ApiHelper.getToken()}`);
    headers = headers.append('Access-Control-Allow-Credentials', 'true');

    let formData = new FormData();
    formData.append('file', file);

      const params = {
      'file' : file
    }
    return this.httpClient.post(this.structure.url, formData, {headers});
  }

  getFileExtension(url: string) {
    let re = /(?:\.([^.]+))?$/;
    let ext = re.exec(url)[1];
    return ext;
  }
  isImage(url: string) {
    let ext = this.getFileExtension(url).toLowerCase();
    return (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'png');
  }

  onDelete(file_id) {
    this.deleteEmitter.emit(file_id);
  }
  downloadFile(file_id: any) {
    let token = ApiHelper.getToken();
    window.open(this.structure.download_url + file_id + '?token=' + token,'_blank');
  }

  getImage(url: string) {
    return this.structure.image_folder + url;
  }


}
