import { Component, ComponentFactoryResolver, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ResetChangePassword } from '../../../models/reset-change-password';
import { InputFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/input-form.directive';
import { LabelFormDirective } from '../../../../../bloonde-ngx-template/src/forms/directives/label-form.directive';
import { ValidationService } from '../../../../../bloonde-ngx-template/src/forms/services/validation.service';
import { SessionService } from '../../../../../bloonde-ngx-template/src/session/services/session.service';
import { ResponseHelper } from '../../../../../bloonde-ngx-template/src/responses/response.helper';
import { MessageService } from '../../../../../bloonde-ngx-template/src/responses/message.service';
import { AbstractFormComponent } from '../../../../../bloonde-ngx-template/src/forms/abstract-form.component';
import { ResetChangePasswordFormConfiguration } from './reset-change-password-form-configuration';
import { CodesHelper } from '../../../../../bloonde-ngx-template/src/responses/codes.helper';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bl-reset-change-password-form',
  templateUrl: './reset-change-password-form.component.html',
  styleUrls: ['./reset-change-password-form.component.css']
})
export class ResetChangePasswordFormComponent extends AbstractFormComponent implements OnInit {

  public resetChangePassword: ResetChangePassword;
  public token: string;
  public sub: any;

  @ViewChildren(InputFormDirective) inputFormDirectives: QueryList<InputFormDirective>;
  @ViewChildren(LabelFormDirective) labelFormDirective: QueryList<LabelFormDirective>;

  constructor(private validationService: ValidationService,
              private sessionService: SessionService,
              private responseHelper: ResponseHelper,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.structure = ResetChangePasswordFormConfiguration.structure;
  }
  ngOnInit() {
    this.new();
    this.sub = this.route.params.subscribe(params => {
      this.token = params['token']; // (+) converts string 'id' to a number
    });
  }
  public passwordReset(args: any[]): void {
    const validate = this.checkForm();
    this.emitError();
    if (validate) {
      this.getModelService().passwordReset(this.getModel(), this.token).subscribe(
        res => {
          this.resetErrors(); // Reseteamos los errores
          this.new();
          this.success();
          this.getMessageService().showSuccessChangePasswordMessage();
        },
        error => {
          if (error.status == CodesHelper.FAILED_VALIDATOR_CODE) {
            this.serverErrors = error.error;
            this.emitServerError();
          } else {
            this.getResponseHelper().handleError(error);
          }
        }
      );
    }
  }

  public new(): void {
    this.resetChangePassword = new ResetChangePassword();
    this.buildErrorModelAndEmitters();
  }
  public getInputFormDirectives(): QueryList<InputFormDirective> {
    return this.inputFormDirectives;
  }
  public getLabelFormDirectives(): QueryList<LabelFormDirective> {
    return this.labelFormDirective;
  }

  public getModel(): any {
    return this.resetChangePassword;
  }
  public getComponentFactoryResolver(): any {
    return this.componentFactoryResolver;
  }
  public getMessageService(): MessageService {
    return this.messageService;
  }
  public getModelService(): any {
    return this.sessionService;
  }
  public getValidationService(): ValidationService {
    return this.validationService;
  }
  public getResponseHelper(): ResponseHelper {
    return this.responseHelper;
  }
  setModel(model: any): any {
    this.resetChangePassword = model;
  }

}
