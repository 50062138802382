import {Component, Input, Output, OnInit, EventEmitter, ViewChild} from '@angular/core';
import {BodyTableComponent} from "../body-table/body-table.component";
import {ThTableComponent} from "../th-table/th-table.component";

@Component({
  selector: '[appFixedTable]',
  templateUrl: './fixed-table.component.html',
  styleUrls: ['./fixed-table.component.css']
})
export class FixedTableComponent implements OnInit {
  @Input() datas;
  @Input() accordionStatus;
  @Input() headers;
  @Input() pagination;
  @Input() withPagination;

  @Output() HandlerClickTd = new EventEmitter();
  @Output() HandlerClickTr = new EventEmitter();
  @Output() HandlerClickBtnIcon = new EventEmitter();
  @Output() HandlerDoubleClickTr = new EventEmitter();
  @Output() HandlerOrder: EventEmitter<any> = new EventEmitter();
  @Output() HandlerCheckOrDischeckAllSelected: EventEmitter<any> = new EventEmitter();
  @Output() HandlerAddOrRemove: EventEmitter<any> = new EventEmitter();
  @Output() handlerlistPaginate: EventEmitter<any> = new EventEmitter();
  @Output() Edit: EventEmitter<any> = new EventEmitter();
  @Output() Show: EventEmitter<any> = new EventEmitter();
  @Output() Delete: EventEmitter<any> = new EventEmitter();
  @Output() Trash: EventEmitter<any> = new EventEmitter();
  @Output() OtherAction: EventEmitter<any> = new EventEmitter();
  @Output() AccordionStatusChange: EventEmitter<any> = new EventEmitter();

  @ViewChild(BodyTableComponent) bodyTableComponent;
  @ViewChild(ThTableComponent) thTableComponent;


  public extraColumn: any = [];

  constructor() { }

  ngOnInit() { }

  setValueMonth ( valueMonth: any ): void {
    this.extraColumn.push(
      {
        label: valueMonth
      }
    )
  }

  clickTd(id) {
    this.HandlerClickTd.emit(id);
  }
  clickTr(id) {
    this.HandlerClickTr.emit(id);
  }
  clickBtn(data): void {
    this.HandlerClickBtnIcon.emit(data);
  }
  doubleClickTr(e) {
    this.HandlerDoubleClickTr.emit(e);
  }
  onShow(id): void {
    this.Show.emit(id);
  }
  onEdit(id): void {
    this.Edit.emit(id);
  }
  onDelete(id): void {
    this.Delete.emit(id);
  }
  onTrash(id): void {
    this.Trash.emit(id);
  }
  onOtherAction(result: any): void {
    this.OtherAction.emit(result);
  }
  onOrder($event): void {
    this.HandlerOrder.emit($event);
  }
  onCheckOrDischeckAllSelected($event): void {
    this.HandlerCheckOrDischeckAllSelected.emit($event);
  }
  getAllSelectorCheckbox(): any {
    return this.thTableComponent.getAllSelectorCheckbox();
  }
  getSelectorCheckboxes(): any {
    return this.bodyTableComponent.getSelectorCheckboxes();
  }
  onAddOrRemove($event): void {
    this.HandlerAddOrRemove.emit($event);
  }
  listPaginate($event): void {
    this.handlerlistPaginate.emit($event);
  }
  onChangeAccordionStatus($event) {
    this.AccordionStatusChange.emit($event);
  }

}
