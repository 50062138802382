import { OfferSelectComponent } from '../../inputs/offer-select/offer-select.component';
export class PreselectCandidateFormConfiguration {
  public static structure = [
    {
      name: 'offer_id',
      label: 'offer',
      placeholder: 'Select an offer',
      type: OfferSelectComponent,
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    }
  ];
}
