import { Component, OnInit, ViewChild } from '@angular/core';
import { RegistrationFormComponent } from '../../components/forms/registration-form/registration-form.component';
import {RegistrationFormService} from '../../components/forms/registration-form/registration-form.service';

@Component({
  selector: 'bl-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.css']
})
export class RegistrationPageComponent implements OnInit {

  @ViewChild(RegistrationFormComponent) registrationFormComponent;

  public configNavbar: any;

  constructor() {
    this.configNavbar = RegistrationFormService.navbarConfig;
  }

  ngOnInit() {
  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.onRegister();
    }
  }

  onRegister(): void {
    this.registrationFormComponent.registration();
  }

}
