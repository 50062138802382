import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiRoutesHelper } from '../helpers/api-routes.helper';
import { Observable } from 'rxjs/index';
import { HttpClient } from '@angular/common/http';
import { User } from '../../../bloonde-ngx-template/src/session/models/user';

/**
 * Created by Daniel on 14/05/2017.
 */

@Injectable()
export class AccessService {

  @Output() changeProfileEmitter: EventEmitter<any> = new EventEmitter();

  constructor(public _http: HttpClient) {
  }
  privileges: any[];
  privileges_group: any[];
  selectedAccess: any; // Perfil seleccionado

  getPrivileges(): Observable<any> {
    return this._http.get(ApiRoutesHelper.getPrivileges());
  }
  getPrivilegesGroups(): Observable<any> {
    return this._http.get(ApiRoutesHelper.getPrivilegesGroups());
  }
  setPrivileges(privileges: any[]): void {
    this.privileges = privileges;
  }
  setPrivilegesGroups(privileges_groups: any[]): void {
    this.privileges_group = privileges_groups;
  }
  checkProfiles(profiles: any[], user: User, all: boolean = false): boolean {
    for (let i in profiles) {
      let check = this.checkProfile(profiles[i], user, all);
      if (check) {
        return true;
      }
    }
    return false;
  }
  checkProfile(profile: any, user: User, all: boolean = false): boolean {
    if(!all) { // Miramos solamente en el seleccionado
      if (!this.selectedAccess) { // Si no hay perfil seleccionado (por ejemplo cuando refrescamos) vemos si hay un perfil dentro de las cookies y comprobamos si es dle usuario autenticado. Si lo es usamos ese perfil
        let cookies = document.cookie.split(';');
        let cookie_profile_id = null;
        for (let cookie of cookies) {
          let splittedCookie = cookie.split('=');
          if (splittedCookie[0].trim() == 'profile_user_id') {
            cookie_profile_id = splittedCookie[1];
          }
        }
        let index = 0;
        if (cookie_profile_id) {
          for (let i = 0; i < user.access.length; i++) {
            if (user.access[i]['id'] == cookie_profile_id) {
              index = i;
              break;
            }
          }
        }
        this.changeProfile(user.access[index]);
      }
      return this.selectedAccess['profile'] === profile;
    } else { // miramos en todos sus perfiles
      for (let i = 0; i < user.access.length; i++) {
        if (user.access[i]['profile'] == profile) {
          return true;
        }
      }
    }
  }
  checkPrivilegeGroup(privilege_group: any, user: User): boolean {
    return true;
  }
  checkPrivilege(privilege: any, user: User): boolean {
    return true;
  }
  changeProfile(access: any) {
    this.selectedAccess = access;
    if (access) { // En el logout access es igual a null y entonces no queremos hacer emit para que no redirecciones
      this.changeProfileEmitter.emit();
    }
  }

  getSelectedAccessId(): any {
    if (this.selectedAccess) {
      return this.selectedAccess['id']
    }
    return '';
  }
}
