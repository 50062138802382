import { FileComponent } from 'bloonde-ngx-template/src/inputs/file/file.component';
import { ApiRoutesHelper } from '../../../helpers/api-routes.helper';
import { LevelSelectComponent } from '../../inputs/level-select/level-select.component';
import {LanguageSelectComponent} from '../../inputs/language-select/language-select.component';
export class CVLanguageFormConfiguration {
  public static structure = [
    {
      name: 'language_id',
      label: 'language_id',
      placeholder: 'Select a language',
      type: LanguageSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
    },
    {
      name: 'level',
      label: 'level',
      placeholder: 'Select a level',
      type: LevelSelectComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
        'required'
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      model: 'offer',
    },
    {
      name: 'observations',
      label: 'observations',
      placeholder: null,
      type: 'textarea',
      default_value: null,
      col: 'col-12 col-md-12',
      style: 'container__input',
      rules: [],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      }
    }
    ,
    {
      name: 'document',
      label: 'document',
      placeholder: null,
      type: FileComponent,
      default_value: null,
      col: 'col-12 col-md-6',
      style: 'container__input',
      rules: [
      ],
      label_classes: null,
      input_classes: null,
      config: {
        hasLabel: true,
        hasInput: true,
        hasHelp: false,
      },
      url: ApiRoutesHelper.getUploadURL(),
      download_url: ApiRoutesHelper.FILE_FOLDER
    }
  ];
}
